

<template>
  <div class="companyQuestionTake">
    <h3 class="tit">代理问题记录</h3>
    <div class="search">
      <div class="left">
        <div class="item">
          <span>处理状态</span>
          <el-select v-model="screenForm.yes_answer" clearable placeholder="按处理状态筛选" size="mini">
            <el-option v-for="item in JDZToptions" :key="item.status" :label="item.table" :value="item.status">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span>查询代理</span>
          <el-select v-model="firmValue" placeholder="选择查询代理" size="mini" filterable @change="getFirmVal">
            <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span>创建日期</span>
          <el-date-picker v-model="createTimeDate" type="datetimerange" range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"  size="mini">
          </el-date-picker>
        </div>
      </div>
      <div class="right">
        <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline" @click.stop="addNiche">新增问题
        </el-button>
        <el-button size="mini" type="primary" icon="el-icon-search" @click.stop="handleSearch">查找
        </el-button>
        <el-button class="reset" size="mini" type="primary" icon="el-icon-refresh" @click.stop="reset">重置
        </el-button>
      </div>
    </div>
    <hr style="margin: 10px 0;">
    <main>
      <div class="company-box" v-if="tableData">
        <div class="company-list" style="margin-bottom: 20px" v-for="v in tableData" :key="v.id">
          <div class="company-title" style="
                font-size: 20px;
                background-color: #dbdfe2;
                padding: 16px 10px;
                box-sizing: border-box;
                border-radius: 5px;
              ">
            {{ v.userName }}
            <el-tag type="info" style="margin-left: 20px">创建时间
              {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", v.created_time) }}</el-tag>
            <el-button type="success" size="mini" v-if="!(role == '客服' && v.answer)" style="margin-left: 10px"
              @click="updataCompany(v)">{{ v.answer ? "修改记录" : "添加记录" }}</el-button>
            <div class="company-content" style="font-size: 18px; font-weight: bold; margin-top: 10px">
              <div style="width: 100px">问 题：</div>
              <div style="margin-top: 10px; text-indent: 60px; line-height: 30px">
                {{ v.question || "空" }}
              </div>
            </div>
          </div>
          <div style="margin: 10px; font-size: 16px">
            <p>
              答案记录：
              <!-- <el-tag type="info" style="margin-left: 10px">记录时间
                {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", v.answer_time) }}</el-tag> -->
            </p>
            <p style="
                  margin-top: 10px;
                  text-indent: 60px;
                  font-weight: bold;
                  line-height: 30px;
                ">
              {{ v.answer || "暂未处理" }}
            </p>
          </div>
          <hr />
        </div>
      </div>
      <el-empty v-else description="暂无问题记录"></el-empty>
      <!--分页  -->
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex" :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange">
        </el-pagination>
      </div>
    </main>
    <!-- <div class="container">
      <el-table :data="form_custom" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column  width="70%" prop="id" label="id" ></el-table-column>
        <el-table-column  prop="name" label="字段名" ></el-table-column>

        <el-table-column  label="操作" width="300" >
          <template #default="scope">
            <el-button @click="getList_customData(scope.row)">查询数据</el-button>
            <el-button type="warning" @click="update_custom_open(scope)">修改</el-button>
            <el-button type="danger" @click="del_custom(scope)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex" :page-size="pageSize"
          :total="pageTotal" @current-change="handlePageChange"></el-pagination>
      </div>
    </div> -->

    <!-- 添加字段弹出框 -->
    <el-dialog title="记录问题" v-model="add_custom_flag">
      <el-form ref="dataAddForm" :model="form" label-width="100px">
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="提问的代理">
              <el-select v-model="form.userId" placeholder="选择提出问题的代理" size="small" filterable @change="getFirmVal"
                :disabled="setText == '禁用'">
                <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item :label="setText == '禁用' ? '答案记录' : '问题的内容'">
          <el-input type="textarea" v-model="form.question" maxlength="1000" show-word-limit
            :autosize="{ minRows: 4, maxRows: 10 }" :placeholder="setText == '禁用' ? '输入答案记录' : '输入问题内容'"
            autosize></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="add_custom_flag = false">取 消</el-button>
          <el-button type="primary" @click="add_custom">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="update_custom_falg" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
        <el-form-item label="自定义名" prop="bindPhone">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="update_custom_falg = false">取 消</el-button>
          <el-button type="primary" @click="update_custom()">确 定</el-button>
        </span>
      </template>
    </el-dialog>


    <!-- 企业数据 弹出框 -->
    <el-dialog title="对应企业数据" v-model="getList_customData_uuid_falg" width="70%">

      <div style="display: flex;width: 100%;justify-content: space-between">

        <div style="display: flex">
          自定义名
          <el-input v-model="form_data.customData"></el-input>
          <el-button type="primary" @click="add_customData()">添 加</el-button>
        </div>

        <div>
          <el-button type="primary" @click="customData_QieHuan(2)">切换--数据</el-button>
        </div>
      </div>

      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @selection-change="handleSelectionChange">
        <el-table-column  label="字段名" width="300" >
          <template #default="scope">
            <P>{{ form.name }}</P>
          </template>
        </el-table-column>
        <el-table-column  prop="id" label="企业id" ></el-table-column>
        <el-table-column  prop="userId" label="代理id" ></el-table-column>
        <el-table-column  prop="name" label="企业名称" ></el-table-column>
        <el-table-column  prop="appid" label="appid" ></el-table-column>

        <!--<el-table-column  label="操作" width="300" >-->
        <!--<template #default="scope">-->
        <!--<el-button type="warning" @click="update_customData_open(scope)">修改</el-button>-->
        <!--<el-button type="danger" @click="del_customData(scope)">删除</el-button>-->
        <!--</template>-->
        <!--</el-table-column>-->

      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <div class="pagination">
            <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
              :page-size="pageSize" :total="pageTotal" :page-sizes="[10, 20, 50, 100, 200]"
              @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
          </div>
          <el-button @click="getList_customData_uuid_falg = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>


    <!--数据 编辑弹出框 -->
    <el-dialog title="数据编辑" v-model="update_customData_falg" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
        <el-form-item label="自定义数据">
          <el-input v-model="form_data.customData"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="update_customData_falg = false">取 消</el-button>
          <el-button type="primary" @click="update_customData()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
} from "@/api/company_renew";

import { userList, userFindlist, userOperatorsFindlist } from "@/api/user";

export default {
  name: "centerNum",
  data() {
    return {
      setText: null,
      firmValue: null,
      createTimeDate: null,
      screenForm: {}, // 筛选表单
      firmList: [],
      JDZToptions: [
        {
          status: 1,
          table: "已处理",
        },
        {
          status: 2,
          table: "未处理",
        },
      ],
      form: {},
      form_data: {},
      form_custom: [],
      name: null,
      add_custom_flag: false, //添加弹出框
      update_custom_falg: false, //编辑弹出框
      getList_customData_falg: false, //查询数据弹出框
      update_customData_falg: false, //查询数据弹出框
      getList_customData_QieHuan: 1, //1 查询原始 2 查询企业数据
      getList_customData_uuid_falg: false, //查询数据  企业弹出框
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量

    };
  },
  created() {
    this.$nextTick(async () => {
      this.role = localStorage.getItem("roleName");
      await this.getList();
      await this.operatorsList();
    })
  },
  //切换界面就执行方法
  activated() {
    // this.getList()
  },
  watch: {},
  methods: {
    addNiche() {
      this.setText = "新增";
      this.add_custom_flag = true;

    },
    // 查代理
    async operatorsList() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res
      if (localStorage.getItem("realname") == 'admin') {
        res = await userFindlist(params);
      } else {
        res = await userOperatorsFindlist(params);
      }

       // console.log("res", res.data);
      this.firmList = res.data;
    },
    //切换
    customData_QieHuan(type) {
      if (1 == type) {
        this.getList_customData_QieHuan = 2
      } else {
        this.getList_customData_QieHuan = 1
      }
      this.getList_customData(this.form);
    },
    //修改 字段数据
    update_customData() {
      let params = {
        id: this.form_data.id,
        userId: this.form_data.userId,
        customId: this.form_data.customId,
        value: this.form_data.customData,
      };
      axios
        .post("api/uuid-custom/update-data", params)
        .then(async (res) => {
          if (res.data.statusCode == "00000") {
            await this.getList_customData(this.form);
            this.form_data = [];
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.update_customData_falg = false;
        });
    },
    //修改 字段数据
    update_customData_open(row) {

      this.form_data = []
      this.form_data = row.row
      this.update_customData_falg = true;
    },
    //删除 字段数据
    del_customData(row) {
      let params = {
        id: row.row.id,
      };
      axios
        .post("api/uuid-custom/del-data", params)
        .then(async (res) => {
          if (res.data.statusCode == "00000") {
            await this.getList_customData(this.form);
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    //添加 字段数据
    add_customData() {
      let params = {
        customId: this.form.id,
        value: this.form_data.customData,
      };
      axios
        .post("api/uuid-custom/add-data", params)
        .then(async (res) => {
          if (res.data.statusCode == "00000") {
            await this.getList_customData(this.form);
            this.form_data = [];
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          // this.getList_customData_falg = false;
        });
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;

      this.getList_customData(this.form);
    },
    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getList_customData(this.form);
    },
    handleSearch() {
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //查询 所有字段名
    async getList() {
      let params = {
        page: this.page,
        size: this.size,
        ...this.screenForm,
      };
      if (this.createTimeDate) {
        params.sta = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createTimeDate[0]
        );
        params.end = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createTimeDate[1]
        );
      }
      if (this.firmValue) {
        params.userId = this.firmValue;
      }
      // let res = await select(params);
      let res = await customerQuestionList(params);
       // console.log("返回参数", res);
      this.pageTotal = res.total;
      this.tableData = res.data;
      // this.tableLoad = false;
      // statusCode  合计金额
      // this.$message.success("已刷新数据");
    },
    //添加 字段名
    async add_custom() {
      const loading = await this.$loading({
        lock: true,
        text: "提交中,请稍等...",
      });

      let params = {
        ...this.form,
      };
      let res;
      if (this.setText == "禁用") {
        params = { id: this.form.name, answer: this.form.question };
        res = await customerQuestionUpdate(params);
      } else {
        res = await customerQuestionAdd(params);
      }
      //  // console.log("this.form", res);

      if (res.statusCode == "00000") {
        this.$message.success(res.message);
        loading.close();
        this.closeDialog();
      } else {
        this.$message.error(res.message);
        loading.close();
      }
    },
    // 关闭弹框
    closeDialog() {
      this.form = {};
      this.add_custom_flag = false;
      this.getList();
    },
    updataCompany(value) {
      this.setText = "禁用";
      this.add_custom_flag = true;
      this.form = {
        userId: value.userName,
        name: value.id,
        question: value.answer,
      };
       // console.log("value", this.form);
      // question
    },
    //编辑 字段名弹出框
    update_custom_open(row) {
      this.form = row.row;
      this.update_custom_falg = true;
    },
    //修改 字段名
    update_custom() {
      let params = {
        id: this.form.id,
        name: this.form.name,
        userId: this.form.userId,
      };
      axios
        .post("api/uuid-custom/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.form = [];
          this.update_custom_falg = false;
        });
    },
    //删除 字段名
    del_custom(row) {
      let params = {
        id: row.row.id
      };
      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post("api/uuid-custom/del", params)
          .then((res) => {
            if (res.data.statusCode == "00000") {
              this.getList();
              this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
        this.getList();
      });
    },
    //查询字段数据
    getList_customData(row) {
      this.form = []
      this.form = row
       // console.log("----------", this.getList_customData_QieHuan)
      let params = {
        page: this.page,
        size: this.size,
        customId: row.id,
      };
      if (1 == this.getList_customData_QieHuan) {
        axios
          .post("api/uuid-custom/find-CustomData", params)
          .then((res) => {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;

            this.getList_customData_uuid_falg = false
            this.getList_customData_falg = true
          })
          .catch((err) => {
             // console.log(err);
          });
      } else {
        axios
          .post("api/uuid-custom/find-uuidCustomData", params)
          .then((res) => {
            this.tableData = res.data.data;
             // console.log("this.tableData", this.tableData.length != 0);
            this.pageTotal = res.data.total;

            this.getList_customData_uuid_falg = false
            this.getList_customData_uuid_falg = true
          })
          .catch((err) => {
             // console.log(err);
          });
      }

    },

    //刷新按钮
    reset() {
      this.screenForm = {}
      this.firmValue = null
      this.createTimeDate = null
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.companyQuestionTake {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.tit {
  font-weight: normal;
  font-size: 32px;
  /* color: #333333; */
}

.el-select {
  width: 100%;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left {
  display: flex;
  align-items: center;
}

.item {
  width: 26%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  &>span {
    white-space: nowrap;
    width: 100px;
    margin-right: 8px;
  }
}

.left span {
  text-align: center;
  width: 100px;
}

.searchBtn {
  margin-left: 30px;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
 /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
    font-size: 13px  !important;
  } */
</style>
