<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="企业申请"></el-tab-pane>
      </el-tabs>

      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">状态</span>
            <el-select v-model="value" placeholder="请选择状态" size="mini">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <div class="leftItem" v-if="realname == 'admin'">
            <span class="lab_tit">代理编号</span>
            <el-input
              v-model="form.userId"
              placeholder="请输入代理编号"
              size="mini"
            >
            </el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">企业名称</span>
            <el-input
              v-model="form.companyName"
              placeholder="请输入企业名称"
              size="mini"
            >
            </el-input>
          </div>
          <div class="leftItem" v-if="realname == 'admin'">
            <span class="lab_tit">实名认证</span>
            <el-select v-model="talk" placeholder="请选择" size="mini">
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="leftItem" v-if="realname == 'admin'" style="width: 242px">
            <span class="lab_tit">选择时间</span>
            <el-date-picker
              v-model="timestamp"
              type="datetimerange"
              range-separator="-"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>

          <!-- <div style="min-width: 190px;flex:1;flex-direction: row-reverse;display: flex;">
                        <div class="transition-box" style="margin-left: 10px">
                            <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini">查找</el-button>
                            <el-button type="primary" icon="el-icon-refresh" @click="reset()" size="mini">重置</el-button>
                        </div>
                    </div> -->
        </div>

        <div class="right">
          <el-button type="success" @click="openWeChatDialog()" size="mini"
            >微信绑定公众号
          </el-button>
          <el-button
            round
            type="primary"
            icon="el-icon-circle-plus-outline"
            @click="chooes"
            size="mini"
            :disabled="searchLoading"
            >线路企业申请
          </el-button>
          <el-button
            round
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            >查找</el-button
          >
          <el-button
            round
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="mini"
            >重置</el-button
          >
        </div>
      </div>

      <!-- <div class="search">
            <div class="left">
                <span v-if="realname == 'admin'">代理编号</span>
                <el-input v-model="form.userId" placeholder="请输入代理编号" class="handle-input mr10" v-if="realname == 'admin'"
                    v-on:keyup.enter="handleSearch"></el-input>
                <span>企业名称</span>
                <el-input v-model="form.companyName" placeholder="请输入企业名称" class="handle-input mr10"
                    v-on:keyup.enter="handleSearch"></el-input>
                <span>状态</span>
                <el-select v-model="value" placeholder="请选择状态" style="width: 400px">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <span class="demonstration" v-if="realname == 'admin'">选择时间</span>
                <el-date-picker v-if="realname == 'admin'" v-model="timestamp" type="datetimerange"
                    :picker-options="pickerOptions" range-separator="至" :clearable="false" start-placeholder="开始日期"
                    end-placeholder="结束日期" align="right" style="width: 70%">
                </el-date-picker>
            </div>
            <div class="right">
                <el-button type="primary" icon="el-icon-search" @click="handleSearch" class="searchBtn"
                    :disabled="searchLoading">查找
                </el-button>
                <el-button class="reset" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
            </div>
        </div> -->
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="companyName"
              width="240"
              label="企业名称"
            ></el-table-column>
            <el-table-column
              prop="userId"
              v-if="realname == 'admin'"
              width="100"
              label="代理编号"
            ></el-table-column>
            <el-table-column
              prop="changeTime"
              width="180"
              label="最近一次操作时间"
            ></el-table-column>
            <el-table-column prop="status" label="状态" width="100">
              <template #default="scope">
                <span
                  v-if="scope.row.status == 0"
                  style="color: #97a8be; font-size: 15px"
                  >未提交</span
                >
                <span
                  v-if="scope.row.status == 1"
                  style="color: darkorange; font-size: 15px"
                  >待审核</span
                >
                <span
                  v-if="scope.row.status == 2"
                  style="color: red; font-size: 15px"
                  >未通过</span
                >
                <span
                  v-if="scope.row.status == 3"
                  style="color: chartreuse; font-size: 15px"
                  >已通过</span
                >
                <span
                  v-if="scope.row.status == 4"
                  style="color: #409eff; font-size: 15px"
                  >报备中</span
                >
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column
              label="操作"
              width="300"
              align="center"
              fixed="right"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="handleClick(scope)"
                  size="mini"
                  v-if="scope.row.status != 3 && scope.row.status != 4"
                  >补充资料
                </el-button>
                <el-button type="primary" @click="details(scope)" size="mini"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!--下面这段代码有问题  -->
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 企业申请弹出框 -->
    <el-dialog title="企业申请" v-model="applyShow" width="60%">
      <el-form
        ref="dataAddForm"
        :model="forms"
        :rules="addRules"
        label-width="130px"
      >
        <!-- <el-form-item label="代理编号" prop="userId">
                  <el-input v-model="forms.userId" placeholder="请输入代理编号"></el-input>
                </el-form-item> -->
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            v-model="forms.companyName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalName">
          <el-input
            v-model="forms.legalName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalCard">
          <el-input
            v-model="forms.legalCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人电话号" prop="legalPhone">
          <el-input
            v-model="forms.legalPhone"
            placeholder="请输入法人电话号"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照号" prop="businessNumber">
          <el-input
            v-model="forms.businessNumber"
            placeholder="请输入营业执照号"
          ></el-input>
        </el-form-item>
        <el-form-item label="话术" prop="talk">
          <el-input
            v-model="forms.talk"
            type="textarea"
            maxlength="500"
            placeholder="请输入话术，字数限制:500字"
          ></el-input>
        </el-form-item>
        <el-form-item label="报备通道（线路）" prop="channel">
          <el-input v-model="forms.channel" maxlength="500"></el-input>
        </el-form-item>
      </el-form>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >营业执照复印件（只可上传一张）(盖公章)</span
      >
      <div style="display: flex; justify-content: center">
        <el-upload
          style="margin: 20px"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px">
        法人身份证复印件(盖公章)
        <!-- <h5 style="margin-left: 115px">
                  联通1,移动4需要添加手持身份证照片（正反面）(法人无法提供就提供经办人的手持身份证照片)
                </h5>

                <h5 style="margin-left: 115px">电信额外材料请联系客服</h5>-->
      </span>
      <div style="display: flex; justify-content: center">
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          limit="1"
          list-type="picture-card"
          :on-success="handleSuccess1"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl1" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess4"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl4" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          limit="1"
          list-type="picture-card"
          :on-success="handleSuccess6"
          :on-preview="handlePictureCardPreviewhandlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl6" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red"></span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >经办人身份证复印件（盖公章）/经办人手持身份证照片</span
      >
      <div style="display: flex; justify-content: center">
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          list-type="picture-card"
          limit="1"
          :headers="myHeaders"
          :on-success="handleSuccess2"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl2" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess5"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl5" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess7"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl7" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >公司报备话术模板证复印件(盖公章)</span
      >
      <div style="display: flex; justify-content: center">
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess3"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl3" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess8"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl8" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyShow = false">取 消</el-button>
          <el-button type="primary" @click="applySubmit('draft')"
            >暂 存</el-button
          >
          <el-button type="primary" @click="applySubmit('add')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 企业申请弹出框 -->
    <el-dialog
      title="线路企业申请"
      v-model="applyShowD"
      width="80%"
      hidden="100%"
      @close="cleatPar"
    >
      <el-form
        ref="dataAddForm"
        :model="forms"
        :rules="addRules"
        label-width="130px"
      >
        <!-- <el-form-item label="代理编号" prop="userId">
                  <el-input v-model="forms.userId" placeholder="请输入代理编号"></el-input>
                </el-form-item> -->
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            v-model="forms.companyName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalName">
          <el-input
            v-model="forms.legalName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalCard">
          <el-input
            v-model="forms.legalCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人电话号" prop="legalPhone">
          <el-input
            v-model="forms.legalPhone"
            placeholder="请输入法人电话号"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照号" prop="businessNumber">
          <el-input
            v-model="forms.businessNumber"
            placeholder="请输入营业执照号"
          ></el-input>
        </el-form-item>
        <el-form-item label="话术" prop="talk">
          <el-input
            v-model="forms.talk"
            type="textarea"
            maxlength="500"
            placeholder="请输入话术，字数限制:500字"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="报网站/公众号/小程序链接"
          prop="channel"
          style="font-size: 10%"
        >
          <el-input v-model="forms.channel" maxlength="500"></el-input>
        </el-form-item>
      </el-form>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >营业执照复印件(盖公章)</span
      >
      <div style="display: flex; justify-content: center">
        <el-upload
          ref="uploadNo1"
          style="margin: 20px"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px">
        法人身份证复印件(盖公章)
        <!-- <h5 style="margin-left: 115px">
                  联通1,移动4需要添加手持身份证照片（正反面）(法人无法提供就提供经办人的手持身份证照片)
                </h5>

                <h5 style="margin-left: 115px">电信额外材料请联系客服</h5>-->
      </span>
      <div style="display: flex; justify-content: center">
        <el-upload
          ref="uploadNo2"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          limit="1"
          list-type="picture-card"
          :on-success="handleSuccess1"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl1" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          ref="uploadNo3"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess4"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl4" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          ref="uploadNo4"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          limit="1"
          list-type="picture-card"
          :on-success="handleSuccess6"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl6" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red"></span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >经办人身份证复印件（盖公章）</span
      >
      <div style="display: flex; justify-content: center">
        <el-upload
          ref="uploadNo5"
          style="margin: 20px"
          :action="doUpload"
          list-type="picture-card"
          limit="1"
          :headers="myHeaders"
          :on-success="handleSuccess2"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl2" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          ref="uploadNo6"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess5"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl5" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >公司报备话术模板证复印件(盖公章)</span
      >

      <div style="display: flex; justify-content: center">
        <el-upload
          ref="uploadNo8"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess3"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl3" alt="" />
          </el-dialog>
        </el-upload>
        <el-upload
          ref="uploadNo7"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess7"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
          <el-dialog v-model="dialogVisible">
            <img width="100%" :src="dialogImageUrl7" alt="" />
          </el-dialog>
        </el-upload>
      </div>
      <!--<span style="font-size: 25px; "> <b>↓</b> 压缩全部文件(rar)</span>-->
      <!--<div>-->
      <!--<a href="http://syshb.cddmt.cn:9005/cai_liao.rar"-->
      <!--target="_blank" style="font-size: 70px">模板下载</a>-->
      <!--<div style="background: #d4d8ff; font-size: 25px; font-weight: bold;">1、业务授权承诺书，号码使用授权书，用户入网承诺书均加盖公章和多页骑缝章</div>-->
      <!--<div style="background: #8489ff; font-size: 25px; font-weight: bold;">2、被叫来源说明及话术word文档</div>-->
      <!--<div style="background: #3f68ff; font-size: 25px; font-weight: bold;">3、号码表格(报备号码请写在excel表格里面)</div>-->
      <!--</div>-->
      <div
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border: 1px dashed rgb(199 216 214);
          margin-left: 35%;
          width: 28%;
          height: 28%;
        "
      >
        <a
          href="http://syshb.cddmt.cn:9005/cai_liao.rar"
          target="_blank"
          style="font-size: 170%; color: rgb(199 216 214)"
        >
          👉 点击模板下载 👈
        </a>
        <el-upload
          ref="uploadPreview"
          style="margin: 20px"
          :action="doUpload"
          :headers="myHeaders"
          list-type="picture-card"
          limit="1"
          :on-success="handleSuccess8"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType2"
        >
          <i class="el-icon-plus"></i>
          <div class="el-upload__text">上传附件</div>
          <!-- <el-dialog v-model="dialogVisible">
                      <img width="100%"
                           :src="dialogImageUrl8"
                           alt="">

                    </el-dialog> -->
        </el-upload>
        <div
          v-if="dataUrl8 != null && dataUrl8 != '' && dataUrl8 != 'null'"
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-link type="primary" :href="dataUrl8"
            >{{ dataUrl8 }} 附件下载</el-link
          >
          <i
            class="el-icon-circle-close"
            style="color: red; margin-left: 5px; cursor: pointer"
            @click="beforeAvatarUpload"
          ></i>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="applyShowD = false">取 消</el-button>
          <el-button type="primary" @click="applySubmit('draft')"
            >暂 存</el-button
          >
          <el-button type="primary" @click="applySubmit('add')"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 补充资料弹出框 -->
    <el-dialog title="补充资料" v-model="editShow" width="60%">
      <el-form
        ref="dataAddForm"
        :model="forms"
        :rules="addRules"
        label-width="130px"
      >
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            v-model="forms.companyName"
            placeholder="请输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalName">
          <el-input
            v-model="forms.legalName"
            placeholder="请输入法人姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalCard">
          <el-input
            v-model="forms.legalCard"
            placeholder="请输入法人身份证号"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人电话号" prop="legalPhone">
          <el-input
            v-model="forms.legalPhone"
            placeholder="请输入法人电话号"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照号" prop="businessNumber">
          <el-input
            v-model="forms.businessNumber"
            placeholder="请输入营业执照号"
          ></el-input>
        </el-form-item>
        <el-form-item label="话术" prop="talk">
          <el-input
            v-model="forms.talk"
            type="textarea"
            maxlength="500"
            placeholder="请输入话术，字数限制:500字"
          ></el-input>
        </el-form-item>
        <el-form-item label="报备通道（线路）" prop="channel">
          <el-input v-model="forms.channel" maxlength="500"></el-input>
        </el-form-item>
      </el-form>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >营业执照复印件(盖公章)</span
      >
      <!-- 第一排第一张 -->
      <div style="display: flex; justify-content: center">
        <el-upload
          style="margin: 20px"
          :file-list="fileList"
          :on-change="imgPreview"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl" alt="" />
        </el-dialog>
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px">
        法人身份证复印件(盖公章)
        <h5 style="margin-left: 115px">
          联通1,移动4需要添加手持身份证照片（正反面）
        </h5>
        <h5 style="margin-left: 115px">电信额外材料请联系客服</h5>
      </span>
      <div style="display: flex; justify-content: center">
        <!-- 第二排第一张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList1"
          :on-change="imgPreview1"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess1"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl1" alt="" />
        </el-dialog>
        <!-- 第二排第二张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList4"
          :on-change="imgPreview4"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess4"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl4" alt="" />
        </el-dialog>
        <!-- 第二排第三张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList6"
          :on-change="imgPreview6"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess6"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl6" alt="" />
        </el-dialog>
      </div>
      <span></span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >经办人身份证复印件（盖公章）</span
      >
      <div style="display: flex; justify-content: center">
        <!-- 第三排第一张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList2"
          :on-change="imgPreview2"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess2"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl2" alt="" />
        </el-dialog>
        <!-- 第三排第二张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList5"
          :on-change="imgPreview5"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess5"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl5" alt="" />
        </el-dialog>
        <!-- 第三排第三张 -->
      </div>
      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >公司报备话术模板证复印件(盖公章)</span
      >
      <div style="display: flex; justify-content: center">
        <!-- 第四排第一张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList3"
          :on-change="imgPreview3"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess3"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl3" alt="" />
        </el-dialog>
        <!-- 第四排第二张 -->
        <el-upload
          style="margin: 20px"
          :file-list="fileList7"
          :on-change="imgPreview7"
          :headers="myHeaders"
          :action="doUpload"
          limit="1"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-success="handleSuccess7"
          :on-remove="handleRemove"
          :before-upload="checkFileType"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog v-model="dialogVisible" class="pic">
          <img width="500" height="500" :src="dataUrl7" alt="" />
        </el-dialog>
        <!--&lt;!&ndash; :on-change="imgPreview8" &ndash;&gt;-->
        <!--<el-upload style="margin: 20px"-->
        <!--:file-list="fileList8"-->
        <!--:headers="myHeaders"-->
        <!--:action="doUpload"-->
        <!--limit="1"-->
        <!--list-type="picture-card"-->
        <!--:on-preview="handlePictureCardPreview"-->
        <!--:on-success="handleSuccess8"-->
        <!--:on-remove="handleRemove">-->
        <!--<i class="el-icon-plus"></i>-->
        <!--</el-upload>-->

        <!--<el-dialog v-model="dialogVisible"-->
        <!--class="pic">-->
        <!--<img width="500"-->
        <!--height="500"-->
        <!--:src="dataUrl"-->
        <!--alt="" />-->
        <!--</el-dialog>-->
        <!--<div>-->
        <!--<el-link type="primary"-->
        <!--:href=dataUrl8-->
        <!--v-if="dataUrl8 != null && dataUrl8 != '' && dataUrl8 != 'null'">下载</el-link>-->
        <!--</div>-->
      </div>
      <div
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border: 1px dashed rgb(199 216 214);
          margin-left: 35%;
          width: 28%;
          height: 28%;
        "
      >
        <a
          href="http://syshb.cddmt.cn:9005/cai_liao.rar"
          target="_blank"
          style="font-size: 170%; color: rgb(199 216 214)"
        >
          👉 点击模板下载 👈
        </a>
        <el-upload
          style="margin: 20px"
          :action="doUpload"
          ref="uploadPreview"
          :headers="myHeaders"
          list-type="text"
          limit="1"
          :on-success="handleSuccess8"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :before-upload="checkFileType2"
        >
          <i class="el-icon-plus"></i>
          <div class="el-upload__text">上传附件</div>
          <!-- <el-dialog v-model="dialogVisible">
                            <img width="100%"
                                 :src="dialogImageUrl8"
                                 alt="">

                          </el-dialog> -->
        </el-upload>
        <div
          v-if="dataUrl8 != null && dataUrl8 != '' && dataUrl8 != 'null'"
          style="display: flex; align-items: center; justify-content: center"
        >
          <el-link type="primary" :href="dataUrl8"
            >{{ dataUrl8 }} 附件下载</el-link
          >
          <i
            class="el-icon-circle-close"
            style="color: red; margin-left: 5px; cursor: pointer"
            @click="beforeAvatarUpload"
          ></i>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editShow = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit('draft')"
            >暂 存</el-button
          >
          <el-button type="primary" @click="editSubmit('add')">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 详情弹出框 -->
    <el-dialog title="申请详情:" v-model="detailsShow" width="60%">
      <el-form
        ref="dataAddForm"
        :model="forms"
        :rules="addRules"
        label-width="130px"
        style="text-align: center"
      >
        <!-- <el-form-item label="代理编号" prop="userId">
                  <el-input v-model="forms.userId" placeholder="请输入代理编号" disabled="false"></el-input>
                </el-form-item> -->
        <el-form-item label="企业名称" prop="companyName">
          <el-input
            v-model="forms.companyName"
            placeholder="请输入企业名称"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalName">
          <el-input
            v-model="forms.legalName"
            placeholder="请输入法人姓名"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号" prop="legalCard">
          <el-input
            v-model="forms.legalCard"
            placeholder="请输入法人身份证号"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="法人电话号" prop="legalPhone">
          <el-input
            v-model="forms.legalPhone"
            placeholder="请输入法人电话号"
            disabled="false"
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照代码" prop="businessNumber">
          <el-input
            v-model="forms.businessNumber"
            placeholder="请输入营业执照号"
            disabled="false"
          ></el-input>
        </el-form-item>

        <el-form-item label="话术编辑" prop="talk">
          <el-input
            class="talkClass"
            v-model="forms.talk"
            type="textarea"
            maxlength="500"
            placeholder="请输入话术，字数限制:500字"
            disabled="false"
          ></el-input>
        </el-form-item>

        <el-form-item label="报备通道（线路）" prop="channel">
          <el-input
            v-model="forms.channel"
            maxlength="500"
            disabled="false"
          ></el-input>
        </el-form-item>
      </el-form>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >营业执照复印件(盖公章)</span
      >

      <div style="display: flex; justify-content: center">
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
        <!-- <img :src="dataUrl" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;"> -->
      </div>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px">
        法人身份证复印件(盖公章)
        <h5 style="margin-left: 115px">
          联通1,移动4需要添加手持身份证照片（正反面）
        </h5>
        <h5 style="margin-left: 115px">电信额外材料请联系客服</h5>
      </span>
      <div style="display: flex; justify-content: center">
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl1"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl4"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>

        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl6"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
        <!-- <img :src="dataUrl1" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;">
                <img :src="dataUrl4" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;"> -->
      </div>
      <span></span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >经办人身份证复印件（盖公章）</span
      >
      <div style="display: flex; justify-content: center">
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl2"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl5"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>

        <!-- <img :src="dataUrl2" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;">
                <img :src="dataUrl5" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;"> -->
      </div>

      <span style="font-weight: bold; font-size: 18px; color: red">*</span>
      <span style="margin-left: 2px; font-weight: bold; font-size: 18px"
        >公司报备话术模板证复印件(盖公章)</span
      >

      <div style="display: flex; justify-content: center">
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl3"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
        <el-image
          style="width: 200px; margin: 20px; height: 200px; cursor: pointer"
          :src="dataUrl7"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              暂未上传
            </div>
          </template>
        </el-image>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border: 1px dashed rgb(199 216 214);
          margin-left: 35%;
          width: 28%;
          height: 28%;
        "
      >
        <a
          href="http://syshb.cddmt.cn:9005/cai_liao.rar"
          target="_blank"
          style="font-size: 170%; color: rgb(199 216 214)"
        >
          👉 点击模板下载 👈
        </a>
        <el-image
          style="
            width: 200px;
            height: 150px;
            margin-top: 25%;
            cursor: pointer;
            flex-direction: column;
          "
          :src="dataUrl8"
          :preview-src-list="srcList"
        >
          <template #error>
            <div class="el-image_error" style="text-align: center">
              <div
                v-if="dataUrl8 != null && dataUrl8 != '' && dataUrl8 != 'null'"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <el-link type="primary" :href="dataUrl8"
                  >{{ dataUrl8 }} 附件下载</el-link
                >
              </div>
            </div>
          </template>
        </el-image>
      </div>

      <!--<div style="display: flex; justify-content: center">-->

      <!--<el-image style="width: 200px; margin: 20px; height: 200px; cursor: pointer" :src="dataUrl8"-->
      <!--:preview-src-list="srcList">-->
      <!--<template #error>-->
      <!--<div class="el-image_error" style="text-align: center">-->
      <!--暂未上传-->
      <!--</div>-->
      <!--</template>-->
      <!--</el-image>-->
      <!--&lt;!&ndash; <img :src="dataUrl3" alt="暂未上传" style="margin:20px;height: 200px;width: 200px;"> &ndash;&gt;-->
      <!--<div>-->
      <!--<el-link type="primary" :href=dataUrl8-->
      <!--v-if="dataUrl8 != null && dataUrl8 != '' && dataUrl8 != 'null'">下载-->
      <!--</el-link>-->
      <!--</div>-->
      <!--</div>-->

      <template #footer>
        <span class="dialog-footer">
          <el-button
            type="danger"
            icon="el-icon-close"
            circle
            @click="noPass"
            v-if="realname == 'admin'"
            :disabled="noPassLoding"
            >审核驳回</el-button
          >
          <el-button
            type="success"
            icon="el-icon-check"
            circle
            @click="pass"
            :disabled="passLoding"
            v-if="realname == 'admin'"
            >审核通过</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-check"
            circle
            @click="pass_dai"
            :disabled="passLoding"
            v-if="realname == 'admin'"
            >报备中</el-button
          >
          <el-button @click="detailsShow = false">退 出</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 审核驳回弹出框 -->
    <el-dialog title="驳回原因:" v-model="remarksShow" width="60%">
      <el-input
        type="textarea"
        :rows="10"
        placeholder="请输入驳回原因"
        v-model="textarea"
      >
      </el-input>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="remarksSubmit" :disabled="submitClick"
            >确认提交</el-button
          >
          <el-button @click="remarksShow = false">退 出</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 审核驳回弹出框 -->
    <el-dialog title="绑定微信账户:" v-model="WeChatShow" width="50%">
      <div class="WeChatBox">
        <div class="bindInfo">
        <!-- bindWXInfo -->
        <p>
          绑定状态：<el-tag
            style="margin: 0"
            size="mini"
            :type="bindWXInfo ? 'success' : 'info'"
          >
            {{ bindWXInfo ? "已绑定" : "未绑定" }}
          </el-tag>
        </p>
        <div v-if="bindWXInfo" class="WXinfoBox">
          <p class="WXinfoItem"><p style="margin: 10px;margin-left:0;"> 微信昵称: </p><p style="font-weight: bold;">{{ bindWXInfo.name }}</p></p>
          <p class="WXinfoItem"><p style="margin-right:10px;"> 微信头像: </p><el-image 
    style="width: 100px; height: 100px;border-radius: 80px;"
    :src="bindWXInfo.headimgurl" 
    :preview-src-list="[bindWXInfo.headimgurl]">
  </el-image> </p>
        </div>
      </div>
      <div class="codeImg">
<p>
  关注公众号
</p>
<el-image 
    style="width: 150px; height: 150px;margin: 0 50px;"
    :src="officialAccountUrl" 
    :preview-src-list="[officialAccountUrl]">
  </el-image>
      </div>
      <div class="codeImg">
<p>
  绑定微信号
</p>
<el-image 
    style="width: 150px; height: 150px;margin: 0 50px;"
    :src="codeImgUrl" 
    :preview-src-list="[codeImgUrl]">
  </el-image>
      </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import QRCode from "qrcode";
import { getWeiXinByUserId } from "@/api/user";

// let token = localStorage.getItem("TOKEN");
export default {
  name: "enterpriseApplication",
  data() {
    return {
      codeImgUrl: "",
      bindWXInfo: null,
      officialAccountUrl: require(`@/assets/img/officialAccount.jpg`),
      WeChatShow: false,
      talk: null,
      timestamp: null,
      sta: null,
      end: null,
      options1: [
        {
          value: "1",
          label: "企业申请(旧版本)",
        },
        {
          value: "2",
          label: "D线路企业申请",
        },
      ],

      imgShow: false,
      imgUrl: "",
      myHeaders: {
        "X-Access-Token": "",
      },
      // doUpload: "http://106.14.0.130:9998/vo/uuid-pass/upload",
      doUpload: "api/uuid-pass/upload",
      // doUpload:"http://hp.damaitongxin.com/uuid-pass/upload",
      dialogImageUrl: "",
      dialogVisible: false,
      tableData: [],
      fileList: [],
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileList6: [],
      fileList7: [],
      fileList8: [],
      fileFalg8: true,

      companyName: "",
      form: {
        id: "",
        userId: null,
        companyName: null,
        createTime: "",
        status: null,
      },
      forms: {
        id: "",
        userId: "",
        companyName: "",
        createTime: "",
        changeTime: "",
        status: "",
        legalName: "",
        legalCard: "",
        legalPhone: "",
        businessNumber: "",
        talk: "",
        channel: "",
      },
      options: [
        {
          value: "0",
          label: "未提交",
        },
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "2",
          label: "未通过审核",
        },
        {
          value: "3",
          label: "已通过审核",
        },
        {
          value: "4",
          label: "报备中",
        },
      ],
      options2: [
        {
          value: null,
          label: "正常",
        },
        {
          value: "实名认证",
          label: "实名认证",
        },
      ],
      value: null,
      value1: null,
      status: 1,
      pageTotal: 0, //总共有多少数据
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      remarks: "", //备注（只传不回显）
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      applyShow: false, //企业申请弹出框
      applyShowD: false, //企业申请弹出框
      editShow: false, //补充资料弹出框
      detailsShow: false, //详情弹窗
      remarksShow: false, //驳回备注弹窗
      textarea: "", //驳回内容
      addRules: {
        userId: [
          { required: true, message: "代理编号为必填项", trigger: "blur" },
        ],
        companyName: [
          { required: true, message: "企业名称为必填项", trigger: "blur" },
        ],
        legalName: [
          { required: true, message: "法人姓名为必填项", trigger: "blur" },
        ],
        legalCard: [
          { required: true, message: "法人身份证号为必填项", trigger: "blur" },
        ],
        legalPhone: [
          { required: true, message: "法人电话号为必填项", trigger: "blur" },
        ],
        businessNumber: [
          { required: true, message: "营业执照号码为必填项", trigger: "blur" },
        ],
        talk: [{ required: true, message: "话术为必填项", trigger: "blur" }],
      },
      dataUrl: "",
      dataUrl1: "",
      dataUrl2: "",
      dataUrl3: "",
      dataUrl4: "",
      dataUrl5: "",
      dataUrl6: "",
      dataUrl7: "",
      dataUrl8: "",
      realname: "",
      srcList: [],
      currentFile: "",
      submitClick: false,
      passLoding: false,
      noPassLoding: false,
      searchLoading: false,
    };
  },
  destroyed() {
    localStorage.removeItem("salesman");
  },
  created() {
    this.toCode();
    // window.location.href = url;
    this.form.companyName = this.$route.query.companyName;
    console.log("地址参数", this.$route.query);
    let token = localStorage.getItem("TOKEN");
    this.myHeaders = {
      "X-Access-Token": token,
    };

    // console.log(localStorage.getItem("TOKEN"));
    //  // console.log(this.value);
    this.getList();
    this.realname = localStorage.getItem("realname");
    // let rootDate = new Date().toLocaleDateString()
    // this.timestamp = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
  },
  //切换界面就执行方法
  activated() {
    this.value = localStorage.getItem("status") || "";
    this.getList();
  },
  watch: {
    value() {
      this.getList();
    },
    // fileList() {
    //     if (this.fileList.length >= 1) {
    //          // console.log("zjj-----------------"+file.url);
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    //
    // },
    // fileList1() {
    //     if (this.fileList1.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList2() {
    //     if (this.fileList2.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList3() {
    //     if (this.fileList3.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList4() {
    //     if (this.fileList4.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList5() {
    //     if (this.fileList2.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList6() {
    //     if (this.fileList6.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList7() {
    //     if (this.fileList7.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
    // fileList8() {
    //     if (this.fileList8.length >= 1) {
    //         document.getElementsByClassName(
    //             "el-upload--picture-card"
    //         )[0].style.display = "none";
    //     }
    // },
  },
  methods: {
    // 设置绑定微信二维码地址
    toCode() {
      let Id = localStorage.getItem("id");
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx735ffa5a7e5039ef&redirect_uri=http%3A%2F%2Ftest2.cddmt.cn%2Ftest%2Fwx%2Fsave-code&response_type=code&scope=snsapi_userinfo&state=${Id}#wechat_redirect`;
      let canvas = document.createElement("canvas");
      canvas.width = "100%";
      canvas.height = "100%";
      // 添加 margin 参数，将其设置为 0
      let qrCodeOptions = {
        margin: 1,
      };
      QRCode.toCanvas(canvas, url, qrCodeOptions, (error) => {
        if (error) {
          console.log(error);
        } else {
          this.codeImgUrl = canvas.toDataURL("image/jpeg");
        }
      });
    },
    // https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx735ffa5a7e5039ef&redirect_uri=http%3A%2F%2Ftest2.cddmt.cn%2Ftest%2Fwx%2Fsave-code&response_type=code&scope=snsapi_userinfo&state=paas#wechat_redirect
    // 打开微信公众号弹框
    openWeChatDialog() {
      let Id = localStorage.getItem("id");
      let params = {
        userId: Id,
      };
      getWeiXinByUserId(params)
        .then((res) => {
          // console.log("参数", res.statusCode);
          if (res.statusCode == "00000") {
            this.bindWXInfo = res.data;
          } else {
            this.$alert(res.message || "查询失败,稍后再试", "", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });
          }
        })
        .catch((err) => {});
      this.WeChatShow = true;
    },
    checkFileType2(file) {
      // //限制类型
      // const fileName = file.name;   //取文件名字
      // const fileType = fileName.substring(fileName.lastIndexOf("."));//去文件后缀
      // // jpeg,.png,.jpg,
      // if (fileType === ".jpg" || fileType === ".png" || fileType === ".jpeg") {
      //     // 不处理
      // } else {
      //     // this.$message.error(
      //     //   '请上传正确的格式的图片'
      //     // )
      //     return false;
      // }
      //限制大小
      if (file.size / 1024 / 1024 > 30) {
        this.$message.warning("上传压缩包过大，不能大于30M，请重新上传！");
        return false;
      }
    },
    checkFileType(file) {
      // //限制类型
      // const fileName = file.name;   //取文件名字
      // const fileType = fileName.substring(fileName.lastIndexOf("."));//去文件后缀
      // // jpeg,.png,.jpg,
      // if (fileType === ".jpg" || fileType === ".png" || fileType === ".jpeg") {
      //     // 不处理
      // } else {
      //     // this.$message.error(
      //     //   '请上传正确的格式的图片'
      //     // )
      //     return false;
      // }
      //  // console.log("文件大小----------------------" + file.size)
      //限制大小
      if (file.size / 1024 / 1024 > 8) {
        this.$message.warning("上传图片过大，不能大于8M，请重新上传！");
        return false;
      }
    },

    //清除上传状态
    beforeColorUpload() {
      this.$refs.uploadImgs.clearFiles(); //上传成功之后清除历史记录
    },
    beforeAvatarUpload() {
      if (this.dataUrl8) {
        this.handleRemove(this.currentFile);
      }
      this.$refs["uploadPreview"].clearFiles();
      this.dataUrl8 = "";
    },
    handleExceed(files, fileList) {
      // console.log(files);
      // console.log(fileList);
    },
    imgPreview(file, fileList) {
      let arr = [];
      fileList.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return false;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return false;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList = arr;
    },

    imgPreview1(file, fileList1) {
      let arr = [];
      fileList1.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList1 = arr;
    },
    imgPreview2(file, fileList2) {
      let arr = [];
      fileList2.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList2 = arr;
    },
    imgPreview3(file, fileList3) {
      let arr = [];
      fileList3.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList3 = arr;
    },
    imgPreview4(file, fileList4) {
      let arr = [];
      fileList4.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList4 = arr;
    },
    imgPreview5(file, fileList5) {
      let arr = [];
      fileList5.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList5 = arr;
    },
    imgPreview6(file, fileList6) {
      let arr = [];
      fileList6.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList6 = arr;
    },
    imgPreview7(file, fileList7) {
      let arr = [];
      fileList7.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;
          const isJpgOrPng =
            item.raw.type === "image/jpeg" || item.raw.type === "image/png";
          if (!isJpgOrPng) {
            this.$message.error("只能上传图片!");
            return;
          } else if (!isLt2M) {
            this.$message.error("上传单张图片大小超过3M或命名过长!");
            return;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList7 = arr;
    },
    imgPreview8(file, fileList8) {
      let arr = [];
      fileList8.forEach((item) => {
        if (item.id) {
          arr.push(item);
        } else {
          const isLt2M = item.size / 3072 / 3072 < 1;

          if (!isLt2M) {
            this.$message.error("上传大小超过3M或命名过长!");
            this.fileFalg8 = false;
            return false;
          } else {
            arr.push(item);
          }
        }
      });
      this.fileList8 = arr;
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },

    handleSuccess(response, file) {
      this.dataUrl = response.data;
    },
    handleSuccess1(response, file) {
      this.dataUrl1 = response.data;
    },
    handleSuccess2(response, file) {
      this.dataUrl2 = response.data;
    },
    handleSuccess3(response, file) {
      this.dataUrl3 = response.data;
    },
    handleSuccess4(response, file) {
      this.dataUrl4 = response.data;
    },
    handleSuccess5(response, file) {
      this.dataUrl5 = response.data;
    },
    handleSuccess6(response, file) {
      this.dataUrl6 = response.data;
    },
    handleSuccess7(response, file) {
      this.dataUrl7 = response.data;
    },
    handleSuccess8(response, file) {
      file.url = response.data;
      file.response = response.data;
      this.currentFile = file;
      this.dataUrl8 = response.data;

      // this.beforeColorUpload()
    },
    //删除图片
    handleRemove(file, fileList) {
      //  // console.log("准备删除了----------------------------------", file)
      //  // console.log(1111+file.response.data)
      let Myurl = null;
      if (isNaN(file.response)) {
        Myurl = file.url;
      } else {
        Myurl = file.response;
      }
      if (null == Myurl || "" == Myurl) {
        return;
      }

      let params = {
        // url:file.url,
        url: Myurl,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/del-img", params)
        .post("api/uuid-pass/del-img", params)
        .then((res) => {
          // console.log(res)
          if ((res.data.statusCode = "00000")) {
            this.$message.success(res.data.message);
            document.getElementsByClassName(
              "el-upload--picture-card"
            )[0].style.display = "inline-block";
            localStorage.setItem("deletUrl", file.url);
            if (this.dataUrl == file.url) {
              // this.dataUrl = null;
              this.dataUrl = "null";
            } else if (this.dataUrl1 == file.url) {
              // this.dataUrl1 = null;
              this.dataUrl1 = "null";
            } else if (this.dataUrl2 == file.url) {
              // this.dataUrl2 = null;
              this.dataUrl2 = "null";
            } else if (this.dataUrl3 == file.url) {
              // this.dataUrl3 = null
              this.dataUrl3 = "null";
            } else if (this.dataUrl4 == file.url) {
              // this.dataUrl4 = null;
              this.dataUrl4 = "null";
            } else if (this.dataUrl5 == file.url) {
              // this.dataUrl5 = null;
              this.dataUrl5 = "null";
            } else if (this.dataUrl6 == file.url) {
              // this.dataUrl6 = null;
              this.dataUrl6 = "null";
            } else if (this.dataUrl7 == file.url) {
              // this.dataUrl7 = null;
              this.dataUrl7 = "null";
            } else if (this.dataUrl8 == file.url) {
              // this.dataUrl8 = null;
              this.dataUrl8 = "null";
            }

            this.editSubmit(); //修改
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error("！删除失败！");
        });
    },
    //预览
    handlePictureCardPreview(file) {
      // console.log("zjj-----------------111" + file.url);
      this.dataUrl = file.url;
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },

    //重置刷新按钮
    reset() {
      this.form.userId = null;
      this.form.companyName = null;
      this.value = null;
      this.timestamp = null;
      this.sta = null;
      this.end = null;
      this.getList();
    },
    //企业申请表单
    chooes(value1) {
      this.applyD();
      // if (value1 == 1) {//旧版本
      //   // alert(111+value1)
      //   this.apply();
      // } else if (value1 == 2) {
      //   this.applyD();
      //   // alert(222+value1)
      // } else {
      //   alert(333 + value1)
      // }
      this.value1 = null;
    },
    apply() {
      // alert(qqqq)
      this.applyShow = true;
      this.forms = [];
      this.dataUrl = null;
      this.dataUrl1 = null;
      this.dataUrl2 = null;
      this.dataUrl3 = null;
      this.dataUrl4 = null;
      this.dataUrl5 = null;
      this.dataUrl6 = null;
      this.dataUrl7 = null;
      this.dataUrl8 = null;
    },
    applyD() {
      // alert(qqqq)
      this.applyShowD = true;
      this.forms = [];
      this.dataUrl = null;
      this.dataUrl1 = null;
      this.dataUrl2 = null;
      this.dataUrl3 = null;
      this.dataUrl4 = null;
      this.dataUrl5 = null;
      this.dataUrl6 = null;
      this.dataUrl7 = null;
      this.dataUrl8 = null;
      this.beforeAvatarUpload();
    },
    //企业申请表单的提交按钮
    applySubmit(type) {
      if (type == "add") {
        this.status = 1;
      } else {
        this.status = 0;
      }
      let params = {
        companyName: this.forms.companyName, //公司名称
        legalName: this.forms.legalName, //法人姓名
        legalCard: this.forms.legalCard, //法人身份证号
        legalPhone: this.forms.legalPhone, //法人手机号
        businessNumber: this.forms.businessNumber, //营业执照号
        talk: this.forms.talk, //话术
        channel: this.forms.channel, //报备通道
        businessImg: this.dataUrl, //营业执照复印件（只可上传一张）
        legalImg: this.dataUrl1,
        legalHandImg: this.dataUrl4,
        legalHand2Img: this.dataUrl6,
        agencyImg: this.dataUrl2,
        agencyHandImg: this.dataUrl5,
        agencyHand2Img: this.dataUrl7,
        talkImg: this.dataUrl3,
        talk2Img: this.dataUrl8,
        status: this.status,
      };
      let salesman = localStorage.getItem("salesman");

      if (salesman) {
        params.salesman = salesman;
      }

      axios
        //  .post("http://106.14.0.130:9998/vo/uuid-pass/add",params)
        .post("api/uuid-pass/add", params)
        .then((res) => {
          if ((res.data.statusCode = "00000")) {
            this.$message.success(res.data.message);
            this.getList();
            this.cleatPar();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
      this.applyShow = false;
      this.applyShowD = false;
    },
    cleatPar() {
      this.dialogImageUrl1 = null;
      this.dialogImageUrl2 = null;
      this.dialogImageUrl3 = null;
      this.dialogImageUrl4 = null;
      this.dialogImageUrl5 = null;
      this.dialogImageUrl6 = null;
      this.dialogImageUrl7 = null;
      this.dialogImageUrl8 = null;

      for (let i = 1; i <= 8; i++) {
        this.$refs[`uploadNo${i}`].clearFiles();
      }
    },

    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //点击查找
    handleSearch() {
      //  // console.log("----------"+this.timestamp)
      this.searchLoading = true;
      if (this.timestamp != null) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[1]);
        this.getList();
      } else if (this.timestamp == null || this.timestamp == "") {
        this.getList();
      }
    },
    //展示，搜索
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        userId: this.form.userId,
        companyName: this.form.companyName,
        status: this.value,
        sta: this.sta,
        end: this.end,
        talk: this.talk,
      };
      let salesman = localStorage.getItem("salesman");
      if (salesman) {
        params.salesman = salesman;
      }
      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/list", params)
        .post("api/uuid-pass/list", params)
        .then((res) => {
          //  // console.log(res.data)
          this.searchLoading = false;
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          this.searchLoading = false;
          this.$message.error(err.data.message);
        });
    },
    //点击补充资料按钮后回显
    handleClick(row) {
      // console.log("执行回显操作！")
      this.fileList = [];
      this.fileList1 = [];
      this.fileList2 = [];
      this.fileList3 = [];
      this.fileList4 = [];
      this.fileList5 = [];
      this.fileList6 = [];
      this.fileList7 = [];
      this.fileList8 = [];
      this.forms.id = row.row.id;
      this.forms.userId = row.row.userId;
      let params = {
        id: this.forms.id,
        userId: this.forms.userId,
      };
      // console.log("准备调接口")
      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/findOne",params)
        .post("api/uuid-pass/findOne", params)
        .then((res) => {
          // console.log(res.data.data)
          this.forms.companyName = res.data.data.companyName;
          this.forms.legalName = res.data.data.legalName;
          this.forms.legalCard = res.data.data.legalCard;
          this.forms.legalPhone = res.data.data.legalPhone;
          this.forms.businessNumber = res.data.data.businessNumber;
          this.forms.talk = res.data.data.talk;
          this.forms.channel = res.data.data.channel;

          this.dataUrl = res.data.data.businessImg;

          if (
            res.data.data.businessImg == "" ||
            res.data.data.businessImg == "null" ||
            res.data.data.businessImg == null
          ) {
            // console.log("-----------------------------zjj测试测试测试" + res.data.data.businessImg)
            this.dataUrl = null;
          } else {
            let str = {
              url: "",
            };
            if (this.fileList.length < 1) {
              str.url = res.data.data.businessImg;
              this.fileList.push(str);
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl1 = res.data.data.legalImg; //法人身份证复印件
          if (
            res.data.data.legalImg == "" ||
            res.data.data.legalImg == "null"
          ) {
            this.dataUrl1 = null;
          } else {
            let str1 = {
              url: "",
            };
            //  // console.log(9998, this.fileList1.length)
            if (this.fileList1.length < 1) {
              str1.url = res.data.data.legalImg;
              this.fileList1.push(str1);
              //  // console.log(this.fileList1)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl4 = res.data.data.legalHandImg;
          if (
            res.data.data.legalHandImg == "" ||
            res.data.data.legalHandImg == "null"
          ) {
            this.dataUrl4 = null;
          } else {
            let str4 = {
              url: "",
            };
            // console.log(this.fileList4)
            if (this.fileList4.length < 1) {
              str4.url = res.data.data.legalHandImg;
              this.fileList4.push(str4);
              //  // console.log(this.fileList4)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl6 = res.data.data.legalHand2Img;
          if (
            res.data.data.legalHand2Img == "" ||
            res.data.data.legalHand2Img == "null"
          ) {
            this.dataUrl6 = null;
          } else {
            let str6 = {
              url: "",
            };
            if (this.fileList6.length < 1) {
              str6.url = res.data.data.legalHand2Img;
              this.fileList6.push(str6);
              //  // console.log(this.fileList6)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl2 = res.data.data.agencyImg; //代理人身份证复印件
          if (
            res.data.data.agencyImg == "" ||
            res.data.data.agencyImg == "null"
          ) {
            this.dataUrl2 = null;
          } else {
            let str2 = {
              url: "",
            };
            if (this.fileList2.length < 1) {
              str2.url = res.data.data.agencyImg;
              this.fileList2.push(str2);
              //  // console.log(this.fileList2)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl5 = res.data.data.agencyHandImg;
          if (
            res.data.data.agencyHandImg == "" ||
            res.data.data.agencyHandImg == "null"
          ) {
            this.dataUrl5 = null;
          } else {
            let str5 = {
              url: "",
            };
            if (this.fileList5.length < 1) {
              str5.url = res.data.data.agencyHandImg;
              this.fileList5.push(str5);
              //  // console.log(this.fileList5)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl7 = res.data.data.agencyHand2Img;
          if (
            res.data.data.agencyHand2Img == "" ||
            res.data.data.agencyHand2Img == "null"
          ) {
            this.dataUrl7 = null;
          } else {
            let str7 = {
              url: "",
            };
            if (this.fileList7.length < 1) {
              str7.url = res.data.data.agencyHand2Img;
              this.fileList7.push(str7);
              //  // console.log(this.fileList7)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl3 = res.data.data.talkImg; //公司报备话术模板复印件（只可上传一张
          if (res.data.data.talkImg == "" || res.data.data.talkImg == "null") {
            this.dataUrl3 = null;
          } else {
            let str3 = {
              url: "",
            };
            if (this.fileList3.length < 1) {
              str3.url = res.data.data.talkImg;
              this.fileList3.push(str3);
              //  // console.log(this.fileList3)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }

          this.dataUrl8 = res.data.data.talk2Img;
          if (
            res.data.data.talk2Img == "" ||
            res.data.data.talk2Img == "null"
          ) {
            this.dataUrl8 = null;
          } else {
            let str8 = {
              url: "",
            };
            if (this.fileList8.length < 1) {
              // console.log("<1---------------------------" + this.fileList8.length)
              str8.url = res.data.data.talk2Img;
              this.fileList8.push(str8);
              //  // console.log(this.fileList8)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "none";
              // })
            } else {
              //  // console.log(">1---------------------------" + this.fileList8.length)
              // this.$nextTick(() => {
              //     document.getElementsByClassName(
              //         "el-upload--picture-card"
              //     )[0].style.display = "inline-block";
              // })
            }
          }
        });
      this.editShow = true;
    },
    //补充资料提交按钮
    editSubmit(type) {
      if (type == "add") {
        this.status = 1;
      } else {
        this.status = 0;
      }

      let params = {
        id: this.forms.id, //id必传
        userId: this.forms.userId, //代理编号
        companyName: this.forms.companyName, //公司名称
        legalName: this.forms.legalName, //法人姓名
        legalCard: this.forms.legalCard, //法人身份证号
        legalPhone: this.forms.legalPhone, //法人手机号
        businessNumber: this.forms.businessNumber, //营业执照号
        talk: this.forms.talk, //话术
        channel: this.forms.channel,
        businessImg: this.dataUrl, //营业执照复印件（只可上传一张）
        legalImg: this.dataUrl1,
        legalHandImg: this.dataUrl4,
        legalHand2Img: this.dataUrl6,
        agencyImg: this.dataUrl2,
        agencyHandImg: this.dataUrl5,
        agencyHand2Img: this.dataUrl7,
        talkImg: this.dataUrl3,
        talk2Img: this.dataUrl8,
        status: this.status,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/update", params)
        .post("api/uuid-pass/update", params)
        .then((res) => {
          if ((res.data.statusCode = "00000")) {
            this.$message.success(res.data.message);
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
      if (null != type) {
        this.editShow = false;
      }
    },

    //详情按钮,只查看不修改
    details(row) {
      this.srcList = [];
      this.forms.id = row.row.id;
      let params = {
        id: this.forms.id,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/findOne",params)
        .post("api/uuid-pass/findOne", params)
        .then((res) => {
          this.forms.userId = res.data.data.userId;
          this.forms.companyName = res.data.data.companyName;
          this.forms.legalName = res.data.data.legalName;
          this.forms.legalCard = res.data.data.legalCard;
          this.forms.legalPhone = res.data.data.legalPhone;
          this.forms.businessNumber = res.data.data.businessNumber;
          this.forms.talk = res.data.data.talk;
          this.forms.channel = res.data.data.channel;
          this.dataUrl = res.data.data.businessImg;
          this.dataUrl1 = res.data.data.legalImg; //法人身份证复印件
          this.dataUrl4 = res.data.data.legalHandImg;
          this.dataUrl6 = res.data.data.legalHand2Img;
          this.dataUrl2 = res.data.data.agencyImg; //代理人身份证复印件
          this.dataUrl5 = res.data.data.agencyHandImg;
          this.dataUrl7 = res.data.data.agencyHand2Img;
          this.dataUrl3 = res.data.data.talkImg; //公司报备话术模板复印件（只可上传一张)
          this.dataUrl8 = res.data.data.talk2Img;
          this.detailsShow = true;
          this.srcList.push(
            this.dataUrl,
            this.dataUrl1,
            this.dataUrl2,
            this.dataUrl3,
            this.dataUrl4,
            this.dataUrl5,
            this.dataUrl6,
            this.dataUrl7,
            this.dataUrl8
          );
        });
    },
    //审核通过按钮
    pass(row) {
      this.passLoding = true;
      (this.detailsShow = false), (this.status = 3);
      let params = {
        id: this.forms.id,
        status: this.status,
        companyName: this.forms.companyName,
        talk: this.forms.talk,
        userId: this.forms.userId,
        remark: "",
        businessImg: this.dataUrl,
        legalImg: this.dataUrl1,
        legalHandImg: this.dataUrl4,
        legalHand2Img: this.dataUrl6,
        agencyImg: this.dataUrl2,
        agencyHandImg: this.dataUrl5,
        agencyHand2Img: this.dataUrl7,
        talkImg: this.dataUrl3,
        talk2Img: this.dataUrl8,
      };

      this.$confirm("请确认, 是否通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/uuid-pass/update", params)
            .post("api/uuid-pass/update", params)
            .then((res) => {
              if ((res.data.statusCode = "00000")) {
                this.$message.success(res.data.message);
                this.getList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消通过",
          });
        });
      this.passLoding = false;
    },
    //审核待审核按钮
    pass_dai(row) {
      this.passLoding = true;
      (this.detailsShow = false), (this.status = 4);
      let params = {
        id: this.forms.id,
        status: this.status,
        companyName: this.forms.companyName,
        talk: this.forms.talk,
        userId: this.forms.userId,
        remark: "",
        businessImg: this.dataUrl,
        legalImg: this.dataUrl1,
        legalHandImg: this.dataUrl4,
        legalHand2Img: this.dataUrl6,
        agencyImg: this.dataUrl2,
        agencyHandImg: this.dataUrl5,
        agencyHand2Img: this.dataUrl7,
        talkImg: this.dataUrl3,
        talk2Img: this.dataUrl8,
      };

      this.$confirm("请确认, 是否通过?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/uuid-pass/update", params)
            .post("api/uuid-pass/update", params)
            .then((res) => {
              if ((res.data.statusCode = "00000")) {
                this.$message.success(res.data.message);
                this.getList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消通过",
          });
        });
      this.passLoding = false;
    },
    //审核驳回按钮
    noPass(row) {
      this.remarksShow = true;
    },
    //驳回详情提交按钮
    remarksSubmit() {
      this.submitClick = true;
      this.status = 2;
      let params = {
        id: this.forms.id,
        remark: this.textarea,
        userId: this.forms.userId,
        status: this.status,
        businessImg: this.dataUrl,
        legalImg: this.dataUrl1,
        legalHandImg: this.dataUrl4,
        legalHand2Img: this.dataUrl6,
        agencyImg: this.dataUrl2,
        agencyHandImg: this.dataUrl5,
        agencyHand2Img: this.dataUrl7,
        talkImg: this.dataUrl3,
        talk2Img: this.dataUrl8,
        companyName: this.forms.companyName,
      };

      axios
        // .post("http://106.14.0.130:9998/vo/uuid-pass/update", params)
        .post("api/uuid-pass/update", params)
        .then((res) => {
          this.remarksShow = false;
          this.detailsShow = false;
          this.submitClick = false;
          if ((res.data.statusCode = "00000")) {
            this.$message.success(res.data.message);
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
          this.submitClick = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.WeChatBox {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  .bindInfo {
    .WXinfoBox {
      .WXinfoItem {
        display: flex;
        align-items: center;
      }
    }
  }
  .codeImg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .talkClass .el-textarea__inner {
  min-height: 72px !important;
}
.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .el-select {
      width: 100%;
    }

    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;

    .left {
      // width: 60%;
      width: 548px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .leftItem {
        width: 180px;
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .el-input,
        .el-select,
        .el-date-editor {
          // width: 120px;
          flex: 1;
        }

        .el-input__inner {
          height: 23px !important;
          border-radius: 25px;
          border: 1px solid #666;
        }

        .el-input__icon {
          line-height: 24px !important;
        }

        .lab_tit {
          font-size: 12px;
          // font-weight: bold;
          white-space: nowrap;
          width: 70px !important;
          text-align: right;
          margin-right: 3px;
          margin-left: 8px;
        }
      }
    }

    .right {
      text-align: right;
      // width: 30%;
      flex: 1;

      // display: flex;
      .el-button {
        margin-bottom: 3px;
      }
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      // .el-table__row {
      //     td {
      //         padding: 4px 0;
      //     }
      // }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgViewbox {
  width: 904px;
  height: 706px;
  position: relative;
}

.imgView {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-content: center;
}

.imgView img {
  width: 904px;
  height: 706px;
  justify-content: center;
  align-content: center;
}

.close {
  position: absolute;
  right: 20px;
  top: 0;
  font-size: 20px;
  cursor: pointer;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .search {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
// }

// .left {
//     display: flex;
//     align-items: center;
//     margin-right: 30px;
// }

// .left span {
//     width: 190px;
//     text-align: center;
// }

.searchBtn {
  margin-left: 15px;
  align-items: center;
}

// .right {
//     display: flex;
//     justify-content: flex-end;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* 话术编辑框高度设置 */
/* .el-textarea__inner{
      min-height: 300px !important;
    } */
.el-form-item__label {
  text-align: center;
}

div ::v-deep .el-upload {
  position: relative;
  width: 148px;
  height: 148px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
}

div ::v-deep .el-upload img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

div ::v-deep .el-upload-list__item-name {
  display: none;
}

div ::v-deep .el-upload-list__item-status-label {
  display: none !important;
}

.el-dialog span {
  margin-left: 100px;
}

.el-dialog el-upload {
  margin: 20px;
}

.viewitem {
  width: 146px;
  height: 146px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.viewitem img {
  width: 146px;
  height: 146px;
}

::v-deep header {
  h1 {
    font-weight: normal;

    > span {
      font-size: 18px;
      color: #606369;
    }
  }

  .search {
    display: flex;
    margin-top: 10px;
    flex-direction: column;

    .left {
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;
      line-height: 30px;

      .left-wrap {
        max-width: 20%;
        display: flex;
        align-items: center;
        margin-right: 10px;

        .el-date-editor {
          width: 100% !important;
        }

        .el-select {
          width: 100%;
        }

        .noBorder {
          width: 120px;

          .el-input__inner {
            border: none;
            padding-right: 0 !important;
          }
        }

        & > span {
          margin: 6px;
          width: 70px;
          white-space: nowrap;
          text-align: right;
          align-items: center;
          font-size: 12px;
        }
      }
    }

    .right {
      margin-top: 10px;
      // text-align: right;
      display: flex;
      /* justify-content: space-between; */
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

.modle {
  width: 146px;
  height: 146px;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.modle i {
  font-style: normal;
  margin-left: 20px;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.viewitem:hover .modle {
  opacity: 1;
}
</style>
