<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="企业自定义"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">字段名</span>
                        <el-input v-model="name" placeholder="请输入字段名" size="mini"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">报备状态</span>
                        <el-select v-model="remarks" placeholder="请选择报备状态" size="mini">
                            <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="leftItem" style="width: 230px;">
                        <span class="lab_tit" style="width: 88px !important;">企业ID(字段名)</span>
                        <el-input v-model="uuid" placeholder="请输入完整企业id" size="mini"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <!--<div class="item">-->
                    <!--<span>主叫</span>-->
                    <!--<el-input-->
                    <!--v-model="activePhone"-->
                    <!--placeholder="请输入主叫"-->
                    <!--class="handle-input mr10"-->
                    <!--v-on:keyup.enter="handleSearch"-->
                    <!--&gt;</el-input>-->
                    <!--</div>-->

                    <!--<div class="item">-->
                    <!--<span>备注</span>-->
                    <!--<el-input-->
                    <!--v-model="remake"-->
                    <!--placeholder="请输入备注"-->
                    <!--class="handle-input mr10"-->
                    <!--v-on:keyup.enter="handleSearch"-->
                    <!--&gt;</el-input>-->
                    <!--</div>-->

                    <!--<el-button-->
                    <!--type="primary"-->
                    <!--icon="el-icon-search"-->
                    <!--@click="handleSearch"-->
                    <!--class="searchBtn"-->
                    <!--&gt;查找-->
                    <!--</el-button>-->
                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="add_custom_flag = true" round size="mini"
                        v-if="realname == 'admin'">添加
                    </el-button>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>

                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="form_custom" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column prop="id" label="id" width="100"></el-table-column>
                        <el-table-column prop="name" label="字段名"></el-table-column>
                        <el-table-column width="120" prop="remarks" label="是否已报备"></el-table-column>

                        <el-table-column label="操作" width="300" align="center" fixed="right">
                            <template #default="scope">
                                <div class="operateColumn">
                                    <el-button @click="getList_customData2(scope.row)" size="mini">查询数据</el-button>
                                    <el-button type="warning" @click="update_custom_open(scope)" size="mini"
                                        v-if="realname == 'admin'">修改</el-button>
                                </div>
                                <!-- <el-button type="danger" @click="del_custom(scope)" v-if="realname == 'admin'">删除</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageTotal" @current-change="handleToPageChange"
                            @size-change="handleToSizeChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 添加字段弹出框 -->
        <el-dialog title="添加自定义字段名称" v-model="add_custom_flag" width="30%">
            <el-form ref="dataAddForm" :model="form" label-width="80px">

                <el-form-item label="自定义名" prop="bindPhone">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="报备状态">
                    <el-select v-model="form.remarks" placeholder="请选择报备状态">
                        <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="add_custom_flag = false">取 消</el-button>
                    <el-button type="primary" @click="add_custom()">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="update_custom_falg" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
                <el-form-item label="自定义名" prop="bindPhone">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="报备状态">
                    <el-select v-model="form.remarks" placeholder="请选择报备状态">
                        <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="update_custom_falg = false">取 消</el-button>
                    <el-button type="primary" @click="update_custom()">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 数据 弹出框 -->
        <el-dialog title="数据" v-model="getList_customData_falg" width="70%">

            <div style="display: flex;width: 100%;justify-content: space-between">

                <div style="display: flex">
                    <!-- 自定义名 -->
                    <!-- <el-input v-model="form_data.customData"></el-input> -->
                    <div class="allPhoneQuery">
                        <div style="display: flex; flex: 4">
                            <span style="width: 130px; line-height: 40px">查询字段数据</span>
                            <el-input v-model="allPhoneActivePhone" placeholder="请输入完整号码" size="medium"></el-input>
                        </div>
                        <el-button @click="queryTextData" type="primary" style="flex: 1">查 询</el-button>
                    </div>
                </div>

                <div>
                    <el-button type="primary" @click="add_customData()" v-if="realname == 'admin'">添 加</el-button>
                    <el-button type="primary" @click="customData_QieHuan(1)">切换--企业</el-button>
                </div>
            </div>
            <hr>
            <el-table :data="tableCustomData" class="table" ref="multipleTable" header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column label="字段名" width="300">
                    <template #default="scope">
                        <P type="danger" @click="del_custom(scope)">{{ form.name }}</P>
                    </template>
                </el-table-column>
                <el-table-column prop="value" label="字段数据"></el-table-column>

                <el-table-column label="操作" width="300" align="center">
                    <template #default="scope">
                        <el-button type="warning" @click="update_customData_open(scope)"
                            v-if="realname == 'admin'">修改</el-button>
                        <el-button type="danger" @click="del_customData(scope)" v-if="realname == 'admin'">删除</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <div class="pagination">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageCustomTotal" :page-sizes="[10, 20, 50, 100, 200]"
                            @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
                    </div>
                    <el-button @click="getList_customData_falg = false">取 消</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 企业数据 弹出框 -->
        <el-dialog title="对应企业数据" v-model="getList_customData_uuid_falg" width="70%">

            <div style="display: flex;width: 100%;justify-content: space-between">

                <div style="display: flex">
                    <!-- 自定义名 -->
                    <!-- <el-input v-model="form_data.customData"></el-input> -->
                </div>

                <div>
                    <el-button type="primary" @click="add_customData()" v-if="realname == 'admin'">添 加</el-button>
                    <el-button type="primary" @click="customData_QieHuan(2)">切换--数据</el-button>
                </div>
            </div>

            <el-table :data="tableCustomData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column label="字段名" width="300">
                    <template #default="scope">
                        <P>{{ form.name }}</P>
                    </template>
                </el-table-column>
                <el-table-column prop="id" label="企业id"></el-table-column>
                <el-table-column prop="userId" label="代理id"></el-table-column>
                <el-table-column prop="name" label="企业名称"></el-table-column>
                <el-table-column label="锁定状态">
                    <template #default="scope">
                        <P>{{ scope.row.status == 0 ? '正常' : '锁定中' }}</P>
                    </template>
                </el-table-column>
                <el-table-column prop="appid" label="appid"></el-table-column>

                <!--<el-table-column  label="操作" width="300" >-->
                <!--<template #default="scope">-->
                <!--<el-button type="warning" @click="update_customData_open(scope)">修改</el-button>-->
                <!--<el-button type="danger" @click="del_customData(scope)">删除</el-button>-->
                <!--</template>-->
                <!--</el-table-column>-->

            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <div class="pagination">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageCustomTotal" :page-sizes="[10, 20, 50, 100, 200]"
                            @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
                    </div>
                    <el-button @click="getList_customData_uuid_falg = false">取 消</el-button>
                </span>
            </template>
        </el-dialog>


        <!--数据 编辑弹出框 -->
        <el-dialog title="数据编辑" v-model="update_customData_falg" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
                <el-form-item label="自定义数据">
                    <el-input v-model="form_data.customData"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="update_customData_falg = false">取 消</el-button>
                    <el-button type="primary" @click="update_customData()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            allPhoneActivePhone: null,
            realname: "",
            uuid: null,
            options: [
                {
                    label: "已报备",
                },
                {
                    label: "报备中",
                },
                {
                    label: "报备失败",
                },
            ],
            form: {},
            form_data: {},
            form_custom: [],
            name: null,
            add_custom_flag: false, //添加弹出框
            update_custom_falg: false, //编辑弹出框
            getList_customData_falg: false, //查询数据弹出框
            update_customData_falg: false, //查询数据弹出框
            getList_customData_QieHuan: 1, //1 查询原始 2 查询企业数据
            getList_customData_uuid_falg: false, //查询数据  企业弹出框
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableCustomData: [],
            pageCustomTotal: 0,
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量
            pageListIndex: 1,
            pageListSize: 10,
            remarks: null,

        };
    },
    created() {
        (this.realname = localStorage.getItem("realname")), this.getList();
    },
    //切换界面就执行方法
    activated() {
        // this.getList()
    },
    watch: {},
    methods: {
        queryTextData() {
            this.pageIndex = 1
            this.getList_customData2(this.form);
        },
        //切换
        customData_QieHuan(type) {
            this.pageIndex = 1
            if (1 == type) {
                this.getList_customData_QieHuan = 2
            } else {
                this.getList_customData_QieHuan = 1
            }
            this.getList_customData2(this.form);
        },
        //修改 字段数据
        update_customData() {
            let params = {
                id: this.form_data.id,
                userId: this.form_data.userId,
                customId: this.form_data.customId,
                value: this.form_data.customData,
            };
            axios
                .post("api/uuid-custom/update-data", params)
                .then(async (res) => {
                    if (res.data.statusCode == "00000") {
                        await this.getList_customData(this.form);
                        this.form_data = [];
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.update_customData_falg = false;
                });
        },
        //修改 字段数据
        update_customData_open(row) {

            this.form_data = []
            this.form_data = row.row
            this.update_customData_falg = true;
        },
        //删除 字段数据
        del_customData(row) {
            let params = {
                id: row.row.id,
            };
            axios
                .post("api/uuid-custom/del-data", params)
                .then(async (res) => {
                    if (res.data.statusCode == "00000") {
                        await this.getList_customData(this.form);
                        this.$message.success(res.data.message);
                        this.getList_customData2(this.form);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        //添加 字段数据
        add_customData() {
            let params = {
                customId: this.form.id,
            };

            this.$prompt('请输入自定义名', '添加', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S+/,
                inputErrorMessage: '不能为空'
            }).then(({ value }) => {
                params.value = value
                axios
                    .post("api/uuid-custom/add-data", params)
                    .then(async (res) => {
                        if (res.data.statusCode == "00000") {
                            await this.getList_customData(this.form);
                            this.form_data = [];
                            this.getList_customData2(this.form);
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.error(res.data.message);
                        }
                        // this.getList_customData_falg = false;
                    });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消添加'
                });
            });


        },

        // 分页导航
        handlePageChange(val) {
            this.pageIndex = val;

            this.getList_customData2(this.form);
        },

        handleToPageChange(val) {
            this.pageListIndex = val;
            // console.log("要去的页数", val)
            this.getList()
        },
        //分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList_customData2(this.form);
        },
        handleToSizeChange(val) {
            this.pageListSize = val;
            this.getList();
        },

        handleSearch() {
            this.getList();
        },

        //查询 所有字段名
        getList() {
            let params = {
                name: this.name,
                uuid: this.uuid,
                page: this.pageListIndex, //当前在第几页
                size: this.pageListSize, //前端规定每页显示数量
                remarks: this.remarks
            };
            axios
                .post("api/uuid-custom/find-uuidCustom", params)
                .then((res) => {
                    this.form_custom = res.data.data;
                    this.pageTotal = res.data.total
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        //添加 字段名
        add_custom() {
            let params = {
                name: this.form.name,
                remarks: this.form.remarks
            };

            axios
                .post("api/uuid-custom/add", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.form = [];
                    this.add_custom_flag = false;
                });
        },
        //编辑 字段名弹出框
        update_custom_open(row) {
            this.form = row.row
            this.update_custom_falg = true;
        },
        //修改 字段名
        update_custom() {
            let params = {
                id: this.form.id,
                name: this.form.name,
                userId: this.form.userId,
                remarks: this.form.remarks
            };
            axios
                .post("api/uuid-custom/update", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.form = [];
                    this.update_custom_falg = false;
                });
        },
        //删除 字段名
        del_custom(row) {
            let params = {
                id: row.row.id
            };
            this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                axios
                    .post("api/uuid-custom/del", params)
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            this.getList();
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
            }).catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
                this.getList();
            });
        },
        //查询字段数据
        getList_customData(row) {
            this.form = []
            this.form = row
            // console.log("----------001", this.getList_customData_QieHuan)
            let params = {
                page: this.page,
                size: this.size,
                customId: row.id,
            };
            if (1 == this.getList_customData_QieHuan) {
                axios
                    .post("api/uuid-custom/find-CustomData", params)
                    .then((res) => {
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;

                        this.getList_customData_uuid_falg = false
                        this.getList_customData_falg = true
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            } else {
                axios
                    .post("api/uuid-custom/find-uuidCustomData", params)
                    .then((res) => {
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;

                        this.getList_customData_uuid_falg = false
                        this.getList_customData_uuid_falg = true
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            }

        },

        getList_customData2(row) {
            this.form = []
            this.form = row
            let params = {
                page: this.pageIndex,
                size: this.pageSize,
                customId: row.id,
            };
            if (1 == this.getList_customData_QieHuan) {
                params.uuid = this.allPhoneActivePhone
                axios
                    .post("api/uuid-custom/find-CustomData", params)
                    .then((res) => {
                        this.tableCustomData = res.data.data;
                        this.pageCustomTotal = res.data.total;
                        // console.log("切花数据");
                        this.getList_customData_uuid_falg = false
                        this.getList_customData_falg = true
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            } else {
                axios
                    .post("api/uuid-custom/find-uuidCustomData", params)
                    .then((res) => {
                        this.tableCustomData = res.data.data;
                        this.pageCustomTotal = res.data.total;
                        // console.log("切花企业");

                        this.getList_customData_uuid_falg = false
                        this.getList_customData_uuid_falg = true
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            }

        },
        //刷新按钮
        reset() {
            this.remarks = null
            this.uuid = null
            this.name = null;
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            // width: 60%;
            width: 660px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 210px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    height: 23px !important;
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .el-input__icon {
                    line-height: 24px !important;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 52px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        .right {
            text-align: right;
            // width: 30%;
            flex: 1;

            // display: flex;
            .el-button {
                margin-bottom: 3px;
            }
        }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.allPhoneQuery {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid #606369;
    border-radius: 10px;
    overflow: hidden;
    padding-left: 10px;
    box-sizing: border-box;
    margin-bottom: 20px;


}

.allPhoneQuery ::v-deep .el-input__inner {
    height: 100% !important;
    padding: 0;
    border: 0;
    border-left: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.search {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.left {
    display: flex;
    align-items: center;
}

.item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.left span {
    text-align: center;
    width: 100px;
}

.searchBtn {
    margin-left: 30px;
}

.right {
    // display: flex;
    // justify-content: flex-end;
}

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}

/* ::v-deep.el-form-item__label{
          font-size: 13px  !important;
        } */
</style>
