<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="实时报表"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">代理id</span>
                        <el-input v-model="userId" size="mini" placeholder="请输入代理id" style="width: 70%"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input v-model="appid" placeholder="请输入appid" style="width: 70%" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">选择通道</span>
                        <el-select v-model="type" placeholder="请选择通道" size="mini">
                            <el-option v-for="item in options" :key="item.type" :label="item.label1" :value="item.type">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="leftItem" style="width: 390px;">
                        <span class="lab_tit">时间</span>
                        <el-date-picker size="mini" v-model="createdTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="right">

                    <el-button size="mini" round type="primary" icon="el-icon-search" @click="handleSearch">查找
                    </el-button>
                    <el-button size="mini" round type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                    <el-button size="mini" round type="primary" icon="el-icon-search" @click="sumMinute()">根据实际查询通话分钟数
                    </el-button>

                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange" show-summary
                        :summary-method="getSummaries">

                        <el-table-column prop="name" label="公司名称"></el-table-column>

                        <el-table-column prop="appid" label="appid"></el-table-column>
                        <el-table-column prop="time" label="使用分钟数"></el-table-column>
                        <el-table-column prop="sumMoney" label="使用金额"></el-table-column>
                        <el-table-column label="通道类型"><template #default="scope">{{$typeFilter(scope.row.type)}}</template></el-table-column>



                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
                            @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 根据时间查询总分钟数-->
        <el-dialog title="通话分钟数" v-model="sumTimeMoneyVisible" width="30%">
            总分钟<div><span style="color: red;font-size: 30px">{{ sumTime }}</span></div>
            总金额<div><span style="color: red;font-size: 30px">{{ sumMoney }}</span></div>
            <br />
            <div class="minute-count-btn">
                <el-button @click="sumTimeMoneyVisible = false" type="primary">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { options } from '@/utils/typeUtil.js'; //全局查找通道类型type
export default {
    name: "centerNum",
    data() {
        return {
            sumTime: null,
            sumMoney: null,
            sumTimeMoneyVisible: false,
            tableData: [],
            userId: null,
            appid: null,
            name: null,
            type: null,
            createdTime: null,
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量
            sta: null,
            end: null,
            options1: [
                // {
                //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
                //   type: 0,
                //   label1: "隐私小号通道",
                // },
                {
                    type: 5,
                    label1: "移动ax通道",
                },
                {
                    type: 2,
                    label1: "移动1通道",
                },
                {
                    type: 10,
                    label1: "移动3通道",
                },
                {
                    type: 1,
                    label1: "南京线通道",
                },
                {
                    type: 13,
                    label1: "移动5通道",
                },
                // {
                //   type: 3,
                //   label1: "联通2通道",
                // },
                // {
                //   type: 4,
                //   label1: "联通1通道",
                // },

                // {
                //   type: 6,
                //   label1: "联通3通道",
                // },
                // {
                //   type: 7,
                //   label1: "联通4通道",
                // },
                {
                    type: 8,
                    label1: "H",
                },
                {
                    type: 14,
                    label1: "S",
                },

                {
                    type: 15,
                    label1: "D",
                },

                {
                    type: 16,
                    label1: "S2",
                },
                {
                    type: 17,
                    label1: "SIP",
                },
                {
                    type: 18,
                    label1: "X",
                },
                {
                    type: 21,
                    label1: "2号线",
                },
                {
                    type: 22,
                    label1: "3号线",
                },
                // {
                //   type: 24,
                //   label1: "重庆线路",
                // },
                {
                    type: 25,
                    label1: "1号线",
                },
                {
                    type: 26,
                    label1: "无锡",
                },
            ],
            options:[]
        };
    },

    created() {
        let rootDate = new Date().toLocaleDateString()
        this.createdTime = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
        this.realname = localStorage.getItem("realname");
        // this.getList();
        this.options = options
    },
    //切换界面就执行方法
    activated() {
        // this.getList();
    },
    inject: ["reload"],
    methods: {
        // 根据时间查询总的分钟数
        sumMinute() {
            if (this.createdTime == null) {
                this.$message.error("请选择时间");
                return;
            }
            this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
            this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            this.sumTimeMoneyVisible = true;
            //  // console.log("=======", this.count);
            let params = {
                sta: this.sta,
                end: this.end,
                userId: this.userId,
                type: this.type,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/callLog/yd-one-minute-count", params)
                .post("api/callLog-expense/sum-timeMoney", params)
                .then((res) => {
                    if (null == res.data.data) {
                        this.sumTime = 0;
                        this.sumMoney = 0;
                    } else {
                        this.sumTime = res.data.data.time;
                        this.sumMoney = res.data.data.money;
                    }
                    this.$message.success(res.data.message);
                })
                .catch((err) => {
                });
            this.sumTime = "等待中";
            this.sumMoney = "等待中";
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },


        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.size = val;
            this.getList();
        },
        // 搜索
        handleSearch() {
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            this.getList();
        },
        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        //展示and查找
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                appid: this.appid,
                type: this.type,
                sta: this.sta,
                end: this.end,
            };
            axios
                .post("api/callLog-expense/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                });
        },

        //刷新按钮
        reset() {
            this.appid = null;
            this.name = null;
            this.type = null;
            this.createdTime = null;
            this.sta = null;
            this.end = null;

            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {

        .el-select,
        .el-date-editor {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            width: 560px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 176px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input {
                    // width: 120px;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    // width: 80px !important;
                    text-align: right;
                    margin-right: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }

        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
}

.upload-demo {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

// .left {
//     display: flex;
//     margin: 20px 0;
// }

// .item {
//     display: flex;
//     align-items: center;
// }

// .left span {
//     text-align: right;
//     margin-right: 5px;
// }

// .right {
//     text-align: right;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
