<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="通话记录"></el-tab-pane>
      </el-tabs>
      <!-- <h3 class="tit">通话记录</h3> -->
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">主叫</span>
            <el-input v-model="phone" placeholder="请输入主叫手机号" size="mini"></el-input>
          </div>

          <!-- <span class="lab_tit">绑定ID</span>
        <el-input v-model="callid" placeholder="请输入callid" size="mini"></el-input> -->
          <div class="leftItem">
            <span class="lab_tit">通道类型</span>
            <el-select v-model="thoroughfareType" placeholder="请选择通道类型" size="mini">
              <el-option v-for="item in options1" :key="item.type" :label="item.label1" :value="item.type">
              </el-option>
            </el-select>
          </div>
          <div class="leftItem">
            <span class="lab_tit">子企业编号</span>
            <!-- <el-input v-model="id" placeholder="子企业编号" size="mini"></el-input> -->
            <el-select v-model="id" placeholder="请选择企业" label-width="80px" size="mini" filterable>
              <el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </div>

          <div class="leftItem">
            <span class="lab_tit">被叫</span>
            <el-input v-model="passive_phone" placeholder="请输入被叫手机号" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">时间</span>
            <el-date-picker v-model="createdTime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"  @change="changeTime" :disabled-date="publishDateAfter" size="mini">
            </el-date-picker>
          </div>
        </div>

        <!-- type 通道类型  -->
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button type="primary" icon="el-icon-refresh" @click="reset()" round size="mini">重置</el-button>
        </div>
      </div>

      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column  type="selection" width="55" ></el-table-column>
            <el-table-column  prop="id" label="子企业编号" ></el-table-column>
            <el-table-column  prop="active_phone" label="主叫" ></el-table-column>
            <el-table-column  prop="passive_phone" label="被叫" ></el-table-column>
            <!--<el-table-column  prop="bind_phone" label="中间号" ></el-table-column>-->
            <el-table-column  prop="start_time" label="开始时间" >
              <template #default="scope">
                {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.start_time) }}
              </template>
            </el-table-column>
            <el-table-column  prop="release_time" label="结束时间" >
              <template #default="scope">
                {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.release_time) }}

              </template>
            </el-table-column>
            <el-table-column  label="录音"  prop="record_url">
              <template #default="scope">
                <el-button type="primary" icon="el-icon-search" @click="startaudio(scope)"
                  v-if="scope.row.record_url != '' && scope.row.record_url != '略'" class="searchBtn">查看录音</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next , sizes" :page-sizes="[10, 20, 50, 100, 200]"
              :current-page="pageIndex" :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"
              @size-change="handleSizeChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="录音" v-model="Showaudio" width="30%" :before-close="handleClose">
      <audio :src="currentAudio" controls="controls"></audio>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "callLog",
  data() {
    return {
      createdTime: null,
      thoroughfareType: null,
      passive_phone: null,
      tableData: [],
      multipleSelection: [],
      delList: [],
      address: "",
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      editVisible: false, //是否显示编辑/添加弹窗
      pageTotal: 0, //总共有多少数据
      form: {},
      phone: null,
      id: null,
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      callid: null,
      url: "",
      Showaudio: false,
      currentAudio: "",
      audioPaused: false,
      pickerOneDate: '',
      options1: [
        // {
        //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
        //   type: 0,
        //   label1: "隐私小号通道",
        // },

        // {
        //     type: 5,
        //     label1: "移动ax通道",
        // },
        {
          type: 1,
          label1: "南京线通道",
        },
        // {
        //   type: 2,
        //   label1: "移动1通道",
        // },
        // {
        //     type: 10,
        //     label1: "移动3通道",
        // },

        // {
        //     type: 13,
        //     label1: "移动5通道",
        // },
        // {
        //   type: 3,
        //   label1: "联通2通道",
        // },
        // {
        //   type: 4,
        //   label1: "联通1通道",
        // },

        // {
        //   type: 6,
        //   label1: "联通3通道",
        // },
        // {
        //   type: 7,
        //   label1: "联通4通道",
        // },
        // {
        //   type: 8,
        //   label1: "H",
        // },
        // {
        //   type: 14,
        //   label1: "S",
        // },
        //
        // {
        //   type: 15,
        //   label1: "D",
        // },

        // {
        //     type: 16,
        //     label1: "S2",
        // },

        {
          type: 17,
          label1: "SIP",
        },
        {
          type: 18,
          label1: "X",
        },
        {
            type: 20,
            label1: "甘肃",
        },
        {
          type: 21,
          label1: "2号线",
        },
        {
          type: 22,
          label1: "3号线",
        },
        // {
        //   type: 23,
        //   label1: "新2号线",
        // },
        // {
        //   type: 24,
        //   label1: "重庆线路",
        // },
        // {
        //   type: 25,
        //   label1: "1号线",
        // },
        // {
        //   type: 26,
        //   label1: "无锡",
        // },
        // {
        //   type: 27,
        //   label1: "翼信",
        // },
        {
          type: 28,
          label1: "连云港",
        },
      ],
      options2: [],
      sefuId: null,
    };
  },
  created() {
    this.sefuId = localStorage.getItem("id");
    this.changeAppid();
    let rootDate = new Date().toLocaleDateString()
    this.createdTime = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
    this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
    this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
    // this.getList();
  },

  watch: {
    phone() {
      if (this.phone == "") {
        this.phone = null;
      }
    },
    callid() {
      if (this.callid == "") {
        this.callid = null;
      }
    },
  },
  // computed: {
  //   luyin(boolean) {
  //     if (boolean == "略" || boolean == "") {
  //       return false;
  //     } else {
  //       return true;
  //     }
  //   },
  // },
  methods: {
    //子企业选择框
    changeAppid() {
      let params = {
        id: this.sefuId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/find-uuidbyuser", params)
        .post("api/uuid/find-uuidbyuser", params)
        .then((res) => {
          this.options2 = res.data.data;
           // console.log("资企业", res.data.data);
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    publishDateAfter(time) {
      const day = 30 * 24 * 3600 * 1000 // 90天
      if (this.pickerOneDate) {
        let maxTime = this.pickerOneDate + day
        let minTime = this.pickerOneDate - day
        return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
      } else {
        return time.getTime() > Date.now() || time.getTime() < Date.now() - day * 3
      }
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    startaudio(row) {
      if (row.row.record_url == "略") {
        this.$message.warning("暂无音频");
      } else {
        this.currentAudio = row.row.record_url;
        this.Showaudio = true;
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    getList() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      } else {
        this.$message.warning("请选择时间!");
        return false
      }
      let params = {
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        phone: this.phone,
        // call_id: this.callid,
        uuid: this.id,
        phoneB: this.passive_phone,
        type: this.thoroughfareType
      };

      axios
        // .post("http://106.14.0.130:9998/vo/callLog/list", params)
        .post("api/callLog/list2", params)
        .then((res) => {
           // console.log(res.data.data);
          //  // console.log(res.data.data.record_url)
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            if (res.data.statusCode != null) {

              this.$message.error(res.data.message);
            } else {
              this.tableData = res.data.data;
              this.pageTotal = res.data.total;
            }

          }
        })
        .catch((err) => {
           // console.log(err);
        });
    },

    //搜索查询
    handleSearch() {
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.passive_phone = null;
      this.phone = null;
      this.callid = null;
      this.id = null;
      this.thoroughfareType = null;
      this.getList();
    },
  },
};
</script>

<style scoped  lang="scss">
.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;


  .search {

    .el-select,
    .el-date-editor {
      width: 100%;
    }

    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;


    .left {
      width: 68%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .leftItem {
        width: 30%;
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .el-input__inner {
          border-radius: 25px;
          border: 1px solid #666;
        }

        .lab_tit {
                font-size: 12px;
          // font-weight: bold;
          white-space: nowrap;
          width: 90px;
          text-align: right;
          margin-right: 8px;
        }
      }
    }

    .right {
      text-align: right;
      width: 30%;

      .el-button {
        margin-bottom: 4px;
      }

      // display: flex;

    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      .el-table__row {
        td {
          padding: 4px 0;
        }
      }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }

// .left {
//   flex: 1;
//   display: flex;
//   align-items: center;
// }


// .left .left-row {
//   width: 20%;
//   display: flex;
//   align-items: center;
// }



// .left span {
//   min-width: 111px;
//   text-align: center;
// }

.searchBtn {
  margin-left: 15px;
}

.right {
  // width: 200px;
  // display: flex;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

div ::v-deep .el-dialog__body {
  display: flex;
  justify-content: center;
}
</style>
