<template>
    <div class="appPage">
        <!-- <h3 class="tit">2号线密钥</h3> -->
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="VOS质检管理"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">

                    <div class="leftItem">
                        <span class="lab_tit">地址</span>
                        <el-input v-model="url" placeholder="请输入地址" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">名称</span>
                        <el-input v-model="vosName" placeholder="请输入名称" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input v-model="appid" placeholder="请输入appid" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">ip</span>
                        <el-input v-model="ip" placeholder="请输入ip" size="mini"></el-input>
                    </div>
                </div>
                <div class="right">
                    <el-button type="warning" round size="mini" @click="getDuiJie_gateway">同步VOS对接网关</el-button>
                    <el-button type="primary" round size="mini" @click="get_zhijian_statusList()">质检密钥列表</el-button>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
                    <el-button type="primary" round size="mini" @click="open">添加</el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                              header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column prop="vosName" label="对接网关名称"></el-table-column>
                        <el-table-column prop="appid" label="appid"></el-table-column>
                        <el-table-column prop="ip" label="IP"></el-table-column>
                        <el-table-column prop="calleePrefix" label="被叫前缀"></el-table-column>
                        <el-table-column prop="status" label="状态" width="100">
                            <template #default="scope">
                <span
                        v-if="scope.row.status == 1"
                        style="color: #97a8be; font-size: 15px"
                >正常</span
                >
                                <span
                                        v-if="scope.row.status == 2"
                                        style="color: darkorange; font-size: 15px"
                                >关停</span
                                >
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" align="center">
                            <template #default="scope">
                                <el-button type="primary" @click="handleClick(scope)" size="mini">编辑</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                                       :page-size="pageSize"
                                       :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>


        <el-dialog
                :title="`质检密钥列表`"
                v-model="statusListVisible"
                width="50%"
        >
            <el-table
                    :data="tableStatusData"
                    border
                    header-cell-class-name="table-header"
                    height="500"
            >
                <el-table-column prop="id" label="ID"></el-table-column>
                <el-table-column
                        prop="name"
                        label="密钥名称"
                        width="280"
                ></el-table-column>
                <el-table-column prop="status" label="状态" width="100">
                    <template #default="scope">
                <span
                        v-if="scope.row.status == 0"
                        style="color: #97a8be; font-size: 15px"
                >关停</span
                >
                        <span
                                v-if="scope.row.status == 1"
                                style="color: darkorange; font-size: 15px"
                        >启动</span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100" align="center">
                    <template #default="scope">
                        <el-button type="primary" @click="get_zhijian_status_update(scope)" size="mini">锁定/解锁</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- <div style="text-align: right">
                    <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                      :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                      @current-change="handlePageChange"></el-pagination>
                  </div> -->
        </el-dialog>
        <!-- 添加弹出框 -->
        <el-dialog title="添加" v-model="addVisible" width="40%" @close="resetAdd">
            <el-form
                    ref="dataAddForm"
                    :model="form"
                    :rules="editRules"
                    label-width="100px"
            >
                <el-form-item label="对接网关名称">
                    <el-input v-model="form.vosName"></el-input>
                </el-form-item>
                <el-form-item label="appid" prop="areaCode">
                    <el-input v-model="form.appid"></el-input>
                </el-form-item>
                <el-form-item label="ip" prop="areaCode">
                    <el-input v-model="form.ip"></el-input>
                </el-form-item>
                <el-form-item label="被叫前缀" prop="areaCode">
                    <el-input v-model="form.calleePrefix"></el-input>
                </el-form-item>

                <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="请选择通道类型">
                        <el-option
                                v-for="item in options"
                                :key="item.type"
                                :label="item.label"
                                :value="item.type"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
            </template>
        </el-dialog>


        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="40%">
            <el-form
                    ref="dataAddForm"
                    :model="form"
                    :rules="editRules"
                    label-width="100px"
            >
                <el-form-item label="对接网关名称" prop="areaCode">
                    <el-input v-model="form.vosName"></el-input>
                </el-form-item>
                <el-form-item label="appid" prop="areaCode">
                    <el-input v-model="form.appid"></el-input>
                </el-form-item>
                <el-form-item label="ip" prop="areaCode">
                    <el-input v-model="form.ip"></el-input>
                </el-form-item>
                <el-form-item label="被叫前缀" prop="areaCode">
                    <el-input v-model="form.calleePrefix"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="form.status" placeholder="请选择通道类型">
                        <el-option
                                v-for="item in options"
                                :key="item.type"
                                :label="item.label"
                                :value="item.type"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
            </template>
        </el-dialog>


    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "centerNum",
        data() {
            return {
                url: "http://113.250.191.66:1616",
                ip: null,
                appid: null,
                vosName: null,
                status: null,
                optionsWeek: [],
                switchValue: false,
                form: {},
                zhiJianStatus: null,//密钥状态
                zhiJianId: null,//密钥状态
                tableStatusData: [],//用于接收后端数据表的数组
                statusListVisible: false,//密钥列表弹窗
                editVisible: false,//是否显示编辑弹窗
                addVisible: false,//是否显示添加弹窗
                pageIndex: 1,//当前在第几页
                pageSize: 10,//前端规定每页显示数量
                pageTotal: 0,//总共有多少数据
                tableData: [],//用于接收后端数据表的数组
                page: 1,//接口请求的页数
                size: 10,//接口请求的每页的数量
                id: null,
                city: null,
                options: [

                    {
                        type: 1,
                        label: "正常",
                    },
                    {
                        type: 2,
                        label: "停用",
                    },

                ],
            };
        },
        created() {

            this.getList();
        },
        //切换界面就执行方法
        activated() {
            this.getList()
        },
        watch: {},
        methods: {

            // 分页导航
            handlePageChange(val) {
                this.page = val;
                this.getList();
            },
            handleSearch() {
                this.getList();
            },
            //展示and查找
            getList() {
                let params = {
                    page: this.page,
                    size: this.size,
                    status: this.status,
                    ip: this.ip,
                    vosName: this.status,
                    appid: this.appid,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/xphone/list", params)
                    .post("api/param-vos/list", params)
                    .then((res) => {
                        if (res.data.success == false) {
                            this.$router.push('/403')
                        } else {
                            this.tableData = res.data.data
                            this.pageTotal = res.data.total
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    })
            },
            //添加
            submitAdd() {
                let params = {
                    ...this.form
                };
                axios.post("api/param-vos/add-update", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.$message.success(res.data.message)
                            this.getList()
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.addVisible = false
                    })

            },
            //编辑
            submitEdit() {
                let params = {
                    id: this.form.id,
                    appid: this.form.appid,
                    ip: this.form.ip,
                    vosName: this.form.vosName,
                    calleePrefix: this.form.calleePrefix,
                    status: this.form.status,
                };
                axios.post("api/param-vos/add-update", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.$message.success(res.data.message)
                            this.getList()
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.editVisible = false
                    })
            },
            //同步vos对接网关
            getDuiJie_gateway() {
                let params = {
                    url : this.url
                };
                axios.post("api/param-vos/getDuiJie-gateway", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.$message.success(res.data.message)
                            this.getList()
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
            },

            //查询质检列表
            get_zhijian_statusList() {
                let params = {};
                axios.post("api/quality/get-sendRecord-three-status", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.tableStatusData = res.data.data
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.statusListVisible = true
                    })
            },
            //修改质检列表
            get_zhijian_status_update(row) {
                this.zhiJianId = row.row.id
                this.zhiJianStatus = row.row.status
                if (null == this.zhiJianStatus) {
                    return;
                }
                if (0 == this.zhiJianStatus) {
                    this.zhiJianStatus = 1
                } else {
                    this.zhiJianStatus = 0
                }

                let params = {
                    id: this.zhiJianId,
                    status: this.zhiJianStatus,
                };
                axios.post("api/quality/update-sendRecord-three-status", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                        this.statusListVisible = false

                    })
            },
            //刷新按钮
            reset() {
                this.id = null;
                this.appid = null;
                this.ip = null;
                this.vosName = null;
                this.status = null;
                this.getList();
            },
            //打开添加弹窗
            open() {
                this.form = {};
                this.addVisible = true;
            },
            //打开编辑弹窗
            handleClick(row) {
                this.form = {};
                this.form = row.row
                // console.log("row", this.form);
                this.editVisible = true;
            }
        }
    };
</script>

<style scoped lang="scss">
    .frequency {
        display: flex;
    }

    .appPage {
        height: 100%;
    }

    ::v-deep .appPageMain {
        height: 100%;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        .search {
            .leftItem {
                width: 180px;

                .lab_tit {
                    // width: 110px !important;
                }
            }

            .left {
                width: 750px;
            }
        }

        .mainTable {
            flex: 1;
            display: flex;
            flex-direction: column;

            .table {
                flex: 1;
            }

            .mainTableFY {
                height: 40px;
                text-align: right;
                margin-top: 4px;
            }
        }
    }

    ::v-deep .titleBox {
        height: 40px;

        .el-tabs__item {
            font-size: 24px;
            font-weight: bold;
            color: #333333 !important;
        }
    }

    .tit {
        font-weight: normal;
        font-size: 36px;
        color: #333333;
    }

    // .search {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-top: 20px;
    // }

    // .left {
    //   display: flex;
    //   align-items: center;
    // }

    // .item {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    // .left span {
    //   margin-left: 15px;
    //   width: 100px;
    //   align-items: center;
    // }

    // .searchBtn {
    //   margin-left: 30px;
    // }

    // .right {
    //   display: flex;
    //   justify-content: flex-end;
    // }

    .reset {
        width: 80px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .add {
        margin-left: 15px;
    }

    .container {
        /* // margin-top: 20px; */
    }

    div ::v-deep .el-form-item__label {
        font-size: 13px !important;
    }
</style>
