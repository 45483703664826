<template>
    <div class="appPage">
        <!-- <h3 class="tit">D发送短信号码状态查询</h3> -->
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="D发送短信号码状态查询"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">主叫</span>
                        <el-input v-model="activePhone" placeholder="请输入主叫" size="mini"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">是否回复</span>
                        <el-select v-model="replyStatus" placeholder="选择状态" size="mini">
                            <el-option v-for="item in options" :key="item.replyStatus" :label="item.label"
                                :value="item.replyStatus">
                            </el-option>
                        </el-select>
                    </div>

                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                    <el-button type="primary" @click="whitenings()" round size="mini">批量发送短信</el-button>

                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">

                        <el-table-column prop="activePhone" label="主叫"></el-table-column>

                        <el-table-column prop="sendStatus" label="发送状态"></el-table-column>
                        <el-table-column prop="replyContent" label="回复内容"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间"></el-table-column>

                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>



        <!-- 批量发送短信验证 -->
        <el-dialog title="批量发送短信验证" v-model="numShow_sendSms_d" width="30%">
            <div class="content">
                <el-button type="success" icon="el-icon-download" @click="upload_sms()">点击下载模板</el-button>
                <el-upload class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
                    :before-remove="beforeRemove" :auto-upload="false" multiple :limit="1" :on-exceed="handleExceed"
                    :file-list="fileList" :on-change="changeUpload" drag="true" width="100%">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="numsSubmit_sendSms_d">确认提交</el-button>
                    <el-button @click="numShow_sendSms_d = false">退 出</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            numShow_sendSms_d: false, //D发送短信弹出框
            form: {},

            activePhone: null, //搜索框主叫
            createTime: null, //
            replyContent: null, //
            sendStatus: null, //
            replyStatus: null, //

            pageIndex: 1, //当前在第几页
            pageSize: 20, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 20, //接口请求的每页的数量
            options: [
                {
                    replyStatus: 0,
                    label: "未回复",
                },
                {
                    replyStatus: 1,
                    label: "已回复",
                },
            ],
        };
    },
    created() {
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        // this.getList()
    },
    watch: {
        industry() {
            if (this.industry == "") {
                this.industry = null;
            }
        },
        // bindPhone1() {
        //   if (this.bindPhone1 == "") {
        //     this.bindPhone1 = null;
        //   }
        // },
    },
    methods: {
        upload_sms() {
            window.open("http://paas.cddmt.cn:9005/sendSms.xls");
        },
        changeUpload(file, fileList) {
            this.file = file.raw;
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定释放 ${file.name}？`);
        },
        handlePreview(file) {
            // console.log(file);
        },
        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        // 发送验证码弹出框
        whitenings() {
            this.numShow_sendSms_d = true;
            this.formDate = null;
        },
        //提交发送D加白验证码号码
        numsSubmit_sendSms_d() {
            this.numShow = false;
            let formDate = new FormData();
            this.$message.success("数据加载中，稍等！请勿离开页面");
            formDate.append("file", this.file);
            axios
                // .post("http://106.14.0.130:9998/vo/call/send/import_white_d", formDate)
                .post("api/fs-sendSms/import-phone", formDate)
                .then((res) => {
                    // console.log(res.data);
                    this.$message.success(res.data.data);
                    // if (res.data.statusCode == "00000") {
                    //     this.$message.success(res.data.message);
                    //     this.file = {};
                    // } else {
                    //     this.$message.error(res.data.message);
                    //     this.file = {};
                    // }
                    this.numShow_sendSms_d = false
                })
                .catch((err) => {
                    this.$message.error(err.data.message);
                });
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSearch() {
            this.getList();
        },
        //展示and查找
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                activePhone: this.activePhone,
                replyStatus: this.replyStatus,
            };
            axios
                .post("api/fs-sendSms/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                    // console.log(err);
                });
        },


        //刷新按钮
        reset() {
            this.form = null;
            this.replyStatus = null;
            this.activePhone = null;
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .search {
        .leftItem {
            width: 184px;
        }

        .left {
            width: 750px;
        }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;
        }



        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

// .search {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
// }

// .left {
//     display: flex;
//     align-items: center;
// }

// .item {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .left span {
//     text-align: center;
//     width: 100px;
// }

// .searchBtn {
//     margin-left: 30px;
// }

// .right {
//     display: flex;
//     justify-content: flex-end;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    // margin-top: 20px;
}

.right {
    // text-align: right;
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
