<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="新2号线密钥"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">id</span>
            <el-input v-model="id" placeholder="请输入id" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">企业白名单</span>
            <el-input v-model="city" placeholder="请输入企业白名单" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>

        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
          <el-button type="primary" round size="mini" @click="open">添加</el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column prop="id" label="id"></el-table-column>
            <el-table-column prop="city" label="企业白名单"></el-table-column>
            <el-table-column prop="remark" label="频次"></el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template #default="scope">
                <el-button type="primary" @click="handleClick(scope)" size="mini">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex" :page-size="pageSize"
              :total="pageTotal" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="30%" @close="resetAdd">
      <el-form ref="dataAddForm" :model="form" :rules="rules" label-width="100px">
        <!-- <el-form-item label="中间号编号" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item> -->
        <el-form-item label="是否限制主叫" prop="bindPhone">
          <el-switch v-model="switchValue" active-text="是" inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="频次" prop="bindPhone">
          <div class="frequency">
            <el-select v-model="dayTime" placeholder="请选择周期">
              <el-option v-for="item in optionsDay" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="margin: 0 10px;">——</span>
            <el-select v-model="weekTime" placeholder="请选择次数">
              <el-option v-for="item in optionsWeek" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="企业白名单" prop="city">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入要限制的区域" v-model="form.city">
          </el-input>
        </el-form-item>
        <el-form-item label="appid" prop="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="appKey" prop="appKey">
          <el-input v-model="form.appKey"></el-input>
        </el-form-item>
        <el-form-item label="accountid" prop="accountid">
          <el-input v-model="form.accountid"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>




    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%" @close="resetAdd">
      <el-form ref="dataAddForm" :model="form" :rules="rules" label-width="100px">
        <!-- <el-form-item label="中间号编号" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item> -->
        <el-form-item label="是否限制主叫" prop="bindPhone">
          <el-switch v-model="switchValue" active-text="是" inactive-text="否">
          </el-switch>
        </el-form-item>
        <el-form-item label="频次" prop="bindPhone">
          <div class="frequency">
            <el-select v-model="dayTime" placeholder="请选择周期">
              <el-option v-for="item in optionsDay" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <span style="margin: 0 10px;">——</span>
            <el-select v-model="weekTime" placeholder="请选择次数">
              <el-option v-for="item in optionsWeek" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="企业白名单" prop="city">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5 }" placeholder="请输入要限制的区域" v-model="form.city">
          </el-input>
        </el-form-item>
        <el-form-item label="appid" prop="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="appKey" prop="appKey">
          <el-input v-model="form.appKey"></el-input>
        </el-form-item>
        <el-form-item label="accountid" prop="accountid">
          <el-input v-model="form.accountid"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      dayTime: null,
      weekTime: null,
      optionsDay: [{
        value: '日',
        label: '日'
      }, {
        value: '周',
        label: '周'
      }],
      optionsWeek: [],
      switchValue: false,
      form: {
      },
      editVisible: false,//是否显示编辑弹窗
      addVisible: false,//是否显示添加弹窗
      pageIndex: 1,//当前在第几页
      pageSize: 20,//前端规定每页显示数量
      pageTotal: 0,//总共有多少数据
      tableData: [],//用于接收后端数据表的数组
      page: 1,//接口请求的页数
      size: 20,//接口请求的每页的数量
      id: null,
      city: null,
    };
  },
  created() {
    this.setWeekTime()
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList()
  },
  watch: {
    city() {
      if (this.city == "") {
        this.city = null;
      }
    },
    id() {
      if (this.id == "") {
        this.id = null;
      }
    },
  },
  methods: {
    resetAdd() {
      this.switchValue - false
      this.form = {}
      this.dayTime = null
      this.weekTime = null
    },
    setWeekTime() {
      for (let i = 1; i <= 10; ++i) {
        this.optionsWeek.push({
          value: i,
          label: i
        })
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        city: this.city,
        id: this.id,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphone/list", params)
        .post("api/paramYdTwoNew/list", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push('/403')
          } else {
            this.tableData = res.data.data
            this.pageTotal = res.data.total
          }
        })
        .catch((err) => {
          // console.log(err);
        })
    },
    //添加
    submitAdd() {
      if (!(this.dayTime && this.weekTime)) {
        this.$message.warning("频次不能为空~")
        return false
      }

      let remark = this.dayTime + "-" + this.weekTime
      if (this.switchValue) {
        remark = "主叫" + remark
      }

      let params = {
        remark,
        ...this.form
      };
      // axios.post("http://106.14.0.130:9998/vo/xphone/add",params)
      axios.post("api/paramYdTwoNew/add", params)
        .then(res => {
          if (res.data.statusCode == '00000') {
            this.$message.success(res.data.message)
            this.getList()
          } else {
            this.$message.error(res.data.message)
          }
          this.addVisible = false
        })

    },
    //编辑
    submitEdit() {
      let params = {}

      if (this.dayTime && this.weekTime) {
        // 编辑手动填值时
        // this.$message.warning("频次不能为空~")
        let remark = this.dayTime + "-" + this.weekTime
        if (this.switchValue) {
          params.remark = "主叫" + remark
        }
      } else {
        // 没有修改过时
        params.remark = this.form.remark
        params.id = this.form.id
        params.city = this.form.city

      }

      axios.post("api/paramYdTwoNew/update", params)
        .then(res => {
          if (res.data.statusCode == '00000') {
            this.$message.success(res.data.message)
            this.getList()
          } else {
            this.$message.error(res.data.message)
          }
          this.editVisible = false
        })
    },
    //刷新按钮
    reset() {
      this.id = null;
      this.city = null;
      this.getList();
    },
    //打开添加弹窗
    open() {
      this.addVisible = true;
    },
    //打开编辑弹窗
    handleClick(row) {
      // this.form = {
      //   id: row.row.id,
      //   city: row.row.city
      // }
      this.form = row.row
      // console.log("row", this.form);
      this.editVisible = true;
    }
  }
};
</script>

<style scoped lang="scss">
.frequency {
  display: flex;
}


.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .search {
        .leftItem {
            width: 220px;

            .lab_tit {
                width: 70px !important;
            }
        }

        .left {
            width: 750px;
        }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;
        }



        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }

// .left {
//   display: flex;
//   align-items: center;
// }

// .item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .left span {
//   margin-left: 15px;
//   width: 100px;
//   align-items: center;
// }

// .searchBtn {
//   margin-left: 30px;
// }

// .right {
//   display: flex;
//   justify-content: flex-end;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

div  ::v-deep .el-form-item__label {
  font-size: 13px !important;
}
</style>
