<template>
  <div class="appPage">
    <!-- <h3 class="tit">D线路号码加白次数设置</h3> -->
    <!-- <div class="search"> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="线路号码加白次数设置"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <!--  <div class="item">
          <span>号码查询</span>
          <el-input
            v-model="phone"
            placeholder="请输入号码"
            style="width: 70%"
          ></el-input>
        </div>-->
          <div class="leftItem">
            <span class="lab_tit">代理id</span>
            <el-input
              v-model="userId"
              placeholder="请输入代理id"
              size="mini"
            ></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input
              v-model="appid"
              placeholder="请输入appid"
              size="mini"
            ></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">企业名称</span>
            <el-input
              v-model="name"
              placeholder="请输入企业名称"
              size="mini"
            ></el-input>
          </div>
          <!--<div class="item">
          <span>选择状态</span>
          <el-select v-model="status" placeholder="选择状态">
            <el-option
                    v-for="item in options"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status"
            >
            </el-option>
          </el-select>
        </div>-->
          <!-- <div class="item">
          <span>时间</span>
          <el-date-picker
            v-model="createdTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 70%"
          >
          </el-date-picker>
        </div>-->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            round
            size="mini"
            >重置</el-button
          >
          <el-button type="primary" @click="whitening()" round size="mini"
            >添加</el-button
          >
          <!--<el-button type="primary" @click="whitenings()">批量加白</el-button>-->
          <!--<el-button type="primary" @click="excel()">导出</el-button>-->
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="userId" label="代理id"></el-table-column>
            <el-table-column prop="name" label="公司名称"></el-table-column>
            <el-table-column prop="appid" label="appid"></el-table-column>
            <!-- <el-table-column prop="type" label="通道"></el-table-column> -->
            <el-table-column label="通道"
              ><template #default="scope">{{
                $typeFilter(scope.row.type)
              }}</template></el-table-column
            >

            <el-table-column prop="topKey" label="上游密钥"></el-table-column>
            <el-table-column prop="count" label="剩余次数"></el-table-column>
            <el-table-column
              prop="sumCount"
              label="使用总次数"
            ></el-table-column>
            <!--  <el-table-column
          prop="createdTime"
          label="创建时间"
        ></el-table-column>-->
            <!--  <el-table-column
          prop="status"
          label="号码状态"
          
        >
          <template #default="scope">
            <span v-if="scope.row.status == 1">正常</span>
            <span v-if="scope.row.status == 2">退订</span>
          </template>
        </el-table-column>-->
            <el-table-column
              prop="changeTime"
              label="最近修改时间"
            ></el-table-column>
            <el-table-column
              label="操作"
              width="100"
              align="center"
              style="dispaly: flex"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="unsubscribe(scope)"
                  size="mini"
                  >编辑</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next,sizes"
              :current-page="pageIndex"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200]"
              :total="pageTotal"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 加白弹出框 -->
    <el-dialog title="加白" v-model="whiteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="次数">
          <el-input v-model="form.count"></el-input>
        </el-form-item>
        <el-form-item label="通道类型">
          <!-- <el-input v-model="form.topKey" @input="getCompany"></el-input> -->
          <el-select filterable v-model="form.type" style="width: 100%">
            <el-option
              v-for="item in typeOption"
              :key="item.type"
              :label="item.label1"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上游密钥">
          <!-- <el-input v-model="form.topKey" @input="getCompany"></el-input> -->
          <el-select
            filterable
            v-model="form.topKey"
            style="width: 100%"
            placeholder="请先选择通道类型"
          >
            <el-option
              v-for="item in topKeyList"
              :key="item.Id"
              :label="item.companyName"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" v-if="form.type == 22">
          <el-input v-model="enterpriseName"></el-input>
        </el-form-item>

        <!-- typeOption -->

        <!--   <el-form-item label="坐席id">
          <el-input v-model="form.seatPhoneId"></el-input>
        </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="whiteVisible = false">取 消</el-button>
          <el-button type="primary" @click="whiteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 加白弹出框 -->
    <el-dialog
      title="编辑"
      v-model="updateVisible"
      width="30%"
      @closed="updateForm = {}"
    >
      <el-form ref="form" :model="updateForm" label-width="70px">
        <!--  <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>-->
        <el-form-item label="次数">
          <el-input v-model="updateForm.count"></el-input>
        </el-form-item>
        <el-form-item label="通道类型">
          <!-- <el-input v-model="form.topKey" @input="getCompany"></el-input> -->
          <el-select filterable v-model="updateForm.type" style="width: 100%">
            <el-option
              v-for="item in typeOption"
              :key="item.type"
              :label="item.label1"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上游密钥">
          <!-- <el-input v-model="updateForm.topKey"></el-input> -->
          <el-select
            filterable
            v-model="updateForm.topKey"
            style="width: 100%"
            placeholder="请先选择通道类型"
          >
            <el-option
              v-for="item in topKeyList"
              :key="item.Id"
              :label="item.companyName"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" v-if="updateForm.type == 22">
          <el-input v-model="enterpriseName"></el-input>
        </el-form-item>

        <!--   <el-form-item label="坐席id">
             <el-input v-model="form.seatPhoneId"></el-input>
           </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { options } from "@/utils/typeUtil.js";

export default {
  name: "centerNum",
  data() {
    return {
      enterpriseName: "",
      userId: null,
      topKeyList: [],
      queryOne: false,
      updateForm: {},
      form: {},
      numShow: false,
      whiteVisible: false,
      updateVisible: false,
      // phone: null,
      appid: null,
      seatPhoneId: null,
      changeTime: null,
      name: null,
      count: null,
      sumCount: null,
      activePhone: "", //搜索框主叫
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      sta: null,
      end: null,
      typeOption: [], // 通道类型
      options: [
        {
          status: 1,
          label: "正常",
        },
        {
          status: 2,
          label: "退订",
        },
      ],
    };
  },
  watch: {
    "updateForm.type": {
      handler: function (value) {
        // 通过value参数(参数名可以自己命名)接受侦听数据的最新值
        console.log(value);
        if (value == 22) {
          // 查询三号线密钥 22
          this.getAllocationKay(value);
        } else if (value == 21) {
          // 查询二号线密钥 21
          this.getCompany();
        }
      },
      // 代表在wacth里声明了username这个方法之后立即先去执行handler方法
      immediate: true,
    },
    "form.type": {
      handler: function (value) {
        // 通过value参数(参数名可以自己命名)接受侦听数据的最新值
        console.log(value);
        if (value == 22) {
          // 查询三号线密钥 22
          this.getAllocationKay(value);
        } else if (value == 21) {
          // 查询二号线密钥 21
          this.getCompany();
        }
      },
      // 代表在wacth里声明了username这个方法之后立即先去执行handler方法
      immediate: true,
    },
  },
  created() {
    this.getList();
    this.typeOption = options;
    // this.getCompany();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  inject: ["reload"],
  methods: {
    //点击通道类型时 查询三号线密钥
    getAllocationKay(type) {
      //  // console.log(type)
      let params = {
        type,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/list", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          console.log("三号线", res.data);
          // Id  companyName  value
          this.topKeyList = res.data.data.map((v) => {
            v.companyName = v.appid;
            v.value = v.id;
            return v;
          });
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //下载模板
    upload() {
      window.open("http://syshb.cddmt.cn:9005/white-d.xls");
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 批量加白
    whitenings() {
      this.numShow = true;
      this.formDate = null;
    },
    // 查询二号线配置密钥
    getCompany() {
      // 只需要查询一次
      // if (this.queryOne) {
      //   return false;
      // }
      axios
        .post("api/call/get-company-yd-two", {})
        .then((res) => {
          // console.log(res.data);
          if (res.data.statusCode == "00000") {
            // this.queryOne = true; // 查询成功后不需要再查询
            this.topKeyList = res.data.data.map((v) => {
              v.value = v.id + "-" + v.companyName;
              return v;
            });
            // this.$message.success(res.data.message);
          } else {
            // this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // this.$message.error(err.data.message);
        });
    },
    numsSubmit() {
      this.numShow = false;
      let formDate = new FormData();
      this.$message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      axios
        // .post("http://106.14.0.130:9998/vo/call/send/import_white_d", formDate)
        .post("api/call/send/import_white_d", formDate)
        .then((res) => {
          // console.log(res.data);
          if (res.data.statusCode == "000000") {
            this.$message.success(res.data.message);
            this.file = {};
          } else {
            this.$message.error(res.data.message);
            this.file = {};
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    // 加白
    whitening() {
      // this.getCompany();
      this.whiteVisible = true;
      this.form = {
        count: 0,
      };
      // this.$set(this.form, "expand", val);
    },
    whiteSubmit() {
      let params = {
        // appid: this.form.appid,
        // count: this.form.count,
        ...this.form,
      };

      if (this.form.type == 22) {
        params.topKey = this.form.topKey + "-" + this.enterpriseName;
      }

      // console.log("测试",this.form);
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD-count/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.whiteVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {});
    },

    //导出
    excel() {
      if (this.createdTime) {
        this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      let params = {
        sta: this.sta1,
        end: this.end1,
      };
      // console.log(params)

      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD/export-whitephoneD", params, {
          responseType: "blob",
        })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers["content-disposition"];

          a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);

          a.href = url;

          a.click();
        });
      this.reload();
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 搜索
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        appid: this.appid,
        userId: this.userId,
        name: this.name,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/whitePhoneD-count/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {});
    },
    //编辑按钮
    unsubscribe(row) {
      // this.getCompany();
      this.updateId = row.row.id;
      this.updateForm = {
        count: 0,
      };
      // this.$set(this.updateForm, "count", 0);
      this.updateVisible = true;
    },
    updateSubmit() {
      let params = {
        id: this.updateId,
        // count: this.updateForm.count,
        ...this.updateForm,
      };

      if (this.updateForm.type == 22) {
        params.topKey = this.updateForm.topKey + "-" + this.enterpriseName;
      }

      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
        .post("api/whitePhoneD-count/update", params)
        .then((res) => {
          this.$message.success(res.data.message);
          this.updateVisible = false;

          this.getList();
        })
        .catch((err) => {});
    },
    //刷新按钮
    reset() {
      this.name = null;
      this.appid = null;
      this.userId = null;

      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 184px;
    }

    .left {
      width: 750px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.upload-demo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .left {
//   display: flex;
//   margin: 20px 0;
// }

// .item {
//   display: flex;
//   align-items: center;
// }

// .left span {
//   text-align: right;
//   margin-right: 5px;
// }

// .right {
//   text-align: right;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
