<template>
  <div class="appPage">
    <!-- <h3 class="tit">H号码管理</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="H号码管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">中间号码</span>
            <el-input v-model="bindPhone1" placeholder="请输入中间号码" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <!-- <div class="leftItem">
          <span>主叫</span>
          <el-input
            v-model="activePhone"
            placeholder="请输入主叫"
            size="mini"
            v-on:keyup.enter="handleSearch"
          ></el-input>
        </div> -->

          <div class="leftItem">
            <span class="lab_tit">备注</span>
            <el-input v-model="industry" placeholder="请输入备注" size="mini" v-on:keyup.enter="handleSearch1"></el-input>
          </div>

        </div>
        <div class="right">

          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch1" round size="mini">备注查找</el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
          <el-button type="primary" round size="mini" @click="open()">绑定中间号</el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column prop="id" label="中间号编号"></el-table-column>
            <!-- <el-table-column
          prop="activePhone"
          label="主叫"
          
        ></el-table-column> -->
            <el-table-column prop="bindPhone" label="中间号码"></el-table-column>
            <el-table-column prop="industry" label="备注"></el-table-column>
            <el-table-column label="操作" width="200" align="center">
              <template #default="scope">
                <el-button type="primary" @click="handleClick(scope)" size="mini">编辑</el-button>
                <el-button type="primary" @click="del(scope)" size="mini">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex" :page-size="pageSize"
              :total="pageTotal" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 绑定中间号弹出框 -->
    <el-dialog title="绑定中间号" v-model="addVisible" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="中间号码" prop="bindPhone">
          <!-- <el-select v-model="bindPhone" filterable>
            <el-option
              @click="clickSelect(item)"
              v-for="item in Options"
              :key="item.id"
              :label="item.bindPhone"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <el-input v-model="bindPhone"></el-input>
        </el-form-item>

        <!-- <el-form-item label="主叫" prop="activePhone">
          <el-input v-model="form.activePhone"></el-input>
        </el-form-item> -->

        <el-form-item label="备注" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
        <el-form-item label="中间号码" prop="bindPhone">
          <!-- <el-select v-model="form.bindPhone" filterable>
            <el-option
              @click="clickSelect1(item)"
              v-for="item in Options"
              :key="item.id"
              :label="item.bindPhone"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>

        <!-- <el-form-item label="主叫" prop="areaCode">
          <el-input v-model="form.activePhone"></el-input>
        </el-form-item> -->
        <el-form-item label="备注" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="次数" prop="count">
          <el-input v-model="form.count"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      areaCode: "",
      form: {
        id: "",
        bindPhone: "",
        activePhone: "",
        industry: "",
        count: "",
        areaCode: "",
      },
      paramid: 1,
      // activePhone: null, //搜索框主叫
      industry: null,
      editVisible: false, //是否显示编辑弹窗
      addVisible: false, //是否显示添加弹窗
      pageIndex: 1, //当前在第几页
      pageSize: 20, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 20, //接口请求的每页的数量
      bindPhone: null,
      bindPhone1: null, //搜索框的中间号
      count: null,
      Options: [],
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    industry() {
      if (this.industry == "") {
        this.industry = null;
      }
    },
    bindPhone1() {
      if (this.bindPhone1 == "") {
        this.bindPhone1 = null;
      }
    },
  },
  methods: {
    clickSelect(item) {
      this.areaCode = item.areaCode;
      this.bindPhone = item.bindPhone;
    },
    clickSelect1(item) {
      this.areaCode = item.areaCode;
      this.form.bindPhone = item.bindPhone;
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    handleSearch1() {
      let params = {
        industry: this.industry,
      };
      if (params.industry) {
        this.screen("industry", params.industry);
      } else {
        this.getList();
        return false;
      }
    },
    // 查找筛选
    screen(name, params) {
      let data = [...this.tableData];
      let arr = [];
      //  // console.log(data);
      data.forEach((v) => {
        //  // console.log("名字", v[name]);
        if (JSON.stringify(v[name]).indexOf(params) != -1) {
          arr.push(v);
        }
      });
      this.tableData = arr;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        activePhone: this.activePhone,
        bindPhone: this.bindPhone1,
        industry: this.industry,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneH/list", params)
        .post("api/xphoneH/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //打开绑定中间号弹窗
    open() {
      this.form = [];
      this.areaCode = "";
      this.addVisible = true;
      // axios
      //   .post("http://106.14.0.130:9998/vo/xphoneltOneX/get_lt2_bindPhone")
      //   .then((res) => {
      //     res.data.data.forEach((item, index) => {
      //       item.id = index;
      //     });
      //     this.Options = res.data.data;
      //   });
    },
    //绑定中间号确定按钮
    submitAdd() {
      let params = {
        bindPhone: this.bindPhone,
        activePhone: this.form.activePhone,
        industry: this.form.industry,
        areaCode: this.areaCode,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneH/add", params)
        .post("api/xphoneH/add", params)
        .then((res) => {
          // console.log(res);
          if (res.data.statusCode == "00000") {
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.addVisible = false;
          this.form = {};
          this.bindPhone = null;
        });
    },
    //打开编辑弹窗
    handleClick(row) {
      this.editVisible = true;
      this.id = row.row.id;
      this.form.activePhone = row.row.activePhone;
      this.form.bindPhone = row.row.bindPhone;
      this.form.industry = row.row.industry;
      this.form.count = row.row.count;
      this.areaCode = row.row.areaCode;
      //   axios
      //     .post("http://106.14.0.130:9998/vo/xphoneltOneX/get_lt2_bindPhone")
      //     .then((res) => {
      //        // console.log(res.data.data);
      //       res.data.data.forEach((item, index) => {
      //         item.id = index;
      //       });
      //       this.Options = res.data.data;
      //     });
    },
    //编辑确定按钮
    submitEdit() {
      let params = {
        id: this.id,
        activePhone: this.form.activePhone,
        industry: this.form.industry,
        count: this.form.count,
        bindPhone: this.form.bindPhone,
        areaCode: this.areaCode,
      };
      //    // console.log(this.form.industry);
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneH/update", params)
        .post("api/xphoneH/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible = false;
        });
    },
    //删除按钮
    del(row) {
      let params = {
        id: row.row.id,
        activePhone: row.row.activePhone,
      };

      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/xphoneH/del", params)
            .post("api/xphoneH/del", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    //刷新按钮
    reset() {
      this.bindPhone1 = null;
      this.activePhone = null;
      this.industry = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 184px;
    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }



    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }
// .left {
//   display: flex;
//   align-items: center;
// }
// .item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .left span {
//   text-align: center;
//   width: 100px;
// }
// .searchBtn {
//   margin-left: 30px;
// }
// .right {
//   display: flex;
//   justify-content: flex-end;
// }
.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
