<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="质检员管理"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">

                    <div class="leftItem">
                        <span class="lab_tit">客服id</span>
                        <el-input v-model="operatorsId" placeholder="请输入客服id" size="mini"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">代理id</span>
                        <el-input v-model="userId" placeholder="请输入代理id" size="mini"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>


                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini" round>查找
                    </el-button>
                    <el-button size="mini" round type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                    <el-button type="primary" size="mini" round @click="open()">添加
                    </el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column prop="operators_id" label="客服id"></el-table-column>
                        <el-table-column prop="operatorsName" label="客服名称"></el-table-column>
                        <el-table-column prop="user_id" label="代理id"></el-table-column>
                        <el-table-column prop="userName" label="代理名称"></el-table-column>
                        <el-table-column prop="createdTime" label="创建时间">
                            <template v-slot="scope">
                                <div>
                                    {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.created_time) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="300" align="center">
                            <template #default="scope">
                                <el-button type="primary" @click="handleClick(scope)">编辑
                                </el-button>
                                <el-button type="primary" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- 绑定中间号弹出框 -->
        <el-dialog title="添加" v-model="addVisible" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="rules" label-width="80px">
                <el-form-item label="客服id" prop="operatorsId">
                    <el-input v-model="form.operatorsId"></el-input>
                </el-form-item>
                <el-form-item label="代理id" prop="userId">
                    <el-input v-model="form.userId"></el-input>
                </el-form-item>

            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitAdd()">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
                <el-form-item label="客服id" prop="operatorsId">
                    <el-input v-model="form.operatorsId"></el-input>
                </el-form-item>
                <el-form-item label="代理id" prop="userId">
                    <el-input v-model="form.userId"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitEdit()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            form: {},
            userName: null,
            operatorsName: null,
            operatorsId: null,
            operators_id: null,
            user_id: null,
            userId: null,
            editVisible: false, //是否显示编辑弹窗
            addVisible: false, //是否显示添加弹窗
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量

        };
    },
    created() {
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        // this.getList()
    },
    watch: {
        industry() {
            if (this.industry == "") {
                this.industry = null;
            }
        },

    },
    methods: {

        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSearch() {
            this.getList();
        },
        //展示and查找
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                userId: this.user_id,
                operatorsId: this.operators_id,
            };
            axios
                .post("api/quality-operators/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;


                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        //打开绑定中间号弹窗
        open() {
            this.addVisible = true;

        },
        //添加
        submitAdd() {
            let params = {
                userId: this.form.userId,
                operatorsId: this.form.operatorsId,
            };
            axios
                .post("api/quality-operators/add", params)
                .then((res) => {
                    // console.log(res);
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.addVisible = false;
                    this.form = {};
                });
            getList();
        },
        //打开编辑弹窗
        handleClick(row) {
            this.editVisible = true;
            this.id = row.row.id;
            this.form.userId = row.row.user_id;
            this.form.operatorsId = row.row.operators_id;
        },
        //编辑确定按钮
        submitEdit() {
            let params = {
                id: this.id,
                userId: this.form.userId,
                operatorsId: this.form.operatorsId,
            };
            axios
                .post("api/quality-operators/update", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.editVisible = false;
                });
        },
        //删除按钮
        del(row) {
            let params = {
                id: row.row.id,
            };

            this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    axios
                        .post("api/quality-operators/del", params)
                        .then((res) => {
                            if (res.data.statusCode == "00000") {
                                this.$message({
                                    type: "success",
                                    message: "删除成功!",
                                });
                                this.getList();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.data.message);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                    this.getList();
                });
        },
        //刷新按钮
        reset() {
            this.userId = null;
            this.operatorsId = null;
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            width: 700px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 200px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 62px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }

        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

// .search {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
// }

// .left {
//     display: flex;
//     align-items: center;
// }

// .item {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .left span {
//     text-align: center;
//     width: 100px;
// }

// .searchBtn {
//     margin-left: 30px;
// }

// .right {
//     display: flex;
//     justify-content: flex-end;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    // margin-top: 20px;
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
