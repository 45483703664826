<template>
  <div class="appPage">
    <!-- <h3 class="tit">D线路号码加白次数设置</h3> -->
    <!-- <div class="search"> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="提醒记录列表"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <!--  <div class="item">
          <span>号码查询</span>
          <el-input
            v-model="phone"
            placeholder="请输入号码"
            style="width: 70%"
          ></el-input>
        </div>-->
          <div class="leftItem">
            <span class="lab_tit">主题</span>
            <el-input
              v-model="liquery.theme"
              placeholder="请输入完整的主题"
              size="mini"
            ></el-input>
          </div>

          <!-- <span v-if="scope.row.type == 1">号码</span> -->
          <!-- <span v-if="scope.row.type == 2">公司</span> -->

          <div class="leftItem">
            <span class="lab_tit">提醒类型</span>
            <el-select
              v-model="liquery.type"
              placeholder="请选择提醒类型"
              size="mini"
            >
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <!-- <div class="item">
          <span>时间</span>
          <el-date-picker
            v-model="createdTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 70%"
          >
          </el-date-picker>
        </div>-->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            round
            size="mini"
            >重置</el-button
          >
          <el-button type="primary" @click="whitening('号码')" round size="mini"
            >添加号码提醒</el-button
          >
          <el-button type="primary" @click="whitening('企业')" round size="mini"
            >添加企业提醒</el-button
          >
          <!--<el-button type="primary" @click="whitenings()">批量加白</el-button>-->
          <!--<el-button type="primary" @click="excel()">导出</el-button>-->
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
          >
            <!-- <el-table-column prop="id" label="代理id"></el-table-column> -->
            <el-table-column prop="theme" label="主题"></el-table-column>
            <el-table-column prop="body" label="内容">
              <template #default="scope">
                <span v-if="scope.row.type == 1">
                  <div
                    v-for="item in scope.row.body.split(':')"
                    style="font-weight: bold"
                  >
                    {{ item }}
                  </div>
                </span>
                <span v-if="scope.row.type == 2"> {{ scope.row.body }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
            <!-- <el-table-column
              prop="sumCount"
              label="使用总次数"
            ></el-table-column> -->
            <!--  <el-table-column
          prop="createdTime"
          label="创建时间"
          
        ></el-table-column>-->

            <el-table-column
              prop="createTime"
              label="创建时间"
            ></el-table-column>

            <el-table-column label="提醒类型" width="100" fixed="right">
              <template #default="scope">
                <span v-if="scope.row.type == 1">号码</span>
                <span v-if="scope.row.type == 2">公司</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              label="操作"
              width="100"
              align="center"
              fixed="right"
              style="dispaly: flex"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="unsubscribe(scope)"
                  size="mini"
                  >编辑</el-button
                >
              </template>
            </el-table-column> -->
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next,sizes"
              :current-page="pageIndex"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200]"
              :total="pageTotal"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加提醒号码弹出框 -->
    <el-dialog title="添加提醒号码" v-model="whiteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <!-- remindPhoneList -->
        <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="次数">
          <el-input v-model="form.count"></el-input>
        </el-form-item>
        <!--   <el-form-item label="坐席id">
          <el-input v-model="form.seatPhoneId"></el-input>
        </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="whiteVisible = false">取 消</el-button>
          <el-button type="primary" @click="whiteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 加白弹出框 -->
    <el-dialog title="编辑" v-model="updateVisible" width="30%">
      <el-form ref="form" :model="updateForm" label-width="70px">
        <!--  <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>-->
        <el-form-item label="次数">
          <el-input v-model="updateForm.count"></el-input>
        </el-form-item>
        <!--   <el-form-item label="坐席id">
             <el-input v-model="form.seatPhoneId"></el-input>
           </el-form-item>-->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="updateVisible = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 提醒号码 弹出框 -->
    <el-dialog
      :lock-scroll="false"
      title="提醒号码列表"
      v-model="remindPhoneVisible"
      @closed="remindPhoneIndex = null"
      width="70%"
    >
      <div
        style="
          display: flex;
          align-items: center;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
        "
      >
        <span style="font-size: 13px">号码: &nbsp;</span>
        <el-select
          filterable
          v-model="remindPhoneIndex"
          size="mini"
          style="flex: 1"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="(item, index) in oldRemindList"
            :key="index"
            :label="item.theme"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="setRList" size="mini"
          >搜索</el-button
        >

        <el-button type="primary" @click="allAllRemind" size="mini"
          >批量添加</el-button
        >
      </div>
      <hr style="margin-top: 10px" />
      <el-table
        ref="Table1"
        :data="remindPhoneList"
        class="userTable"
        style="width: 100%"
        height="450px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          align="left"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column label="号码" align="left">
          <template #default="scope">
            <span>{{ scope.row.theme }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业APPID" align="left">
          <template #default="scope">
            <div
              v-for="item in scope.row.body.split(':')"
              style="font-weight: bold"
            >
              {{ item }}
            </div>
            <!-- <span>{{ scope.row.body }}</span> -->
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="190"
          align="center"
          style="dispaly: flex"
        >
          <template #default="scope">
            <el-button type="primary" @click="addRemind(scope.row)" size="mini"
              >添加提醒</el-button
            >
            <el-button
              type="primary"
              @click="queryEnterprise(scope.row)"
              size="mini"
              >查询企业</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 提醒企业 弹出框 -->
    <el-dialog
      :lock-scroll="false"
      title="提醒企业列表"
      v-model="remindUuidVisible"
      @closed="resetData"
      width="70%"
    >
      <div
        style="
          display: flex;
          align-items: center;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
        "
      >
        <span style="font-size: 13px">企业名称: &nbsp;</span>
        <el-select
          filterable
          v-model="remindPhoneIndex"
          size="mini"
          style="flex: 1"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="(item, index) in oldRemindList"
            :key="index"
            :label="item.name"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="setRList" size="mini"
          >搜索</el-button
        >

        <el-button type="primary" @click="allAllRemind" size="mini"
          >批量添加</el-button
        >
      </div>
      <hr style="margin-top: 10px" />
      <el-table
        ref="Table1"
        :data="remindPhoneList"
        class="userTable"
        style="width: 100%"
        height="450px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          show-overflow-tooltip
          type="selection"
          align="left"
          :selectable="selectable"
        ></el-table-column>
        <el-table-column label="代理ID" align="left">
          <template #default="scope">
            <span>{{ scope.row.user_id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业名称" align="left">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="appid" align="left">
          <template #default="scope">
            <span>{{ scope.row.theme }}</span>
          </template>
        </el-table-column>
        <el-table-column label="使用坐席数:现有坐席数" align="left">
          <template #default="scope">
            <span>{{ scope.row.body }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="企业APPID" align="left">
          <template #default="scope">
            <div
              v-for="item in scope.row.body.split(':')"
              style="font-weight: bold"
            >
              {{ item }}
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          label="操作"
          width="190"
          align="center"
          style="dispaly: flex"
        >
          <template #default="scope">
            <el-button type="primary" @click="addRemind(scope.row)" size="mini"
              >添加提醒</el-button
            >
            <el-button
              type="primary"
              v-if="remindType == '号码'"
              @click="queryEnterprise(scope.row)"
              size="mini"
              >查询企业</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog
      :lock-scroll="false"
      :title="addType + '添加提醒'"
      v-model="followUpFormVisible"
      @closed="bodyContent = ''"
      width="50%"
    >
      <el-form label-width="100px">
        <el-form-item label="备注事由">
          <el-input
            type="textarea"
            class="textareaMinHeight"
            v-model="bodyContent"
            show-word-limit
            size="mini"
            maxlength="300"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="followUpFormVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="addRemindFn">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :lock-scroll="false"
      title="企业列表"
      v-model="enterpriseListVisible"
      @closed="queryEnterpriseName = null"
      width="60%"
    >
      <div
        style="
          display: flex;
          align-items: center;
          border: 1px solid #dcdfe6;
          border-radius: 5px;
        "
      >
        <span style="font-size: 13px">企业名称: &nbsp;</span>
        <el-select
          filterable
          v-model="queryEnterpriseName"
          size="mini"
          style="flex: 1"
          placeholder="请选择"
          clearable
        >
          <el-option
            v-for="(item, index) in enterpriseOldList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-button icon="el-icon-search" @click="setEList" size="mini"
          >搜索</el-button
        >
      </div>
      <hr style="margin-top: 10px" />
      <el-table
        ref="Table1"
        :data="enterpriseList"
        class="userTable"
        style="width: 100%"
        height="400px"
      >
        <!-- <el-table-column
          show-overflow-tooltip
          type="selection"
          align="left"
          :selectable="selectable"
        ></el-table-column> -->
        <el-table-column label="代理ID" align="left">
          <template #default="scope">
            <span>{{ scope.row.userId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="企业名称" align="left">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="appid" align="left">
          <template #default="scope">
            <span>{{ scope.row.appid }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { number } from "echarts";
export default {
  name: "centerNum",
  data() {
    return {
      oneQuery: true,
      antiBrushing: false,
      typeOptions: [
        {
          value: 1,
          label: "号码",
        },
        {
          value: 2,
          label: "公司",
        },
      ],
      liquery: {},
      remindUuidVisible: false,
      queryEnterpriseName: null,
      enterpriseList: [],
      enterpriseOldList: [],
      enterpriseListVisible: false,
      currentRow: {},
      selectedList: [],
      remindPhoneIndex: null,
      bodyContent: "",
      followUpFormVisible: false,
      userId: null,
      remindPhoneVisible: false,
      updateForm: {},
      form: {},
      numShow: false,
      whiteVisible: false,
      updateVisible: false,
      // phone: null,
      appid: null,
      remindType: "",
      seatPhoneId: null,
      changeTime: null,
      name: null,
      count: null,
      sumCount: null,
      activePhone: "", //搜索框主叫
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      sta: null,
      end: null,
      remindPhoneList: [],
      options: [
        {
          status: 1,
          label: "正常",
        },
        {
          status: 2,
          label: "退订",
        },
      ],
    };
  },

  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  inject: ["reload"],
  methods: {
    resetData() {
      this.remindPhoneList = [];
      this.oldRemindList = [];
      this.remindPhoneIndex = null;
    },
    allAllRemind() {
      console.log("selectedList", this.selectedList);
      if (this.selectedList.length == 0) {
        this.$message.warning("请选择要添加的提醒");
      } else {
        this.addType = "批量";
        this.followUpFormVisible = true;
      }
    },
    setEList() {
      if (typeof this.queryEnterpriseName != "number") {
        this.enterpriseList = [...this.enterpriseOldList];
      } else {
        this.enterpriseList = this.enterpriseOldList.filter(
          (item) => item.id === this.queryEnterpriseName
        );
      }
    },
    setRList() {
      console.log("value", typeof this.remindPhoneIndex != "number");
      if (typeof this.remindPhoneIndex != "number") {
        this.remindPhoneList = [...this.oldRemindList];
      } else {
        this.remindPhoneList = [this.oldRemindList[this.remindPhoneIndex]];
      }
      // oldRemindList
    },
    // 确认添加提醒
    addRemindFn() {
      if (this.addType == "单个") {
        let params = {
          // currentRow
          body: this.currentRow.body,
          theme: this.currentRow.theme,
          remarks: this.bodyContent,
        };
        if (this.remindType == "号码") {
          params.type = 1;
        } else {
          params.type = 2;
        }
        axios
          .post("api/remindLog/add", params)
          .then((res) => {
            if (res.data.statusCode == "00000") {
              this.$message.success(res.data.message);
              this.followUpFormVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {});
      } else {
        let type;
        if (this.remindType == "号码") {
          type = 1;
        } else {
          type = 2;
        }
        let params = {
          listRemindLog: this.selectedList.map((v) => {
            let obj = {
              body: v.body,
              theme: v.theme,
              remarks: this.bodyContent,
              type,
            };
            return obj;
          }),
          // body: this.currentRow.body,
          // theme: this.currentRow.theme,
          // remarks: this.bodyContent,
        };

        axios
          .post("api/remindLog/add-many", params)
          .then((res) => {
            if (res.data.statusCode == "00000") {
              this.$message.success(res.data.message);
              this.followUpFormVisible = false;
              this.getList();
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {});
      }
    },
    // 查询企业
    queryEnterprise(row) {
      let params = {
        page: 1,
        size: 500,
        appidList: row.body.split(":"),
      };
      axios
        .post("api/uuid/list", params)
        .then((res) => {
          console.log("res.data6666", res.data);
          this.enterpriseList = res.data.data;
          this.enterpriseOldList = res.data.data;
          this.enterpriseListVisible = true;
        })
        .catch((err) => {});
    },
    // 添加提醒
    addRemind(row) {
      this.currentRow = row;
      console.log("row", row);
      this.addType = "单个";
      this.followUpFormVisible = true;
      // let params = {};
      // axios
      //   .post("api/remindLog/remind-phone", params)
      //   .then((res) => {
      //     if (res.data.statusCode == "00000") {
      //       console.log("res.data", res.data);
      //       this.remindPhoneList = res.data.data;
      //       console.log("remindPhoneList", this.remindPhoneList);
      //       this.remindPhoneVisible = true;
      //     } else {
      //       this.$message.error(res.data.message);
      //     }
      //   })
      //   .catch((err) => {});
    },
    //下载模板
    upload() {
      window.open("http://118.190.2.70:9005/white-d.xls");
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 批量加白
    whitenings() {
      this.numShow = true;
      this.formDate = null;
    },
    handleSelectionChange(val) {
      // 批量选择行数
      this.selectedList = val;
    },
    numsSubmit() {
      this.numShow = false;
      let formDate = new FormData();
      this.$message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      axios
        // .post("http://106.14.0.130:9998/vo/call/send/import_white_d", formDate)
        .post("api/call/send/import_white_d", formDate)
        .then((res) => {
          // console.log(res.data);
          if (res.data.statusCode == "000000") {
            this.$message.success(res.data.message);
            this.file = {};
          } else {
            this.$message.error(res.data.message);
            this.file = {};
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    // 加白
    whitening(type) {
      this.remindType = type;
      this.form = {};
      if (type == "号码") {
        axios
          // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
          .post("api/remindLog/remind-phone", {})
          .then((res) => {
            if (res.data.statusCode == "00000") {
              console.log("res.data", res.data);
              this.remindPhoneList = res.data.data;
              this.oldRemindList = res.data.data;
              // console.log("remindPhoneList", );
              this.remindPhoneVisible = true;
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {});
      } else {
        axios
          // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
          .post("api/remindLog/remind-uuid", {})
          .then((res) => {
            if (res.data.statusCode == "00000") {
              console.log("res.data", res.data);
              this.remindPhoneList = res.data.data;
              this.oldRemindList = res.data.data;
              // console.log("remindPhoneList", );
              this.remindUuidVisible = true;
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch((err) => {});
      }
    },
    whiteSubmit() {
      let params = {
        appid: this.form.appid,
        count: this.form.count,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD-count/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.whiteVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {});
    },

    //导出
    excel() {
      if (this.createdTime) {
        this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      let params = {
        sta: this.sta1,
        end: this.end1,
      };
      // console.log(params)

      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD/export-whitephoneD", params, {
          responseType: "blob",
        })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers["content-disposition"];

          a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);

          a.href = url;

          a.click();
        });
      this.reload();
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 搜索
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //展示and查找
    getList() {
      if (this.oneQuery) {
        this.$message.warning("第一次查询数据量较少,可能需要等待较长时间~");
      }
      if (this.antiBrushing) {
        this.$message.warning("查询数据中，请稍等~");
        return false;
      }
      let params = {
        page: this.page,
        size: this.size,
        // appid: this.appid,
        // userId: this.userId,
        // name: this.name,
        ...this.liquery,
      };
      this.antiBrushing = true;
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/remindLog/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
          this.antiBrushing = false;
          this.oneQuery = false;
        })
        .catch((err) => {});
    },
    //编辑按钮
    unsubscribe(row) {
      this.updateId = row.row.id;
      this.updateVisible = true;
    },
    updateSubmit() {
      let params = {
        id: this.updateId,
        count: this.updateForm.count,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
        .post("api/whitePhoneD-count/update", params)
        .then((res) => {
          this.$message.success(res.data.message);
          this.updateVisible = false;

          this.getList();
        })
        .catch((err) => {});
    },
    //刷新按钮
    reset() {
      this.name = null;
      this.appid = null;
      this.userId = null;
      this.liquery = {};
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .textareaMinHeight .el-textarea__inner {
  min-height: 120px !important;
}

.content {
  display: flex;
  flex-direction: column;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 200px;
    }

    .left {
      width: 750px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.upload-demo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .left {
//   display: flex;
//   margin: 20px 0;
// }

// .item {
//   display: flex;
//   align-items: center;
// }

// .left span {
//   text-align: right;
//   margin-right: 5px;
// }

// .right {
//   text-align: right;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
