<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="报错信息查询"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">

          <div class="leftItem">
            <span class="lab_tit">企业名称</span>
            <el-input v-model="name" placeholder="请输入企业名称" size="mini"></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input v-model="appid" placeholder="请输入appid" size="mini"></el-input>
          </div>
          <div class="leftItem" style="width: 268px;">
            <span class="lab_tit">时间</span>
            <el-date-picker v-model="created_time" size="mini" type="datetimerange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>

          <div class="leftItem">
            <span class="lab_tit">请求信息查询</span>
            <el-input v-model="req" placeholder="请求信息" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">返回信息查询</span>
            <el-input v-model="resp" placeholder="返回信息" size="mini"></el-input>
          </div>
          <!--<div class="item">-->
          <!--<span class="lab_tit">选择状态</span>-->
          <!--<el-select v-model="type" placeholder="选择状态">-->
          <!--<el-option-->
          <!--v-for="item in options"-->
          <!--:key="item.type"-->
          <!--:label="item.label"-->
          <!--:value="item.type"-->
          <!--&gt;-->
          <!--</el-option>-->
          <!--</el-select>-->
          <!--</div>-->

        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button type="primary" icon="el-icon-refresh" @click="reset()" round size="mini">重置</el-button>
          <!--<el-button type="primary" @click="whitening()">加白</el-button>-->

        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">

            <!--<el-table-column-->
            <!--prop="name"-->
            <!--label="公司名称"-->
            <!---->
            <!--&gt;</el-table-column>-->

            <el-table-column prop="name" label="企业名称"></el-table-column>
            <el-table-column prop="appid" label="appid"></el-table-column>
            <el-table-column prop="req" label="请求信息"></el-table-column>
            <el-table-column prop="resp" label="返回信息"></el-table-column>

            <!--<el-table-column-->
            <!--prop="type"-->
            <!--label="类型"-->
            <!---->
            <!--&gt;-->
            <!--<template #default="scope">-->
            <!--<span v-if="scope.row.type == 0">放行</span>-->
            <!--<span v-if="scope.row.type == 1" style="color: red">拦截</span>-->
            <!--</template>-->
            <!--</el-table-column>-->
            <el-table-column prop="created_time" label="创建时间"></el-table-column>
            <!--<el-table-column-->
            <!--label="操作"-->
            <!--width="100"-->
            <!---->
            <!--style="dispaly: flex"-->
            <!--&gt;-->
            <!--<template #default="scope">-->

            <!--<el-button type="primary" @click="unsubscribe(scope)">变更</el-button>-->
            <!--<el-button type="primary" @click="del(scope)">删除</el-button>-->

            <!--</template>-->
            <!--</el-table-column>-->

          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
              :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
              @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 加白弹出框 -->
    <!--<el-dialog title="加白" v-model="whiteVisible" width="30%">-->
    <!--<el-form ref="form" :model="form" label-width="70px">-->
    <!--<el-form-item label="appid">-->
    <!--<el-input v-model="form.appid"></el-input>-->
    <!--</el-form-item>-->

    <!--</el-form>-->
    <!--<template #footer>-->
    <!--<span class="dialog-footer">-->
    <!--<el-button @click="whiteVisible = false">取 消</el-button>-->
    <!--<el-button type="primary" @click="whiteSubmit">确 定</el-button>-->
    <!--</span>-->
    <!--</template>-->
    <!--</el-dialog>-->
    <!--&lt;!&ndash; 批量加白弹出框 &ndash;&gt;-->
    <!--<el-dialog title="批量加白" v-model="numShow" width="30%">-->
    <!--<div class="content">-->
    <!--<el-button type="success" icon="el-icon-download" @click="upload()"-->
    <!--&gt;点击下载模板</el-button-->
    <!--&gt;-->
    <!--<el-upload-->
    <!--class="upload-demo"-->
    <!--action=""-->
    <!--:on-preview="handlePreview"-->
    <!--:on-remove="handleRemove"-->
    <!--:before-remove="beforeRemove"-->
    <!--:auto-upload="false"-->
    <!--multiple-->
    <!--:limit="1"-->
    <!--:on-exceed="handleExceed"-->
    <!--:file-list="fileList"-->
    <!--:on-change="changeUpload"-->
    <!--drag="true"-->
    <!--width="100%"-->
    <!--&gt;-->
    <!--<i class="el-icon-upload"></i>-->
    <!--<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
    <!--</el-upload>-->
    <!--</div>-->
    <!--<template #footer>-->
    <!--<span class="dialog-footer">-->
    <!--<el-button @click="numsSubmit">确认提交</el-button>-->
    <!--<el-button @click="numShow = false">退 出</el-button>-->
    <!--</span>-->
    <!--</template>-->
    <!--</el-dialog>-->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      form: {},
      numShow: false,
      whiteVisible: false,
      resp: null,
      req: null,
      name: null,
      appid: null,
      userId: null,
      createdTime: null,
      created_time: null,
      type: null,
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      sta: null,
      end: null,
      options: [
        {
          type: 15,
          label: "D",
        },
        // {
        //     type: 1,
        //     label: "拦截",
        // },
      ],
    };
  },

  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  inject: ["reload"],
  methods: {
    //下载模板
    // upload() {
    //   window.open("http://118.190.2.70:9005/white-d.xls");
    // },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 批量加白
    whitenings() {
      this.numShow = true;
      this.formDate = null;
    },
    // 添加
    whitening() {
      this.whiteVisible = true;
      this.form = {};
    },
    // whiteSubmit() {
    //   let params = {
    //     appid: this.form.appid,
    //   };
    //   axios
    //     // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
    //     .post("api/black-list/add", params)
    //     .then((res) => {
    //       if (res.data.statusCode == "00000") {
    //         this.$message.success(res.data.message);
    //         this.whiteVisible = false;
    //         this.getList();
    //       } else {
    //         this.$message.error(res.data.message);
    //       }
    //     })
    //     .catch((err) => {});
    // },


    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 搜索
    handleSearch() {
      if (this.created_time) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.created_time[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.created_time[1]);
      }
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        req: this.req,
        resp: this.resp,
        name: this.name,
        appid: this.appid,
        type: this.type,
        sta: this.sta,
        end: this.end,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/log/callMessageList", params)
        .then((res) => {
          this.tableData = res.data.data;
          // this.form.create_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.data.data.create_time);
          this.tableData.forEach((v) => {
            v.created_time = this.getYMDHMS(
              "YYYY-mm-dd HH:MM:SS",
              v.created_time
            );
            v.created_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.created_time);

          });
          this.pageTotal = res.data.total;
        })
        .catch((err) => { });
    },
    //退订按钮
    // unsubscribe(row) {
    //
    //     let Tstatis = row.row.type;
    //     if (0 === Tstatis) {
    //         Tstatis = 1
    //     } else if (1 === Tstatis) {
    //         Tstatis = 0
    //     } else {
    //         Tstatis = 0
    //     };
    //     let params = {
    //         id: row.row.id,
    //         type : Tstatis
    //     }
    //     axios
    //         // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
    //         .post("api/black-list/update", params)
    //         .then((res) => {
    //             this.$message.success(res.data.message);
    //             this.getList();
    //         })
    //         .catch((err) => {
    //
    //         });
    //
    // },
    //退订按钮
    // del(row) {
    //     let params = {
    //         id: row.row.id,
    //
    //     }
    //     axios
    //         // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
    //         .post("api/black-list/del", params)
    //         .then((res) => {
    //             this.$message.success(res.data.message);
    //         })
    //         .catch((err) => {
    //
    //         });
    //     this.reload();
    // },
    //刷新按钮
    reset() {
      this.req = null;
      this.resp = null;
      this.type = null;
      this.appid = null;
      this.name = null;
      this.type = null;
      this.created_time = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 224px;

      .lab_tit {
        width: 80px !important;
      }
    }

    .left {
      width: 750px;
    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }



    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.upload-demo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .left {
//   display: flex;
//   margin: 20px 0;
// }

// .item {
//   display: flex;
//   align-items: center;
// }

// .left span {
//   text-align: right;
//   margin-right: 5px;
// }

// .right {
//   text-align: right;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
