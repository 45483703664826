<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="代理管理APP端"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="right">
          <el-button
            type="primary"
            round
            icon="el-icon-search"
            @click="screenDrawerShow = true"
            size="mini"
            >筛选
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            round
            size="mini"
            >重置
          </el-button>
          <el-button
            type="primary"
            round
            icon="el-icon-circle-plus-outline"
            @click="openAdd()"
            size="mini"
            >添加
          </el-button>
          <!--<el-button type="primary" class="yd1Num" @click="yd1Num()"-->
          <!--&gt;移动1激活号码-->
          <!--</el-button-->
          <!--&gt;-->
          <!--<el-button type="primary" class="addW" @click="addWhite()"-->
          <!--&gt;主叫加白-->
          <!--</el-button-->
          <!--&gt;-->
          <!--<el-button-->
          <!--type="primary"-->
          <!--icon="el-icon-folder-opened"-->
          <!--@click="allWhite()"-->
          <!--class="allWhite"-->
          <!--&gt;批量加白-->
          <!--</el-button-->
          <!--&gt;-->
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-row>
            <el-col :span="24"
              ><div
                class="appListBox"
                v-for="(item, index) in tableData"
                :key="index"
                @click="openDetails(item)"
              >
                <div class="justified mg-b6">
                  <div style="color: #999; font-weight: bold">
                    代理编号-{{ item.id }}
                  </div>
                  <div
                    :style="{ color: item.status == 1 ? '#67c23a' : '#409eff' }"
                  >
                    {{ item.status == 1 ? "正常" : "冻结" }}
                  </div>
                </div>
                <div class="justified mg-b6">
                  <div style="font-weight: bold">余额：{{ item.money }} 元</div>
                  <div>透支额度：{{ item.moneyOverdraft }}</div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle">客服:</div>
                  <div class="lcontent">
                    {{ item.operators }}
                  </div>
                </div>

                <div class="titleLine mg-b6">
                  <div class="ltitle">账号:</div>
                  <div class="lcontent">
                    {{ item.username }}
                  </div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle">业务员:</div>
                  <div class="lcontent">
                    {{ item.salesman }}
                  </div>
                </div>
                <div class="titleLine">
                  <div class="ltitle" style="width: 70px">代理名称:</div>
                  <div class="lcontent">
                    {{ item.realname }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="mainTableFY">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :pager-count="3"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 问题记录弹出框 -->
    <el-dialog
      title="添加问题记录"
      v-model="companyTakeShow"
      top="2%"
      width="100%"
    >
      <el-form ref="form" :model="forms" label-width="100px">
        <el-form-item label="公司名称">
          <el-input
            v-model="forms.realname"
            disabled
            placeholder="请输入账号"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item label="新增问题">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4, maxRows: 6 }"
            placeholder="请输入问题"
            v-model="currentCompanyTake.question"
          >
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="答案记录">
          <el-input type="textarea" :autosize="{ minRows: 6, maxRows: 6 }" :disabled="!currentCompanyTake.question"
            placeholder="请输入内容" v-model="currentCompanyTake.answer">
          </el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="companyTakeShow = false">取 消</el-button>
          <el-button type="primary" @click="updateSubmitCompanyTake"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 预警 弹出框 -->
    <el-dialog title="预警提醒" v-model="earlyWarningVisible" width="100%">
      <el-form
        ref="addForm"
        :model="addForm"
        label-width="100px"
        :rules="addFormRules"
      >
        <el-form-item label="提醒电话" prop="phone">
          <el-input
            v-model="addForm.phone"
            oninput="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="预警金额" prop="money">
          <el-input
            v-model="addForm.money"
            oninput="this.value=this.value.replace(/[^\d^\.]/g,'')"
          ></el-input>
        </el-form-item>
        <el-form-item label="更新时间">
          <el-input v-model="addForm.changeTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="提醒开关" prop="status">
          <el-radio-group v-model="addForm.status">
            <el-radio :label="1">打开</el-radio>
            <el-radio :label="2">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="updataEarlyWarning"
            >设置保存</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="100%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="账号">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="代理名称">
          <el-input v-model="form.realname"></el-input>
        </el-form-item>
        <el-form-item label="坐席额度">
          <el-input v-model="form.seatCount"></el-input>
        </el-form-item>
        <el-form-item label="客服">
          <el-input v-model="form.operators"></el-input>
        </el-form-item>
        <el-form-item label="业务员">
          <el-select
            v-model="form.salesman"
            placeholder="请选择业务员"
            class="reset-width"
          >
            <el-option
              v-for="(item, i) in salesmanList"
              :key="i"
              :label="item.lable"
              :value="item.lable"
              class="reset-width"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()" :disabled="rechargeBtn"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="100%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="用户账户">
          <el-input v-model="form.username"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="透支额度">
          <el-input v-model="form.moneyOverdraft"></el-input>
        </el-form-item>
        <el-form-item label="坐席额度">
          <el-input v-model="form.seatCount"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="form.realname"></el-input>
        </el-form-item>
        <el-form-item label="签约周期">
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="客服">
          <el-input v-model="form.operators"></el-input>
        </el-form-item>
        <el-form-item label="业务员">
          <el-select
            v-model="form.salesman"
            placeholder="请选择业务员"
            class="reset-width"
          >
            <el-option
              v-for="(item, i) in salesmanList"
              :key="i"
              :label="item.lable"
              :value="item.lable"
              class="reset-width"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 充值弹出框 -->
    <el-dialog title="充值" v-model="addMoney" width="100%">
      <el-form ref="dataAddForm" :rules="addMoneyRules" label-width="80px">
        <el-form-item label="余额">
          <el-input-number
            :min="0"
            v-model="formAdd"
            :precision="3"
            :step="1.0"
            :max="99999999"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="充值/扣费">
          <!-- 到账账户 -->
          <el-radio-group v-model="rechargeValue">
            <el-radio label="充值">充值</el-radio>
            <el-radio label="扣费">扣费</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="附件">
          <!-- <el-upload ref="uploadImg" :limit="1" class="upload-demo" :headers="uploadHeaders"
                        action="http://syshb.cddmt.cn/api/uuid-pass/upload" :multiple="false" :on-exceed="handleExceed"
                        :before-upload="beforeAvatarUpload" :before-remove="beforeRemove" :on-success="handleAvatarSuccess"
                        :file-list="addForm.workImg" list-type="picture">
                        <el-button size="mini" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传图片</div>
                    </el-upload> -->

          <el-upload
            ref="uploadImg"
            :limit="1"
            class="upload-demo"
            :headers="uploadHeaders"
            action="api/uuid-pass/upload"
            :multiple="false"
            drag
            :on-exceed="handleExceed"
            :before-upload="beforeAvatarUpload"
            :before-remove="beforeRemove"
            :on-success="handleAvatarSuccess"
            :file-list="addForm.workImg"
            list-type="picture"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">只能上传图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="到账账户">
          <!-- 到账账户 -->
          <el-radio-group v-model="companyType">
            <el-radio :label="0">暂无</el-radio>
            <el-radio :label="1">商机云</el-radio>
            <el-radio :label="2">星链云</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="充值类型">
          <!-- 充值类型 -->
          <el-select v-model="rechargeType" filterable>
            <el-option
              v-for="item in rechargeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addMoney_record()" type="success"
            >充值记录</el-button
          >
          <!-- <el-button @click="addMoney = false">取 消</el-button> -->
          <!-- <el-button type="primary" v-if="formAdd == 0 || formAdd == null" disabled>确 定</el-button> -->
          <!-- <el-button type="warning" v-if="formAdd != 0" @click="rechargeSubmit('扣费')">扣费</el-button> -->
          <el-button type="primary" v-if="formAdd != 0" @click="rechargeSubmit"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 主叫加白弹出框 -->
    <el-dialog title="主叫加白" v-model="addWhiteShow" width="100%">
      <span>请输入appid:</span>
      <el-input v-model="appid"></el-input>
      <span>请选择密钥:</span>
      <br />
      <el-select v-model="param_id" style="100%">
        <el-option
          v-for="item in options2"
          :key="item.type"
          :label="item.appid"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-form-item>
        <span>请输入加白号码:</span>
        <el-input v-model="phone"></el-input>
      </el-form-item>
      <el-form-item>
        <span>请输入名字</span>
        <el-input v-model="name"></el-input>
      </el-form-item>
      <el-form-item>
        <span>请输入身份证号</span>
        <el-input v-model="idCard"></el-input>
      </el-form-item>
      <el-form-item>
        <span>请输入验证码</span>
        <el-input v-model="code"></el-input>
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addWhiteShow = false">取 消</el-button>
          <el-button type="primary" @click="addWhiteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量加白弹出框 -->
    <el-dialog
      title="批量加白"
      v-model="allVisible"
      width="100%"
      custom-class="upload"
    >
      <div class="content">
        <el-button type="success" icon="el-icon-download" @click="upload()"
          >点击下载模板
        </el-button>

        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <!-- <template #tip>
                    <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                  </template> -->
        </el-upload>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 移动一激活号码弹出框 -->
    <el-dialog title="移动一激活号码" v-model="yd1NumShow" width="100%">
      <!-- <span>请输入appid:</span>
                <el-input v-model="appid"></el-input> -->
      <span>请选择移动一密钥:</span>
      <br />
      <el-select v-model="param_id1" style="100%">
        <el-option
          v-for="item in options3"
          :key="item.type"
          :label="item.appid"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-form-item>
        <span>请输入手机号</span>
        <el-input v-model="phone"></el-input>
      </el-form-item>
      <el-form-item>
        <span>请输入验证码</span>
        <el-input v-model="code"></el-input>
      </el-form-item>
      <el-form-item
        style="width: 100%; display: flex; justify-content: flex-end"
      >
        <el-button type="primary" round @click="getCode()"
          >获取验证码
        </el-button>
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="yd1NumShow = false">取 消</el-button>
          <el-button type="primary" @click="yd1NumSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!--充值记录弹出框 -->
    <el-drawer
      title="充值记录"
      size="100%"
      v-model="addMoney_record_flag"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-table
        :data="rechargeRecord"
        style="width: 100%; text-align: center"
        height="80%"
      >
        <el-table-column property="oldMoney" label="充值前"></el-table-column>
        <el-table-column property="money" label="充值后"></el-table-column>
        <el-table-column property="createTime" label="时间"></el-table-column>
        <el-table-column prop="img" label="附件">
          <template #default="scope">
            <el-image
              v-if="scope.row.img"
              style="width: 100px; height: 100px"
              :src="scope.row.img"
              :preview-src-list="[scope.row.img]"
            >
              <div
                slot="error"
                class="image-slot"
                style="text-align: center; line-height: 100px"
              >
                <i class="el-icon-picture-outline"></i>暂无附件
              </div>
            </el-image>
            <p v-else>暂无附件</p>
          </template>
        </el-table-column>
        <el-table-column property="remarks" label="备注"></el-table-column>
      </el-table>
      <el-pagination
        @current-change="handlePageChange_addMoney_recoed"
        :current-page="pageIndexDw"
        :page-size="pageSizeDw"
        layout="total, prev, pager, next"
        :total="pageTotalAddMoney"
      >
      </el-pagination>
    </el-drawer>

    <!-- 详情和操作 -->
    <el-drawer
      title="详情&操作"
      v-model="drawerShow"
      direction="rtl"
      size="100%"
    >
      <el-row>
        <el-col :span="24"
          ><div class="appListBox" style="padding: 10px">
            <div class="justified mg-b6">
              <div style="color: #999; font-weight: bold">
                代理编号-{{ currentRow.id }}
              </div>
              <div
                :style="{
                  color: currentRow.status == 1 ? '#67c23a' : '#409eff',
                }"
              >
                {{ currentRow.status == 1 ? "正常" : "冻结" }}
              </div>
            </div>
            <div class="justified mg-b6">
              <div style="font-weight: bold">
                余额：{{ currentRow.money }} 元
              </div>
              <div>透支额度：{{ currentRow.moneyOverdraft }}</div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">客服:</div>
              <div class="lcontent">
                {{ currentRow.operators }}
              </div>
            </div>

            <div class="titleLine mg-b6">
              <div class="ltitle">账号:</div>
              <div class="lcontent">
                {{ currentRow.username }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">业务员:</div>
              <div class="lcontent">
                {{ currentRow.salesman }}
              </div>
            </div>
            <div class="titleLine">
              <div class="ltitle" style="width: 70px">代理名称:</div>
              <div class="lcontent">
                {{ currentRow.realname }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="padding: 10px">
        <el-button
          type="warning"
          style="margin-top: 10px"
          @click="getMoneyAlarm(currentRow)"
          size="mini"
          >预警</el-button
        >
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="recharge(currentRow)"
          size="mini"
          >充值</el-button
        >
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="handleClick(currentRow)"
          size="mini"
          >编辑
        </el-button>
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="lock(currentRow)"
          size="mini"
          v-if="currentRow.status == '1'"
          >锁定
        </el-button>
        <el-button
          type="danger"
          style="margin-top: 10px"
          @click="lock(currentRow)"
          size="mini"
          v-if="currentRow.status == '2'"
          >解锁
        </el-button>
        <el-button
          type="danger"
          style="margin-top: 10px"
          @click="login(currentRow)"
          size="mini"
          >登录
        </el-button>
        <el-button
          type="primary"
          style="margin-top: 10px"
          size="mini"
          @click="queryCompanyTake(currentRow)"
          >记录</el-button
        >
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="enterprisesList(currentRow)"
          size="mini"
          >列表
        </el-button>
      </div>
    </el-drawer>

    <!-- 筛选过滤 -->
    <el-drawer
      title="筛选数据"
      v-model="screenDrawerShow"
      direction="ltr"
      size="100%"
    >
      <el-row>
        <el-col :span="24">
          <div class="search">
            <div class="left">
              <div class="leftItem">
                <span class="lab_tit">账号</span>
                <el-input
                  v-model="username"
                  placeholder="请输入账号"
                  v-on:keyup.enter="handleSearch"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem">
                <span class="lab_tit">代理ID</span>
                <el-input
                  v-model="agentId"
                  placeholder="请输入代理编号"
                  v-on:keyup.enter="handleSearch"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem">
                <span class="lab_tit">代理名称</span>
                <el-input
                  v-model="realname"
                  placeholder="请输入代理名称"
                  v-on:keyup.enter="handleSearch"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem">
                <span class="lab_tit">客服</span>
                <el-input
                  v-model="operators"
                  placeholder="请输入客服"
                  v-on:keyup.enter="handleSearch"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem">
                <span class="lab_tit">业务员</span>
                <el-select
                  v-model="salesman"
                  placeholder="请选择业务员"
                  class="reset-width"
                  size="mini"
                >
                  <el-option
                    v-for="(item, i) in salesmanList"
                    :key="i"
                    :label="item.lable"
                    :value="item.lable"
                    class="reset-width"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="leftItem">
                <span class="lab_tit">锁定状态</span>
                <el-select
                  v-model="status"
                  placeholder="请选择锁定状态"
                  size="mini"
                >
                  <el-option
                    v-for="item in options4"
                    :key="item.type"
                    :label="item.label1"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <hr />
      <div style="padding: 10px">
        <el-button
          type="primary"
          round
          icon="el-icon-search"
          @click="handleSearch"
          size="mini"
          >查找
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          @click="reset"
          round
          size="mini"
          >重置
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";

import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
} from "@/api/company_renew";

export default {
  name: "agent",
  data() {
    return {
      screenDrawerShow: false,
      currentRow: {},
      drawerShow: false,
      rechargeValue: "充值",
      rechargeType: 0,
      rechargeOptions: [
        // 0没有 1充值、2返费、3赠送、4罚款、5扣号码费、6退费  7 转移
        {
          value: 0,
          label: "暂无",
        },
        {
          value: 1,
          label: "充值",
        },
        {
          value: 2,
          label: "返费",
        },
        {
          value: 3,
          label: "赠送",
        },
        {
          value: 4,
          label: "罚款",
        },
        {
          value: 5,
          label: "扣号码费",
        },
        {
          value: 6,
          label: "退费",
        },
        {
          value: 7,
          label: "转移",
        },
      ],
      companyType: 0,
      addFormRules: {
        phone: [
          { required: true, message: "请输入提醒电话号码", trigger: "blur" },
          {
            min: 11,
            max: 12,
            message: "号码长度需要在11-12位",
            trigger: "blur",
          },
        ],
        money: [{ required: true, message: "请输入预警金额", trigger: "blur" }],
        status: [
          { required: true, message: "请选择提醒开关", trigger: "change" },
        ],
      },
      earlyWarningVisible: false,
      salesmanList: [
        {
          lable: "李天豪-总经办",
        },
        {
          lable: "四川盛世百通通信技术有限责任公司-总经办",
        },
        {
          lable: "刘瑶-飞跃队",
        },
        {
          lable: "简波-飞跃队",
        },
        {
          lable: "谭淼-飞跃队",
        },
        {
          lable: "曹月-飞跃队",
        },
        {
          lable: "陈睿-飞跃队",
        },
        {
          lable: "杨鹏-骑士团",
        },
        {
          lable: "蒋发-骑士团",
        },
        {
          lable: "邓昊燃-骑士团",
        },
        {
          lable: "都文豪-骑士团",
        },
        {
          lable: "陈思-骑士团",
        },
        {
          lable: "沈涛-骑士团",
        },
        {
          lable: "李巧-飞跃队",
        },
      ],
      agentId: null,
      addForm: {},
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      currentCompanyTake: {},
      forms: {},
      rechargeRecord: [],
      pageTotalAddMoney: 0,
      addMoney_record_flag: false,
      options4: [
        {
          type: 1,
          label1: "未锁定",
        },
        {
          type: 2,
          label1: "已锁定",
        },
      ],
      imgUrl: "",
      status: null,
      rechargeBtn: false,
      tableData: [],
      multipleSelection: [],
      yd1NumShow: false,
      delList: [],
      addVisible: false, //添加弹框是否显示
      editVisible: false, //编辑弹框是否显示
      addWhiteShow: false, //主叫加白弹框是否显示
      remarks: "", //充值时备注，只传不收
      pageTotal: 0,
      addMoney: false,
      allVisible: false,
      param_id: "",
      param_id1: "",
      appid: "",
      phone: "",
      code: "",
      name: "",
      idCard: "",
      operators: "",
      salesman: "",
      Options2: [],
      Options3: [],
      type: "1", //通道类型暂时固定为3（南京线通道）
      form: {
        id: "",
        username: "",
        password: "",
        money: "",
        realname: "",
        seatCount: "",
        status: "",
        oldMoney: null,
        remark: "",
        operators: null,
        salesman: null,
      },
      pageIndex: 1,
      pageSize: 10,
      page: 1,
      size: 10,
      companyTakeShow: false,
      options: [
        {
          status: "1",
          label: "正常",
        },
        {
          status: "2",
          label: "禁止",
        },
      ],
      username: null,
      realname: null,
      formAdd: "",
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    username() {
      if (this.username == "") {
        this.username = null;
      }
    },
    realname() {
      if (this.realname == "") {
        this.realname == null;
      }
    },
    //  "type"(newValue, oldValue){
    //    // console.log(newValue)
    //   this.options2 = []
    //   this.getData(newValue)
    // }
  },
  methods: {
    openDetails(item) {
      console.log("点击了", item);
      this.currentRow = item;
      this.drawerShow = true;
    },
    //登录
    login(row) {
      let token = localStorage.getItem("TOKEN");
      let params = {
        username: row.username,
        password: "13882738650",
        token: token,
      };
      this.$confirm("请确定是否登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/user/update", params)
            .post("api/login/login/noPassword", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                // console.log(res.data.data.user.realname)
                // console.log(res.data.data.user.realname)
                window.localStorage.clear();
                this.$message.success("登录成功");

                localStorage.setItem(
                  "ms_username",
                  res.data.data.user.username
                );
                localStorage.setItem("id", res.data.data.user.id);
                localStorage.setItem("money", res.data.data.user.money);
                localStorage.setItem("realname", res.data.data.user.realname);
                // console.log("---------" + res.data.data.user.realname)
                // console.log(res.data.data.user.realname)
                //储存token到application 下的 localStorage
                localStorage.setItem("TOKEN", res.data.data.token);
                //储存Permission到application 下的 localStorage
                localStorage.setItem(
                  "Permission",
                  JSON.stringify(res.data.data.Permission)
                );
                // let url = 'localhost:80' // 这里的地址前面就不用加协议了'http:// 或者 https://' 下面一句话就是处理这个的。
                let url = "syshb.cddmt.cn:80"; // 这里的地址前面就不用加协议了'http:// 或者 https://' 下面一句话就是处理这个的。

                let path = window.location.protocol + "//" + url;

                window.location.href = path;
                // window.open('http://127.0.0.1:80/dashboard',"_self");
                // this.$router.push("/dashboard");
              } else {
                this.$message.error(res.data.message);
                // return false;
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    updataEarlyWarning() {
      // 表单验证
      this.$refs["addForm"].validate(async (valid) => {
        if (valid) {
          this.earlyWarningVisible = false;
          let params = {
            ...this.addForm,
          };
          axios
            .post("api/user-money-alarm/add-update", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                this.$message.success(res.data.message);
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    getMoneyAlarm(row) {
      let params = {
        userId: row.id,
      };
      axios
        .post("api/user-money-alarm/list", params)
        .then((res) => {
          // console.log("res", res.data);
          this.earlyWarningVisible = true;
          res.data.data.length != 0
            ? (this.addForm = res.data.data[0])
            : (this.addForm = {});
          this.addForm.userId = row.id;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 图片上传成功时
    handleAvatarSuccess(res, file, fileList) {
      this.imgUrl = "";
      if (file.response.statusCode == "00000") {
        this.imgUrl = file.response.data;
      }
      // if (fileList) {
      //     this.imgUrl = fileList.map((v) => {
      //         return v.response
      //     });
      // }
      // this.imgUrl ? this.imgUrl = this.imgUrl.join() : ''

      // let imageUrl = URL.createObjectURL(file.raw);
      //  // console.log("res", res);
      //  // console.log("file", file);

      //  // console.log("imageUrl", this.imgUrl);
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith("image/");
      // const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage) {
        this.$message.error("上传附件只能是图片格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isImage && isLt2M;
      return isImage;
    },
    // 提交问题记录
    async updateSubmitCompanyTake() {
      let params;
      let res;
      if (!!this.currentCompanyTake) {
        params = {
          userId: this.forms.id,
          ...this.currentCompanyTake,
        };
        res = await customerQuestionAdd(params);
      } else {
        params = {
          id: this.currentCompanyTake.name,
          answer: this.currentCompanyTake.question,
        };
        res = await customerQuestionUpdate(params);
      }
      if (res.statusCode == "00000") {
        this.$message.success(res.message);
        this.companyTakeShow = false;
        this.currentCompanyTake = {};
      } else {
        this.$message.error(res.message);
      }
    },
    // 查看问题记录
    async queryCompanyTake(row) {
      this.forms = row;
      //  // console.log("row", row);
      // let params = {
      //   parentId: row.id,
      // };
      // let res = await customerQuestionList(params);
      //  // console.log("resasdasd", res);
      // if (res.data.data.length) {
      //   this.currentCompanyTake = res.data.data[0];
      // } else {
      //   this.currentCompanyTake = {}
      // }
      //  // console.log("currentCompanyTake", this.currentCompanyTake);

      this.companyTakeShow = true;
    },
    //展示、搜索
    getList_addMoney_recoed(id) {
      let params = {
        page: this.page1,
        size: this.size,
        id: id,
        remarks: "admin",
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/list", params)
        .post("api/log/userlist", params)
        .then((res) => {
          //  // console.log(res)
          this.rechargeRecord = res.data.data;
          this.pageTotalAddMoney = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 分页导航
    handlePageChange_addMoney_recoed(val) {
      this.page1 = val;
      this.getList_addMoney_recoed(this.id);
    },
    //充值记录
    addMoney_record() {
      this.addMoney_record_flag = true;
      // console.log("id------------------------", this.id)
      this.getList_addMoney_recoed(this.id);
    },
    //批量加白
    allWhite() {
      this.allVisible = true;
    },
    //点击下载
    upload() {
      window.open("http://118.190.2.70:9005/white.xls");
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
      // console.log(file);
      // console.log(fileList);
    },
    submitUpload() {
      let formDate = new FormData();
      formDate.append("file", this.file);
      axios
        // .post("http://106.14.0.130:9998/vo/call/import-seat", formDate)
        .post("api/call/import-seat", formDate)
        .then((res) => {
          // console.log(res.data.statusCode);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.data);
            this.allVisible = false;
            this.file = {};
            this.getList();
          } else {
            this.$message.error(res.data.data);
            this.allVisible = false;
            this.file = {};
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    //展示、搜索
    getList() {
      let params = {
        id: this.agentId,
        page: this.page,
        size: this.size,
        username: this.username,
        realname: this.realname,
        status: this.status,
        salesman: this.salesman,
        operators: this.operators,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/list", params)
        .post("api/user/list", params)
        .then((res) => {
          //  // console.log(res)
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //重置
    reset() {
      this.form = {};
      this.username = null;
      this.realname = null;
      this.status = null;
      this.salesman = null;
      this.operators = null;
      this.agentId = null;
      this.getList();
      this.screenDrawerShow = false;
    },
    //搜索
    handleSearch() {
      this.getList();
      this.screenDrawerShow = false;
    },
    openAdd() {
      this.addVisible = true;
      this.form.username = null;
      this.form.realname = null;
      this.form.seatCount = null;
      this.form.password = null;
    },

    //添加确定按钮
    submitAdd() {
      let params = {
        username: this.form.username,
        realname: this.form.realname,
        seatCount: this.form.seatCount,
        password: this.form.password,
        money: this.form.money,
        salesman: this.form.salesman,
        operators: this.form.operators,
        // status:this.form.status,
      };
      this.rechargeBtn = false;
      axios
        // .post("http://106.14.0.130:9998/vo/user/add", params)
        .post("api/user/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            setTimeout(() => {
              this.rechargeBtn = false;
            }, 2000);
            this.addVisible = false;
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
          this.rechargeBtn = false;
          this.$message({
            message: res.data.message,
          });
        });
    },
    //编辑回显
    handleClick(row) {
      this.form.id = row.id;
      this.editVisible = true;
      let params = {
        id: this.form.id,
      };
      axios
        //
        // .post("http://106.14.0.130:9998/vo/user/findOne", params)
        .post("api/user/findOne", params)
        .then((res) => {
          // console.log(res.data.data);
          (this.form.username = res.data.data.username),
            (this.form.realname = res.data.data.realname),
            (this.form.seatCount = res.data.data.seatCount),
            (this.form.status = res.data.data.status),
            (this.form.salesman = res.data.data.salesman),
            (this.form.operators = res.data.data.operators),
            (this.form.remark = res.data.data.remark);
          this.form.moneyOverdraft = res.data.data.moneyOverdraft;
        });
    },
    //编辑确定按钮
    saveEdit() {
      let params = {
        id: this.form.id,
        username: this.form.username,
        realname: this.form.realname,
        seatCount: this.form.seatCount,
        password: this.form.password,
        status: this.form.status,
        remark: this.form.remark,
        salesman: this.form.salesman,
        operators: this.form.operators,
        moneyOverdraft: this.form.moneyOverdraft,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible = false;
          this.realname = null;
          this.getList();
        });
    },

    //绑定
    lock(row) {
      let status = "";
      if (row.status == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        id: row.id,
        status: status,
      };
      this.$confirm("请确定是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/user/update", params)
            .post("api/user/update", params)
            .then((res) => {
              // console.log(res);
              if (res.data.statusCode == "00000") {
                this.$message.success(res.data.message);
                this.getList();
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //充值
    recharge(row) {
      //  // console.log(row);
      this.addMoney = true;
      this.id = row.id;
      this.formAdd = 0;
      this.money = row.money;
      this.realname = row.realname;
      this.remarks = null;
      this.companyType = 0;
      this.rechargeType = 0;
      this.rechargeValue = "充值";
    },
    //充值提交确定按钮
    rechargeSubmit(type) {
      // 扣费
      // 充值

      // if (!this.imgUrl) {
      //     this.$message.warning('充值时必须上传附件图片!');
      //     return false
      // }
      this.rechargeBtn = false;

      let params = {
        id: this.id,
        username: this.username,
        old_money: this.money,
        money: this.formAdd,
        realname: this.realname,
        remarks: this.remarks,
        img: this.imgUrl,
        companyType: this.companyType,
        type: this.rechargeType,
      };
      if (this.rechargeValue == "扣费") {
        params.money = this.formAdd * -1;
      }
      this.addMoney = false;
      this.rechargeBtn = true;
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // setTimeout(() => {
            //     this.rechargeBtn = false;
            // }, 2000);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.realname = null;
            this.username = null;
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.$refs.uploadImg.clearFiles();
          this.imgUrl = "";
          this.rechargeBtn = false;
          this.addMoney = false;
        });
    },

    //主叫加白
    addWhite() {
      this.addWhiteShow = true;
      // this.appid = null;
      // this.param_id = null;
      this.num = null;
      // this.code = null;
      let params = {
        type: this.type,
      };
      //  // console.log(this.type)
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/findAllkey", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          //  // console.log(res)
          this.options2 = res.data.data;
          // this.$message.success(res.data.message)
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    //主叫加白提交按钮
    addWhiteSubmit() {
      let params = {
        appid: this.appid,
        param_id: this.param_id,
        phone: this.phone,
        name: this.name,
        idCard: this.idCard,
        code: this.code,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-yd", params)
        .post("api/call/add-white-yd", params)
        .then((res) => {
          this.addWhiteShow = false;
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.addMoney = false;
        });
    },

    //移动一激活号码
    yd1Num() {
      this.yd1NumShow = true;
      this.phone = null;
      this.code = null;
      this.param_id1 = null;
      let params = {
        type: 2,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/findAllkey", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          //  // console.log(res)
          this.options3 = res.data.data;
          // this.$message.success(res.data.message)
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getCode() {
      let params = {
        phone: this.phone,
        code: "",
        param_id: this.param_id1,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/yd_one_dx_send", params)
        .post("api/call/yd_one_dx_send", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    yd1NumSubmit() {
      let params = {
        phone: this.phone,
        code: this.code,
        param_id: this.param_id1,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/yd_one_dx_send", params)
        .post("api/call/yd_one_dx_send", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.data);
          } else {
            this.$message.error(res.data.message);
          }
          this.yd1NumShow = false;
        })
        .catch((err) => {
          // console.log(err);
          this.yd1NumShow = false;
        });
    },
    //点击企业列表按钮
    enterprisesList(row) {
      //  // console.log(row.row.id)
      this.$router.push({
        path: "/enterprise",
        query: {
          userId: row.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.mg-b6 {
  margin-bottom: 6px;
}
.appListBox {
  display: flex;
  flex-flow: column;
  padding: 10px 0;
  border-bottom: 1px solid green;
  .justified {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titleLine {
    display: flex;
    .ltitle {
      font-weight: bold;
      width: 55px;
    }
    
.lcontent {
      flex: 1;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
.appPage {
  // height: 100%;
}

::v-deep .appPageMain {
  // height: 100%;
  overflow-y: auto;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 14px;
    font-weight: bold;
    color: #333333 !important;
  }
}

div ::v-deep .el-select {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .upload-demo {
  margin-top: 50px;
}

.content ::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

::v-deep .search {
  // height: 70px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .left {
    // width: 60%;
    width: 580px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .leftItem {
      width: 192px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .el-input,
      .el-select,
      .el-date-editor {
        // width: 120px;
        flex: 1;
      }

      .el-input__inner {
        height: 23px !important;
        border-radius: 25px;
        border: 1px solid #666;
      }

      .el-input__icon {
        line-height: 24px !important;
      }

      .lab_tit {
        font-size: 12px;
        // font-weight: bold;
        white-space: nowrap;
        width: 50px !important;
        text-align: right;
        margin-right: 3px;
        margin-left: 8px;
      }
    }
  }

  .right {
    text-align: right;
    // width: 30%;
    flex: 1;

    // display: flex;
    .el-button {
      margin-bottom: 3px;
    }
  }
}

.el-input {
  width: 100%;
}

.el-select {
  width: 100%;
}

.item:nth-child(1) {
  margin-left: 0;
}

.searchBtn {
  margin-left: 15px;
}

.reset {
  width: 90px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

::v-deep.operateColumn {
  .el-button {
    margin-top: 3px !important;
    margin-left: 4px !important;
  }

  .el-button--mini {
    // min-height: 20px;
    // padding: 8px;
  }
}
</style>
