<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="移动AX通话记录"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">主叫</span>
            <el-input v-model="phone" placeholder="请输入主叫" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">被叫</span>
            <el-input v-model="phoneB" placeholder="请输入被叫" size="mini"></el-input>
          </div>
          <div class="leftItem" style="width: 180px;">
            <span class="lab_tit" style="width: 66px !important;">子企业编号</span>
            <el-input v-model="uuid" placeholder="子企业编号" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">绑定ID</span>
            <el-input v-model="callid" placeholder="请输入callid" size="mini" @input="logV"></el-input>
          </div>
        
          <div class="leftItem" style="width: 262px;">
            <span class="lab_tit">选择时间</span>
            <el-date-picker v-model="timestamp" type="datetimerange" :picker-options="pickerOptions" range-separator="至"
              :clearable="false" start-placeholder="开始日期" end-placeholder="结束日期" align="right" size="mini">
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button type="primary" icon="el-icon-refresh" @click="reset()" round size="mini">重置</el-button>
          <el-button type="primary" icon="el-icon-search" @click="tape" round size="mini">查询录音</el-button>
          <el-button type="primary" icon="el-icon-warning-outline" @click="detectionClick()" round
            size="mini">质检</el-button>
          <el-button type="primary" icon="el-icon-warning-outline" @click="qc_sendRecord_text()" round size="mini">转文字质检
          </el-button>
          <!-- <el-dropdown @command="handleTags" trigger="click">
            <el-button round size="mini" type="primary" style="margin-left: 10px;">
              更多操作
              <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu size="small">
                <el-dropdown-item command="查询录音">查询录音</el-dropdown-item>
                <el-dropdown-item command="转文字质检">转文字质检</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="selectionLineChangeHandle">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="uuid" label="子企业编号"></el-table-column>
            <el-table-column prop="active_phone" width="120" label="主叫"></el-table-column>
            <el-table-column prop="passive_phone" width="120" label="被叫"></el-table-column>
            <el-table-column prop="bind_phone" label="中间号" width="120"></el-table-column>
            <el-table-column prop="start_time" label="开始时间" width="180"></el-table-column>
            <el-table-column prop="release_time" label="结束时间" width="180"></el-table-column>
            <el-table-column label="录音" width="120" fixed="right" prop="record_url">
              <template #default="scope">
                <div v-if="scope.row.record_url">
                  <el-button type="primary" icon="el-icon-search" @click="startaudio(scope)"
                    class="searchBtn">查看录音</el-button>
                </div>
                <div v-else>
                  暂无
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column  label="质检"  >
          <template #default="scope">
            <el-button type="primary" 
            icon="el-icon-warning-outline" 
            @click="startaudio(scope)" 
            class="searchBtn">质检录音</el-button>
          </template>
        </el-table-column> -->
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
              :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
              @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="录音" v-model="Showaudio" width="30%" :before-close="handleClose">
      <audio :src="currentAudio" controls="controls"></audio>
    </el-dialog>

    <!-- 录音质检弹出框 -->
    <el-dialog title="录音质检" v-model="detectionVisible" style="position: relative">
      <el-form :model="form">
        <!-- 上传文件名 -->
        <div class="ipt">
          <div style="width: 100px">上传文件名</div>
          <el-input v-model="form.name" autocomplete="off" placeholder="可写企业ID"></el-input>
        </div>
        <!-- 任务标识 -->
        <div class="ipt">
          <div style="width: 100px">任务标识</div>
          <el-input v-model="form.task" autocomplete="off" placeholder="例：A B C D（ 区分大小写 ）"></el-input>
        </div>
        <div class="slt">
          <!-- 业务场景 -->
          <el-form-item label="业务场景" :label-width="formLabelWidth" style="margin-right: 80px">
            <el-select v-model="businessValue" placeholder="请选择业务场景">
              <el-option v-for="item in sceneList" :key="item.value" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <!-- 场景名称 -->
          <el-form-item label="场景名称" :label-width="formLabelWidth">
            <el-select v-model="sceneName" placeholder="请选择场景名称">
              <el-option v-for="item in sceneList1" :key="item.value" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="dialog-footer btns">
        <el-button @click="detectionVisible = false">取 消</el-button>
        <el-button type="primary" @click="detectionSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "callLog",
  data() {
    return {
      businessValue: "",
      sceneName: "",
      scene: "",
      dataonLineListSelections: [], // 选中的项
      tableData: [],
      multipleSelection: [],
      delList: [],
      address: "",
      // task: "",
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      editVisible: false, //是否显示编辑/添加弹窗
      pageTotal: 0, //总共有多少数据
      form: {
        bindId: "",
        task: "",
        name: "",
        file: "",
        industryId: "",
        industryName: "",
        taskGroupFlag: "",
      },
      phone: null,
      phoneB: null,
      // id: null,
      uuid: null,
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      callid: null,
      url: "",
      inUrl: "",
      timestamp: null,
      Showaudio: false,
      currentAudio: "",
      audioPaused: false,
      detectionVisible: false,
      sceneList: [
        {
          value: 0,
          label: "9990",
        },
        {
          value: 1,
          label: "9993",
        },
        {
          value: 2,
          label: "9995",
        },
        {
          value: 3,
          label: "99910",
        },
        {
          value: 4,
          label: "99911",
        },
      ],
      sceneList1: [
        {
          value: 0,
          label: "通用",
        },
        {
          value: 1,
          label: "房产",
        },
        {
          value: 2,
          label: "其他",
        },
        {
          value: 3,
          label: "金融",
        },
        {
          value: 4,
          label: "教育",
        },
      ],
    };
  },
  created() {
    let rootDate = new Date().toLocaleDateString()
    this.sta = this.formatDate(new Date()).substring(0, 10) + " 00:00:00"
    this.end = this.formatDate(new Date()).substring(0, 10) + " 23:59:59"
    this.timestamp = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
    this.getList();
  },

  watch: {
    phone() {
      if (this.phone == "") {
        this.phone = null;
      }
    },
    phoneB() {
      if (this.phoneB == "") {
        this.phoneB = null;
      }
    },
    callid() {
      if (this.callid == "") {
        this.callid = null;
      }
    },
  },
  inject: ["reload"],
  methods: {
    handleTags(command) {
      if (command === "查询录音") {
        this.tape()
      } else if (command === "转文字质检") {
        this.qc_sendRecord_text()
      }
    },
    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    // 质检录音 转文字
    qc_sendRecord_text() {
      let arr = [];
      this.dataonLineListSelections.forEach((v) => {
        let qc = {};

        qc.callId = v.callid;
        qc.recordUrl = v.record_url;
        qc.uuid = v.uuid
        qc.type = "5"
        qc.chooseType = "2"
        v.record_url != "" ? arr.push(qc) : "";
      });
      let params = {
        qcList: arr
      };
      // console.log("参数-------------------", params)
      axios
        .post("api/quality/send/aliyun-sendRecord-wait-list", params)
        .then((res) => {
          // console.log(">>>>>>>>", res.data);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    // 添加选中项
    selectionLineChangeHandle(val) {
      this.dataonLineListSelections = val;
      // console.log("已被选中的数据", this.dataonLineListSelections);
    },
    // 质检录音
    detectionClick() {
      this.detectionVisible = true;
      this.form = [];
    },
    // 质检录音确定
    detectionSubmit() {
      // 处理参数
      let arrBindId = [];
      let arrFile = [];
      this.dataonLineListSelections.forEach((v) => {
        v.callid != "" ? arrBindId.push(v.callid) : "";
        v.record_url != "" ? arrFile.push(v.record_url) : "";
      });
      let bindId = arrBindId.join();
      let file = arrFile.join();

      let params = {
        bindId, // 要传多个 字符串类型 逗号隔开
        name: this.form.name,
        file, // 要传多个 字符串类型 逗号隔开
        industryId: this.businessValue,
        industryName: this.sceneName,
        taskGroupFlag: this.form.task,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/quality/sendRecord", params)
        .post("api/quality/sendRecord", params)
        .then((res) => {
          // console.log(">>>>>>>>", res.data);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    // 一键查询所有音频
    tape() {
      let success = this.getList("1");
      if (success) {
        this.$message.success("查询成功");
      }
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 音频
    startaudio(row) {
      // console.log(row.row.record_url);
      if (row.row.record_url == "略") {
        this.$message.warning("暂无音频");
      } else {
        this.currentAudio = row.row.record_url;
        this.Showaudio = true;
      }
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    getList(inUrl) {
      let params = {
        page: this.page,
        size: this.size,
        phone: this.phone,
        phoneB: this.phoneB,
        call_id: this.callid,
        uuid: this.uuid,
        inUrl,
        sta: this.sta,
        end: this.end,
      };
      return axios
        // .post("http://106.14.0.130:9998/vo/callLog/yd-ax-list", params)
        .post("api/callLog/yd-ax-list", params)
        .then((res) => {
          // console.log(res.data.data);
          //  // console.log(res.data.data.record_url)
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
            return true;
            //  // console.log(this.tableData);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    //搜索查询
    handleSearch() {
      if (this.timestamp != null) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[1]);
        this.getList();
      } else if (this.timestamp == null || this.timestamp == "") {
        this.getList();
      }
      // console.log("sta", this.sta);
      // console.log("end", this.end);
      this.getList();
    },
    //重置刷新按钮
    reset() {
      this.phone = null;

      this.phoneB = null;
      this.callid = null;
      this.id = null;
      this.uuid = null;
      this.getList();
      // this.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;


  .search {

    .el-select,
    .el-date-editor {
      width: 100%;
    }

    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;


    .left {
      width: 520px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .leftItem {
        width: 158px;
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .el-input,
        .el-select,
        .el-date-editor {
          // width: 120px;
          flex: 1;
        }

        .el-input__inner {
          border-radius: 25px;
          border: 1px solid #666;
        }

        .lab_tit {
          font-size: 12px;
          // font-weight: bold;
          white-space: nowrap;
          width: 50px !important;
          text-align: right;
          margin-right: 3px;
          margin-left: 8px;
        }
      }
    }

    .right {
      text-align: right;
      width: 30%;

      .el-button {
        margin-bottom: 4px;
      }

      // display: flex;

    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      .el-table__row {
        td {
          padding: 4px 0;
        }
      }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

// .left-wrap {
//   display: flex;
//   margin-bottom: 15px;
//   align-items: center;
// }

.btns {
  position: absolute;
  /* position: ; */
  /* margin-right: 0; */
  right: 2%;
  bottom: 4%;
}

.ipt {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.slt {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

/* div /deep/ .detection{
  height: 600px;
} */
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.search {
  // display: flex;
  // flex-direction: column;
  /* justify-content: space-between; */
  /* // margin-top: 20px; */
}

// .left {
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
// }

// .left span {
//   width: 140px;
//   text-align: center;
// }

// .searchBtn {
//   margin-left: 15px;
// }

.right {
  // text-align: right;
  /* display: flex; */
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* div /deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
} */
</style>
