<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="代理开票记录"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">代理编号</span>
                        <el-input v-model="form.userId" placeholder="请输入代理编号" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">充值备注</span>
                        <el-input v-model="form.remarks" placeholder="请输入充值备注" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">开票备注</span>
                        <el-input v-model="form.remark" placeholder="请输入开票备注" size="mini"></el-input>
                    </div>
                    <div class="leftItem" style="width: 262px;">
                        <span class="lab_tit">时间</span>
                        <el-date-picker v-model="createdTime" type="datetimerange" range-separator="至" size="mini"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
                </div>
            </div>

            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange"
                        @cell-dblclick="ellDblclick">
                        <!-- <el-table-column  type="selection" width="55" ></el-table-column> -->
                        <el-table-column prop="userId" label="代理编号"></el-table-column>
                        <el-table-column prop="realname" label="代理名称"></el-table-column>
                        <!--        <el-table-column  label="账户余额">-->
                        <!--          <template #default="scope">￥{{ scope.row.money }}</template>-->
                        <!--        </el-table-column>-->
                        <!--<el-table-column  prop="money" label="充值后余额" ></el-table-column>-->
                        <!--<el-table-column  prop="oldMoney" label="充值前余额" ></el-table-column>-->
                        <!--<el-table-column  prop="addMoney" label="充值金额" -->
                        <!--v-if="realname == 'admin'"></el-table-column>-->
                        <el-table-column prop="createTime" label="开票时间">
                            <template v-slot="scope">
                                <div>
                                    {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createTime) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="remarks" label="充值备注"></el-table-column>
                        <el-table-column prop="remark" label="开票备注"></el-table-column>
                        <!--<el-table-column-->
                        <!--prop="status"-->
                        <!--label="财务确认状态"-->
                        <!---->
                        <!--v-if="realname == 'admin'"-->
                        <!--&gt;-->
                        <!--<template #default="scope">-->
                        <!--<span v-if="scope.row.status == 0">未查看</span>-->
                        <!--<span v-if="scope.row.status == 1">已查看</span>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                        <!--<el-table-column-->

                        <!--label="财务查看"-->
                        <!--width="100"-->
                        <!---->
                        <!--style="dispaly: flex"-->
                        <!--v-if="realname == 'admin'"-->
                        <!--&gt;-->
                        <!--<template #default="scope">-->

                        <!--<el-button type="primary" @click="unsubscribe(scope)" v-if="realname == 'admin'">确认</el-button>-->

                        <!--</template>-->
                        <!--</el-table-column>-->

                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination gination background layout="total, prev, pager, next" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "agentTicket",
    data() {
        return {
            // userLogTicketFlag: false, //日志开票记录
            form: {},
            // form_ticket: {}, //回显开票信息
            tableData: [],
            // tableData_ticket: [],
            pageTotal: 0, //总共有多少数据
            pageIndex: 1,//当前在第几页
            pageSize: 10,//前端规定每页显示数量
            page: 1,
            size: 10,
            sta: null,
            end: null,
            createdTime: null,
            // adminSum_Z: 'admin充值',
            // adminSum_F: 'admin扣款',
            listType: 1,
            realname: "",
            status: null,
            // options: [
            //     {
            //         status: 0,
            //         label: "未看",
            //     },
            //     {
            //         status: 1,
            //         label: "确认",
            //     },
            // ],
            // options: [{
            //   value: '选项1',
            //   label: '充值',
            //   disabled: true
            // }, {
            //   value: '选项2',
            //   label: '划拨'
            // }],
            // value: ''
        };
    },
    created() {
        this.realname = localStorage.getItem("realname")
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        this.getList()
    },
    watch: {
        id() {

        }
    },
    inject: ["reload"],

    methods: {

        // 搜索
        handleSearch() {
            //  // console.log("111111111111111111111",this.createdTime);
            if (this.form.id == "") {
                this.form.id = null;
            }
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            this.getList();
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            if (2 === this.listType) {
                this.getList2();
            } else if (3 === this.listType) {
                this.getList3();

            } else {
                this.getList();

            }
        },

        //展示and搜索
        getList() {
            this.listType = 1;
            let params = {
                // id: this.form.id,
                userId: this.form.userId,
                // status: this.form.status,
                page: this.page,
                size: this.size,
                sta: this.sta,
                end: this.end,
                remarks: this.form.remarks,
                remark: this.form.remark
            };
            // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
            axios.post("api/userLogTicket/list", params)
                .then(res => {
                    // console.log(res.data.data)
                    if (res.data.success == false) {
                        this.$router.push('/403')
                    } else {
                        this.tableData = res.data.data
                        this.pageTotal = res.data.total
                    }
                })
                .catch((err) => {
                    // console.log(err);
                })
        }, //展示and搜索 只admin充值
        getList2() {
            this.listType = 2;
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            let params = {
                id: this.form.id,
                page: this.page,
                size: this.size,
                sta: this.sta,
                end: this.end,
                remarks: this.form.remarks,
                moneyType: 1
            };
            // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
            axios.post("api/log/sum-userlog", params)
                .then(res => {
                    // console.log(res.data.data)
                    if (res.data.success == false) {
                        this.$router.push('/403')
                    } else {
                        this.tableData = res.data.data.list
                        this.pageTotal = res.data.total
                        this.adminSum_Z = res.data.data.sum
                    }
                })
                .catch((err) => {
                    // console.log(err);
                })
        },
        getList3() {
            this.listType = 3;
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            let params = {
                id: this.form.id,
                page: this.page,
                size: this.size,
                sta: this.sta,
                end: this.end,
                remarks: this.form.remarks
            };
            // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
            axios.post("api/log/sum-userlog", params)
                .then(res => {
                    // console.log(res.data.data)
                    if (res.data.success == false) {
                        this.$router.push('/403')
                    } else {
                        this.tableData = res.data.data.list
                        this.pageTotal = res.data.total
                        this.adminSum_F = res.data.data.sum
                    }
                })
                .catch((err) => {
                    // console.log(err);
                })
        },
        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        //确认按钮
        unsubscribe(row) {
            // console.log(row)
            let Tstatis = row.row.status;
            if (0 === Tstatis) {
                Tstatis = 1
            } else if (1 === Tstatis) {
                Tstatis = 0
            } else {
                Tstatis = null
            }
            ;

            let params = {
                id: row.row.id,

                status: Tstatis
            }
            axios
                // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
                .post("api/log/userlog-update", params)
                .then((res) => {
                    this.$message.success(res.data.message);
                })
                .catch((err) => {

                });
            // this.reload();
            if (2 === this.listType) {
                this.getList2();
            } else if (3 === this.listType) {
                this.getList3();

            } else {
                this.getList();

            }
        },
        //重置
        reset() {
            this.form.id = null;
            this.form.remarks = null;
            this.form.createdTime = null;
            this.createdTime = null;
            this.adminSum_Z = 'admin充值';
            this.adminSum_F = 'admin扣款';
            this.listType = 1;
            // this.page = 1
            // this.getList();
            this.reload()
        },
        //搜索,再次调用getList方法就是展示
        // handleSearch() {
        //
        //     this.getList();
        // },
    }
};
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            width: 540px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 176px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 50px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }

        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}


.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.search {
    /*display: flex;*/
    /*justify-content: space-between;*/
    /* // margin-top: 20px; */
}

// .left {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     margin-bottom: 20px;
// }

// .left span {
//     width: 25%;
//     margin: 10px;
// }

.time span {
    text-align: right;
    margin-right: 5px;
    width: 120px;
}

.searchBtn {
    margin-left: 15px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

// div /deep/ .el-input {
/*width%;*/
// }

#ticket .el-input {
    width: 30%;
    background-color: #98fd98;


}

#ticket ::v-deep .el-input__inner {
    width: 99%;
    /*height: 99%;*/
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}

// .el-input__inner {
//     width: 170%;
// }
</style>
