<template>
  <div class="appPage">
    <!-- <h3 class="tit">2号线密钥</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="线路开关管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <!--<div class="leftItem">-->
          <!--<span class="lab_tit">url</span>-->
          <!--<el-input v-model="url" placeholder="请输入url" size="mini"></el-input>-->
          <!--</div>-->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="addVisible = true"
            round
            size="mini"
            >添加</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找</el-button
          >
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            >重置</el-button
          >
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column
              prop="type"
              label="通道"
              :formatter="typeFilter"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="创建/更新时间"
            ></el-table-column>
            <el-table-column label="是否启用" align="left" width="100">
              <template #default="scope">
                <el-switch
                  @change="handleSwitchChange(scope.row)"
                  :value="scope.row.status === 1 ? true : false"
                  :formatter="statusFilter"
                ></el-switch>
              </template>
            </el-table-column>

            <!--<el-table-column show-overflow-tooltip label="是否使用" align="left" width="100">-->
            <!--<el-switch slot-scope="scope"  v-model="scope.row.status == 1 ?true : fasle" ></el-switch>-->
            <!--</el-table-column>-->

            <!--<el-table-column label="操作" width="100" align="center">-->
            <!--<template #default="scope">-->
            <!--&lt;!&ndash;<el-button type="primary" @click="getVos_user(scope)" size="mini">账户明细</el-button>&ndash;&gt;-->
            <!--<el-button type="primary" @click="user_table_one(scope.row);" size="mini">查询报表-->
            <!--</el-button>-->
            <!--</template>-->
            <!--</el-table-column>-->
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="加白" v-model="addVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <div>
          <span>选择通道</span>
          <el-select v-model="form.type" placeholder="选择通道">
            <el-option
              v-for="item in options1"
              :key="item.type"
              :label="item.label1"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <span>选择状态</span>
          <el-select v-model="form.status" placeholder="选择状态">
            <el-option
              v-for="item in options2"
              :key="item.status"
              :label="item.label"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "centerNum",
  data() {
    return {
      sta: null,
      end: null,
      timestamp: null,
      type: null,
      trueStatus: true,
      falseStatus: false,

      userData: [],
      userTableData: [],

      form: {},
      tableStatusData: [], //用于接收后端数据表的数组
      statusListVisible: false, //密钥列表弹窗
      editVisible: false, //是否显示编辑弹窗
      addVisible: false, //是否显示添加弹窗
      pageIndex: 1, //当前在第几页
      pageSize: 20, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      id: null,

      options1: [
        // {
        //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
        //   type: 0,
        //   label1: "隐私小号通道",
        // },

        // {
        //     type: 5,
        //     label1: "移动ax通道",
        // },
        {
          type: 1,
          label1: "南京线通道",
        },
        {
          type: 2,
          label1: "移动1通道",
        },
        // {
        //     type: 10,
        //     label1: "移动3通道",
        // },

        // {
        //     type: 13,
        //     label1: "移动5通道",
        // },
        // {
        //   type: 3,
        //   label1: "联通2通道",
        // },
        // {
        //   type: 4,
        //   label1: "联通1通道",
        // },

        // {
        //   type: 6,
        //   label1: "联通3通道",
        // },
        // {
        //   type: 7,
        //   label1: "联通4通道",
        // },
        {
          type: 8,
          label1: "H",
        },
        {
          type: 14,
          label1: "S",
        },

        {
          type: 15,
          label1: "D",
        },

        // {
        //     type: 16,
        //     label1: "S2",
        // },

        {
          type: 17,
          label1: "SIP",
        },
        {
          type: 18,
          label1: "X",
        },
        {
          type: 20,
          label1: "甘肃",
        },
        {
          type: 21,
          label1: "2号线",
        },
        {
          type: 22,
          label1: "3号线",
        },
        {
          type: 23,
          label1: "新2号线",
        },
        {
          type: 24,
          label1: "重庆线路",
        },
        {
          type: 25,
          label1: "1号线",
        },
        {
          type: 26,
          label1: "无锡",
        },
        {
          type: 27,
          label1: "翼信",
        },
        {
          type: 28,
          label1: "连云港YT",
        },
        {
          type: 50,
          label1: "vos",
        },
      ],
      options2: [
        {
          status: 1,
          label: "启用",
        },
        {
          status: 2,
          label: "报停",
        },
      ],
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  mounted() {
    if (document.body.clientWidth <= 900) {
      // 手机端适配,自动跳转到对应的手机页面
      this.$router.push("/uuidTypeOpenApp");
    }
    window.onresize = () => {
      return (() => {
        // 监听窗口大小 document.body.clientWidth
        if (document.body.clientWidth <= 900) {
          // 手机端适配,自动跳转到对应的手机页面
          this.$router.push("/uuidTypeOpenApp");
        }
      })();
    };
  },
  watch: {},
  methods: {
    statusFilter(row, column, cellValue) {
      console.log("状态", row);
      if (row.status == 1) {
        row.bor == true;
      } else {
        row.bor = false;
      }
      return row.bor;
    },
    typeFilter(row, column, cellValue) {
      const found = this.options1.find((option) => option.type === cellValue);
      return found ? found.label1 : "查无数据-" + cellValue;
    },
    //开关修改触发
    handleSwitchChange(row) {
      console.log("状态", row);
      if (row.status === 1) {
        // 开关状态为开启，调用接口更新数据
        this.updateData(row.type, 2);
      } else {
        // 开关状态为关闭，调用接口更新数据
        this.updateData(row.type, 1);
      }
    },
    //添加按钮
    addSubmit() {
      if (this.form.type == null || this.form.status == null) {
        this.$message.error("通道或状态必填");
        return;
      }
      this.updateData(this.form.type, this.form.status);
      this.form = [];
      this.addVisible = false;
    },
    //开关修改
    updateData(type, status) {
      let params = {
        type: type,
        status: status,
      };
      axios.post("api/uuid-typeOpen/add-update", params).then((res) => {
        if (res.data.statusCode == "00000") {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
        this.getList();
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        url: this.url,
        type: this.type,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphone/list", params)
        .post("api/uuid-typeOpen/list", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = this.tableData.length;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    //刷新按钮
    reset() {
      this.form = [];
      this.type = null;
      this.url = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.frequency {
  display: flex;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 180px;

      .lab_tit {
        // width: 110px !important;
      }
    }

    .left {
      width: 750px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }

// .left {
//   display: flex;
//   align-items: center;
// }

// .item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .left span {
//   margin-left: 15px;
//   width: 100px;
//   align-items: center;
// }

// .searchBtn {
//   margin-left: 30px;
// }

// .right {
//   display: flex;
//   justify-content: flex-end;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

div ::v-deep .el-form-item__label {
  font-size: 13px !important;
}

.left {
  // width: 60%;
  width: 710px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .leftItem {
    width: 175px;
    height: 28px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .el-input,
    .el-select,
    .el-date-editor {
      // width: 120px;
      flex: 1;
    }

    .el-input__inner {
      height: 23px !important;
      border-radius: 25px;
      border: 1px solid #666;
    }

    .el-input__icon {
      line-height: 24px !important;
    }

    .lab_tit {
      font-size: 12px;
      // font-weight: bold;
      white-space: nowrap;
      width: 50px !important;
      text-align: right;
      margin-right: 3px;
      margin-left: 8px;
    }
  }
}

.right {
  text-align: right;
  // width: 30%;
  flex: 1;
  // display: flex;
  .el-button {
    margin-bottom: 3px;
  }
}
</style>
