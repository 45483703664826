<template>
    <div class="appPage">
        <!-- <h3 class="tit">2号线密钥</h3> -->
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="VOS账户/报表"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">

                    <!--<div class="leftItem">-->
                        <!--<span class="lab_tit">url</span>-->
                        <!--<el-input v-model="url" placeholder="请输入url" size="mini"></el-input>-->
                    <!--</div>-->
                </div>
                <div class="right">
                    <el-button type="primary" @click="open_jieSuan" size="mini">批量查看结算账户明细</el-button>
                    <el-button type="primary" @click="open_puTong" size="mini">批量查看普通账户明细</el-button>
                    <el-button type="primary" @click="open_table" size="mini">批量查看报表</el-button>
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                              header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="50"></el-table-column>
                        <el-table-column prop="account" label="账户名称"></el-table-column>
                        <el-table-column prop="money" label="余额" :formatter="roundToTwoDecimal"></el-table-column>
                        <el-table-column prop="limitMoney" label="透支"></el-table-column>

                        <el-table-column label="操作" width="100" align="center">
                            <template #default="scope">
                                <!--<el-button type="primary" @click="getVos_user(scope)" size="mini">账户明细</el-button>-->
                                <el-button type="primary" @click="user_table_one(scope.row);" size="mini">查询报表</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                                       :page-size="pageSize"
                                       :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>


        <!-- 结算账户明细弹出框 -->
        <el-dialog
                :title="`结算账户`"
                v-model="user_jieSuan_flag"
                width="50%"
        >
            <el-table
                    :data="userData"
                    border
                    header-cell-class-name="table-header"
                    height="500"
            >
                <el-table-column prop="account" label="公司名称" width="280"></el-table-column>
                <el-table-column prop="money" label="余额" :formatter="roundToTwoDecimal"></el-table-column>
                <el-table-column prop="limitMoney" label="透支"></el-table-column>
                <el-table-column prop="feeRateGroup" label="费率组"></el-table-column>
            </el-table>
            <!-- <div style="text-align: right">
                    <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                      :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                      @current-change="handlePageChange"></el-pagination>
                  </div> -->
        </el-dialog>
        <!-- 普通账户明细弹出框 -->
        <el-dialog
                :title="`普通账户`"
                v-model="user_puTong_flag"
                width="50%"
        >
            <el-table
                    :data="userData"
                    border
                    header-cell-class-name="table-header"
                    height="500"
            >
                <el-table-column prop="account" label="公司名称" width="280"></el-table-column>
                <el-table-column prop="money" label="余额" :formatter="roundToTwoDecimal"></el-table-column>
                <el-table-column prop="limitMoney" label="透支"></el-table-column>
                <el-table-column prop="feeRateGroup" label="费率组"></el-table-column>
            </el-table>
            <!-- <div style="text-align: right">
                    <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                      :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                      @current-change="handlePageChange"></el-pagination>
                  </div> -->
        </el-dialog>
        <!-- 报表数据弹出框 -->
        <el-dialog
                :title="`账户报表`"
                v-model="user_table_flag"
                width="70%"
        >
            <div class="search">
                <div class="left">
                    <div >
                        <span class="lab_tit">类型</span>
                        <el-select size="mini" v-model="type" placeholder="请选择类型">
                            <el-option v-for="item in options" :key="item.type" :label="item.label" :value="item.type">
                            </el-option>
                        </el-select>
                    </div>
                    <div >
                        <span class="lab_tit">选择时间</span>
                        <el-date-picker v-model="timestamp" class="datetimerangebox" type="datetimerange"
                                        :clearable="false"
                                        :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right" >
                        </el-date-picker>
                    </div>

                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="getVos_table_list()" >查找</el-button>
                </div>
            </div>


            <el-table
                    :data="userTableData"
                    border
                    header-cell-class-name="table-header"
                    height="600"
            >
                <el-table-column prop="time" label="时间" :formatter="formatTime" width="280"></el-table-column>
                <el-table-column prop="account" label="公司名称" width="280"></el-table-column>
                <el-table-column prop="totalFee" label="费用统计" :formatter="roundToTwoDecimal"></el-table-column>
                <el-table-column prop="cdrCount" label="话单统计"></el-table-column>
                <el-table-column prop="totalTime" label="计费时长（秒）"></el-table-column>
                <el-table-column prop="totalSuiteFee" label="套餐费用总计"></el-table-column>
                <el-table-column prop="netTime" label="国内通话计费时长"></el-table-column>
            </el-table>
            <!-- <div style="text-align: right">
                    <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="pageIndex"
                      :page-size="pageSize" :page-sizes="[10, 20, 50]" :total="pageTotal" @size-change="handleSizeChange"
                      @current-change="handlePageChange"></el-pagination>
                  </div> -->
        </el-dialog>

    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "centerNum",
        data() {
            return {
                sta: null,
                end: null,
                timestamp: null,
                type: null,
                numsArray: [],
                userData: [],
                userTableData: [],
                user_table_flag: false,
                user_jieSuan_flag: false,
                user_puTong_flag: false,
                url: null,
                appid: null,
                form: {},
                tableStatusData: [],//用于接收后端数据表的数组
                statusListVisible: false,//密钥列表弹窗
                editVisible: false,//是否显示编辑弹窗
                addVisible: false,//是否显示添加弹窗
                pageIndex: 1,//当前在第几页
                pageSize: 20,//前端规定每页显示数量
                pageTotal: 0,//总共有多少数据
                tableData: [],//用于接收后端数据表的数组
                page: 1,//接口请求的页数
                size: 10,//接口请求的每页的数量
                id: null,
                city: null,
                options: [
                    {
                        type: 1,
                        label: "日报表",
                    },
                    {
                        type: -2,
                        label: "月报表",
                    },

                ],
            };
        },
        created() {

            this.getList();
        },
        //切换界面就执行方法
        activated() {
            this.getList()
        },
        watch: {},
        methods: {
            formatTime(row, column, cellValue) {
                const beginTime = new Date(row.beginTime);
                const endTime = new Date(row.endTime + 86400000); // 假设每个时间段为一天
                // const beginDate = beginTime.toLocaleDateString();
                // const endDate = endTime.toLocaleDateString();
                const beginDate = this.getYMDHMS("YYYY-mm-dd", beginTime);
                const endDate = this.getYMDHMS("YYYY-mm-dd", endTime);

                return `${beginDate}至${endDate}`;
            },
            // 转换日期
            getYMDHMS(format, date) {
                if (!date) {
                    return "";
                }
                date = new Date(date);
                const dataItem = {
                    "Y+": date.getFullYear().toString(),
                    "m+": (date.getMonth() + 1).toString(),
                    "d+": date.getDate().toString(),
                    "H+": date.getHours().toString(),
                    "M+": date.getMinutes().toString(),
                    "S+": date.getSeconds().toString(),
                };
                Object.keys(dataItem).forEach((item) => {
                    const ret = new RegExp(`(${item})`).exec(format);
                    if (ret) {
                        format = format.replace(
                            ret[1],
                            ret[1].length === 1
                                ? dataItem[item]
                                : dataItem[item].padStart(ret[1].length, "0")
                        );
                    }
                });
                return format;
            },
            //保留5位
            roundToTwoDecimal(row, column, cellValue) {
                return parseFloat(cellValue).toFixed(5);
            },
            //选择table表格
            handleSelectionChange(val) {
                this.numsArray = val;
            },
            //结算账户弹出框
            open_jieSuan() {
                if (this.numsArray.length == 0) {
                    this.$message({
                        type: "warning",
                        message: "请先选中账户",
                    });
                    return false;
                } else {
                    this.getVos_user_list(2)
                    this.user_jieSuan_flag = true;
                }
            },
            //普通账户弹出框
            open_puTong() {
                if (this.numsArray.length == 0) {
                    this.$message({
                        type: "warning",
                        message: "请先选中账户",
                    });
                    return false;
                } else {
                    this.getVos_user_list(0)
                    this.user_puTong_flag = true;
                }
            },
            //报表弹出框
            open_table() {
                if (this.numsArray.length == 0) {
                    this.$message({
                        type: "warning",
                        message: "请先选中账户",
                    });
                    return false;
                } else {

                    this.user_table_flag = true;
                }
            },
            //报表单个弹出框
            user_table_one(row) {
                this.numsArray=[]
                this.numsArray.push(row)
                this.open_table()
            },


            // 分页导航
            handlePageChange(val) {
                this.page = val;
                this.getList();
            },
            handleSearch() {
                this.getList();
            },
            //展示and查找
            getList() {
                let params = {
                    url: this.url,
                    type: 1
                };
                axios
                // .post("http://106.14.0.130:9998/vo/xphone/list", params)
                    .post("api/param-vos/getVos-userAll", params)
                    .then((res) => {
                        if (res.data.success == false) {
                            this.$router.push('/403')
                        } else {
                            this.tableData = res.data.data
                            this.pageTotal = this.tableData.length
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    })
            },


            //查询用户详情 批量
            getVos_user_list(type) {
                let arr = this.numsArray.map((v) => v.account);
                console.log("获取到的数据：", arr)
                let params = {
                    list: arr,
                    type: type
                };
                axios.post("api/param-vos/getVos-user", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.userData = res.data.data
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
            },

            //查询报表详情
            getVos_table_list() {
                console.log("获取到的数据：",this.numsArray)
                if (this.timestamp != null) {
                    this.sta = this.getYMDHMS("YYYYmmdd", this.timestamp[0]);
                    this.end = this.getYMDHMS("YYYYmmdd", this.timestamp[1]);
                } else {
                    this.$message({
                        type: "warning",
                        message: "请先选择时间",
                    });
                    return false;
                }
                if (null == this.type) {
                    this.$message({
                        type: "warning",
                        message: "请先选择类型",
                    });
                    return false;
                }

                let arr = this.numsArray.map((v) => v.account);
                let params = {
                    list: arr,
                    type: this.type,
                    sta: this.sta,
                    end: this.end
                };
                axios.post("api/param-vos/getVos-table", params)
                    .then(res => {
                        if (res.data.statusCode == '00000') {
                            this.userTableData = res.data.data
                            this.$message.success(res.data.message)
                        } else {
                            this.$message.error(res.data.message)
                        }
                    })
            },


            //刷新按钮
            reset() {
                this.url = null;
                this.numsArray=[]
                this.url =null
                this.getList();
            },
        }
    };
</script>

<style scoped lang="scss">
    .frequency {
        display: flex;
    }

    .appPage {
        height: 100%;
    }

    ::v-deep .appPageMain {
        height: 100%;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        .search {
            .leftItem {
                width: 180px;

                .lab_tit {
                    // width: 110px !important;
                }
            }

            .left {
                width: 750px;
            }
        }

        .mainTable {
            flex: 1;
            display: flex;
            flex-direction: column;

            .table {
                flex: 1;
            }

            .mainTableFY {
                height: 40px;
                text-align: right;
                margin-top: 4px;
            }
        }
    }

    ::v-deep .titleBox {
        height: 40px;

        .el-tabs__item {
            font-size: 24px;
            font-weight: bold;
            color: #333333 !important;
        }
    }

    .tit {
        font-weight: normal;
        font-size: 36px;
        color: #333333;
    }

    // .search {
    //   display: flex;
    //   justify-content: space-between;
    //   margin-top: 20px;
    // }

    // .left {
    //   display: flex;
    //   align-items: center;
    // }

    // .item {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    // }

    // .left span {
    //   margin-left: 15px;
    //   width: 100px;
    //   align-items: center;
    // }

    // .searchBtn {
    //   margin-left: 30px;
    // }

    // .right {
    //   display: flex;
    //   justify-content: flex-end;
    // }

    .reset {
        width: 80px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .add {
        margin-left: 15px;
    }

    .container {
        /* // margin-top: 20px; */
    }

    div ::v-deep .el-form-item__label {
        font-size: 13px !important;
    }
    .left {
        // width: 60%;
        width: 710px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .leftItem {
            width: 175px;
            height: 28px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .el-input,
            .el-select,
            .el-date-editor {
                // width: 120px;
                flex: 1;
            }

            .el-input__inner {
                height: 23px !important;
                border-radius: 25px;
                border: 1px solid #666;
            }

            .el-input__icon {
                line-height: 24px !important;
            }

            .lab_tit {
                font-size: 12px;
                // font-weight: bold;
                white-space: nowrap;
                width: 50px !important;
                text-align: right;
                margin-right: 3px;
                margin-left: 8px;
            }
        }
    }
    .right {
        text-align: right;
        // width: 30%;
        flex: 1;
        // display: flex;
        .el-button {
            margin-bottom: 3px;
        }
    }
</style>
