<template>
  <div class="appPage">
    <!-- <h3 class="tit">南京线中间号管理</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="南京线中间号管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">

          <div class="leftItem">
            <span class="lab_tit">中间号码</span>
            <el-input v-model="bindPhone" placeholder="请输入中间号码" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">行业</span>
            <el-input v-model="industry" placeholder="请输入行业" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>

        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
          <el-button type="primary" round size="mini" @click="open()">添加</el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column prop="id" label="中间号编号"></el-table-column>
            <el-table-column prop="bindPhone" label="中间号码"></el-table-column>
            <el-table-column prop="areaCode" label="区号"></el-table-column>
            <el-table-column prop="count" label="次数"></el-table-column>
            <el-table-column prop="industry" label="行业"></el-table-column>
            <el-table-column label="操作" width="100" align="center">
              <template #default="scope">

                <el-button type="primary" @click="handleClick(scope)" size="mini">编辑</el-button>

              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex" :page-size="pageSize"
              :total="pageTotal" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="rules" label-width="80px">
        <!-- <el-form-item label="中间号编号" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item> -->
        <el-form-item label="中间号码" prop="bindPhone">
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>

        <el-form-item label="区号" prop="areaCode">
          <el-input v-model="form.areaCode"></el-input>
        </el-form-item>


        <el-form-item label="次数" prop="count">
          <el-input v-model="form.count"></el-input>
        </el-form-item>

        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>


    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
        <!-- <el-form-item label="中间号编号" prop="id">
          <el-input v-model="form.id"></el-input>
        </el-form-item> -->
        <el-form-item label="中间号码" prop="bindPhone">
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>

        <el-form-item label="区号" prop="areaCode">
          <el-input v-model="form.areaCode"></el-input>
        </el-form-item>


        <el-form-item label="次数" prop="count">
          <el-input v-model="form.count"></el-input>
        </el-form-item>

        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>


  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      form: {
        id: "",
        bindPhone: "",
        areaCode: "",
        count: "",
        industry: "",
      },
      editVisible: false,//是否显示编辑弹窗
      addVisible: false,//是否显示添加弹窗
      pageIndex: 1,//当前在第几页
      pageSize: 20,//前端规定每页显示数量
      pageTotal: 0,//总共有多少数据
      tableData: [],//用于接收后端数据表的数组
      page: 1,//接口请求的页数
      size: 20,//接口请求的每页的数量
      bindPhone: null,
      industry: null,
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList()
  },
  watch: {
    industry() {
      if (this.industry == "") {
        this.industry = null;
      }
    },
    bindPhone() {
      if (this.bindPhone == "") {
        this.bindPhone = null;
      }
    },
  },
  methods: {
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        industry: this.industry,
        bindPhone: this.bindPhone,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphone/list", params)
        .post("api/xphone/list", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push('/403')
          } else {
            this.tableData = res.data.data
            this.pageTotal = res.data.total
          }
        })
        .catch((err) => {
          // console.log(err);
        })
    },
    //添加
    submitAdd() {
      let params = {
        id: this.form.id,
        bindPhone: this.form.bindPhone,
        areaCode: this.form.areaCode,
        count: this.form.count,
        industry: this.form.industry,
      };
      // axios.post("http://106.14.0.130:9998/vo/xphone/add",params)
      axios.post("api/xphone/add", params)
        .then(res => {
          // console.log(res)
          if (res.data.statusCode == '00000') {
            this.getList()
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
          this.addVisible = false
        })

    },
    //编辑
    submitEdit() {
      let params = {
        id: this.form.id,
        bindPhone: this.form.bindPhone,
        areaCode: this.form.areaCode,
        count: this.form.count,
        industry: this.form.industry,
      };
      // axios.post("http://106.14.0.130:9998/vo/xphone/subim",params)
      axios.post("api/xphone/subim", params)
        .then(res => {
          // console.log(res)
          if (res.data.statusCode == '00000') {
            this.getList();
            this.$message.success(res.data.message)
          } else {
            this.$message.error(res.data.message)
          }
          this.editVisible = false
        })
    },
    //刷新按钮
    reset() {
      this.bindPhone = null;
      this.industry = null;
      this.getList();
    },
    //打开添加弹窗
    open() {
      this.addVisible = true;
    },
    //打开编辑弹窗
    handleClick(row) {
      this.editVisible = true;
      let params = {
        id: row.row.id,
      };
      //  // console.log(row.row.id)
      // axios.post("http://106.14.0.130:9998/vo/xphone/findOne",params)
      axios.post("api/xphone/findOne", params)
        .then(res => {
          // console.log(res)
          this.form.id = res.data.data.id
          this.form.bindPhone = res.data.data.bindPhone
          this.form.areaCode = res.data.data.areaCode
          this.form.count = res.data.data.count
          this.form.industry = res.data.data.industry
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search{
    .leftItem{
      width: 184px;
    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }



    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}


// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }

// .left {
//   display: flex;
//   align-items: center;
// }

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .left span {
//   margin-left: 15px;
//   width: 100px;
//   align-items: center;
// }

// .searchBtn {
//   margin-left: 30px;
// }

// .right {
//   display: flex;
//   justify-content: flex-end;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  // margin-top: 20px;
}

div ::v-deep .el-form-item__label {
  font-size: 13px !important;
}
</style>
