<template>
  <div class="appPage">
    <h3 class="tit">联通2号码管理</h3>
    <div class="search">
      <div class="left">
          
       <div class="item">
        <span>中间号码</span>
        <el-input
          v-model="bindPhone1"
          placeholder="请输入中间号码"
          class="handle-input mr10"
          v-on:keyup.enter="handleSearch"
        ></el-input>
       </div>

        <div class="item">
        <span>主叫</span>
        <el-input
         v-model="activePhone"
         placeholder="请输入主叫"
         class="handle-input mr10"
         v-on:keyup.enter="handleSearch"
        ></el-input>
        </div>
        
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          class="searchBtn"
        >查找</el-button>
      </div>
      <div class="right">
         <el-button class="reset" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
        <el-button
            type="primary"
            class="add"
            @click="open()"
        >绑定中间号</el-button>
      </div>
    </div>
    <div class="container">
      <el-table
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange"
      >
        <el-table-column  prop="id" label="中间号编号" ></el-table-column>
        <el-table-column  prop="activePhone" label="主叫" ></el-table-column>
        <el-table-column  prop="bindPhone" label="中间号码" ></el-table-column>    
        <el-table-column  prop="remake" label="备注" ></el-table-column>
         <el-table-column  label="操作" width="300" align="center">
          <template #default="scope">
            <el-button type="primary" @click="handleClick(scope)">编辑</el-button>
            <el-button type="primary" @click="del(scope)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="pageTotal"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 绑定中间号弹出框 -->
    <el-dialog title="绑定中间号" v-model="addVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="中间号码" prop="bindPhone">
            <el-select v-model="bindPhone">
            <el-option
              v-for="item in Options"
              :key="item"
              :label="item"
              :value="item"
              @change="changeBind">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="主叫" prop="activePhone">
          <el-input v-model="form.activePhone"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remake">
          <el-input v-model="form.remake"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>


    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="editRules"
        label-width="80px">
        <el-form-item label="中间号码" prop="bindPhone">
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>

        <el-form-item label="主叫" prop="areaCode">
          <el-input v-model="form.activePhone"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remake">
          <el-input v-model="form.remake"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      form:{
        id: "",
        bindPhone: "",
        activePhone: "",
        remake:"",
      },
      paramid:1,
      activePhone:null,//搜索框主叫
      remake:null,
      editVisible:false,//是否显示编辑弹窗
      addVisible:false,//是否显示添加弹窗
      pageIndex: 1,//当前在第几页
      pageSize: 20,//前端规定每页显示数量
      pageTotal: 0,//总共有多少数据
      tableData: [],//用于接收后端数据表的数组
      page:1,//接口请求的页数
      size:20,//接口请求的每页的数量
      bindPhone:null,
      bindPhone1:null,//搜索框的中间号
      industry:null,
      Options:[
        {
          id:'',
          bindPhone:'',
        }
      ],
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated(){
    // this.getList()
  },
  watch: {
    industry() {
      if (this.industry == "") {
        this.industry = null;
      }
    },
    bindPhone1() {
      if (this.bindPhone1 == "") {
        this.bindPhone1 = null;
      }
    },
  },
  methods: {

    //中间号选择框
    changeBind(){
      axios
      // .post("http://106.14.0.130:9998/vo/xphoneltTwo/get_lt2_bindPhone",params)
      .post("api/xphoneltTwo/get_lt2_bindPhone",params)
      .then(res =>{
         // console.log("调到这里了")
      })
    },

    // 分页导航
    handlePageChange(val) {
       this.page = val;
       this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size, 
        activePhone:this.activePhone,
        bindPhone:this.bindPhone1,
        remake:this.remake,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneltTwo/list", params)
        .post("api/xphoneltTwo/list", params)
        .then((res) => {
                this.tableData = res.data.data
                this.pageTotal = res.data.total
        })
        .catch((err) => {
           // console.log(err);
        })
    },
    //打开绑定中间号弹窗
    open(){
        this.addVisible = true;
        axios
        // .post("http://106.14.0.130:9998/vo/xphoneltTwo/get_lt2_bindPhone")
        .post("api/xphoneltTwo/get_lt2_bindPhone")
        .then(res =>{
           // console.log(res.data.data)
          this.Options = res.data.data
           // console.log(this.Options)
          })
     },
    //绑定中间号确定按钮
    submitAdd(){
       // console.log(this.form.id)
      let params = {
        bindPhone:this.bindPhone,
        activePhone:this.form.activePhone,
        remake:this.form.remake
      };
      // axios.post("http://106.14.0.130:9998/vo/xphoneltTwo/add",params)
      axios.post("http://106.14.0.130:9998/vo/xphoneltTwo/add",params)
      .then(res =>{
         // console.log(res)
          if(res.data.statusCode=='00000'){
            this.getList()
            this.$message.success(res.data.message)
          }else{
            this.$message.error(res.data.message)
          }
          this.addVisible = false
          this.form ={}
          this.bindPhone = null
      })
    },
     //打开编辑弹窗
    handleClick(row){
       // console.log(row.row)
       this.editVisible = true;
       this.id = row.row.id;
       this.form.activePhone = row.row.activePhone
       this.form.bindPhone = row.row.bindPhone
       this.form.remake = row.row.remake
     },
    //编辑确定按钮
    submitEdit(){
      let params = {
        id:this.id,
        activePhone:this.form.activePhone,
        remake:this.form.remake,
      };
       // console.log(this.form.remake)
      axios
      // .post("http://106.14.0.130:9998/vo/xphoneltTwo/update",params)
      .post("api/xphoneltTwo/update",params)
      .then(res =>{
          if(res.data.statusCode=='00000'){
            this.getList();
            this.$message.success(res.data.message)
          }else{
            this.$message.error(res.data.message)
          }
          this.editVisible = false
      })
    },
    //删除按钮
    del(row){
      let params = {
        id:row.row.id
      }      

      this.$confirm('此操作将永久删除该项, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

          axios
          // .post("http://106.14.0.130:9998/vo/xphoneltTwo/del",params)
          .post("api/xphoneltTwo/del",params)
          .then(res =>{
            if(res.data.statusCode == "00000"){
              this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
            }else{
              this.$message.error(res.data.message)
            }
          })
          .catch(err =>{
            this.$message.error(err.data.message)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
          this.getList()
        });
    },
    //刷新按钮
    reset() {
      this.bindPhone1 = null;
      this.activePhone = null;   
      this.getList();
    },
    
  }
};
</script>

<style scoped>

.tit{
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}
.search{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.left{
  display: flex;
  align-items: center;
}
.item{
  display: flex;
  justify-content: center;
  align-items: center;
}
.left span{
  text-align:center;
  width: 100px;
}
.searchBtn{
  margin-left: 30px;
}
.right{
  display: flex;
  justify-content: flex-end;
}
.reset{
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.add{
  margin-left: 15px;
}
.container{
  margin-top: 20px;
}
/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
