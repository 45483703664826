<template>
    <div class="appPage">
        <!-- <h3 class="tit">2号线线路号码加白</h3> -->
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="甘肃号码加白"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">号码查询</span>
                        <el-input v-model="phone" placeholder="请输入号码" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">公司名称</span>
                        <el-input v-model="name" placeholder="请输入公司名称" size="mini"></el-input>
                    </div>
                    <div class="leftItem"  v-if="realname == 'admin'">
                        <span class="lab_tit">代理id</span>
                        <el-input v-model="userId" placeholder="请输入代理id" size="mini"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input v-model="appid" placeholder="请输入appid" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">选择状态</span>
                        <el-select v-model="status" placeholder="选择状态" size="mini">
                            <el-option v-for="item in options" :key="item.status" :label="item.label" :value="item.status">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">时间</span>
                        <el-date-picker v-model="createdTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" size="mini">
                        </el-date-picker>
                    </div>
                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找
                    </el-button>

                    <el-button type="primary" icon="el-icon-refresh" @click="reset()" round size="mini">重置
                    </el-button>

                    <!--<el-button type="primary" @click="whitening2()">加白数据库</el-button>-->
                    <el-button type="primary" @click="whitening()"  round size="mini"    v-if="realname == 'admin'">加白</el-button>
                    <!--<el-button type="primary" @click="many_del()" round size="mini">批量退订</el-button>-->
                    <el-button type="primary" @click="whitenings()" round size="mini"    v-if="realname == 'admin'">批量加白</el-button>

                    <!--<el-button type="primary" @click="excel()" round size="mini"    v-if="realname == 'admin'">导出</el-button>-->

                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="selectionLineChangeHandle">
                        >
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="userId" label="代理id"    v-if="realname == 'admin'"></el-table-column>
                        <el-table-column prop="name" label="公司名称"></el-table-column>
                        <el-table-column prop="phone" label="号码"></el-table-column>
                        <el-table-column prop="realname" label="线路id"></el-table-column>
                        <el-table-column prop="appid" label="appid"></el-table-column>
                        <el-table-column prop="createdTime" label="创建时间"></el-table-column>
                        <el-table-column prop="status" label="号码状态">
                            <template #default="scope">
                                <span v-if="scope.row.status == 0">待验证</span>
                                <span v-if="scope.row.status == 1">正常</span>
                                <span v-if="scope.row.status == 2">退订</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="appKey" label="加白通道">
                            <template #default="scope">
                                <span v-if="scope.row.appKey == null">甘肃</span>
                                <span v-if="scope.row.appKey == 220518222201445048">甘肃</span>
                                <span v-if="scope.row.appKey == 2403281629023610162">青海</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="changeTime" label="退订时间"></el-table-column>
                        <el-table-column label="操作" width="100" align="center" style="dispaly: flex"  v-if="realname == 'admin'">
                            <template #default="scope">

                                <el-button type="primary" @click="update_tan(scope)" size="mini">修改</el-button>

                            </template>
                        </el-table-column>

                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
                            @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 加白弹出框 -->
        <el-dialog title="加白" v-model="whiteVisible" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="appid">
                    <el-input v-model="form.appid"></el-input>
                </el-form-item>
                <el-form-item label="加白号码">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="线路id">
                    <el-input v-model="form.realname"></el-input>
                </el-form-item>
                <span>选择通道</span>
                <el-select v-model="appKey" placeholder="选择通道">
                    <el-option v-for="item in options2" :key="item.appKey" :label="item.label" :value="item.appKey">
                    </el-option>
                </el-select>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="whiteVisible = false">取 消</el-button>
                    <el-button type="primary" @click="whiteSubmit">确 定</el-button>
                </span>
            </template>
        </el-dialog>


        <!-- 加白发送验证码弹出框 -->
        <el-dialog title="加白发送验证码" v-model="whiteCodeVisible" width="30%">
            <el-form ref="form" :model="form">
                <el-form-item label="姓名">
                    <el-input v-model="form.realname" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="电话">
                    <el-input v-model="form.phone" type="number" placeholder="请输入电话"></el-input>
                </el-form-item>
                <el-form-item label="appid">
                    <el-input v-model="form.appid" placeholder="请输入appid"></el-input>
                </el-form-item>
                <!-- <el-form-item prop="identity" class="identity-wrap">
                    <div>验证码</div>
                    <div class="identity">
                        <el-input v-model="identity" prefix-icon="el-icon-tickets" placeholder="验证码"
                            style="margin-right: 10px"></el-input>
                        <div>
                            <el-button type="primary" @click="sendNote" v-if="!noteTiem">发送验证码
                            </el-button>
                            <el-button type="primary" v-else="noteTiem" disabled>{{ noteTiem }}秒后重发
                            </el-button>
                        </div>
                    </div>
                </el-form-item> -->
            </el-form>

            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="whiteCodeVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit">确定提交</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="whiteupdate" width="30%">
            <el-form ref="form" :model="form" label-width="70px">
                <el-form-item label="appid">
                    <el-input v-model="form.appid"></el-input>
                </el-form-item>
                <el-form-item label="加白号码">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <span>选择状态</span>
                <el-select v-model="status" placeholder="选择状态">
                    <el-option v-for="item in options" :key="item.status" :label="item.label" :value="item.status">
                    </el-option>
                </el-select>

            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="whiteupdate = false">取 消</el-button>
                    <el-button type="primary" @click="update">确 定</el-button>
                </span>
            </template>
        </el-dialog>



        <!-- 批量加白弹出框 -->
        <el-dialog title="批量加白" v-model="numShow" width="30%">
            <div class="content">
                <el-button type="success" icon="el-icon-download" @click="upload()">点击下载模板
                </el-button>
                <el-upload class="upload-demo" action="" :on-preview="handlePreview" :on-remove="handleRemove"
                    :before-remove="beforeRemove" :auto-upload="false" multiple :limit="1" :on-exceed="handleExceed"
                    :file-list="fileList" :on-change="changeUpload" drag="true" width="100%">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                </el-upload>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="numsSubmit">确认提交</el-button>
                    <el-button @click="numShow = false">退 出</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            noteTiem: 0,
            numsArray: [],
            name: null,
            form: {},
            numShow: false,
            whiteVisible: false,
            whiteCodeVisible: false,
            whiteupdate: false,
            phone: null,
            appid: null,
            userId: null,
            createdTime: null,
            changeTime: null,
            status: null,
            appKey: null,
            activePhone: "", //搜索框主叫
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量
            sta: null,
            end: null,
            identity: null,
            options: [
                {
                    status: 0,
                    label: "待验证",
                },
                {
                    status: 1,
                    label: "正常",
                },
                {
                    status: 2,
                    label: "退订",
                },
            ],
            options2: [
                {
                    appKey: null,
                    label: "甘肃",
                },
                {
                    appKey: 1,
                    label: "青海",
                },
            ],
        };
    },

    created() {
        this.userId = this.$route.query.userId;
        (this.realname = localStorage.getItem("realname")), this.getList();
    },
    //切换界面就执行方法
    activated() {
        this.getList();
    },
    inject: ["reload"],
    methods: {
        addSubmit() {
            if (!this.form.realname) {
                this.$message.warning("请填写姓名");
                return false
            } else if (!this.form.phone) {
                this.$message.warning("请填写电话");
                return false
            } else if (!this.form.appid) {
                this.$message.warning("请填写appid");
                return false
            }
            // else if (!this.identity) {
            //     this.$message.warning("请填写验证码");
            //     return false
            // }
            this.sendNote()
            this.whiteCodeVisible = false
            let params = {
                realname: this.form.realname,
                phone: this.form.phone,
                appid: this.form.appid,
                // code: this.identity,
                status: 0  // 无需验证码时传此参数  反之 传0
            };

            axios({
                method: 'post',
                url: 'http://2hxwhite2.cddmt.cn/api/whitePhoneYdSix/add-admin',
                headers: { //头部参数
                    'Content-Type': 'application/json',
                    "X-Access-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODczMzU5MjgsInVzZXJuYW1lIjoieWluaG9uZyJ9.1nbnH-36o0TDKPH3Nnt4Wk7_-CLRJrc6k8Wc8tAds04"
                },
                data: params
            })
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data);
                    } else {
                        this.$message.error(res.data.message + res.data.data);
                    }
                });
        },
        showWcode() {
            this.form = {}
            this.whiteCodeVisible = true
        },
        sendNote() {
            if (this.form.phone) {
                let params = {
                    phone: this.form.phone,
                };
                axios({
                    method: 'post',
                    url: 'http://2hxwhite2.cddmt.cn/api/whitePhoneYdSix/sms/get_code_admin',
                    headers: { //头部参数
                        'Content-Type': 'application/json',
                        "X-Access-Token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2ODczMzU5MjgsInVzZXJuYW1lIjoieWluaG9uZyJ9.1nbnH-36o0TDKPH3Nnt4Wk7_-CLRJrc6k8Wc8tAds04"
                    },
                    data: params
                })
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            this.noteText = "重新发送";
                            this.noteTiem = 60;
                            let tiemId = setInterval(() => {
                                this.noteTiem--;
                                if (this.noteTiem <= 0) {
                                    clearInterval(tiemId);
                                    this.noteTiem = 0;
                                }
                            }, 1000);
                            // this.$message.success(res.data);
                        } else {
                            // this.$message.error(res.data);
                        }
                    });
            } else {
                // this.$message.warning("请先填写电话号码");
            }
        },
        // 批量加白
        whitenings() {
            this.numShow = true;
            this.formDate = null;
        },
        many_del() {
            let params = {
                listPhone: this.numsArray,
            };
            axios
                .post("api/whitePhoneYdSix/many-del", params)
                .then((res) => {
                    this.$message.success(res.data.message);

                    this.getList();
                })
                .catch((err) => {
                });
        },
        // 添加选中项
        selectionLineChangeHandle(val) {
            this.numsArray = [];
            val.forEach((item) => {
                this.numsArray.push(item.phone);
            });
        },
        //加白
        whiteSubmit() {
            let params = {
                appid: this.form.appid,
                phone: this.form.phone,
                realname: this.form.realname,
                appKey: this.appKey,
                status: 1

            };
            console.log("-------",params)
            axios
                // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
                .post("api/whitePhoneYdSix/add", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);
                        this.whiteVisible = false;
                        this.getList();
                    } else {
                        this.$message.error(res.data.message + res.data.data);
                    }
                })
                .catch((err) => {
                });
        },
        //下载模板
        upload() {
            window.open("http://paas.cddmt.cn:9005/white-ydSix.xls");
        },

        handleRemove(file, fileList) {
            // console.log(file, fileList);
        },
        handlePreview(file) {
            // console.log(file);
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        beforeRemove(file, fileList) {
            return this.$confirm(`确定释放 ${file.name}？`);
        },
        changeUpload(file, fileList) {
            this.file = file.raw;
        },
        // 批量加白
        whitening() {
            this.form = [];
            this.whiteVisible = true;
        },
        numsSubmit() {
            this.numShow = false;
            let formDate = new FormData();
            this.$message.success("数据加载中，稍等！请勿离开页面");
            formDate.append("file", this.file);
            axios
                // .post("http://106.14.0.130:9998/vo/call/send/import_white_d", formDate)
                .post("api/whitePhoneYdSix/send/import_white_ydSix", formDate)
                .then((res) => {
                    // console.log(res.data);
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);
                        this.file = {};
                    } else {
                        this.$message.error(res.data.message);
                        this.file = {};
                    }
                })
                .catch((err) => {
                    this.$message.error(err.data.message);
                });
        },




        //导出
        excel() {
            if (this.createdTime) {
                this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            let params = {
                sta: this.sta1,
                end: this.end1,
            };
            // console.log(params)

            // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
            axios
                // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
                .post("api/whitePhoneYdSix/export-whitePhoneYdSix", params, { responseType: "blob" })
                //     .post("api/whitePhoneYdSix/export-whitePhoneYdSix", params,{responseType: "multipart/form-data"  })
                .then((res) => {
                    this.loading = false

                    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

                    const url = window.URL.createObjectURL(res.data);

                    const a = document.createElement("a");

                    a.style.display = "none";

                    let str = res.headers['content-disposition']

                    a.download = decodeURI(str).substr(decodeURI(str).indexOf('=') + 1);

                    a.href = url;

                    a.click();

                });
            this.reload();
        },

        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.size = val;
            this.getList();
        },
        // 搜索
        handleSearch() {
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            this.getList();
        },
        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        //展示and查找
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                phone: this.phone,
                name: this.name,
                phoneCity: this.phoneCity,
                appid: this.appid,
                userId: this.userId,
                status: this.status,
                sta: this.sta,
                end: this.end,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/whitePhoneYdSix/list", params)
                .post("api/whitePhoneYdSix/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                });
        },
        //修改弹出框
        update_tan(row) {
            this.form = {}
            this.form.id = row.row.id
            this.form.phone = row.row.phone
            this.form.appid = row.row.appid
            this.status = row.row.status
            this.whiteupdate = true;
        },
        //修改按钮
        update(row) {

            let params = {
                id: this.form.id,
                phone: this.form.phone,
                appid: this.form.appid,
                realname: this.form.realname,
                status: this.status,

            }
            axios
                // .post("http://106.14.0.130:9998/vo/whitePhoneYdSix/update", params)
                .post("api/whitePhoneYdSix/update", params)
                .then((res) => {
                    this.$message.success(res.data.message);
                })
                .catch((err) => {

                });
            this.reload();

            // this.form = []
            // this.status = null;
            // this.whiteupdate = false
            // this.getList();
        },
        //刷新按钮
        reset() {
            this.phone = null;
            this.name = null;
            this.appid = null;
            this.userId = null;
            this.status = null;
            this.createdTime = null;
            this.form = {}
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.identity-wrap {
    display: flex;
    flex-direction: column;
    text-align: left;

}

.identity {
    display: flex;
    align-items: center;
}

.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .search {
        .leftItem {
            width: 220px;

            .lab_tit {
                // width: 70px !important;
            }
        }

        .left {
            width: 750px;
        }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;
        }



        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
}

.upload-demo {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

// .left {
//     display: flex;
//     margin: 20px 0;
// }

// .item {
//     display: flex;
//     align-items: center;
// }

// .left span {
//     text-align: right;
//     margin-right: 5px;
// }

// .right {
//     text-align: right;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    // margin-top: 20px;
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
