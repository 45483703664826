<template>
  <div class="appPage">
    <!--    <div class="crumbs">-->
    <!--      <el-breadcrumb separator="/">-->
    <!--        <el-breadcrumb-item>-->
    <!--          基础表格-->
    <!--        </el-breadcrumb-item>-->
    <!--      </el-breadcrumb>-->
    <!--    </div>-->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="企业充值记录管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input
              v-model="form.appid"
              placeholder="请输入appid"
              size="mini"
            ></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">企业名称</span>
            <el-input
              v-model="form.name"
              placeholder="请输入企业名称"
              size="mini"
            ></el-input>
          </div>
          <div class="leftItem" style="width: 262px">
            <span class="lab_tit">时间</span>
            <!-- <el-date-picker v-model="createdTime" type="datetimerange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" style="width: 70%">
                    </el-date-picker> -->
            <el-date-picker
              v-model="createdTime"
              type="datetimerange"
              range-separator="-"
              size="mini"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeTime"
              :disabled-date="publishDateAfter"
            >
            </el-date-picker>
          </div>
          <!-- <span>类型</span> -->
          <!--    下拉框    -->
          <!-- <el-select v-model="value" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select> -->
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找</el-button
          >
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            >重置</el-button
          >
          <el-button type="primary" @click="excel()" round size="mini"
            >导出</el-button
          >
        </div>
      </div>

      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
              prop="appid"
              label="appid"
              width="310"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="企业名称"
              width="200"
            ></el-table-column>
            <el-table-column
              prop="money"
              width="120"
              label="充值后余额"
            ></el-table-column>
            <el-table-column
              prop="oldMoney"
              width="120"
              label="充值前余额"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              width="180"
              label="时间"
            ></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              gination
              background
              layout="total, prev, pager, next,sizes"
              :current-page="pageIndex"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200]"
              :total="pageTotal"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "enterpriseRecharge",
  data() {
    return {
      createdTime: null,
      form: {
        id: null,
        name: null,
        old_money: "",
        money: "",
        createTime: "",
        appid: null,
      },
      tableData: [],

      pageTotal: 0, //总共有多少数据
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      page: 1,
      size: 10,
      realname: null,
    };
  },
  mounted() {
    if (document.body.clientWidth <= 900) {
      // 手机端适配,自动跳转到对应的手机页面
      this.$router.push("/enterpriseRechargeApp");
    }
    window.onresize = () => {
      return (() => {
        // 监听窗口大小 document.body.clientWidth
        if (document.body.clientWidth <= 900) {
          // 手机端适配,自动跳转到对应的手机页面
          this.$router.push("/enterpriseRechargeApp");
        }
      })();
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    let rootDate = new Date().toLocaleDateString();
    this.createdTime = [
      new Date(rootDate + " 00:00:00"),
      new Date(rootDate + " 23:59:59"),
    ];
    this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
    this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  methods: {
    changeTime(val) {
      if (new Date(val[1]) - new Date(val[0]) > 1 * 24 * 60 * 60 * 1000 * 30) {
        this.$message.error("选择时间范围不能大于30天");
        this.createdTime = "";
      }
    },
    publishDateAfter(time) {
      const day = 30 * 24 * 3600 * 1000; // 90天
      if (this.pickerOneDate) {
        let maxTime = this.pickerOneDate + day;
        let minTime = this.pickerOneDate - day;
        return (
          time.getTime() > maxTime ||
          time.getTime() < minTime ||
          time.getTime() > Date.now()
        );
      } else {
        return (
          time.getTime() > Date.now() || time.getTime() < Date.now() - day * 3
        );
      }
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //导出
    excel() {
      if (this.createdTime == null) {
        // this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        // this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
        this.$message({
          type: "error",
          message: "请选择时间后导出",
        });
        return;
      }
      let params = {
        // sta: this.sta1,
        // end: this.end1,
        exportList: this.tableData,
      };
      // console.log(params)

      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/log/export-uuid-log", params, { responseType: "blob" })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers["content-disposition"];

          a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);

          a.href = url;

          a.click();
        });
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },

    //展示和搜索
    getList() {
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        name: this.form.name,
        appid: this.form.appid,
        sta: this.sta,
        end: this.end,
      };
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      } else {
        if (this.realname != "admin") {
          this.$message.warning("请选择时间!");
          return false;
        } else {
          delete params.sta;
          delete params.end;
        }
      }
      // axios.post("http://106.14.0.130:9998/vo/log/uuidlist",params)
      axios
        .post("api/log/uuidlist", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    //重置
    reset() {
      this.form.id = null;
      this.form.appid = null;
      this.form.name = null;
      if (this.realname == "admin") {
        this.createdTime = null;
        this.sta = null;
        this.end = null;
      }
      this.getList();
    },
    //搜索,再次调用getList方法就是展示
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .el-select {
      width: 100%;
    }

    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;

    .left {
      width: 68%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .leftItem {
        width: 176px;
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .el-input,
        .el-select,
        .el-date-editor {
          // width: 120px;
          flex: 1;
        }

        .el-input__inner {
          border-radius: 25px;
          border: 1px solid #666;
        }

        .lab_tit {
          font-size: 12px;
          // font-weight: bold;
          white-space: nowrap;
          width: 50px !important;
          text-align: right;
          margin-right: 3px;
          margin-left: 8px;
        }
      }
    }

    // .right {
    //     text-align: right;
    //     width: 30%;

    //     .el-button {
    //         margin-bottom: 4px;
    //     }

    // }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      // .el-table__row {
      //     td {
      //         padding: 4px 0;
      //     }
      // }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.item {
  display: flex;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

// .left {
//     display: flex;
//     align-items: center;
// }

// .left span {
//     width: 180px;
//     text-align: center
// }

.searchBtn {
  margin-left: 15px;
}

.right {
  /*display: flex;*/
  // justify-content: flex-end;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}
</style>
