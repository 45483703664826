<template>
  <div class="appPage">
    <!-- <h3 class="tit">黑名单管理</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="黑名单管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <!-- <div class="leftItem">
          </div>
          <div class="leftItem">
          </div> -->

          <div class="leftItem">
            <span class="lab_tit">黑名单id</span>
            <el-input v-model="blackAppid" placeholder="请输入黑名单id" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input v-model="appid" placeholder="请输入appid" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">选择状态</span>
            <el-select v-model="type" placeholder="选择状态" size="mini">
              <el-option v-for="item in options" :key="item.type" :label="item.label" :value="item.type">
              </el-option>
            </el-select>
          </div>

        </div>
        <div class="right">
          <div style="margin-bottom: 8px;">
            <el-switch style="display: block" v-model="blackOpen" active-color="#13ce66" inactive-color="#ff4949"
              @change="setBlackOpen" active-text="开启黑名单" inactive-text="关闭黑名单">
            </el-switch>
          </div>

          <el-button type="primary" icon="el-icon-refresh" @click="reset()" round size="mini">重置</el-button>
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button type="primary" @click="whitening()" round size="mini">加白</el-button>

        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">

            <!--<el-table-column-->
            <!--prop="name"-->
            <!--label="公司名称"-->
            <!---->
            <!--&gt;</el-table-column>-->

            <el-table-column prop="appid" label="appid"></el-table-column>
            <el-table-column prop="count" label="频率次数限制" width="200"></el-table-column>
            <el-table-column prop="blackAppid" label="黑名单id" width="200"></el-table-column>
            <el-table-column prop="type" label="黑名单状态" width="200">
              <template #default="scope">
                <span v-if="scope.row.type == 0">放行</span>
                <span v-if="scope.row.type == 1" style="color: red">拦截</span>
              </template>
            </el-table-column>

            <el-table-column prop="createdTime" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="200" align="center" style="dispaly: flex">
              <template #default="scope">

                <el-button type="primary" @click="update_open(scope)">变更</el-button>
                <el-button type="primary" @click="del(scope)">删除</el-button>

              </template>
            </el-table-column>

          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
              :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
              @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <el-dialog title="加白" v-model="whiteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="whiteVisible = false">取 消</el-button>
          <el-button type="primary" @click="whiteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>


    <!-- 修改弹出框 -->
    <el-dialog title="加白" v-model="update_flag" width="30%">
      <el-form ref="form" :model="form" label-width="100px">

        <el-form-item label="拦截状态">
          <el-select v-model="form.type" filterable placeholder="请选择状态">
            <el-option v-for="item in options" :key="item.type" :label="item.label" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="频率次数">
          <el-input v-model="form.count"></el-input>
        </el-form-item>

        <el-form-item label="黑名单密钥id">
          <el-input v-model="form.blackAppid"></el-input>
        </el-form-item>
        <el-form-item label="黑名单密钥">
          <el-input v-model="form.blackKey"></el-input>
        </el-form-item>

      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="update_flag = false">取 消</el-button>
          <el-button type="primary" @click="unsubscribe">确 定</el-button>
        </span>
      </template>
    </el-dialog>




  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {
      blackOpen: false,
      blacklist: [],
      blackAppid: null,
      update_flag: false,
      form: {},
      numShow: false,
      whiteVisible: false,
      appid: null,
      seatPhoneId: null,
      userId: null,
      createdTime: null,
      type: null,
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      sta: null,
      end: null,
      options: [
        {
          type: 0,
          label: "放行",
        },
        {
          type: 1,
          label: "拦截",
        },
      ],
    };
  },

  created() {
    this.getList();
    this.getBlacklist()
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  inject: ["reload"],
  methods: {
    // 设置黑名单开关
    setBlackOpen(bool) {
      // this.blackOpen = bool
      let text
      if (bool) {
        text = '开启'
      } else {
        text = '关闭'
      }

      this.$confirm(`此操作将${text}黑名单, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log("this.blackOpen", this.blackOpen);
        let params = {}
        this.blackOpen ? params.open = 1 : params.open = 0
        axios
          // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
          .post("api/black-list/open", params)
          .then((res) => {
            // console.log("接口成功", res.data.data);
            this.$message.success(res.data.data)
          })
          .catch((err) => {
            this.$message.error(res.data.data)
            // console.log("接口出错", err);
          });
      }).catch(() => {
        this.blackOpen = !bool
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });


    },
    // 查询所有黑名单状态 /black-list/param-black/list   
    getBlacklist() {
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/black-list/param-black/list", {})
        .then((res) => {
          // console.log("res", res.data.data);
          this.blacklist = res.data.data
          this.blacklist[0].open ? this.blackOpen = true : this.blackOpen = false
        })
        .catch((err) => {
          // console.log("接口出错", err);
        });
    },
    //下载模板
    // upload() {
    //   window.open("http://118.190.2.70:9005/white-d.xls");
    // },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 批量加白
    whitenings() {
      this.numShow = true;
      this.formDate = null;
    },
    // 添加
    whitening() {
      this.whiteVisible = true;
      this.form = {};
    },
    whiteSubmit() {
      let params = {
        appid: this.form.appid,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/black-list/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.whiteVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => { });
    },


    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 搜索
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        appid: this.appid,
        type: this.type,
        blackAppid: this.blackAppid
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/black-list/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          // this.form.create_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.data.data.create_time);
          this.tableData.forEach((v) => {
            v.createdTime = this.getYMDHMS(
              "YYYY-mm-dd HH:MM:SS",
              v.createdTime
            );
            v.createdTime = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.createdTime);

          });
          this.pageTotal = res.data.total;
        })
        .catch((err) => { });
    },
    update_open(row) {
      this.form = { ...row.row }
      this.update_flag = true;
    },
    //退订按钮
    unsubscribe() {

      let params = {
        id: this.form.id,
        type: this.form.type,
        count: this.form.count,
        blackAppid: this.form.blackAppid,
        blackKey: this.form.blackKey,
      }
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
        .post("api/black-list/update", params)
        .then((res) => {
          this.$message.success(res.data.message);
          this.update_flag = false;
          this.getList();
        })


    },
    //退订按钮
    // unsubscribe(row) {
    //
    //     let Tstatis = row.row.type;
    //     if (0 === Tstatis) {
    //         Tstatis = 1
    //     } else if (1 === Tstatis) {
    //         Tstatis = 0
    //     } else {
    //         Tstatis = 0
    //     };
    //     let params = {
    //         id: row.row.id,
    //         type : Tstatis
    //     }
    //     axios
    //         // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
    //         .post("api/black-list/update", params)
    //         .then((res) => {
    //             this.$message.success(res.data.message);
    //             this.getList();
    //         })
    //         .catch((err) => {
    //
    //         });
    //
    // },
    //退订按钮
    del(row) {
      let params = {
        id: row.row.id,

      }
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
        .post("api/black-list/del", params)
        .then((res) => {
          this.$message.success(res.data.message);
        })
        .catch((err) => {

        });
      this.reload();
    },
    //刷新按钮
    reset() {
      this.appid = null;
      this.type = null;
      this.blackAppid = null;

      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 184px;
    }

    .left {
      width: 750px;
    }
  }


  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }



    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.upload-demo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .left {
//   display: flex;
//   margin: 20px 0;
// }

// .item {
//   display: flex;
//   align-items: center;
// }

// .left span {
//   text-align: right;
//   margin-right: 5px;
// }

// .right {
//   text-align: right;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
