<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="危机企业管理"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">名称</span>
                        <el-input size="mini" v-model="name" placeholder="请输入子企业名称"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input size="mini" v-model="appid" placeholder="请输入appid"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit" v-if="realname == 'admin'">子企业编号</span>
                        <el-input size="mini" v-model="id" placeholder="请输入子企业编号" v-on:keyup.enter="handleSearch"
                            v-if="realname == 'admin'"></el-input>
                    </div>


                    <div class="leftItem">
                        <span class="lab_tit" v-if="realname == 'admin'">选择通道</span>
                        <el-select size="mini" v-model="paramTpye" placeholder="请选择通道">
                            <el-option v-for="item in options1" :key="item.type" :label="item.label1" :value="item.type">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit" v-if="realname == 'admin'">企业类型</span>
                        <el-select size="mini" v-model="qyType" placeholder="请选择通道" @change="handleSearch">
                            <el-option v-for="item in options5" :key="item.type" :label="item.label" :value="item.status">
                            </el-option>
                        </el-select>
                    </div>

                    <!-- <div class="left-wrap">
                    <span v-if="realname == 'admin'">选择锁定状态</span>
                    <el-select v-model="status" placeholder="请选择锁定状态">
                        <el-option
                                v-for="item in options4"
                                :key="item.type"
                                :label="item.label1"
                                :value="item.type"
                        >
                        </el-option>
                    </el-select>
                </div>-->
                </div>

                <div class="right">
                    <el-button round type="primary" size="mini" icon="el-icon-search" @click="handleSearch">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>

                    <!--<el-button-->
                    <!--type="primary"-->
                    <!--class="add"-->
                    <!--@click="open()"-->
                    <!--v-if="realname == 'admin'"-->
                    <!--&gt;添加-->
                    <!--</el-button>-->
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 1rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="30"></el-table-column>
                        <el-table-column prop="user_id" width="100" label="代理编号"
                            v-if="realname == 'admin'"></el-table-column>
                        <el-table-column prop="id" width="100" label="子企业编号" v-if="realname == 'admin'"></el-table-column>
                        <el-table-column prop="appid" width="310" label="appid"
                            v-if="realname == 'admin'"></el-table-column>
                        <el-table-column prop="name" label="子企业名称" width="300"></el-table-column>

                        <!--        <el-table-column  label="账户余额">-->
                        <!--          <template #default="scope">￥{{ scope.row.money }}</template>-->
                        <!--        </el-table-column>-->

                        <el-table-column prop="money" width="80" label="余额"></el-table-column>
                        <!-- <el-table-column
                        prop="seatMoney"
                        label="套餐余额"
                        
                ></el-table-column>-->

                        <!--<el-table-column
                        prop="paramId"
                        label="配置密钥"
                        
                        v-if="realname == 'admin'"
                ></el-table-column>-->
                        <!-- <el-table-column
                        prop="rate"
                        label="费率"
                        
                ></el-table-column>-->

                        <el-table-column prop="create_time" width="210" label="企业创建时间"></el-table-column>
                        <!--<el-table-column-->
                        <!--prop="q_created_time"-->
                        <!--label="最近质检时间"-->
                        <!---->
                        <!--&gt;</el-table-column>-->

                        <el-table-column prop="type" label="通道类型">
                            <template #default="scope">
                                <span v-if="scope.row.type == 0">商机云</span>
                                <span v-if="scope.row.type == 1">南京线</span>
                                <span v-if="scope.row.type == 2">电信</span>
                                <span v-if="scope.row.type == 3">联通2</span>
                                <span v-if="scope.row.type == 4">联通1</span>
                                <span v-if="scope.row.type == 5">移动ax</span>
                                <span v-if="scope.row.type == 6">联通3</span>
                                <span v-if="scope.row.type == 7">联通4</span>
                                <span v-if="scope.row.type == 8">H</span>
                                <span v-if="scope.row.type == 9">联通5</span>
                                <span v-if="scope.row.type == 10">移动3</span>
                                <span v-if="scope.row.type == 11">联通6</span>
                                <span v-if="scope.row.type == 12">联通7</span>
                                <span v-if="scope.row.type == 13">移动5</span>
                                <span v-if="scope.row.type == 14">S</span>
                                <span v-if="scope.row.type == 15">D</span>
                                <span v-if="scope.row.type == 23">新2号线</span>
                                <span v-if="scope.row.type == 26">无锡</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="120" fixed="right" align="center" style="dispaly: flex">
                            <template #default="scope">
                                <!--<el-button type="primary" @click="recharge(scope)">充值</el-button>
                        <el-button
                                type="primary"
                                @click="handleClick(scope)"
                                v-if="realname == 'admin'"
                        >编辑
                        </el-button
                        >-->
                                <!--  <el-button
                            type="primary"
                            @click="lock(scope)"
                            v-if="scope.row.status == '0' && realname == 'admin'"
                            >锁定</el-button
                          >-->
                                <!-- <el-button
                           type="danger"
                           @click="lock(scope)"
                           v-if="scope.row.status == '1' && realname == 'admin'"
                           >解锁</el-button
                         >-->
                                <el-button type="primary" size="mini" @click="opensay(scope)" v-if="qyType != 7">移除危机
                                </el-button>
                                <!-- <el-button type="primary" @click="middleNum(scope)"
                           >中间号</el-button
                         >-->

                                <!-- <el-button type="primary" @click="details(scope)">详情</el-button>
                        <el-button
                                type="primary"
                                :plain="false"
                                @click="appidShow(scope)"
                                v-if="realname != 'admin'"
                        >appid
                        </el-button
                        >-->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="mainTableFY">
                    <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                        :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                </div>
            </div>
        </div>
        <!-- 添加弹出框 -->
        <el-dialog title="添加" v-model="addVisible" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="addRules" label-width="90px">
                <el-form-item label="代理编号" prop="userId">
                    <el-input v-model="form.userId"></el-input>
                </el-form-item>
                <el-form-item label="子企业名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="余额" prop="money">
                    <el-input v-model="form.money"></el-input>
                </el-form-item>
                <el-form-item label="收费模式">
                    <el-select v-model="form.chargeMode" placeholder="请选择收费模式">
                        <el-option v-for="item in options" :key="item.chargeMode" :label="item.label"
                            :value="item.chargeMode">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="通道类型">
                    <el-select v-model="form.type" placeholder="请选择通道类型">
                        <el-option v-for="item in options1" :key="item.type" :label="item.label1" :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置密钥">
                    <el-select v-model="form.paramId" placeholder="请选择配置密钥">
                        <el-option v-for="item in options2" :key="item.type" :label="item.appid" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="限制坐席">
                    <el-select v-model="form.seatPass" placeholder="请选择是否限制">
                        <el-option v-for="item in options3" :key="item.value3" :label="item.label3" :value="item.value3">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="行业" prop="industry">
                    <el-input v-model="form.industry"></el-input>
                </el-form-item>
                <el-form-item label="月租">
                    <el-input-number v-model="form.seatRate" :precision="1" :step="0.1" :max="1000"></el-input-number>
                </el-form-item>
                <el-form-item label="费率">
                    <el-input-number v-model="form.rate" :precision="3" :step="0.001" :max="11"></el-input-number>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addSubmit()" :disabled="rechargeBtn">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="100px">
                <el-form-item label="代理编号" prop="userId">
                    <el-input v-model="form.userId"></el-input>
                </el-form-item>

                <el-form-item label="子企业名称" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="收费模式">
                    <el-select v-model="form.chargeMode" placeholder="请选择收费模式">
                        <el-option v-for="item in options" :key="item.chargeMode" :label="item.label"
                            :value="item.chargeMode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="通道类型">
                    <el-select v-model="form.type" placeholder="请选择通道类型">
                        <el-option v-for="item in options1" :key="item.type" @change="changeType" :label="item.label1"
                            :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配置密钥">
                    <el-select v-model="form.paramId" filterable placeholder="请选择配置密钥">
                        <el-option v-for="item in options2" :key="item.type" :label="item.appid" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="限制坐席">
                    <el-select v-model="form.seatPass" placeholder="请选择是否限制">
                        <el-option v-for="item in options3" :key="item.value3" :label="item.label3" :value="item.value3">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="行业" prop="industry">
                    <el-input v-model="form.industry"></el-input>
                </el-form-item>
                <el-form-item label="月租">
                    <el-input-number v-model="form.seatRate" :precision="1" :step="0.1" :max="99999"></el-input-number>
                </el-form-item>
                <el-form-item label="费率">
                    <el-input-number v-model="form.rate" :precision="3" :step="0.001" :max="1"></el-input-number>
                </el-form-item>
                <el-form-item label="拨打时间限制">
                    <el-switch v-model="form.userTime" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
                        :inactive-value="0" @change="changeSwitch"></el-switch>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="editSubmit()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 充值弹框 -->
        <el-dialog title="充值" v-model="addMoney" width="30%">
            <el-form ref="dataAddForm" :rules="addMoneyRules" label-width="80px">
                <el-form-item label="余额">
                    <el-input-number v-model="formAdd" :precision="3" :step="1.0">
                    </el-input-number>
                </el-form-item>
                <!-- 备注 -->
                <el-form-item label="备注">
                    <el-input v-model="remarks" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="addMoney = false">取 消</el-button>
                    <el-button type="primary" @click="rechargeSubmit" :disabled="rechargeBtn">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <!-- 话术 -->
        <el-dialog title="话术" v-model="sayShow">
            <el-form ref="dataAddForm" :model="form" :rules="sayRules" label-width="80px">
                <el-form-item label="话术">
                    <el-input v-model="form.say" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="sayShow = false">取 消</el-button>
                    <el-button type="primary" @click="submitTalk()">确 定</el-button>
                </span>
            </template>
        </el-dialog>

        <!-- 中间号 -->
        <el-dialog :title="'中间号数量:' + middleCount" v-model="middleNumShow">
            <span style="font-size: 18px">中间号单价:</span>
            <el-input-number v-model="middleNumPrice" :precision="2" :step="1" :max="50" :min="0"></el-input-number>
            <el-input v-model="middleNums" type="textarea" :disabled="realname != 'admin'"></el-input>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="middleNumShow = false">取 消</el-button>
                    <el-button type="primary" @click="submitMiddleNum()" v-if="realname == 'admin'">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>


<script>
import axios from "axios";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
    //appid弹出消息
    setup() {
        return {
            appidShow(row) {
                //  // console.log(row.row.appid)
                ElMessage({
                    message: row.row.appid,
                    duration: 3000, //显示时间，毫秒，为0时不消失
                    showClose: true, //是否显示'×'
                    type: "success", //成功的样式
                });
            },
        };
    },

    name: "enterprises",
    data() {
        return {
            options: [

                {
                    chargeMode: "1",
                    label: "被叫应答计费",
                },
            ],
            chargeMode: "",
            qyType: "",

            options1: [
                // {
                //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
                //   type: 0,
                //   label1: "隐私小号通道",
                // },
                {
                    type: 5,
                    label1: "移动ax通道",
                },
                {
                    type: 2,
                    label1: "移动1通道",
                },
                {
                    type: 10,
                    label1: "移动3通道",
                },
                {
                    type: 1,
                    label1: "南京线通道",
                },
                {
                    type: 13,
                    label1: "移动5通道",
                },
                // {
                //   type: 3,
                //   label1: "联通2通道",
                // },
                // {
                //   type: 4,
                //   label1: "联通1通道",
                // },

                // {
                //   type: 6,
                //   label1: "联通3通道",
                // },
                // {
                //   type: 7,
                //   label1: "联通4通道",
                // },
                {
                    type: 8,
                    label1: "H",
                },
                {
                    type: 14,
                    label1: "S",
                },
                {
                    type: 15,
                    label1: "D",
                },
                {
                    type: 17,
                    label1: "SIP",
                },
                {
                    type: 18,
                    label1: "X",
                },
                {
                    type: 21,
                    label1: "2号线",
                },
                {
                    type: 23,
                    label1: "新2号线",
                },
                // {
                //   type: 24,
                //   label1: "重庆线路",
                // },
                {
                    type: 25,
                    label1: "1号线",
                },
                {
                    type: 26,
                    label1: "无锡",
                },

            ],
            options5: [

                {
                    status: 7,
                    type: 0,
                    label: "新建7天企业",
                },
                {
                    status: null,
                    type: 1,
                    label: "高危企业",
                },


            ],
            options4: [
                {
                    type: 0,
                    label1: "未锁定",
                },
                {
                    type: 1,
                    label1: "已锁定",
                },
            ],
            options2: [],
            options3: [
                {
                    value3: 0,
                    label3: "不使用坐席",
                },
                {
                    value3: 1,
                    label3: "使用坐席",
                },
            ],
            realname: "",
            type: "",
            rate: 0,
            tableData: [],
            sayShow: false, //是否显示话术编辑框
            middleNumShow: false, //是否显示中间号编辑框
            addMoney: false, //是否显示充值弹窗
            addVisible: false, //是否显示添加弹窗
            editVisible: false, //是否显示编辑弹窗
            pageTotal: 0, //总共有多少数据
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            remarks: "", //备注（只传不回显）
            formAdd: "",
            appid: null,
            userId: null,
            paramTpye: null,
            status: null,
            create_time: null,
            q_created_time: null,
            form: {
                userTime: 1,
                id: "",
                name: "",
                money: "",
                type: "",
                rate: "",
                appid: "",
                seatPass: "", //是否限制坐席0不使用坐席1使用
                seatRate: "", //月租
                chargeMode: "",
                industry: "",
                paramId: "",
                user_id: "",
                create_time: "",
                q_created_time: "",
            },
            // middleNums:"0",
            editRules: {
                //校验规则
                name: [
                    { required: true, message: "企业名称为必填项", trigger: "blur" },
                ],
                money: [{ required: true, message: "余额为必填项", trigger: "blur" }],
                type: [
                    { required: true, message: "通道类型为必填项", trigger: "blur" },
                ],
                rate: [{ required: true, message: "费率为必填项", trigger: "blur" }],
            },
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量
            id: null, //搜索的id，如果为null，则显示全部数据
            name: null,
            title: "添加", //弹窗的标题
            btnText: "绑定",
            rechargeId: "",
            rechargeMoney: 0,
            talkId: "",
            middleNums: "", //中间号数组
            middleCount: "", //中间号数量
            middleNumPrice: "", //中间号单价
            rechargeBtn: false,
            notMoney1: null,
            notMonth1: null,
        };
    },
    created() {
        // this.form.userId = this.$route.query.userId;
        this.userId = this.$route.query.userId;
        (this.realname = localStorage.getItem("realname")), this.getList();
    },
    //切换界面就执行方法
    activated() {
        this.getList();
    },
    watch: {
        // '$route': 'getParams',
        id() {
            if (this.id == "") {
                this.id = null;
            }
        },
        name() {
            if (this.name == "") {
                this.name = null;
            }
        },
        "form.type"(newValue, oldValue) {
            this.options2 = [];
            this.getData(newValue);
            this.form.paramId = "";
        },
        middleCount() {
            if (this.middleCount == null) {
                this.middleCount == 0;
            }
        },
        middleNumPrice() {
            if (this.middleNumPrice == null) {
                this.middleNumPrice == 0;
            }
        },
    },
    inject: ["reload"],
    methods: {
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        // 开关
        changeSwitch() {
            // console.log("userTime", this.userTime);
            this.editSubmit(true);
        },
        //点击通道类型时
        getData(type) {
            //  // console.log(type)
            let params = {
                type: type,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/findAllkey", params)
                .post("api/uuid/findAllkey", params)
                .then((res) => {
                    this.options2 = res.data.data;
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        changeType(val) {
            // console.log(val);
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        notMoney() {
            this.notMoney1 = 1000;
            this.getList();
            this.$message.success(
                "已查询出余额不足1000的企业成功！点击“重置”按钮可取消查询！"
            );
        },
        notMonth() {
            this.notMonth1 = 1;
            this.getList();
            this.$message.success(
                "已查询出余额不足以抵扣下月月租的企业！点击“重置”按钮可取消查询！"
            );
        },
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                type: this.paramTpye,
                status: this.qyType,
                appid: this.appid,
                name: this.name,
                userId: this.userId,
                notMoney: this.notMoney1,
                notMonth: this.notMonth1,
                id: this.id,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/uuidDanger/list", params)
                .post("api/uuidDanger/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    // this.form.create_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.data.data.create_time);
                    this.tableData.forEach((v) => {
                        v.create_time = this.getYMDHMS(
                            "YYYY-mm-dd HH:MM:SS",
                            v.create_time
                        );
                        v.create_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.create_time);
                        v.q_created_time = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", v.q_created_time);
                    });
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                    this.$message.error(err.data.message);
                });
        },
        //搜索
        handleSearch() {
            this.getList();
        },
        //添加按钮
        open() {
            this.addVisible = true;
            (this.form.userId = ""), (this.form.name = "");
            this.form.money = "";
            this.form.rate = "";
            this.form.seatRate = "";
            this.form.seatPass = "";
            this.form.type = "";
            this.form.paramId = "";
            this.form.chargeMode = "";
            this.form.industry = "";
            this.options2 = [];
        },

        //重置按钮
        reset() {
            this.id = null;
            this.appid = null;
            this.paramTpye = null;
            this.status = null;
            this.name = null;
            this.userId = null;
            this.notMoney1 = null;
            this.notMonth1 = null;
            this.getList();
        },

        //添加确定
        addSubmit() {
            let params = {
                id: this.form.id,
                name: this.form.name,
                money: this.form.money,
                type: this.form.type,
                rate: this.form.rate,
                seatPass: this.form.seatPass, //是否限制坐席0不使用坐席1使用
                seatRate: this.form.seatRate, //月租
                chargeMode: this.form.chargeMode,
                industry: this.form.industry,
                paramId: this.form.paramId,
                userId: this.form.userId,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/add", params)
                .post("api/uuid/add", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        setTimeout(() => {
                            this.rechargeBtn = false;
                        }, 2000);
                        this.$message.success(res.data.message);
                        this.addVisible = false;
                        this.getList();
                    } else {
                        this.$message.error(res.data.message);
                        this.addVisible = false;
                        this.getList;
                    }
                })
                .catch((err) => {
                    this.$message.error(err.data.message);
                });
        },

        //点击编辑弹窗使用下面的方法(数据回显)
        handleClick(row) {
            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            setTimeout(() => {
                // console.log(row.row);
                this.form = row.row;
                let params = {
                    id: this.form.id,
                };
                axios
                    // .post("http://106.14.0.130:9998/vo/uuid/findOne", params)
                    .post("api/uuid/findOne", params)
                    .then((res) => {
                        // console.log(res.data.data);
                        this.form.chargeMode = res.data.data.chargeMode; //代理编号
                        this.form.money = res.data.data.money; //余额
                        this.form.name = res.data.data.name; //企业名称
                        this.form.rate = res.data.data.rate; //费率
                        this.form.seatRate = res.data.data.seatRate; //月租
                        this.form.seatPass = res.data.data.seatPass; //是否限制坐席 0-不限制
                        this.form.type = res.data.data.type; //通道类型
                        this.form.industry = res.data.data.industry; //行业
                        this.form.paramId = res.data.data.paramId; //密钥
                        this.form.user_id = res.data.data.user_id; //所属一级的编号
                        this.form.appid = res.data.data.appid;
                        // console.log(this.form.paramId);
                        this.editVisible = true; //弹窗解除隐藏状态跳出
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                loading.close();
            }, 700);
        },

        //编辑确定
        editSubmit(kaiguan) {
            let params = {
                id: this.form.id,
                name: this.form.name,
                money: this.form.money,
                type: this.form.type,
                rate: this.form.rate,
                appid: this.form.appid,
                seatPass: this.form.seatPass, //是否限制坐席0不使用坐席1使用
                seatRate: this.form.seatRate, //月租
                chargeMode: this.form.chargeMode,
                industry: this.form.industry,
                paramId: this.form.paramId,
                userId: this.form.userId,
            };
            if (kaiguan) {
                params.userTime = this.form.userTime;
            }
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/update", params)
                .post("api/uuid/update", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        setTimeout(() => {
                            this.rechargeBtn = false;
                        }, 2000);
                        this.$message.success(res.data.message);
                        this.editVisible = false;
                        this.getList();
                    } else {
                        this.$message.error(res.data.message);
                        this.editVisible = false;
                        this.getList;
                    }
                })
                .catch((err) => {
                    this.$message.error(err.data.message);
                });
        },

        //充值
        recharge(row) {
            this.addMoney = true;
            this.rechargeId = row.row.id;
            this.rechargeMoney = row.row.money;
            this.appid = row.row.appid;
            this.name = row.row.name;
            this.formAdd = row.row.money;
            this.userId = row.row.userId;
            this.seatRate = row.row.seatRate;
        },
        //充值确定按钮
        rechargeSubmit() {
            let params = {
                id: this.rechargeId,
                money: this.formAdd,
                old_money: this.rechargeMoney,
                remarks: this.remarks,
                appid: this.appid,
                seatRate: this.seatRate,
                name: this.name,
                userId: this.userId,
            };
            this.rechargeBtn = true;
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/update", params)
                .post("api/uuid/update", params)
                .then((res) => {
                    setTimeout(() => {
                        this.rechargeBtn = false;
                    }, 2000);
                    this.money = res.data.money;
                    this.appid = null;
                    this.name = null;
                    this.userId = null;
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }

                    this.getList();
                })
                .catch((err) => {
                    this.rechargeBtn = false;
                    // console.log(err);
                })
                .finally(() => {
                    this.addMoney = false;
                });
        },
        //绑定
        lock(row) {
            let status = "";
            if (row.row.status == 1) {
                status = 0;
            } else {
                status = 1;
            }
            let params = {
                id: row.row.id,
                appid: row.row.appid,
                status: status,
            };

            this.$confirm("请确定是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                center: true,
            })
                .then(() => {
                    axios
                        // .post("http://106.14.0.130:9998/vo/uuid/del", params)
                        .post("api/uuid/del", params)
                        .then((res) => {
                            //  // console.log(res)
                            if (res.data.statusCode == "00000") {
                                this.$message.success(res.data.message);
                                this.getList();
                            }
                        })
                        .catch((err) => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消",
                    });
                });
        },
        isEdit() {
            // console.log(this.form);
            // return
            this.saveAdd();
        },
        //话术按钮
        opensay(row) {
            let params = {
                uuid: row.row.id,

            }
            axios
                // .post("http://106.14.0.130:9998/vo/uuidDanger/del", params)
                .post("api/uuidDanger/del", params)
                .then((res) => {
                    this.$message.success(res.data.message);
                })
                .catch((err) => {

                });
            this.reload();
        },
        //中间号回显
        middleNum(row) {
            this.middleNumShow = true;
            let params = {
                id: row.row.id,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/findOne", params)
                .post("api/uuidDanger/findOne", params)
                .then((res) => {
                    this.form.id = res.data.data.id;
                    //数量
                    if (res.data.data.num == null || res.data.data.num == "") {
                        this.middleCount = 0;
                    } else {
                        this.middleCount = res.data.data.num;
                    }
                    //中间号
                    this.middleNums = res.data.data.middleNum;
                    //单价
                    if (res.data.data.price == null || res.data.data.price == "") {
                        this.middleNumPrice = 0;
                    } else {
                        this.middleNumPrice = res.data.data.price;
                    }
                    this.getList();
                })
                .catch((err) => {
                    this.getList();
                });
        },
        //中间号确定按钮
        submitMiddleNum() {
            let params = {
                id: this.form.id,
                middleNum: this.middleNums,
                price: this.middleNumPrice,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/updatePhone", params)
                .post("api/uuid/updatePhone", params)
                .then((res) => {
                    //  // console.log(res)
                    if (res.data.statusCode == "00000") {
                        this.middleNumShow = false;
                        this.$message.success(res.data.message);
                        this.getList();
                    } else {
                        this.middleNumShow = false;
                        this.$message.error(res.data.message);
                        this.getList();
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    this.getList();
                });
        },
        //话术提交按钮
        submitTalk() {
            let params = {
                id: this.talkId,
                //把页面的内容赋值给talk，传回后端数据库
                talk: this.form.say,
            };
            // axios.post("http://106.14.0.130:9998/vo/uuid/update", params)
            axios
                // .post("http://106.14.0.130:9998/vo/uuid/update", params)
                .post("api/uuid/update", params)
                .then((res) => {
                    //  // console.log(res)
                    if (res.data.statusCode == "00000") {
                        this.sayShow = false;
                        this.$message.success(res.data.message);
                        this.getList();
                    } else {
                        this.sayShow = false;
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        //详情按钮
        details(row) {
            this.$router.push({
                path: "/audit",
                query: {
                    companyName: row.row.name,
                },
            });
        },
    },
});
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .search {
    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;


    .left {
        width: 616px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .leftItem {
            width: 204px;
            height: 28px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .el-input,
            .el-select,
            .el-date-editor {
                // width: 120px;
                flex: 1;
            }

            .el-input__inner {
                height: 23px !important;
                border-radius: 25px;
                border: 1px solid #666;
            }

            .el-input__icon {
                line-height: 24px !important;
            }

            .lab_tit {
                font-size: 12px;
                // font-weight: bold;
                white-space: nowrap;
                width: 62px !important;
                text-align: right;
                margin-right: 3px;
                margin-left: 8px;
            }
        }
    }

    .right {
        text-align: right;
        width: 30%;

        // display: flex;
        .el-button {
            margin-bottom: 3px;
        }
    }
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .search {
        .el-select {
            width: 100%;
        }
    }

    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}


.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.tit1 {
    display: flex;
    justify-content: space-between;
}

.search {
    /*display: flex;*/
    /*justify-content: space-between;*/
    margin-top: 20px;
}

.left {
    display: flex;
    align-items: center;
    /*margin-right: 30px;*/
}

.left-wrap {
    display: flex;
    align-items: center;
    /*margin-right: 30px;*/
}

.left span {
    width: 50%;
    text-align: center;
}

.searchBtn {
    margin-left: 15px;
    align-items: center;
}

// .right {
//     display: flex;
//     margin-top: 20px;
//     justify-content: flex-end;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}

/* 话术编辑框高度设置 */
/* .el-textarea__inner{
          min-height: 300px !important;
        } */
// div ::v-deep .el-table .cell {
//     display: flex;
//     justify-content: center;
// }
</style>
