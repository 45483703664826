<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="月报表"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">企业名称</span>
                        <el-input v-model="form.name" placeholder="请输入企业名称" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">子企业id</span>
                        <el-input v-model="form.uuid" placeholder="请输入子企业id" size="mini"></el-input>
                        <!-- 日期选择，dayjs -->
                        <!-- :clearable="false"  就是那个小叉叉 -->
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">月份</span>
                        <el-date-picker v-model="form.day" type="month" placeholder="请选择月份" @change="getDate" size="mini"
                            :clearable="false" format="YYYY-MM">
                        </el-date-picker>
                    </div>
                    <div class="leftItem" v-if="realname == 'admin'">
                        <span class="lab_tit">appid</span>
                        <el-input v-model="form.appid" placeholder="请输入appid" size="mini"></el-input>
                    </div>
                    <!-- <span class="demonstration">月</span>
                <el-date-picker
                  v-model="form.day"
                  type="month"
                  placeholder="选择月">
                </el-date-picker> -->
                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch(scope)" size="mini" round>查找
                    </el-button>
                    <el-button size="mini" round type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                    <el-button type="primary" @click="excel()" v-if="realname == 'admin'" size="mini" round>导出</el-button>
                    <el-button v-if="realname == 'admin'" type="primary" icon="el-icon-search" @click="getList2(scope)"
                        size="mini" round>实时查询
                    </el-button>
                    <el-button v-if="realname == 'admin'" type="primary" icon="el-icon-search" @click="getList3(scope)"
                        size="mini" round>统计查询
                    </el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange" show-summary
                        :summary-method="getSummaries">
                        <el-table-column prop="name" label="企业名称" width="240"></el-table-column>
                        <el-table-column prop="day" label="报表日期" width="160"></el-table-column>
                        <el-table-column prop="uuid" label="企业编号" width="100"></el-table-column>
                        <el-table-column prop="type" width="120" label="通道类型" v-if="realname == 'admin'">
                            <template #default="scope">
                                <span v-if="scope.row.type == 0">隐私小号通道</span>
                                <span v-if="scope.row.type == 1">南京线</span>
                                <span v-if="scope.row.type == 2">电信</span>
                                <span v-if="scope.row.type == 3">联通2</span>
                                <span v-if="scope.row.type == 4">联通1</span>
                                <span v-if="scope.row.type == 5">移动ax</span>
                                <span v-if="scope.row.type == 6">联通3</span>
                                <span v-if="scope.row.type == 7">联通4</span>
                                <span v-if="scope.row.type == 8">H</span>
                                <span v-if="scope.row.type == 9">联通5</span>
                                <span v-if="scope.row.type == 10">移动3</span>
                                <span v-if="scope.row.type == 11">联通6</span>
                                <span v-if="scope.row.type == 12">联通7</span>
                                <span v-if="scope.row.type == 13">移动5</span>
                                <span v-if="scope.row.type == 14">S</span>
                                <span v-if="scope.row.type == 15">D</span>
                                <span v-if="scope.row.type == 16">S2</span>
                                <span v-if="scope.row.type == 18">X</span>
                                <span v-if="scope.row.type == 20">6号线</span>
                                <span v-if="scope.row.type == 23">新2号线</span>
                                <span v-if="scope.row.type == 26">无锡</span>
                            </template>
                        </el-table-column>

                        <el-table-column prop="seconds" label="计费秒数(累加)" width="160"></el-table-column>
                        <el-table-column prop="minute" label="计费分钟数" width="100"></el-table-column>
                        <el-table-column prop="money" label="金额" width="100"></el-table-column>
                        <el-table-column prop="callCount" label="呼叫总次数" width="120"></el-table-column>
                        <el-table-column prop="sumCount" label="呼叫成功总次数" width="160"></el-table-column>
                        <el-table-column prop="count" label="接听总次数" width="120"></el-table-column>
                        <el-table-column prop="usercount" label="呼叫总用户" width="120"></el-table-column>
                        <el-table-column prop="avg" label="avg平均通话时长" width="200"></el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageSize" :total="pageTotal"
                            @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs"; //日期选择
export default {
    name: "monthlyReport",
    data() {
        return {
            form: {
                day: null,
                appid: null,
                uuid: "",
                name: null,
                // minute: "",
                seconds: "",
                callCount: "",
                money: "",
                sumCount: "",
                count: "",
                usercount: "",
                avg: "",
            },
            tableData: [],
            multipleSelection: [],
            delList: [],
            editVisible: false,
            pageTotal: 0, //总共有多少数据
            form: {},
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            size: 10, //接口请求的每页的数量
            page: 1, //接口请求的页数
            realname: "",
        };
    },
    created() {
        this.realname = localStorage.getItem("realname");
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        this.getList();
    },
    watch: {
        // day(){
        //   if(this.day == "Invalid Date"){
        //       this.day = null
        // }
        // }
    },
    methods: {

        //导出
        excel() {
            if (null == this.form.day) {
                this.$message.error("请选择日期");
                return
            }
            let params = {
                uuid: this.form.uuid,
                day: this.form.day,
                type: 2
            };
            axios
                .post("api/table/export-whitephoneD", params, { responseType: "blob" })
                // .post("api/table/export-table", params,{responseType: "blob"  })
                .then((res) => {

                    this.loading = false

                    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

                    const url = window.URL.createObjectURL(res.data);

                    const a = document.createElement("a");

                    a.style.display = "none";

                    let str = res.headers['content-disposition']

                    a.download = decodeURI(str).substr(decodeURI(str).indexOf('=') + 1);

                    a.href = url;

                    a.click();
                });
            this.reload();
        },
        getDate(date) {
            this.form.day = dayjs(date).format("YYYY-MM");
},
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.size = val;
            this.getList();
        },
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                day: this.form.day,
                name: this.form.name,
                uuid: this.form.uuid,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/table/monthtableAll", params)
                .post("api/table/monthtableAll", params)
                .then((res) => {
                    //  // console.log('>>>>>>',res.data);
                    if (res.data.success == false) {
                        this.$router.push("/403");
                    } else {
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },

        getCurrentMonthLast() {
            var date = new Date();
            var currentMonth = date.getMonth();
            var nextMonth = ++currentMonth;
            var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
            var oneDay = 1000 * 60 * 60 * 24;
            let date1 = new Date(nextMonthFirstDay - oneDay);

            return dayjs(new Date(nextMonthFirstDay - oneDay)).format("YYYY-MM-DD")
            // return new Date(nextMonthFirstDay - oneDay);
        },
        //展示and查询
        getList2() {

            let endTime = this.getCurrentMonthLast();
            if (null == this.form.appid || "" == this.form.appid) {
                // alert("请输入appid:" +endTime)
                return;
            }
            let time = dayjs(new Date()).format("YYYY-MM")
            let params = {
                sta: time + "-01 00:00:00",
                // end: time + "-30 23:59:59",
                end: endTime + " 23:59:59",
                appid: this.form.appid,
            };

            // this.set();
            axios
                // .post("http://106.14.0.130:9998/vo/table/daytableAll", params)
                .post("api/calculate/count-table", params)
                .then((res) => {
                    if (res.data.success == false) {
                        this.$router.push("/403");
                    } else {

                        let datalist = [res.data.data];

                        this.tableData = datalist;
                        this.pageTotal = 1;
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },

        //展示and查询
        getList3() {
            let params = {
                sum: 1
            };

            // this.set();
            axios
                // .post("http://106.14.0.130:9998/vo/table/daytableAll", params)
                .post("api/table/monthtableAll", params)
                .then((res) => {
                    if (res.data.success == false) {
                        this.$router.push("/403");
                    } else {

                        let datalist = [res.data.data];

                        this.tableData = datalist;
                        this.pageTotal = 1;
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        handleSearch() {
            this.getList();
        },
        //刷新按钮
        reset() {
            this.form.day = null;
            this.form.name = null;
            this.form.appid = null;
            this.form.uuid = null;
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            // width: 68%;
            width: 710px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 176px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input {
                    // width: 120px;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 45px !important;
                    text-align: right;
                    margin-right: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }


        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}


.search {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

/* .left {
    display: flex;
    align-items: center;
}

.left span {
    width: 180px;
    text-align: center;
}

.right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
} */

.right>div {
    display: flex;
    align-items: center;
}

.reset {
    width: 90px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* margin-left: 30px; */
}

.container {
    /* margin-top: 20px; */
}

.searchBtn {
    margin-left: 30px;
}


/* div/deep/.el-input__inner {
    width: 200px;
}

div/deep/.el-input {
    width: 40%;
} */
</style>
