<template>
  <div class="appPage">
    <!-- <h3 class="tit">H号码管理</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="线路号码管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">中间号码</span>
            <el-input
              v-model="bindPhone1"
              placeholder="请输入中间号码"
              size="mini"
              v-on:keyup.enter="handleSearch"
            ></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">备注</span>
            <el-input
              v-model="industry"
              placeholder="请输入备注"
              size="mini"
              v-on:keyup.enter="handleSearch1"
            ></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">选择通道</span>
            <el-select size="mini" v-model="type" placeholder="请选择通道">
              <el-option
                v-for="item in options1"
                :key="item.type"
                :label="item.label1"
                :value="item.type"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找
          </el-button>
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            >重置</el-button
          >
          <el-button type="primary" round size="mini" @click="open()"
            >绑定中间号</el-button
          >
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="id" label="中间号编号"></el-table-column>
            <!-- <el-table-column
                      prop="activePhone"
                      label="主叫"

                    ></el-table-column> -->
            <el-table-column
              prop="bindPhone"
              label="中间号码"
            ></el-table-column>
            <el-table-column prop="count" label="次数"></el-table-column>
            <el-table-column prop="type" label="通道类型">
              <template #default="scope">
                <span v-if="scope.row.type == 0">隐私小号通道</span>
                <span v-if="scope.row.type == 1">南京线</span>
                <span v-if="scope.row.type == 2">电信</span>
                <span v-if="scope.row.type == 3">联通2</span>
                <span v-if="scope.row.type == 4">联通1</span>
                <span v-if="scope.row.type == 5">移动ax</span>
                <span v-if="scope.row.type == 6">联通3</span>
                <span v-if="scope.row.type == 7">联通4</span>
                <span v-if="scope.row.type == 8">H</span>
                <span v-if="scope.row.type == 9">联通5</span>
                <span v-if="scope.row.type == 10">移动3</span>
                <span v-if="scope.row.type == 11">联通6</span>
                <span v-if="scope.row.type == 12">联通7</span>
                <span v-if="scope.row.type == 13">移动5</span>
                <span v-if="scope.row.type == 14">S</span>
                <span v-if="scope.row.type == 15">D</span>
                <span v-if="scope.row.type == 16">S2</span>
                <span v-if="scope.row.type == 17">SIP</span>
                <span v-if="scope.row.type == 18">X</span>
                <span v-if="scope.row.type == 20">甘肃</span>
                <span v-if="scope.row.type == 21">2号线</span>
                <span v-if="scope.row.type == 22">3号线</span>
                <span v-if="scope.row.type == 23">新2号线</span>
                <span v-if="scope.row.type == 24">重庆线路</span>
                <span v-if="scope.row.type == 25">1号线</span>
                <span v-if="scope.row.type == 26">无锡</span>
                <span v-if="scope.row.type == 27">翼信</span>
                <span v-if="scope.row.type == 28">连云港YT</span>
              </template>
            </el-table-column>
            <el-table-column prop="industry" label="行业"></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
            <el-table-column label="操作" width="200" align="center">
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="handleClick(scope)"
                  size="mini"
                  >编辑</el-button
                >
                <el-button type="primary" @click="del(scope)" size="mini"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 绑定中间号弹出框 -->
    <el-dialog title="绑定中间号" v-model="addVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="中间号码" prop="bindPhone">
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>
        <el-form-item label="通道类型">
          <el-select v-model="form.type" placeholder="请选择通道类型">
            <el-option
              v-for="item in options1"
              :key="item.type"
              :label="item.label1"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="industry">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="editRules"
        label-width="80px"
      >
        <el-form-item label="中间号码" prop="bindPhone">
          <el-input v-model="form.bindPhone"></el-input>
        </el-form-item>
        <el-form-item label="通道类型">
          <el-select v-model="form.type" placeholder="请选择通道类型">
            <el-option
              v-for="item in options1"
              :key="item.type"
              :label="item.label1"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="次数" prop="count">
          <el-input v-model="form.count"></el-input>
        </el-form-item>
        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="industry">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "centerNum",
  data() {
    return {
      options1: [
        // {
        //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
        //   type: 0,
        //   label1: "隐私小号通道",
        // },

        // {
        //     type: 5,
        //     label1: "移动ax通道",
        // },
        {
          type: 1,
          label1: "南京线通道",
        },
        {
          type: 2,
          label1: "移动1通道",
        },
        // {
        //     type: 10,
        //     label1: "移动3通道",
        // },

        // {
        //     type: 13,
        //     label1: "移动5通道",
        // },
        // {
        //   type: 3,
        //   label1: "联通2通道",
        // },
        // {
        //   type: 4,
        //   label1: "联通1通道",
        // },

        // {
        //   type: 6,
        //   label1: "联通3通道",
        // },
        // {
        //   type: 7,
        //   label1: "联通4通道",
        // },
        {
          type: 8,
          label1: "H",
        },
        {
          type: 14,
          label1: "S",
        },

        {
          type: 15,
          label1: "D",
        },

        // {
        //     type: 16,
        //     label1: "S2",
        // },

        {
          type: 17,
          label1: "SIP",
        },
        {
          type: 18,
          label1: "X",
        },
        {
          type: 20,
          label1: "甘肃",
        },
        {
          type: 21,
          label1: "2号线",
        },
        {
          type: 22,
          label1: "3号线",
        },
        {
          type: 23,
          label1: "新2号线",
        },
        {
          type: 24,
          label1: "重庆线路",
        },
        {
          type: 25,
          label1: "1号线",
        },
        {
          type: 26,
          label1: "无锡",
        },
        {
          type: 27,
          label1: "翼信",
        },
        {
          type: 28,
          label1: "连云港YT",
        },
      ],
      areaCode: "",
      form: {
        id: "",
        bindPhone: "",
        industry: "",
        count: "",
      },
      type: null,
      paramid: 1,
      industry: null,
      editVisible: false, //是否显示编辑弹窗
      addVisible: false, //是否显示添加弹窗
      pageIndex: 1, //当前在第几页
      pageSize: 20, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 20, //接口请求的每页的数量
      bindPhone: null,
      bindPhone1: null, //搜索框的中间号
      count: null,
      Options: [],
    };
  },
  mounted() {
    if (document.body.clientWidth <= 900) {
      // 手机端适配,自动跳转到对应的手机页面
      this.$router.push("/xianLuNumManageApp");
    }
    window.onresize = () => {
      return (() => {
        // 监听窗口大小 document.body.clientWidth
        if (document.body.clientWidth <= 900) {
          // 手机端适配,自动跳转到对应的手机页面
          this.$router.push("/xianLuNumManageApp");
        }
      })();
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    industry() {
      if (this.industry == "") {
        this.industry = null;
      }
    },
    bindPhone1() {
      if (this.bindPhone1 == "") {
        this.bindPhone1 = null;
      }
    },
  },
  methods: {
    clickSelect(item) {
      this.areaCode = item.areaCode;
      this.bindPhone = item.bindPhone;
    },
    clickSelect1(item) {
      this.areaCode = item.areaCode;
      this.form.bindPhone = item.bindPhone;
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    handleSearch1() {
      let params = {
        industry: this.industry,
      };
      if (params.industry) {
        this.screen("industry", params.industry);
      } else {
        this.getList();
        return false;
      }
    },
    // 查找筛选
    screen(name, params) {
      let data = [...this.tableData];
      let arr = [];
      //  // console.log(data);
      data.forEach((v) => {
        //  // console.log("名字", v[name]);
        if (JSON.stringify(v[name]).indexOf(params) != -1) {
          arr.push(v);
        }
      });
      this.tableData = arr;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        bindPhone: this.bindPhone1,
        industry: this.industry,
        type: this.type,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneCount/list", params)
        .post("api/xphoneCount/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //打开绑定中间号弹窗
    open() {
      this.form = [];
      this.areaCode = "";
      this.addVisible = true;
      // axios
      //   .post("http://106.14.0.130:9998/vo/xphoneltOneX/get_lt2_bindPhone")
      //   .then((res) => {
      //     res.data.data.forEach((item, index) => {
      //       item.id = index;
      //     });
      //     this.Options = res.data.data;
      //   });
    },
    //绑定中间号确定按钮
    submitAdd() {
      let params = {
        id: this.id,
        industry: this.form.industry,
        count: 0,
        bindPhone: this.form.bindPhone,
        type: this.form.type,
        remarks: this.form.remarks,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneCount/add", params)
        .post("api/xphoneCount/add", params)
        .then((res) => {
          // console.log(res);
          if (res.data.statusCode == "00000") {
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.addVisible = false;
          this.form = {};
          this.bindPhone = null;
        });
    },
    //打开编辑弹窗
    handleClick(row) {
      this.editVisible = true;
      this.id = row.row.id;
      this.form.bindPhone = row.row.bindPhone;
      this.form.industry = row.row.industry;
      this.form.count = row.row.count;
      this.areaCode = row.row.areaCode;
      //   axios
      //     .post("http://106.14.0.130:9998/vo/xphoneltOneX/get_lt2_bindPhone")
      //     .then((res) => {
      //        // console.log(res.data.data);
      //       res.data.data.forEach((item, index) => {
      //         item.id = index;
      //       });
      //       this.Options = res.data.data;
      //     });
    },
    //编辑确定按钮
    submitEdit() {
      let params = {
        id: this.id,
        industry: this.form.industry,
        count: this.form.count,
        bindPhone: this.form.bindPhone,
        type: this.form.type,
        remarks: this.form.remarks,
      };
      //    // console.log(this.form.industry);
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneCount/update", params)
        .post("api/xphoneCount/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible = false;
        });
    },
    //删除按钮
    del(row) {
      let params = {
        id: row.row.id,
        activePhone: row.row.activePhone,
      };

      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/xphoneCount/del", params)
            .post("api/xphoneCount/del", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    //刷新按钮
    reset() {
      this.bindPhone = null;
      this.activePhone = null;
      this.industry = null;
      this.type = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 184px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

// .search {
//   display: flex;
//   justify-content: space-between;
//   margin-top: 20px;
// }
// .left {
//   display: flex;
//   align-items: center;
// }
// .item {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .left span {
//   text-align: center;
//   width: 100px;
// }
// .searchBtn {
//   margin-left: 30px;
// }
// .right {
//   display: flex;
//   justify-content: flex-end;
// }
.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
