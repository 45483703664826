<template>
  <div class="appPage">
    <!-- <h3 class="tit">ip加白管理</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="ip加白管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">ip</span>
            <el-input
              v-model="ip"
              placeholder="请输入ip"
              size="mini"
              v-on:keyup.enter="handleSearch"
            ></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">备注</span>
            <el-input
              v-model="remarks"
              placeholder="请输入备注"
              size="mini"
              v-on:keyup.enter="handleSearch"
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找
          </el-button>
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            >重置
          </el-button>
          <el-button type="primary" round size="mini" @click="open()"
            >添加
          </el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="ip" label="ip"></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
            <el-table-column prop="userId" label="代理id"></el-table-column>
            <el-table-column label="操作" width="300" align="center">
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="handleClick(scope)"
                  size="mini"
                  >编辑
                </el-button>
                <el-button type="primary" @click="del(scope)" size="mini"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 绑定中间号弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="ip" prop="ip">
          <el-input v-model="form.ip"></el-input>
        </el-form-item>

        <el-form-item label="备注" prop="remarks">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
        <el-form-item label="代理id">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form
        ref="dataAddForm"
        :model="form"
        :rules="editRules"
        label-width="80px"
      >
        <el-form-item label="ip" prop="areaCode">
          <el-input v-model="form.ip"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
        <el-form-item label="代理id">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "centerNum",
  data() {
    return {
      form: {},
      ip: null, //搜索框主叫
      remarks: null,
      editVisible: false, //是否显示编辑弹窗
      addVisible: false, //是否显示添加弹窗
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    // this.getList()
  },
  mounted() {
    if (document.body.clientWidth <= 900) {
      // 手机端适配,自动跳转到对应的手机页面
      this.$router.push("/ipWhiteApp");
    }
    window.onresize = () => {
      return (() => {
        // 监听窗口大小 document.body.clientWidth
        if (document.body.clientWidth <= 900) {
          // 手机端适配,自动跳转到对应的手机页面
          this.$router.push("/ipWhiteApp");
        }
      })();
    };
  },
  watch: {
    industry() {
      if (this.industry == "") {
        this.industry = null;
      }
    },
  },
  methods: {
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        ip: this.ip,
        remarks: this.remarks,
        userId: this.userId,
      };
      axios
        .post("api/ip-white/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //打开绑定中间号弹窗
    open() {
      this.addVisible = true;
    },
    //添加
    submitAdd() {
      let params = {
        ip: this.form.ip,
        remarks: this.form.remarks,
        userId: this.form.userId,
      };
      axios.post("api/ip-white/add", params).then((res) => {
        // console.log(res);
        if (res.data.statusCode == "00000") {
          this.getList();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
        this.addVisible = false;
        this.form = {};
      });
      getList();
    },
    //打开编辑弹窗
    handleClick(row) {
      this.editVisible = true;
      this.id = row.row.id;
      this.form.ip = row.row.ip;
      this.form.remarks = row.row.remarks;
      this.form.userId = row.row.userId;
    },
    //编辑确定按钮
    submitEdit() {
      let params = {
        id: this.id,
        ip: this.form.ip,
        remarks: this.form.remarks,
        userId: this.form.userId,
      };
      axios.post("api/ip-white/update", params).then((res) => {
        if (res.data.statusCode == "00000") {
          this.getList();
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
        this.editVisible = false;
      });
    },
    //删除按钮
    del(row) {
      let params = {
        id: row.row.id,
        ip: this.form.ip,
      };

      this.$confirm("此操作将永久删除该项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          axios
            .post("api/ip-white/del", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getList();
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getList();
        });
    },
    //刷新按钮
    reset() {
      this.ip = null;
      this.remarks = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 157px;

      .lab_tit {
        width: 28px !important;
      }
    }

    .left {
      width: 750px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

// .search {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
// }

// .left {
//     display: flex;
//     align-items: center;
// }

// .item {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .left span {
//     text-align: center;
//     width: 100px;
// }

// .searchBtn {
//     margin-left: 30px;
// }

// .right {
//     display: flex;
//     justify-content: flex-end;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  // margin-top: 20px;
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
