<template>
    <div class="appPage">
        <!--    <div class="crumbs">-->
        <!--      <el-breadcrumb separator="/">-->
        <!--        <el-breadcrumb-item>-->
        <!--          基础表格-->
        <!--        </el-breadcrumb-item>-->
        <!--      </el-breadcrumb>-->
        <!--    </div>-->
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="企业历史消费"></el-tab-pane>
            </el-tabs>
            <el-tag class="ml-2" type="info">月份 ： {{ getYMDHMS("YYYY-mm", day) }}</el-tag>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">代理ID</span>
                        <el-input v-model="userId" placeholder="请输入代理ID" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">业务员</span>
                        <el-input v-model="salesman" placeholder="请输入业务员名称" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">选择月份</span>
                        <el-date-picker v-model="day" type="month" placeholder="选择月" size="mini">
                        </el-date-picker>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">企业ID</span>
                        <el-input v-model="appid" placeholder="请输入appid" size="mini"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">企业范围</span>
                        <!-- <el-radio v-model="typeCode" label="" border size="mini">全部企业</el-radio> -->
                        <!-- <el-radio v-model="typeCode" :label="1" border size="mini">套餐超出企业</el-radio> -->
                        <el-select v-model="typeCode" placeholder="请选择" size="mini">
                            <el-option v-for="item in typeCodeOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                    <!-- <span>类型</span> -->
                    <!--    下拉框    -->
                    <!-- <el-select v-model="value" placeholder="请选择">
                  <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select> -->

                </div>
                <div class="right">
                    <!-- <el-button type="primary" icon="el-icon-search" @click="handleSearch('套餐超出企业')" round
                        size="mini">套餐超出企业</el-button> -->
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
                    <!-- <el-button type="primary" @click="excel()">导出</el-button> -->

                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column prop="name" label="代理名称" width="300"></el-table-column>
                        <el-table-column :prop="v.prop" :label="v.label" v-for="v in newColumnsData" width="170"
                            :key="v.key"></el-table-column>
                        <el-table-column prop="realname" label="企业名称" fixed="right" width="300"></el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination gination background layout="total, prev, pager, next,sizes" :current-page="page"
                            :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
                            @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "enterpriseRecharge",
    data() {
        return {
            typeCode: '',
            typeCodeOptions: [
                {
                    label: '全部企业',
                    value: ''
                },
                {
                    label: '套餐超出企业',
                    value: 1
                },
            ],
            newColumnsData: [
                {
                    key: 1,
                    prop: 'salesman',
                    label: '业务员',
                },
                {
                    key: 2,
                    prop: 'user_id',
                    label: '代理ID',
                },
                {
                    key: 3,
                    prop: 'add_money',
                    label: '月租',
                },

                {
                    key: 4,
                    prop: 'money',
                    label: '实际消费金额',
                },
                {
                    key: 5,
                    prop: 'money_wai',
                    label: '实际消费金额(套餐外)',
                },
                {
                    key: 6,
                    prop: 'rate',
                    label: '费率',
                },
                {
                    key: 7,
                    prop: 'time',
                    label: '消费分钟',
                },
                {
                    key: 8,
                    prop: 'time_wai',
                    label: '消费分钟(套餐外)',
                }],
            createdTime: null,
            form: {
                id: null,
                name: null,
                old_money: "",
                money: "",
                createTime: "",
                appid: null,
            },
            tableData: [],
            userId: null,
            salesman: null,

            appid: null,
            day: null,
            pageTotal: 0, //总共有多少数据
            pageIndex: 1,//当前在第几页
            pageSize: 10,//前端规定每页显示数量
            page: 1,
            size: 10,
        };
    },
    created() {
        this.$nextTick(() => {
            this.setDay()
            this.getList();
        })

    },
    //切换界面就执行方法
    activated() {
        // this.getList()
    },
    methods: {
        // 设置时间未上个月
        setDay() {
            // 获取当前日期
            const currentDate = new Date();

            // 获取当前年份和月份
            const currentYear = currentDate.getFullYear();
            const currentMonth = currentDate.getMonth() + 1;

            // 计算上个月的年份和月份
            let lastMonthYear;
            let lastMonth;
            if (currentMonth === 1) {
                // 如果当前月份是一月，获取昨年最后一个月份
                lastMonthYear = currentYear - 1;
                lastMonth = 12;
            } else {
                lastMonthYear = currentYear;
                lastMonth = currentMonth - 1;
            }

            this.day = `${lastMonthYear}-${lastMonth}`
        },
        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        handleSizeChange(val) {
            this.size = val;
            this.getList();
        },
        //导出
        excel() {
            if (this.createdTime == null) {
                // this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                // this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
                this.$message({
                    type: "error",
                    message: "请选择时间后导出",
                });
                return;
            }
            let params = {
                // sta: this.sta1,
                // end: this.end1,
                exportList: this.tableData
            };
            // console.log(params)

            // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
            axios
                // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
                .post("api/log/export-uuid-log", params, { responseType: "blob" })
                //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
                .then((res) => {

                    this.loading = false

                    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

                    const url = window.URL.createObjectURL(res.data);

                    const a = document.createElement("a");

                    a.style.display = "none";

                    let str = res.headers['content-disposition']

                    a.download = decodeURI(str).substr(decodeURI(str).indexOf('=') + 1);

                    a.href = url;

                    a.click();

                });
            this.getList();
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            // console.log('this.page111', val);

            this.getList();
        },

        setMonth(value) {
            const date = new Date(value);
            const year = date.getFullYear();
            const month = ('0' + (date.getMonth() + 1)).slice(-2); // 加1是因为月份从0开始计数，使用slice确保月份始终是两位数
            return `${year}-${month}`;
        },
        //展示和搜索
        getList(type) {
            let day = this.setMonth(this.day)
            let params = {
                page: this.page,
                size: this.size,
                day,
                userId: this.userId,
                salesman: this.salesman,
                appid: this.appid
            };
            if (this.typeCode) params.typeCode = this.typeCode
            // console.log('this.page', this.page);
            // axios.post("http://106.14.0.130:9998/vo/log/uuidlist",params)
            axios.post("api/callLog-expense/money-monthtable", params)
                .then(res => {
                    if (res.data.success == false) {
                        this.$router.push('/403')
                    } else {
                        this.tableData = res.data.data
                        this.pageTotal = res.data.total
                    }
                })
                .catch((err) => {
                    // console.log(err);
                })
        },

        //重置
        reset() {
            this.setDay()
            this.userId = null
            this.appid = null
            this.salesman = null
            this.typeCode = ''
            this.page = 1
            this.getList();
        },
        //搜索,再次调用getList方法就是展示
        handleSearch(type) {
            if (this.createdTime) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
            }
            this.page = 1
            this.getList(type);
        },
    }
};
</script>

<style scoped lang="scss">
.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            width: 536px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 176px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 50px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }

        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.item {
    display: flex;
    align-items: center;
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.search {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

// .left {
//     display: flex;
// }


// .left .item {
//     flex: 1;
// }

// .left span {
//     width: 100px;
//     text-align: center
// }

.searchBtn {
    margin-left: 15px;
}

.right {
    /*display: flex;*/
    justify-content: flex-end;
}

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}
</style>
