<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="定时报停管理"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">代理id</span>
                        <el-input size="mini" v-model="bindPhone1" placeholder="请输入代理id"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input size="mini" v-model="appid" placeholder="请输入appid"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">子企业id</span>
                        <el-input size="mini" v-model="uuid" placeholder="请输入子企业id"
                            v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">状态</span>
                        <el-select size="mini" v-model="status" placeholder="请选择状态">
                            <el-option v-for="item in options" :key="item.status" :label="item.label1" :value="item.status">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="right">
                    <el-button round size="mini" type="primary" icon="el-icon-search" @click="handleSearch">查找
                    </el-button>
                    <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @selection-change="handleSelectionChange">
                        <el-table-column prop="userId" width="100" label="代理id"></el-table-column>
                        <el-table-column prop="uuid" width="100" label="子企业id"></el-table-column>
                        <el-table-column prop="name" label="企业名称"></el-table-column>
                        <el-table-column prop="remark" width="200" label="报停原因"></el-table-column>
                        <el-table-column prop="status" label="状态" width="80">
                            <template #default="scope">
                                <span v-if="scope.row.status == 0">待处理</span>
                                <span v-if="scope.row.status == 1">已处理</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="stopTime" width="180" label="报停时间"></el-table-column>
                        <el-table-column prop="createTime" width="180" label="创建时间"></el-table-column>
                        <el-table-column label="操作" width="80" align="center" fixed="right">
                            <template #default="scope">
                                <el-button type="primary" @click="handleClick(scope)" size="mini">编辑
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next" :current-page="pageIndex"
                            :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>


        <!-- 编辑弹出框 -->
        <el-dialog title="编辑" v-model="editVisible" width="30%">
            <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="80px">
                <el-form-item label="定时状态" prop="status">
                    <el-select v-model="status" placeholder="请选择状态">
                        <el-option v-for="item in options" :key="item.status" :label="item.label1" :value="item.status">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="报停时间" prop="stopTime">
                    <el-date-picker v-model="stopTime" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>

                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="editVisible = false">取 消</el-button>
                    <el-button type="primary" @click="submitEdit()">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            stopTime: null,
            status: null,
            userId: null,
            appid: null,
            uuid: null,
            form: {},
            editVisible: false, //是否显示编辑弹窗
            addVisible: false, //是否显示添加弹窗
            pageIndex: 1, //当前在第几页
            pageSize: 20, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 20, //接口请求的每页的数量
            options: [
                {
                    status: 0,
                    label1: "待处理",
                }, {
                    status: 1,
                    label1: "已处理",
                }
            ],

        };
    },
    created() {
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        // this.getList()
    },
    watch: {},
    methods: {

        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSearch() {
            this.getList();
        },
        //展示and查找
        getList() {
            let params = {
                page: this.page,
                size: this.size,
                appid: this.appid,
                userId: this.userId,
                uuid: this.uuid,
                status: this.status,
            };
            axios
                .post("api/uuid-stop/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                    // console.log(err);
                });
        },


        //打开编辑弹窗
        handleClick(row) {
            // console.log(row.row);
            this.editVisible = true;
            this.status = row.row.status;
            this.id = row.row.id;
        },
        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },
        //编辑确定按钮
        submitEdit() {
            let stopTime = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.stopTime)
            let params = {
                id: this.id,
                status: this.status,
                stopTime,
            };
            // console.log(this.form.remake);
            axios
                .post("api/uuid-stop/update", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.editVisible = false;
                });
        },

        //刷新按钮
        reset() {
            this.createTime = null;
            this.status = null;
            this.form = [];
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .search {
        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            // width: 60%;
            width: 710px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 175px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    height: 23px !important;
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .el-input__icon {
                    line-height: 24px !important;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 50px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        .right {
            text-align: right;
            // width: 30%;
            flex: 1;
            // display: flex;
            .el-button {
                margin-bottom: 3px;
            }
        }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}






.searchBtn {
    margin-left: 30px;
}


.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
</style>
