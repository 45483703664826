<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="企业待续费"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">

          <div class="leftItem">
            <span class="lab_tit">代理id</span>
            <el-input v-model="userId" placeholder="请输入代理id" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input v-model="appid" placeholder="请输入appid" size="mini" v-on:keyup.enter="handleSearch"></el-input>
          </div>


        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
          <el-button round size="mini" type="primary" @click="excel()">导出</el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column width="100" prop="userId" label="代理id"></el-table-column>
            <el-table-column width="240" prop="realname" label="代理名称"></el-table-column>
            <el-table-column width="100" prop="dl_money" label="代理金额"></el-table-column>
            <el-table-column prop="name" width="300" label="企业名称"></el-table-column>
            <el-table-column width="310" prop="appid" label="appid"></el-table-column>
            <el-table-column width="100" prop="money" label="余额"></el-table-column>
            <el-table-column width="80" prop="seat_rate" label="套餐"></el-table-column>
            <el-table-column prop="count" label="使用坐席数"></el-table-column>
            <!--<el-table-column  label="操作" width="300" >-->
            <!--<template #default="scope">-->
            <!--<el-button type="primary" @click="handleClick(scope)">编辑</el-button>-->
            <!--<el-button type="primary" @click="del(scope)">删除</el-button>-->
            <!--</template>-->
            <!--</el-table-column>-->
          </el-table>
          <div class="mainTableFY">
            <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
              :page-size="pageSize" :total="pageTotal" :page-sizes="[10, 20, 50, 100, 200]"
              @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>



  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "centerNum",
  data() {
    return {

      pageIndex: 1,//当前在第几页
      pageSize: 10,//前端规定每页显示数量
      pageTotal: 0,//总共有多少数据
      tableData: [],//用于接收后端数据表的数组
      page: 1,//接口请求的页数
      size: 10,//接口请求的每页的数量
      userId: null,
      appid: null,//搜索框的中间号
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    // this.getList()
  },
  watch: {

  },
  methods: {
    //导出
    excel() {

      let params = {
        poiOut: 1
      };
       // console.log(params)

      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/uuid/renew", params, { responseType: "blob" })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {

          this.loading = false

          const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers['content-disposition']

          a.download = decodeURI(str).substr(decodeURI(str).indexOf('=') + 1);

          a.href = url;

          a.click();

        });
      this.getList();
    },


    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSearch() {
      this.getList();
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        appid: this.appid,
        userId: this.userId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/xphoneltTwo/list", params)
        .post("api/uuid/renew", params)
        .then((res) => {
          this.tableData = res.data.data
          this.pageTotal = res.data.total
        })
        .catch((err) => {
           // console.log(err);
        })
    },




    //刷新按钮
    reset() {
      this.userId = null;
      this.appid = null;
      this.getList();
    },

  }
};
</script>

<style scoped lang="scss">

.leftItem{
  width: 170px !important;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left span {
  text-align: center;
  width: 100px;
}

.searchBtn {
  margin-left: 30px;
}

.right {
  display: flex;
  justify-content: flex-end;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

/* /deep/.el-form-item__label{
  font-size: 13px  !important;
} */
</style>
