<template>
  <div class="overview">
    <!-- 上半块 账单汇总 -->
    <div class="Summary">
      <!-- 搜索和按钮 -->
      <div class="flexSpb term">
        <!-- 左块区域 -->
        <div class="flexSpb">
          <div class="dateBox">
            <p>账期：</p>
            <el-date-picker
              style="width: 120px"
              v-model="listQuery.day"
              type="month"
              placeholder="选择月"
              size="mini"
              :clearable="false"
              @change="getList"
            >
            </el-date-picker>
          </div>
        </div>
        <!-- 右块区域 -->
        <div class="right">
          <div class="dateBox">
            <p style="width: 90px; white-space: nowrap">查询代理：</p>
            <!-- <el-select v-model="listQuery.userId" size="mini" filterable clearable @change="getList">
                            <el-option v-for="item in firmList" :key="item.id" :label="item.realname" :value="item.id">
                            </el-option>
                        </el-select> -->
            <el-input v-model="listQuery.userId" size="mini" clearable>
            </el-input>
          </div>
          <!-- <el-button type="danger" size="mini">按钮1</el-button> -->
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getList"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
        </div>
      </div>
      <!-- 数据展示 -->
      <div class="billData">
        <!-- 左块区域 -->
        <div class="left">
          <div class="title">账单汇总</div>
          <div class="contentBox">
            <div class="itemList flexSpb" v-if="realname == 'admin'">
              <div class="item">
                <p style="margin-right: 6px">分钟数汇总</p>
                <i class="el-icon-warning-outline"></i>
              </div>
              <div class="item">
                <p style="margin-right: 6px">{{ timeMap.time || 0 }} 分钟</p>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>

            <!-- <div class="itemList flexSpb">
                            <div class="item">
                                <p style="margin-right: 6px;">实际消费金额</p>
                                <i class="el-icon-warning-outline"></i>
                            </div>
                            <div class="item">
                                <p style="margin-right: 6px;">￥{{ timeMap.money || 0 }}</p>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div> -->

            <!-- <div class="itemList flexSpb">
                            <div class="item">
                                <p style="margin-right: 6px;">套餐外消费金额</p>
                                <i class="el-icon-warning-outline"></i>
                            </div>
                            <div class="item">
                                <p style="margin-right: 6px;">￥{{ timeMap.moneyW || 0 }}</p>
                                <i class="el-icon-arrow-right"></i>
                            </div>
                        </div> -->

            <div class="itemList flexSpb">
              <div class="item">
                <p style="margin-right: 6px">创建坐席金额</p>
                <i class="el-icon-warning-outline"></i>
              </div>
              <div class="item">
                <p style="margin-right: 6px">￥{{ timeMap.addMoney || 0 }}</p>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>

            <div class="itemList flexSpb">
              <div class="item">
                <p style="margin-right: 6px">充值金额汇总</p>
                <i class="el-icon-warning-outline"></i>
              </div>
              <div class="item">
                <p style="margin-right: 6px">￥{{ sumAddMoney || 0 }}</p>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>

            <div class="itemList flexSpb">
              <div class="item">
                <p style="margin-right: 6px">扣款金额汇总</p>
                <i class="el-icon-warning-outline"></i>
              </div>
              <div class="item">
                <p
                  style="margin-right: 6px; font-size: 18px; font-weight: bold"
                >
                  ￥{{ userMoneySum || 0 }}
                </p>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>

            <div class="sumBill">
              <p class="title">总费用构成</p>
              <div style="display: flex">
                <div class="sumBillData">
                  <p class="text">扣款金额</p>
                  <p class="money">￥{{ userMoneySum || 0 }}</p>
                </div>
                <div class="sumBillData">
                  <p class="text">充值金额</p>
                  <p class="money">￥{{ userMoneySum || 0 }}</p>
                </div>
                <!-- <div class="sumBillData">
                                    <p class="text">实际消费金额</p>
                                    <p class="money">￥{{ timeMap.money || 0 }}</p>
                                </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- 右块区域 -->
        <div class="right">
          <div class="title">扣款统计</div>
          <!-- <div class="dateBox">
                        <p>账期</p>
                        <el-date-picker v-model="testValue.a" type="month" placeholder="选择月" size="mini">
                        </el-date-picker>
                    </div> -->

          <div id="billChart" style="min-height: 240px; width: 100%"></div>
        </div>
      </div>
    </div>

    <!-- 下半块 消费分布 -->
    <div class="consumption">
      <!-- 标题和功能 -->
      <div class="flexSpb">
        <!-- <div class="title">消费分布</div> -->
        <!-- <div class="utilsBox">
                    <i class="el-icon-download"></i>
                    <i class="el-icon-setting"></i>
                    <i class="el-icon-close"></i>
                </div> -->
      </div>
      <!-- tab栏 -->
      <el-tabs v-model="tabValue">
        <el-tab-pane label="消费分布" name="first"></el-tab-pane>
      </el-tabs>
      <!-- 数据展示 -->
      <div class="flexSpb">
        <div class="proportion">
          <div
            id="proportionEcharts"
            style="min-height: 220px; width: 100%"
          ></div>
        </div>
        <div class="fabric">
          <el-table
            :data="tableData"
            style="width: 100%"
            show-summary
            height="220"
          >
            <el-table-column prop="day" label="扣款日期"> </el-table-column>
            <el-table-column prop="userMoney" label="扣款金额">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入echarts
import * as echarts from "echarts";
// 引入axios
import axios from "axios";

import { userFindlist, userOperatorsFindlist } from "@/api/user";

export default {
  data() {
    return {
      listQuery: {},
      timeMap: {},
      tableData: [],
      tabValue: "first",
      realname: "",
      resizefun: () => {},
      sumAddMoney: 0,
    };
  },
  computed: {
    userMoneySum() {
      let sum = 0;
      this.tableData.map((v) => {
        sum += v.userMoney;
      });
      return sum;
    },
  },

  created() {
    this.setDay();
    this.realname = localStorage.getItem("realname");
    // this.operatorsList()
    this.getList();
  },
  mounted() {
    this.resizefun = () => {
      echarts.init(document.getElementById("billChart")).resize();
      echarts.init(document.getElementById("proportionEcharts")).resize();
      //多个echarts则在此处添加
    };
    window.addEventListener("resize", this.resizefun);
  },

  methods: {
    // 设置时间为上个月
    setDay() {
      // 获取当前日期
      const currentDate = new Date();
      // 获取当前年份和月份
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      // 计算上个月的年份和月份
      let lastMonthYear;
      let lastMonth;
      if (currentMonth === 1) {
        // 如果当前月份是一月，获取昨年最后一个月份
        lastMonthYear = currentYear - 1;
        lastMonth = 12;
      } else {
        lastMonthYear = currentYear;
        lastMonth = currentMonth - 1;
      }
      this.listQuery.day = `${lastMonthYear}-${lastMonth}`;
    },
    // 查询列表数据
    getList() {
      let params = { ...this.listQuery };
      if (params.day) {
        params.day = this.getYMDHMS("YYYY-mm", this.listQuery.day);
      } else {
        this.$alert("请选择日期", "", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });
        return false;
      }
      axios
        .post("api/callLog-expense/bill-overview", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // this.$message.success(res.data.message || '查询成功');
            this.tableData = res.data.data.sumLogDay;
            if (res.data.data.timeMap) {
              this.timeMap = res.data.data.timeMap;
              this.sumAddMoney = res.data.data.sumAddMoney;
            } else {
              this.timeMap = {};
            }

            this.$nextTick(() => {
              // 绘制echrts
              this.drawColumnChart();
              this.drawProportionChart();
            });
          } else {
            this.$message.warning(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 查代理
    async operatorsList() {
      let params = {
        page: 1,
        size: 499,
        parentId: 0,
      };
      let res;
      if (this.realname == "admin") {
        res = await userFindlist(params);
      } else {
        res = await userOperatorsFindlist(params);
      }

      this.firmList = res.data;
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // echarts
    // 消费走势echarts图
    drawColumnChart() {
      let chartColumn = echarts.init(document.getElementById("billChart"));

      chartColumn.setOption({
        color: ["#5AB1EF", "#2BBABC", "#B6A2DE", "#FFB980", "#D87A80"],
        tooltip: {
          trigger: "axis",
        },
        dataZoom: [
          {
            type: "slider",
            show: true,
            start: 0,
            end: 10,
            xAxisIndex: [0],
            minValueSpan: 6,
          },
        ],
        toolbox: {
          show: true,
          feature: {
            magicType: { show: true, type: ["line", "bar"] },
          },
        },
        calculable: true,
        xAxis: [
          {
            type: "category",
            data: this.tableData.map((v) => v.day),
          },
        ],
        yAxis: [
          {
            name: "金额(￥)                ",
            type: "value",
            max: function (value) {
              return value.max + 100;
            },
          },
        ],
        series: [
          {
            name: "金额(￥):",
            type: "bar",
            barMaxWidth: "30",
            data: this.tableData.map((v) => v.userMoney),
            // 标注显示最大值和最小值
            // markPoint: {
            //     data: [
            //         { type: 'max', name: 'Max' },
            //         { type: 'min', name: 'Min' }
            //     ]
            // },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#3278FE",
              },
              formatter: function (params) {
                return params.value + "元";
              },
            },
            // 显示平均值
            // markLine: {
            //     data: [{ type: 'average', name: 'Avg' }]
            // }
          },
        ],
      });
    },
    // 消费分布echarts图
    drawProportionChart() {
      let sum = 0;
      this.tableData.map((v) => {
        sum += v.userMoney;
      });

      let chartColumn = echarts.init(
        document.getElementById("proportionEcharts")
      );

      chartColumn.setOption({
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "right",
          top: "middle",
        },
        series: [
          {
            name: "消费分布图",
            type: "pie",
            radius: ["50%", "65%"],
            avoidLabelOverlap: false,

            label: {
              show: true,
              position: "center",
              formatter: function (params) {
                return `${params.name}${params.value}元`;
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 25,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              // { value: this.timeMap.time, name: '分钟数汇总/分' },
              // { value: this.timeMap.moneyW, name: '￥套餐外消费金额' },
              { value: this.timeMap.addMoney, name: "￥创建坐席金额汇总" },
              { value: this.timeMap.money, name: "￥消费金额汇总" },
              { value: sum, name: "￥扣款金额汇总" },
              { value: this.sumAddMoney, name: "￥充值金额汇总" },
            ],
          },
        ],
      });
    },
  },
  //移除事件监听
  beforeDestroy() {
    window.removeEventListener("resize", this.resizefun);
    this.resizefun = null;
  },
};
</script>


<style scoped lang="scss">
::v-deep.el-table {
  .el-table__body .el-table__row {
    height: 40px;
  }
  // max-width: 700px;
  .el-table__row {
    & > td {
      padding: 4px 0;
      font-size: 12px;
    }
  }

  .has-gutter {
    th,
    td {
      padding: 2px 0;
    }
  }
}

.overview {
  font-family: STKaiti STHeiti;
  height: 100%;

  .Summary,
  .consumption {
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin-bottom: 5px;
    border-radius: 5px;
  }

  .Summary {
    .term {
      .dateBox {
        display: flex;
        align-items: center;
        margin-right: 8px;

        p {
          margin-right: 10px;
        }
      }

      .right {
        display: flex;
        align-items: center;
      }
    }

    .billData {
      display: flex;
      margin-top: 5px;

      .title {
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .left {
        // flex: 2;
        width: 40%;

        .contentBox {
          font-size: 13px;
          margin-top: 4px;
          width: 100%;

          .itemList {
            margin: 10px 0;

            .item {
              display: flex;
              align-items: center;
            }
          }

          .sumBill {
            position: relative;
            border: 1px solid #e9e9e9;
            background-color: #f8f8f9;
            padding: 0 10px;
            box-sizing: border-box;
            margin-top: 10px;

            &::after {
              position: absolute;
              content: "";
              right: 40px;
              top: -16px;
              width: 0;
              height: 0;
              // 因为我需要的是倒三角,所以只需要给 border-top加颜色即可,其他的就让它透明,以此类推
              border: 8px solid transparent;
              border-bottom: 8px solid #e9e9e9;
            }

            .title {
              font-size: 13px;
              font-weight: bold;
              margin: 6px 0;
            }

            .sumBillData {
              margin-bottom: 8px;
              margin-right: 10px;

              .text {
                font-size: 11px;
                color: #a8b2c5;
              }

              .money {
                margin: 6px 0;
              }
            }
          }
        }
      }

      .right {
        // flex: 3;
        width: 58%;
        margin-left: 20px;

        .dateBox {
          display: flex;
          align-items: center;

          p {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .consumption {
    .proportion {
      // flex: 2;
      width: 40%;
    }

    .fabric {
      // flex: 3;
      width: 58%;
    }

    .title {
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 6px;
    }

    .utilsBox {
      & > i {
        cursor: pointer;
        margin-left: 10px;
        font-size: 16px;
      }
    }

    ::v-deep .el-tabs {
      .el-tabs__item {
        font-size: 13px !important;
      }
    }
  }
}

#billChart {
  & > div {
    width: 100% !important;

    & > canvas {
      width: 100% !important;
    }
  }
}

.flexSpb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
