<template>
  <div class="appPage">
    <!--    <div class="crumbs">-->
    <!--      <el-breadcrumb separator="/">-->
    <!--        <el-breadcrumb-item>-->
    <!--          基础表格-->
    <!--        </el-breadcrumb-item>-->
    <!--      </el-breadcrumb>-->
    <!--    </div>-->
    <!-- <h3 class="tit">企业操作日志</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="企业操作日志"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input v-model="form.appid" placeholder="请输入appid" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">企业名称</span>
            <el-input v-model="form.name" placeholder="请输入企业名称" size="mini"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">操作内容</span>
            <el-input v-model="form.remarke" placeholder="请输入操作内容" size="mini"></el-input>
          </div>
          <!-- <span>类型</span> -->
          <!--    下拉框    -->
          <!-- <el-select v-model="value" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select> -->

        </div>
        <div class="right">
          <el-button type="primary" icon="el-icon-search" @click="handleSearch" round size="mini">查找</el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
        </div>
      </div>

      <div class="container mainTable">
        <hr>
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <!--<el-table-column  type="selection" width="55" ></el-table-column>-->
            <!--<el-table-column  prop="updateUserName" label="操作人姓名" ></el-table-column>-->
            <el-table-column prop="name" label="企业名称" width="310"></el-table-column>
            <el-table-column prop="appid" label="appid" width="310"></el-table-column>
            <el-table-column prop="remarke" label="备注"></el-table-column>
            <el-table-column prop="createTime" label="操作时间" width="310"></el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination gination background layout="total, prev, pager, next" :current-page="pageIndex"
              :page-size="pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "enterpriseRecharge",
  data() {
    return {
      form: {
        id: null,
        name: null,
        old_money: "",
        money: "",
        createTime: "",
        appid: null,
      },
      tableData: [],
      pageTotal: 0, //总共有多少数据
      pageIndex: 1,//当前在第几页
      pageSize: 10,//前端规定每页显示数量
      page: 1,
      size: 10,
    };
  },
  created() {
    this.getList();

  },
  //切换界面就执行方法
  activated() {
    this.getList()
  },
  methods: {
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },

    //展示和搜索
    getList() {
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        name: this.form.name,
        appid: this.form.appid,
        remarke: this.form.remarke,
      };
      // axios.post("http://106.14.0.130:9998/vo/log/uuidlist",params)
      axios.post("api/operationLog/list", params)
        .then(res => {
          if (res.data.success == false) {
            this.$router.push('/403')
          } else {
            this.tableData = res.data.data
            this.pageTotal = res.data.total
          }
        })
        .catch((err) => {
          // console.log(err);
        })
    },

    //重置
    reset() {
      this.form = [];

      this.getList();
    },
    //搜索,再次调用getList方法就是展示
    handleSearch() {
      this.getList();
    },
  }
};
</script>

<style scoped lang="scss"> .appPage {
   height: 100%;
 }

 ::v-deep .appPageMain {
   height: 100%;
   padding: 15px;
   box-sizing: border-box;
   box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
   border-radius: 10px;
   background-color: #fff;
   display: flex;
   flex-direction: column;


   .search {
     .el-select {
       width: 100%;
     }

     // height: 70px;
     display: flex;
     justify-content: space-between;
     margin-top: 20px;
     // overflow: auto;


     .left {
       width: 540px;
       display: flex;
       align-items: center;
       flex-wrap: wrap;

       .leftItem {
         width: 176px;
         height: 28px;
         display: flex;
         align-items: center;
         margin-bottom: 10px;

         .el-input,
         .el-select,
         .el-date-editor {
           // width: 120px;
           flex: 1;
         }

         .el-input__inner {
           border-radius: 25px;
           border: 1px solid #666;
         }

         .lab_tit {
           font-size: 12px;
           // font-weight: bold;
           white-space: nowrap;
           width: 50px !important;
           text-align: right;
           margin-right: 3px;
           margin-left: 8px;
         }
       }
     }

     //  .right {
     //    text-align: right;
     //    width: 30%;

     //    .el-button {
     //      margin-bottom: 4px;
     //    }
     //  }
   }


   .mainTable {
     flex: 1;
     display: flex;
     flex-direction: column;

     .table {
       flex: 1;

       .el-table__row {
         td {
           padding: 4px 0;
         }
       }
     }

     .mainTableFY {
       height: 40px;
       text-align: right;
       margin-top: 4px;
     }
   }
 }

 ::v-deep .titleBox {
   height: 40px;

   .el-tabs__item {
     font-size: 24px;
     font-weight: bold;
     color: #333333 !important;
   }
 }

 .tit {
   font-weight: normal;
   font-size: 36px;
   color: #333333;
 }

 .search {
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
 }

 // .left {
 //   display: flex;
 //   align-items: center;
 // }

 // .left span {
 //   width: 180px;
 //   text-align: center
 // }

 .searchBtn {
   margin-left: 15px;
 }

 // .right {
 //   display: flex;
 //   justify-content: flex-end;
 // }

 .reset {
   width: 80px;
   height: 34px;
   border: 1px solid #ccc;
   border-radius: 4px;
 }

 .add {
   margin-left: 15px;
 }

 .container {
   /* // margin-top: 20px; */
 }
</style>
