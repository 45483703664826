<template>
  <div class="appPage">
    <!-- <h3 class="tit">D线路号码加白</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="线路号码加白"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
           <div class="leftItem">
                        <span class="lab_tit">号码查询</span>
                        <el-input v-model="phone" placeholder="请输入号码" size="mini"></el-input>
                    </div>
          <div class="leftItem">
            <span class="lab_tit">公司名称</span>
            <el-input
              v-model="name"
              placeholder="请输入公司名称"
              size="mini"
            ></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">地区查询</span>
            <el-input
              v-model="phoneCity"
              placeholder="请输入地区"
              size="mini"
            ></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">代理id</span>
            <el-input
              v-model="userId"
              placeholder="请输入代理id"
              size="mini"
            ></el-input>
          </div>

          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input
              v-model="appid"
              placeholder="请输入appid"
              size="mini"
            ></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">选择状态</span>
            <el-select v-model="status" placeholder="选择状态" size="mini">
              <el-option
                v-for="item in options"
                :key="item.status"
                :label="item.label"
                :value="item.status"
              >
              </el-option>
            </el-select>
          </div>
          <div class="leftItem">
            <span class="lab_tit">时间</span>
            <el-date-picker
              v-model="createdTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
            >
            </el-date-picker>
          </div>
          <!--<div class="leftItem">-->
            <!--<span class="lab_tit">加白号码</span>-->
            <!--<el-input-->
              <!--v-model="white_phone"-->
              <!--placeholder="请输入查询加白号码"-->
              <!--size="mini"-->
            <!--&gt;</el-input>-->
          <!--</div>-->
        </div>
        <div class="right">
          <!-- <el-button type="primary" icon="el-icon-refresh" @click="white_phone_top()" round size="mini">查询
                    </el-button> -->
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            round
            size="mini"
            >重置
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            round
            size="mini"
            >查找
          </el-button>

          <el-button type="primary" round size="mini" @click="whitening2()"
            >加白数据库</el-button
          >
          <el-button type="primary" round size="mini" @click="whitening()"
            >加白</el-button
          >
          <el-button type="primary" round size="mini" @click="whitenings()"
            >批量加白</el-button
          >
          <el-button type="primary" round size="mini" @click="excel()"
            >导出</el-button
          >
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table
            :data="tableData"
            stripe
            height="calc(100% - 4rem)"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="userId" label="代理id"></el-table-column>
            <el-table-column prop="name" label="公司名称"></el-table-column>
            <el-table-column prop="phone" label="号码"></el-table-column>
            <el-table-column prop="phoneCity" label="城市"></el-table-column>
            <el-table-column prop="realname" label="姓名"></el-table-column>
            <!-- 身份证号 临时查看使用 -->
            <el-table-column
              prop="idcard"
              label="身份证号码"
              width="200"
            ></el-table-column>
            <el-table-column prop="appid" label="appid"></el-table-column>
            <el-table-column label="通道"
              ><template #default="scope">{{
                $typeFilter(scope.row.type)
              }}</template></el-table-column
            >
            <el-table-column prop="seatPhoneId" label="密钥"></el-table-column>
            <el-table-column
              prop="createdTime"
              label="创建时间"
            ></el-table-column>
            <el-table-column prop="status" label="号码状态">
              <template #default="scope">
                <span v-if="scope.row.status == 1">正常</span>
                <span v-if="scope.row.status == 2">退订</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="changeTime"
              label="退订时间"
            ></el-table-column>
            <el-table-column
              label="操作"
              width="100"
              align="center"
              style="dispaly: flex"
            >
              <template #default="scope">
                <el-button
                  type="primary"
                  @click="unsubscribe(scope)"
                  size="mini"
                  >退订</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="mainTableFY">
            <el-pagination
              background
              layout="total, prev, pager, next,sizes"
              :current-page="pageIndex"
              :page-size="pageSize"
              :page-sizes="[10, 20, 50, 100, 200]"
              :total="pageTotal"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 加白弹出框 -->
    <el-dialog title="加白" v-model="whiteVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="加白号码">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="form.realname"></el-input>
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="form.idcard"></el-input>
        </el-form-item>
        <el-form-item label="坐席id">
          <el-input v-model="form.seatPhoneId"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="whiteVisible = false">取 消</el-button>
          <el-button type="primary" @click="whiteSubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 加白弹出框 -->
    <el-dialog title="加白数据库" v-model="whiteVisible2" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="appid">
          <el-input v-model="form.appid"></el-input>
        </el-form-item>
        <el-form-item label="加白号码">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名">
          <el-input v-model="form.realname"></el-input>
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="form.idcard"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="whiteVisible2 = false">取 消</el-button>
          <el-button type="primary" @click="whiteSubmit2">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 批量加白弹出框 -->
    <el-dialog title="批量加白" v-model="numShow" width="30%">
      <div class="content">
        <el-button type="success" icon="el-icon-download" @click="upload()"
          >点击下载模板
        </el-button>
        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
          width="100%"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="numsSubmit">确认提交</el-button>
          <el-button @click="numShow = false">退 出</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "centerNum",
  data() {
    return {
      name: null,
      phoneCity: null,
      white_phone: null, //查询号码是否加白成功
      form: {},
      numShow: false,
      whiteVisible: false,
      whiteVisible2: false,
      phone: null,
      appid: null,
      seatPhoneId: null,
      userId: null,
      createdTime: null,
      changeTime: null,
      status: null,
      activePhone: "", //搜索框主叫
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      pageTotal: 0, //总共有多少数据
      tableData: [], //用于接收后端数据表的数组
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      sta: null,
      end: null,
      options: [
        {
          status: 1,
          label: "正常",
        },
        {
          status: 2,
          label: "退订",
        },
      ],
    };
  },

  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  inject: ["reload"],
  methods: {
    //查询号码是否加白成功
    white_phone_top() {
      let params = {
        phone: this.white_phone,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD/findOne-top", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message + res.data.data);

            this.getList();
          } else {
            this.$message.error(res.data.message + res.data.data);
          }
        })
        .catch((err) => {});
    },
    //下载模板
    upload() {
      window.open("http://paas.cddmt.cn:9005/white-d.xls");
    },

    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定释放 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
    },
    // 批量加白
    whitenings() {
      this.numShow = true;
      this.formDate = null;
    },
    numsSubmit() {
      this.numShow = false;
      let formDate = new FormData();
      this.$message.success("数据加载中，稍等！请勿离开页面");
      formDate.append("file", this.file);
      axios
        // .post("http://106.14.0.130:9998/vo/call/send/import_white_d", formDate)
        .post("api/call/send/import_white_d", formDate)
        .then((res) => {
          // console.log(res.data);
          if (res.data.statusCode == "000000") {
            this.$message.success(res.data.message);
            this.file = {};
          } else {
            this.$message.error(res.data.message);
            this.file = {};
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    // 加白
    whitening() {
      this.whiteVisible = true;
      this.form = {};
    },

    // 加白
    whitening2() {
      this.whiteVisible2 = true;
      this.form = {};
    },
    whiteSubmit() {
      let params = {
        appid: this.form.appid,
        phone: this.form.phone,
        realname: this.form.realname,
        idcard: this.form.idcard,
        seatPhoneId: this.form.seatPhoneId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/call/add-white-d", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.whiteVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message + res.data.data);
          }
        })
        .catch((err) => {});
    },

    whiteSubmit2() {
      let params = {
        appid: this.form.appid,
        phone: this.form.phone,
        realname: this.form.realname,
        idcard: this.form.idcard,
        seatPhoneId: this.form.seatPhoneId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.whiteVisible2 = false;
            this.getList();
          } else {
            this.$message.error(res.data.message + res.data.data);
          }
        })
        .catch((err) => {});
    },

    //导出
    excel() {
      if (this.createdTime) {
        this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      let params = {
        sta: this.sta1,
        end: this.end1,
      };
      // console.log(params)

      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/whitePhoneD/export-whitephoneD", params, {
          responseType: "blob",
        })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers["content-disposition"];

          a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);

          a.href = url;

          a.click();
        });
      this.reload();
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 搜索
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //展示and查找
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        phone: this.phone,
        name: this.name,
        phoneCity: this.phoneCity,
        appid: this.appid,
        userId: this.userId,
        status: this.status,
        sta: this.sta,
        end: this.end,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/list", params)
        .post("api/whitePhoneD/list", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {});
    },
    //退订按钮
    unsubscribe(row) {
      this.$confirm("请确定是否退订?" + row.row.phone, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          let params = {
            id: row.row.id,
            phone: row.row.phone,
          };
          axios
            // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
            .post("api/whitePhoneD/update", params)
            .then((res) => {
              this.$message.success(res.data.message);
            })
            .catch((err) => {});
          this.reload();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //刷新按钮
    reset() {
      this.phone = null;
      this.name = null;
      this.phoneCity = null;
      this.appid = null;
      this.userId = null;
      this.status = null;
      this.createdTime = null;
      this.white_phone = null;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .leftItem {
      width: 184px;
    }

    .left {
      width: 750px;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.upload-demo {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

// .left {
//     display: flex;
//     margin: 20px 0;
// }

// .item {
//     display: flex;
//     align-items: center;
// }

// .left span {
//     text-align: right;
//     margin-right: 5px;
// }

// .right {
//     text-align: right;
// }

// .reset {
//     width: 80px;
//     height: 34px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
// }

// .add {
//     margin-left: 15px;
// }

.container {
  // margin-top: 20px;
}

/* /deep/.el-form-item__label{
          font-size: 13px  !important;
        } */
</style>
