<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="企业管理"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="left">
          <div class="leftItem">
            <span class="lab_tit">名称</span>
            <el-input size="mini" v-model="name" placeholder="请输入子企业名称" class="handle-input mr10"
              v-on:keyup.enter="handleSearch"></el-input>
          </div>
          <div class="leftItem">
            <span class="lab_tit">appid</span>
            <el-input size="mini" v-model="appid" placeholder="请输入appid" class="handle-input mr20"
              v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <div class="leftItem" v-if="realname == 'admin'">
            <span class="lab_tit">选择通道</span>
            <el-select size="mini" v-model="paramTpye" placeholder="请选择通道">
              <el-option v-for="item in options1" :key="item.type" :label="item.label1" :value="item.type">
              </el-option>
            </el-select>
          </div>

          <div class="leftItem">
            <span class="lab_tit">锁定状态</span>
            <el-select size="mini" v-model="status" placeholder="请选择锁定状态">
              <el-option v-for="item in options4" :key="item.type" :label="item.label1" :value="item.type">
              </el-option>
            </el-select>
          </div>

          <div class="leftItem" v-if="(realname == 'admin' || realname == '客服-xn')">
            <span class="lab_tit">代理编号</span>
            <el-input size="mini" v-model="userId" placeholder="请输入代理编号" class="handle-input mr10"
              v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <div class="leftItem" v-if="realname == 'admin'">
            <span class="lab_tit">子企编号</span>
            <el-input size="mini" v-model="id" placeholder="请输入子企业编号" class="handle-input mr10"
              v-on:keyup.enter="handleSearch"></el-input>
          </div>
          <div class="leftItem" v-if="realname == 'admin'">
            <span class="lab_tit">密钥搜索</span>
            <el-input size="mini" v-model="paramId" placeholder="请输入子密钥" class="handle-input mr10"
              v-on:keyup.enter="handleSearch"></el-input>
          </div>

          <div class="leftItem">
            <span v-if="realname == 'admin'" class="lab_tit">行业</span>
            <el-input size="mini" v-model="industry" placeholder="请输入行业" class="handle-input mr10"
              v-on:keyup.enter="handleSearch" v-if="realname == 'admin'"></el-input>
          </div>
        </div>

        <div class="right">
          <el-button round size="mini" type="primary" icon="el-icon-search" @click="handleSearch">查找
          </el-button>
          <el-button round size="mini" type="primary" icon="el-icon-refresh" @click="reset">重置
          </el-button>
          <el-button round size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="open()"
            v-if="realname == 'admin'">添加
          </el-button>
          <el-button round size="mini" type="danger" icon="el-icon-lock" @click="openBatchDaing"
            v-if="realname == 'admin'">批量锁定/解锁
          </el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-table :data="tableData" stripe height="calc(100% - 1rem)" ref="multipleTable" @sort-change="changeSort"
            header-cell-class-name="table-header" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="50"></el-table-column>
            <el-table-column prop="userId" width="100" label="代理编号"
              v-if="(realname == 'admin' || realname == '客服-xn')"></el-table-column>
            <el-table-column prop="id" width="100" label="子企业编号"
              v-if="(realname == 'admin' || realname == '客服-xn')"></el-table-column>
            <el-table-column prop="name" width="300" column-key="" label="子企业名称"
              v-if="realname == 'admin'"></el-table-column>
            <el-table-column prop="name" align="center" label="子企业名称" v-else></el-table-column>
            <el-table-column prop="appid" width="310" label="appid" v-if="realname == 'admin'"></el-table-column>

            <el-table-column prop="money" width="100" label="余额" sortable="custom"></el-table-column>
            <el-table-column prop="seatMoney" width="100" label="套餐余额"></el-table-column>

            <el-table-column prop="rate" width="80" label="费率"></el-table-column>
            <el-table-column prop="type" width="100" label="通道类型" v-if="(realname == 'admin' || realname == '客服-xn')">
              <template #default="scope">
                <span v-if="scope.row.type == 0">隐私小号通道</span>
                <span v-if="scope.row.type == 1">南京线</span>
                <span v-if="scope.row.type == 2">电信</span>
                <span v-if="scope.row.type == 3">联通2</span>
                <span v-if="scope.row.type == 4">联通1</span>
                <span v-if="scope.row.type == 5">移动ax</span>
                <span v-if="scope.row.type == 6">联通3</span>
                <span v-if="scope.row.type == 7">联通4</span>
                <span v-if="scope.row.type == 8">H</span>
                <span v-if="scope.row.type == 9">联通5</span>
                <span v-if="scope.row.type == 10">移动3</span>
                <span v-if="scope.row.type == 11">联通6</span>
                <span v-if="scope.row.type == 12">联通7</span>
                <span v-if="scope.row.type == 13">移动5</span>
                <span v-if="scope.row.type == 14">S</span>
                <span v-if="scope.row.type == 15">D</span>
                <span v-if="scope.row.type == 16">S2</span>
                <span v-if="scope.row.type == 17">SIP</span>
                <span v-if="scope.row.type == 18">X</span>
                <span v-if="scope.row.type == 20">6号线</span>
                <span v-if="scope.row.type == 21">2号线</span>
                <span v-if="scope.row.type == 22">3号线</span>
                <span v-if="scope.row.type == 23">新2号线</span>
                <span v-if="scope.row.type == 24">重庆线路</span>
                <span v-if="scope.row.type == 25">1号线</span>
                <span v-if="scope.row.type == 26">无锡</span>
                <span v-if="scope.row.type == 27">翼信</span>
                <span v-if="scope.row.type == 28">连云港YT</span>
              </template>
            </el-table-column>

            <el-table-column prop="type" width="100" label="状态" v-if="realname != 'admin'">
              <template #default="scope">
                <span v-if="scope.row.status == '0' && realname != 'admin'">正常</span>
                <span v-if="scope.row.status == '1' && realname != 'admin'">锁定</span>
              </template>
            </el-table-column>

            <el-table-column prop="paramId" label="配置密钥" width="120" v-if="realname == 'admin'"></el-table-column>
            <el-table-column prop="industry" label="行业" v-if="realname == 'admin'"></el-table-column>

            <!-- <el-table-column  label="账户余额">
                    <template #default="scope">￥{{ scope.row.money }}</template>
                </el-table-column> -->

            <el-table-column label="操作" width="340" align="center" fixed="right" style="dispaly: flex">
              <template #default="scope">
                <!-- <el-row :gutter="20">
                          <el-col :span="6"> </el-col>
                          <el-col :span="6"> </el-col>
                          <el-col :span="6"> </el-col>
                          <el-col :span="6"> </el-col>
                        </el-row> -->
                <div class="operateColumn">
                  <!--<el-button type="primary" size="mini" @click="recharge(scope)"-->
                  <!--&gt;划拨-->
                  <!--</el-button>-->
                  <el-button type="primary" size="mini" @click="openLock(scope.row)"
                    v-if="scope.row.status == '0' && (realname == 'admin' || realname == '客服-xn')">锁定
                  </el-button>
                  <el-button type="danger" size="mini" @click="openLock(scope.row)"
                    v-if="scope.row.status == '1' && (realname == 'admin' || realname == '客服-xn')">解锁
                  </el-button>
                  <el-button type="primary" size="mini" @click="handleClick(scope)"
                    v-if="(realname == 'admin' || realname == '客服-xn')">编辑
                  </el-button>
                  <el-button type="primary" size="mini" @click="openOperate(scope)">操作
                  </el-button>

                  <!--<el-button type="primary" size="mini" @click="details(scope)"-->
                  <!--&gt;详情</el-button-->
                  <!--&gt;-->

                  <!-- <el-button type="primary" @click="opensay(scope)" v-if="realname == 'admin'">话术
                            </el-button>
                            <el-button type="primary" @click="middleNum(scope)">中间号
                            </el-button>
                            <el-button type="primary" :plain="false" @click="appidShow(scope)"
                                v-if="realname != 'admin'">appid
                            </el-button>

                            <el-button type="primary" @click="addDanger(scope)" v-if="realname == 'admin'">添加高危
                            </el-button>

                            
                            <el-button @click="drawer_seatPhone(scope)" type="primary">
                                查询坐席
                            </el-button>
                            <el-button @click="uuid_forward(scope)" type="primary" style="margin-left: 16px"
                                v-if="realname == 'admin'">
                                双路由
                            </el-button>
                            <el-button @click="operation_log(scope)" type="primary" v-if="realname == 'admin'">
                                操作日志
                            </el-button>
                            <el-button @click="lockLog(scope.row)" type="primary" v-if="realname == 'admin'">
                                锁定日志
                            </el-button>
                            <el-button @click="uuid_stop(scope)" type="primary" v-if="realname == 'admin'">
                                定时报停
                            </el-button>
                            <el-button @click="uuid_custom(scope.row)" type="primary" v-if="realname == 'admin'">
                                添加行业
                            </el-button>
                            <el-button @click="send_saas(scope.row)" type="primary" v-if="realname == 'admin'">
                                同步saas
                            </el-button> -->
                </div>
                <!-- </div> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="mainTableFY">
          <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
            :page-size="pageSize" :total="pageTotal" :page-sizes="[10, 20, 50, 100, 200]"
            @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="50%" @closed="resetForm" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-width="130px" :rules="formRules">
        <el-form-item label="代理编号" prop="userId">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>
        <el-form-item label="子企业名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="余额" prop="money">
          <el-input v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="收费模式">
          <el-select v-model="form.chargeMode" placeholder="请选择收费模式">
            <el-option v-for="item in options" :key="item.chargeMode" :label="item.label" :value="item.chargeMode">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="通道类型">
          <el-select v-model="form.type" placeholder="请选择通道类型">
            <el-option v-for="item in options1" :key="item.type" :label="item.label1" :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配置密钥">
          <el-select v-model="form.paramId" placeholder="请选择配置密钥">
            <el-option v-for="item in options2" :key="item.type" :label="item.appid" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="限制坐席">
          <el-select v-model="form.seatPass" placeholder="请选择是否限制">
            <el-option v-for="item in options3" :key="item.value3" :label="item.label3" :value="item.value3">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="费率" prop="rate">
          <el-select v-model="form.rate" placeholder="请选择费率" filterable>
            <el-option v-for="item in RateList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="月租" prop="seatRate">
          <el-select v-model="form.seatRate" placeholder="请选择月租" filterable>
            <el-option v-for="item in PackageList" :key="item.id" :label="item.lable" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="套餐外费率" prop="seatRateW">
          <el-select v-model="form.seatRateW" placeholder="请选择套餐外费率" filterable>
            <el-option v-for="item in thaliList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
        <!-- <el-form-item label="月租">
                    <el-input-number v-model="form.seatRate" :precision="1" :step="0.1" :max="1000"></el-input-number>
                </el-form-item> -->
        <!-- <el-form-item label="费率2">
                    <el-input-number v-model="form.rate" :precision="3" :step="0.001" :max="11"></el-input-number>
                </el-form-item> -->

        <!-- <el-form-item label="套餐外费率">
                    <el-input-number v-model="form.seatRateW" :precision="3" :step="0.001" :max="11"></el-input-number>
                </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit()" :disabled="rechargeBtn">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" @closed="resetForm" :close-on-click-modal="false">
      <el-form ref="dataAddForm" :model="form" :rules="editRules" label-width="100px">
        <el-form-item label="代理编号" prop="userId">
          <el-input v-model="form.userId"></el-input>
        </el-form-item>

        <el-form-item label="子企业名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>

        <el-form-item label="收费模式">
          <el-select v-model="form.chargeMode" placeholder="请选择收费模式">
            <el-option v-for="item in options" :key="item.chargeMode" :label="item.label" :value="item.chargeMode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通道类型">
          <el-select v-model="form.type" placeholder="请选择通道类型">
            <el-option v-for="item in options1" :key="item.type" @change="changeType" :label="item.label1"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配置密钥">
          <el-select v-model="form.paramId" filterable placeholder="请选择配置密钥">
            <el-option v-for="item in options2" :key="item.type" :label="item.appid" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="限制坐席">
          <el-select v-model="form.seatPass" placeholder="请选择是否限制">
            <el-option v-for="item in options3" :key="item.value3" :label="item.label3" :value="item.value3">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="费率">
          <el-select v-model="form.rate" placeholder="请选择费率" filterable>
            <el-option v-for="item in RateList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="月租">
          <el-select v-model="form.seatRate" placeholder="请选择月租" filterable>
            <el-option v-for="item in PackageList" :key="item.id" :label="item.lable" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="套餐外费率">
          <el-select v-model="form.seatRateW" placeholder="请选择套餐外费率" filterable>
            <el-option v-for="item in thaliList" :key="item.value" :label="item.value" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="费率">
                    <el-input-number
                            v-model="form.rate"
                            :precision="3"
                            :step="0.001"
                            :max="1"
                    ></el-input-number>
                </el-form-item> -->

        <el-form-item label="行业" prop="industry">
          <el-input v-model="form.industry"></el-input>
        </el-form-item>
        <el-form-item label="质检时间" prop="qualityTime">
          <el-input v-model="form.qualityTime"></el-input>
        </el-form-item>
        <!-- <el-form-item label="月租">
                    <el-input-number v-model="form.seatRate" :precision="1" :step="0.1" :max="99999"></el-input-number>
                </el-form-item> -->

        <!-- <el-form-item label="套餐外费率">
                    <el-input-number v-model="form.seatRateW" :precision="3" :step="0.001" :max="1"></el-input-number>
                </el-form-item> -->
        <el-form-item label="拨打时间限制">
          <!-- <el-switch v-model="form.userTime" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
                        :inactive-value="0" @change="changeSwitch"></el-switch> -->
          <div style="display: flex">
            <el-radio v-model="form.userTime" :label="0" border>不限制</el-radio>
            <el-radio v-model="form.userTime" :label="1" border>09:00-12:00，13:30-21:00</el-radio>
            <!-- 2、09:00:00-12:00:00,13:30:00-22:00:00 -->
            <!-- 3、08:00:00-12:00:00,13:30:00-22:00:00 -->
            <!-- 4、09:00:00-12:00:00,12:00:00-22:00:00 -->
          </div>
          <div style="display: flex; margin-top: 10px">
            <el-radio v-model="form.userTime" :label="2" border>08:00-12:00，13:30-22:00</el-radio>
            <el-radio v-model="form.userTime" :label="3" border>08:00-22:00</el-radio>
            <el-radio v-model="form.userTime" :label="4" border>09:30-12:00，13:30-18:00</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="短信开关限制">
          <el-switch v-model="form.seatAdd" active-color="#13ce66" inactive-color="#ff4949" :active-value="1"
            :inactive-value="0" @change="changeSwitch_sms"></el-switch>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 批量解锁或锁定弹框 -->
    <el-dialog title="批量解锁或锁定" v-model="batchLockShow">
      <el-form label-width="80px">
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="warning" @click="batchLock(0)">解 锁</el-button>
          <el-button type="primary" @click="batchLock(1)" :disabled="rechargeBtn">锁 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 单个解锁或锁定弹框 -->
    <el-dialog v-model="oneLockShow">
      <template #title>
        <div class="lockBox">
          解锁或锁定<span style="font-size: 14px; font-weight: bold">(当前企业坐席个数为<span style="color: #f56c6c">{{
            currentRow.seatCount || 0
          }}</span>)</span>
        </div>
      </template>
      <el-form label-width="80px">
        <!-- <b style="color: red">当前企业坐席个数为：{{currentRow.seatCount}}</b> -->
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="warning" v-if="currentRow.status == 1" @click="lock">解 锁</el-button>
          <el-button type="primary" v-else @click="lock">锁 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 套餐转移弹框 -->
    <el-dialog title="套餐转移" v-model="PackageTransferShow" width="30%" @closed="(addForm = {}), (transferType = '退还套餐')">
      <el-form ref="dataAddForm" :rules="addMoneyRules" label-width="80px">
        <p style="margin: 10px">
          余额
          <span style="font-weight: bold; color: #409eff" v-if="currentForm.row.money > 0">{{ currentForm.row.money
          }}</span>
          <span style="font-weight: bold; color: #f56c6c" v-else>{{
            currentForm.row.money
          }}</span>
        </p>
        <el-form-item label="转移金额">
          <el-input-number v-model="addForm.money" :precision="3" :step="1.0" :min="0">
          </el-input-number>
        </el-form-item>
        <el-form-item label="套餐设置">
          <!-- 到账账户 -->
          <el-radio-group v-model="transferType">
            <el-radio label="退还套餐">退还套餐</el-radio>
            <el-radio label="充值套餐">充值套餐</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="addForm.remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="PackageTransferShow = false">取 消</el-button>
          <!-- <el-button type="warning" @click="rechargeSubmit('扣款')" :disabled="rechargeBtn">扣款</el-button> -->
          <el-button type="primary" @click="transferSubmit" v-if="addForm.money">确定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 划拨弹框 -->
    <el-dialog title="划拨" v-model="addMoney" width="30%" @closed="resetForm">
      <el-form ref="dataAddForm" :rules="addMoneyRules" label-width="80px">
        <p style="margin: 10px 0">
          当前余额为
          <span style="font-weight: bold; color: #409eff" v-if="showformAdd > 0">{{ showformAdd }}</span>
          <span style="font-weight: bold; color: #f56c6c" v-else>{{
            showformAdd
          }}</span>
        </p>
        <el-form-item label="划拨">
          <el-input-number v-model="formAdd" :precision="3" :step="1.0" :min="0">
          </el-input-number>
        </el-form-item>
        <el-form-item label="充值/扣费">
          <!-- 到账账户 -->
          <el-radio-group v-model="rechargeValue">
            <el-radio label="充值">充值</el-radio>
            <el-radio label="扣费">扣费</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="remarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addMoney = false">取 消</el-button>
          <!-- <el-button type="warning" @click="rechargeSubmit('扣款')" :disabled="rechargeBtn">扣款</el-button> -->
          <el-button type="primary" @click="rechargeSubmit" v-if="formAdd != 0">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 话术 -->
    <el-dialog title="话术" v-model="sayShow">
      <el-form ref="dataAddForm" :model="form" :rules="sayRules" label-width="80px">
        <el-form-item label="话术">
          <el-input v-model="form.say" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="sayShow = false">取 消</el-button>
          <el-button type="primary" @click="submitTalk()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 中间号 -->
    <el-dialog :title="'中间号数量:' + middleCount" v-model="middleNumShow">
      <span style="font-size: 18px">中间号单价:</span>
      <el-input-number v-model="middleNumPrice" :precision="2" :step="1" :max="50" :min="0"></el-input-number>
      <el-input v-model="middleNums" type="textarea" :disabled="realname != 'admin'"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="middleNumShow = false">取 消</el-button>
          <el-button type="primary" @click="submitMiddleNum()" v-if="realname == 'admin'">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!--企业坐席 选定购买号码 -->
    <el-drawer title="企业坐席" v-model="drawer" :append-to-body="true" :before-close="handleClose">
      <div>
        <el-button style="background-color: #c7c4b5" @click="drawer_in = true">查看已选定名单
        </el-button>
        <el-drawer title="选定购买号码" :append-to-body="true" v-model="drawer_in" :direction="direction"
          :before-close="handleClose">
          <el-button type="primary" @click="allBuy()">批 量 购 买--当前选定号码个数：{{ rechargeRecordList.size }}
          </el-button>
          <!--<p v-for="(v,i) in rechargeRecordList">_(:зゝ∠)_</p>-->
          <div style="overflow-y: scroll; height: 150px">
            <div v-for="(v, i) in rechargeRecordList">
              <div style="
                  border-radius: 30px;
                  margin: 2px;
                  background-color: #a0ffff;
                ">
                <span style="font-size: 30px"> 姓名：</span>
                <span v-for="(a, i) in v">
                  <span style="color: red; font-size: 30px">{{ a.name }}</span>
                </span>
                <span style="font-size: 30px"> 号码：</span>
                <span v-for="(a, i) in v">
                  <span style="color: red; font-size: 30px; border-radius: 30px">{{ a.activePhone }}</span>
                </span>
              </div>
            </div>
          </div>
        </el-drawer>
      </div>
      <!--:before-close="handleClose"-->

      <el-table :data="rechargeRecord" style="width: 80%; text-align: center" height="80%"
        :row-class-name="tableRowClassName" @cell-dblclick="ellDblclick">
        <el-table-column property="name" label="姓名" width="200"></el-table-column>
        <el-table-column property="activePhone" label="号码" width="150"></el-table-column>
        <el-table-column property="seatMoney" label="坐席金额"></el-table-column>
      </el-table>
      <el-pagination @current-change="handlePageChange_seatPhone" :current-page="pageIndexDw" :page-size="pageSizeDw"
        layout="total, prev, pager, next" :total="pageTotalDw">
      </el-pagination>
    </el-drawer>

    <!-- 批量购买弹框 -->
    <el-dialog title="批量购买" v-model="allBuyVisible" width="30%">
      <!--<span>确定批量购买套餐包(不选则默认50套餐)？购买后当月使用，次月清零</span>-->
      <span>确定批量购买套餐包？购买后当月使用，次月清零</span>
      <template #footer>
        <span class="dialog-footer">
          <span>选择套餐</span>
          <el-select v-model="buySeatPhoneMoney" placeholder="请选择套餐" class="handle-input mr5">
            <el-option v-for="item in options5" :key="item.buySeatPhoneMoney" :label="item.label"
              :value="item.buySeatPhoneMoney">
            </el-option>
          </el-select>
          <el-button type="primary" @click="allBuyVisible = false">取 消</el-button>
          <el-button type="primary" @click="allBuySubmit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 转发线路-->
    <el-dialog title="双路由" v-model="callForwardFlag" width="30%">
      <el-form ref="dataAddForm" :model="form" :rules="callForward_form" label-width="100px">
        <el-form-item label="通道类型">
          <el-select v-model="callForward_form.type" placeholder="请选择通道类型">
            <el-option v-for="item in options1" :key="item.type" @change="changeType" :label="item.label1"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="配置密钥">
          <el-select v-model="callForward_form.paramId" filterable placeholder="请选择配置密钥">
            <el-option v-for="item in options2" :key="item.type" :label="item.appid" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="行业" prop="industry">
          <el-input v-model="callForward_form.industry"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="danger" @click="uuid_forward_del()">删除</el-button>
          <el-button @click="callForwardFlag = false">取 消</el-button>
          <el-button type="primary" @click="uuid_forward_add()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!--操作日志 -->
    <el-drawer title="企业操作日志" v-model="drawer_operation_log" :append-to-body="true" :before-close="handleClose">
      <el-table :data="operationLogRecord" style="width: 100%; text-align: center" height="80%"
        :row-class-name="tableRowClassName" @cell-dblclick="ellDblclick">
        <el-table-column property="updateUserName" label="操作人名字" width="100"></el-table-column>
        <el-table-column property="remarke" label="详细" width="300"></el-table-column>
        <el-table-column property="createTime" label="操作时间"></el-table-column>
      </el-table>
      <el-pagination @current-change="handlePageChange_operation_log" :current-page="pageIndex_peration_log"
        :page-size="pageSize_peration_log" layout="total, prev, pager, next" :total="pageTotal_peration_log">
      </el-pagination>
    </el-drawer>

    <!--锁定日志 -->
    <el-drawer title="锁定日志" v-model="drawer_lock_log" :append-to-body="true">
      <el-table :data="lockList" style="width: 100%; text-align: center">
        <el-table-column property="userId" label="代理ID"></el-table-column>
        <el-table-column property="name" label="企业名称" width="200"></el-table-column>
        <el-table-column property="remarks" label="备注"></el-table-column>
        <el-table-column property="createTime" label="操作时间" width="200"></el-table-column>

        <el-table-column label="操作类型">
          <template #default="scope">
            {{ scope.row.type == 0 ? "解锁" : "锁定" }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination background layout="total, prev, pager, next,sizes,jumper" :current-page="page1" :page-size="size"
        :total="pageTotal" @current-change="handleToPageChange" @size-change="handleToSizeChange"></el-pagination>
    </el-drawer>

    <!-- 定时报停-->
    <el-dialog title="新增定时报停" v-model="uuidStopFlag" width="50%">
      <el-form ref="dataAddForm" :model="uuidstopform" label-width="100px">
        <el-form-item label="代理id" prop="userId">
          <el-input v-model="uuidstopform.userId" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业id" prop="uuid">
          <el-input v-model="uuidstopform.uuid" disabled></el-input>
        </el-form-item>

        <el-form-item label="appid" prop="appid">
          <el-input v-model="uuidstopform.appid" disabled></el-input>
        </el-form-item>
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="uuidstopform.name" disabled></el-input>
        </el-form-item>

        <el-form-item label="报停时间" prop="uuidStopTime">
          <el-date-picker v-model="uuidStopTime" type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报停原因" prop="remark">
          <el-input v-model="remark"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uuidStopFlag = false">取 消</el-button>
          <el-button type="primary" @click="uuid_stop_add()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加行业弹出框-->
    <el-dialog title="添加行业" v-model="uuid_customFlag" width="50%" @close="resetName">
      <el-form ref="dataAddForm" :model="uuidstopform" label-width="100px">
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="name" disabled></el-input>
        </el-form-item>

        <el-form-item label="选择行业">
          <!-- <el-select v-model="uuid_customList.customId" filterable placeholder="请选择行业">
                        <el-option v-for="item in uuid_custom_options" :key="item.customId" :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select> -->
          <el-select v-model="uuid_customList.customId" filterable remote reserve-keyword placeholder="请选择行业"
            remote-show-suffix :remote-method="getStates" :loading="loading">
            <el-option v-for="item in uuid_custom_options" :key="item.customId" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="uuid_customFlag = false">取 消</el-button>
          <el-button type="primary" @click="uuid_custom_add()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 话术列表弹出框 -->
    <el-dialog class="talkDialog" v-model="allNumShow" width="60%" @close="">
      <!-- 话术列表 -->
      <div class="talkTop">
        <h3 class="talkToptitle">话术列表</h3>
        <el-button @click="openTalkDialog('新增')" type="primary" size="mini"
          icon="el-icon-circle-plus-outline">新增话术</el-button>
      </div>
      <div class="box11" style="width: 100%">
        <div style="display: flex">
          <el-table :data="queryCustomerData" border ref="multipleTable" height="400"
            header-cell-class-name="table-header">
            <el-table-column label="话术">
              <template #default="scope">{{ scope.row.talk }}</template>
            </el-table-column>
            <el-table-column label="创建时间" width="200">
              <template #default="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column label="操作" width="180" align="center" style="dispaly: flex">
              <template #default="scope">
                <el-button type="primary" icon="el-icon-edit" size="mini"
                  @click="openTalkDialog('编辑', scope.row)">编辑</el-button>
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="delTalk(scope.row)">删除</el-button>
                <!-- <el-popconfirm confirm-button-text='关注' cancel-button-text='点错了' icon="el-icon-info"
                                    @confirm="" icon-color="green" title="确定要关注该客户吗？">
                                    <el-button slot="reference" size="mini" round>+ 关注</el-button>
                                </el-popconfirm> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination background layout="total, prev, pager, next" :current-page="talkObj.page"
            :page-size="talkObj.size" :total="talkObj.total" @current-change="talkPageChange"></el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 话术 -->
    <el-dialog :title="talktype + '话术'" v-model="talkShow" @close="talk = ''">
      <el-input type="textarea" class="textareaMinHeight" v-model="talk" show-word-limit size="mini" placeholder="话术内容"
        maxlength="2000"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="saveTalk">保 存</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 操作弹框 -->
    <el-dialog title="企业设置" v-model="operateVisible" width="70%" @close="">
      <div class="edit-box">
        <el-button @click="openPackageTransfer(currentForm.row)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          套餐转移<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="openAddSay(currentForm.row)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          添加话术<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="opensay(currentForm)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          话术<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="middleNum(currentForm)" size="medium" style="margin-bottom: 20px">
          中间号<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="appidShow(currentForm)" size="medium" style="margin-bottom: 20px" v-if="realname != 'admin'">
          appid<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="addDanger(currentForm)" size="medium" style="margin-bottom: 20px" v-if="realname == 'admin'">
          添加高危<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="drawer_seatPhone(currentForm)" size="medium" style="margin-bottom: 20px">
          查询坐席<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="uuid_forward(currentForm)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          双路由<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="operation_log(currentForm)" size="medium" style="margin-bottom: 20px"
          v-if="realname == 'admin'">
          操作日志<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="lockLog(currentForm.row)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          锁定日志<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="uuid_stop(currentForm)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          定时报停<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="uuid_custom(currentForm.row)" size="medium" style="margin-bottom: 20px"
          v-if="(realname == 'admin' || realname == '客服-xn')">
          添加行业<i class="el-icon-arrow-right el-icon--right"></i></el-button>
        <el-button @click="send_saas(currentForm.row)" size="medium" style="margin-bottom: 20px"
          v-if="realname == 'admin'">
          同步saas<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
import axios from "axios";
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
const debounce = (function () {
  let timer = 0;
  return function (callback, ms = 200) {
    //设置默认200ms
    clearTimeout(timer);
    timer = setTimeout(callback, ms);
  };
})();

export default defineComponent({
  //appid弹出消息
  setup() {
    return {
      appidShow(row) {
        //  // console.log(row.row.appid)
        ElMessage({
          message: row.row.appid,
          duration: 3000, //显示时间，毫秒，为0时不消失
          showClose: true, //是否显示'×'
          type: "success", //成功的样式
        });
      },
    };
  },

  name: "enterprises",
  data() {
    return {
      transferType: "退还套餐",
      PackageTransferShow: false,
      rechargeValue: "充值",
      talktype: null,
      talkShow: false,
      queryParmas: {},
      queryCustomerData: [],
      allNumShow: false,
      operateVisible: false,
      currentForm: {},
      loading: false,
      oneLockShow: false,
      currentRow: {},
      batchLockShow: false,
      remark: null,
      //验证规则
      formRules: {
        seatRate: [
          { required: true, message: "请选择套餐月租", trigger: "blur" },
        ],
        rate: [{ required: true, message: "请选择费率", trigger: "blur" }],
        seatRateW: [
          { required: true, message: "请选择套餐外费率", trigger: "blur" },
        ],
      },
      addForm: {},
      uuid_custom_options: [], //添加行业数据
      uuid_customList: {
        customId: "",
      }, //添加行业数据
      uuid_customFlag: false, //添加行业弹出框
      uuidStopFlag: false,
      uuidStopTime: null,
      uuidStopform: {}, //定时报停 数据
      drawer_operation_log: false, //查看操作日志弹出框
      drawer_lock_log: false,
      pageTotal_peration_log: 0, //操作日志总条数
      pageIndex_peration_log: 1, //操作日志首页
      pageSize_peration_log: 10, //操作日志每页条数
      operationLogRecord: [], //操作日志数据
      buySeatPhoneMoney: null,
      options5: [
        {
          buySeatPhoneMoney: "20",
          label: "套餐20",
        },
        {
          buySeatPhoneMoney: "50",
          label: "套餐50",
        },
      ],
      options: [
        {
          chargeMode: "0",
          label: "主叫呼叫开始计费",
        },
        {
          chargeMode: "1",
          label: "被叫应答计费",
        },
        {
          chargeMode: "2",
          label: "个人计费",
        },
        {
          chargeMode: "3",
          label: "个人计费-30天周期",
        },
        {
          chargeMode: "4",
          label: "代理计费",
        },
      ],
      chargeMode: "",

      options1: [
        // {
        //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
        //   type: 0,
        //   label1: "隐私小号通道",
        // },

        // {
        //     type: 5,
        //     label1: "移动ax通道",
        // },
        {
          type: 1,
          label1: "南京线通道",
        },
        {
          type: 2,
          label1: "移动1通道",
        },
        // {
        //     type: 10,
        //     label1: "移动3通道",
        // },

        // {
        //     type: 13,
        //     label1: "移动5通道",
        // },
        // {
        //   type: 3,
        //   label1: "联通2通道",
        // },
        // {
        //   type: 4,
        //   label1: "联通1通道",
        // },

        // {
        //   type: 6,
        //   label1: "联通3通道",
        // },
        // {
        //   type: 7,
        //   label1: "联通4通道",
        // },
        {
          type: 8,
          label1: "H",
        },
        {
          type: 14,
          label1: "S",
        },

        {
          type: 15,
          label1: "D",
        },

        // {
        //     type: 16,
        //     label1: "S2",
        // },

        {
          type: 17,
          label1: "SIP",
        },
        {
          type: 18,
          label1: "X",
        },
        // {
        //     type: 20,
        //     label1: "6号线",
        // },
        {
          type: 21,
          label1: "2号线",
        },
        {
          type: 22,
          label1: "3号线",
        },
        {
          type: 23,
          label1: "新2号线",
        },
        {
          type: 24,
          label1: "重庆线路",
        },
        {
          type: 25,
          label1: "1号线",
        },
        {
          type: 26,
          label1: "无锡",
        },
        {
          type: 27,
          label1: "翼信",
        },
        {
          type: 28,
          label1: "连云港YT",
        },
      ],
      options4: [
        {
          type: 0,
          label1: "未锁定",
        },
        {
          type: 1,
          label1: "已锁定",
        },
      ],
      options2: [],
      paramId: null,
      PackageList: [
        {
          id: 1,
          value: 200,
          lable: "200套餐",
        },
        {
          id: 2,
          value: 300,
          lable: "300套餐",
        },
        {
          id: 3,
          value: 360,
          lable: "360套餐",
        },
        {
          id: 4,
          value: 399,
          lable: "399套餐",
        },
        {
          id: 4.1,
          value: 270,
          lable: "270套餐",
        },
        {
          id: 4.2,
          value: 150,
          lable: "150套餐",
        },
        {
          id: 5,
          value: 100,
          lable: "100套餐",
        },
        {
          id: 5.1,
          value: 95,
          lable: "95套餐",
        },
        {
          id: 5.2,
          value: 90,
          lable: "90套餐",
        },
        {
          id: 5.3,
          value: 85,
          lable: "85套餐",
        },
        {
          id: 5.4,
          value: 80,
          lable: "80套餐",
        },
        {
          id: 5.5,
          value: 75,
          lable: "75套餐",
        },
        {
          id: 5.6,
          value: 70,
          lable: "70套餐",
        },
        {
          id: 5.7,
          value: 50,
          lable: "50套餐",
        },
        {
          id: 5.9,
          value: 48,
          lable: "48套餐",
        },
        {
          id: 5.8,
          value: 40,
          lable: "40套餐",
        },
        {
          id: 6,
          value: 1,
          lable: "1测试",
        },
      ],
      thaliList: [
        {
          value: 0.075,
        },
        {
          value: 0.08,
        },
        {
          value: 0.085,
        },
        {
          value: 0.09,
        },
        {
          value: 0.095,
        },
        {
          value: 0.1,
        },
        {
          value: 0.105,
        },
        {
          value: 0.11,
        },
        {
          value: 0.115,
        },
        {
          value: 0.12,
        },
        {
          value: 0.125,
        },
        {
          value: 0.13,
        },
        {
          value: 0.135,
        },
        {
          value: 0.14,
        },
        {
          value: 0.145,
        },
        {
          value: 0.15,
        },
        {
          value: 0.155,
        },
        {
          value: 0.16,
        },
        {
          value: 0.17,
        },
      ],
      RateList: [
        {
          value: 0.075,
        },
        {
          value: 0.08,
        },
        {
          value: 0.085,
        },
        {
          value: 0.09,
        },
        {
          value: 0.095,
        },
        {
          value: 0.1,
        },
        {
          value: 0.105,
        },
        {
          value: 0.11,
        },
        {
          value: 0.115,
        },
        {
          value: 0.12,
        },
        {
          value: 0.125,
        },
        {
          value: 0.13,
        },
        {
          value: 0.135,
        },
        {
          value: 0.14,
        },
        {
          value: 0.145,
        },
        {
          value: 0.15,
        },
        {
          value: 0.16,
        },
      ],
      options3: [
        {
          value3: 0,
          label3: "不使用坐席",
        },
        {
          value3: 1,
          label3: "使用坐席",
        },
      ],
      talkObj: {
        page: 1,
        size: 10,
        total: 0,
      },
      callForwardFlag: false, //转发线路弹出框
      callForward_form: [], //转发线路数据
      direction: "btt", //下向上
      drawer: false, //查看企业坐席
      drawer_in: false, //选定号码弹出
      allBuyVisible: false, //批量购买弹出框
      realname: "",
      type: "",
      rate: 0,
      currentRalkRow: {},
      seatRateW: 0,
      tableData: [],
      rechargeRecord: [], //坐席弹出数据
      rechargeRecordList: new Map(), //坐席弹出选定数据
      pageTotalDw: 0,
      sayShow: false, //是否显示话术编辑框
      middleNumShow: false, //是否显示中间号编辑框
      addMoney: false, //是否显示划拨弹窗
      addVisible: false, //是否显示添加弹窗
      editVisible: false, //是否显示编辑弹窗
      pageTotal: 0, //总共有多少数据
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      remarks: null, //备注（只传不回显）
      formAdd: "",
      appid: null,
      industry: null,
      userId: null,
      paramTpye: null,
      status: null,
      lockList: [],
      lockTotal: null,
      form: {
        userTime: 0,
        seatAdd: 1,
        id: "",
        name: "",
        money: "",
        type: "",
        rate: "",
        seatRateW: "",
        appid: "",
        seatPass: "", // 是否限制坐席0不使用坐席1使用
        seatRate: 0, // 月租
        chargeMode: "",
        industry: "",
        paramId: "",
        userId: "",
      },
      // middleNums:"0",
      editRules: {
        //校验规则
        name: [
          { required: true, message: "企业名称为必填项", trigger: "blur" },
        ],
        money: [{ required: true, message: "余额为必填项", trigger: "blur" }],
        type: [
          { required: true, message: "通道类型为必填项", trigger: "blur" },
        ],
        rate: [{ required: true, message: "费率为必填项", trigger: "blur" }],
        seatRateW: [
          { required: true, message: "费率为必填项", trigger: "blur" },
        ],
      },
      page: 1, //接口请求的页数
      page1: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      id: null, //搜索的id，如果为null，则显示全部数据
      name: null,
      title: "添加", //弹窗的标题
      btnText: "绑定",
      rechargeId: "",
      sortParmas: null,
      numsArray: [],
      rechargeMoney: 0,
      talkId: "",
      middleNums: "", //中间号数组
      middleCount: "", //中间号数量
      middleNumPrice: "", //中间号单价
      rechargeBtn: false,
      notMoney1: null,
      notMonth1: null,
      talk: "",
      showformAdd: 0,
    };
  },
  created() {
    // this.form.userId = this.$route.query.userId;
    this.userId = this.$route.query.userId;
    (this.realname = localStorage.getItem("realname")), this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    id() {
      if (this.id == "") {
        this.id = null;
      }
    },
    name() {
      if (this.name == "") {
        this.name = null;
      }
    },
    "form.type"(newValue, oldValue) {
      this.options2 = [];
      this.getData(newValue);
      this.form.paramId = "";
    },
    "callForward_form.type"(newValue, oldValue) {
      this.options2 = [];
      this.getData(newValue);
      // this.callForward_form.paramId = "";
    },
    middleCount() {
      if (this.middleCount == null) {
        this.middleCount == 0;
      }
    },
    middleNumPrice() {
      if (this.middleNumPrice == null) {
        this.middleNumPrice == 0;
      }
    },
  },
  methods: {
    // 自定义升序降序
    changeSort(column, prop, order) {
      // console.log("column", column);
      if (column.column) {
        // "order":"1", order  1 降序  2 升序
        // "sort":"1"
        // 升序    order: "ascending"
        // 降序    order: "descending"
        // 正常    order: null
        if (column.column.label == "余额") {
          this.sortParmas = { sort: "1" };
          if (column.order == "descending") {
            this.sortParmas.order = 2;
          } else if (column.order == "ascending") {
            this.sortParmas.order = 1;
          }
        }
      } else {
        this.sortParmas = null;
      }
      this.getList();
    },

    // 保存话术
    saveTalk() {
      if (this.talktype == "新增") {
        let params = {
          uuid: this.currentForm.row.id,
          talk: this.talk,
        };
        axios.post("api/uuidTalk/add", params).then((res) => {
          //  // console.log("添加时的res", res.data);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.openAddSay();
            this.talkShow = false;
          } else {
            this.$alert(res.data.message, "", {
              confirmButtonText: "确定",
              callback: (action) => { },
            });
          }
        });
      } else if (this.talktype == "编辑") {
        let params = {
          id: this.currentRalkRow.id,
          talk: this.talk,
        };
        axios.post("api/uuidTalk/update", params).then((res) => {
          //  // console.log("添加时的res", res.data);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.openAddSay();
            this.talkShow = false;
          } else {
            this.$alert(res.data.message, "", {
              confirmButtonText: "确定",
              callback: (action) => { },
            });
          }
        });
      }
    },
    openTalkDialog(str, row) {
      if (str == "编辑") {
        this.currentRalkRow = row;
        this.talk = row.talk;
      }
      this.talktype = str;
      this.talkShow = true;
    },
    openPackageTransfer() {
      this.PackageTransferShow = true;
      console.log("当前企业信息", this.currentForm.row);
    },
    openAddSay() {
      let params = {
        uuid: this.currentForm.row.id,
        page: this.talkObj.page,
        size: this.talkObj.size,
      };
      axios.post("api/uuidTalk/list", params).then((res) => {
        this.queryCustomerData = res.data.data;
        this.talkObj.total = res.data.total;
        this.allNumShow = true;
      });
    },
    delTalk(row) {
      this.$alert("此操作将永久删除该话术，确定要删除吗？", "", {
        confirmButtonText: "删除",
        callback: (action) => {
          if (action == "confirm") {
            let params = {
              id: row.id,
            };
            axios.post("api/uuidTalk/del", params).then((res) => {
              // 刷新数据
              if (res.data.statusCode == "00000") {
                this.$message.success(res.data.message);
                this.openAddSay();
                this.talkShow = false;
              } else {
                this.$alert(res.data.message, "", {
                  confirmButtonText: "确定",
                  callback: (action) => { },
                });
              }
            });
          }
        },
      });
    },
    openOperate(scope) {
      this.currentForm = scope;
      this.operateVisible = true;
    },
    //查询自定义行业
    send_saas(row) {
      // console.log("1111111111111" + row.appid)
      let params = {
        sendData: 1,
        appid: row.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid/saas/findOne", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },

    getStates(val) {
      this.loading = true;
      debounce(async () => {
        let params = {
          page: 1,
          size: 50,
        };
        this.loading = false;
        this.getuuid_custom(val);
      }, 1000);
    },
    handleToPageChange(val) {
      this.page1 = val;
      // console.log("要去的页数", val)
      this.getLockList();
    },
    getLockList() {
      let params = {
        page: this.page1,
        size: this.size,
        appid: this.currentRow.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-stop-log/list", params)
        .then((res) => {
          this.lockList = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    handleToSizeChange(val) {
      this.size = val;
      this.getLockList();
    },
    resetName() {
      this.name = null;
      this.uuid_customList = {};
    },
    resetForm() {
      this.appid = null;
      this.name = null;
      this.remarks = null;
      // this.userId = null
      this.form = {
        userTime: 0,
        seatAdd: 1,
        id: "",
        name: "",
        money: "",
        type: "",
        rate: "",
        seatRateW: "",
        appid: "",
        seatPass: "", // 是否限制坐席0不使用坐席1使用
        seatRate: 0, // 月租
        chargeMode: "",
        industry: "",
        paramId: "",
        userId: "",
      };
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = val;
      //   val.forEach((item) => {
      // this.numsArray.push(item.id);
      //   });
      //  // console.log(this.numsArray);
    },
    openBatchDaing() {
      if (this.numsArray.length == 0) {
        this.$message({
          type: "warning",
          message: "请先选中企业",
        });
        return false;
      } else {
        this.batchLockShow = true;
      }
    },
    batchLock(num) {
      // console.log("numsArray", this.numsArray);
      let params = {
        remarks: this.remarks,
        status: num,
      };
      params.uuidList = this.numsArray.map((v) => {
        return {
          id: v.id,
          appid: v.appid,
          name: v.name,
          userId: v.userId,
        };
      });

      axios
        .post("api/uuid/many-del", params)
        .then((res) => {
          // console.log("解锁数据", res);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.getList();
            this.batchLockShow = false;
          } else {
            this.$message.error(res.data.message);
            this.getList();
            this.batchLockShow = false;
          }
        })
        .catch((err) => {
          // console.log(err);
          this.getList();
          this.batchLockShow = false;
        });
    },
    //添加自定义行业 数据
    uuid_custom_add() {
      // console.log("1111", this.uuid_customList.customId)
      // console.log("1111", this.uuid_customList.uuid)

      let params = {
        customId: this.uuid_customList.customId,
        value: this.uuid_customList.uuid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-custom/add-data2", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
      this.uuid_customFlag = false;
    },
    //查询自定义行业
    uuid_custom(row) {
      this.currentRow = row;
      this.uuid_customFlag = true;
      this.uuid_customList = [];
      this.getuuid_custom();
    },
    getuuid_custom(name) {
      let params = {
        page: 1,
        size: 50,
      };
      if (name) params.name = name;
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-custom/find-uuidCustom", params)
        .then((res) => {
          if (res.data.data.length != 0) {
            this.uuid_custom_options = res.data.data;
            this.name = this.currentRow.name;
            this.uuid_customList.uuid = this.currentRow.id;
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //定时报停  添加
    uuid_stop_add() {
      if (null == this.uuidStopTime) {
        this.$message.error("请选择时间");
        // console.log("uuidstopform" + this.uuidstopform.toString + "---");
        return;
      } else {
        this.uuidStopTime = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.uuidStopTime
        );
      }

      let params = {
        userId: this.uuidstopform.userId,
        uuid: this.uuidstopform.uuid,
        appid: this.uuidstopform.appid,
        name: this.uuidstopform.name,
        stopTime: this.uuidStopTime,
        remark: this.remark,
      };

      // console.log("uuidstopform2" + params);

      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-stop/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // this.callForward_form = res.data.data;
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.uuidStopFlag = false;
        });
    },
    //定时报停 弹出
    uuid_stop(row) {
      this.uuidStopFlag = true;
      this.uuidstopform = {};
      // console.log(row.row.appid);
      this.uuidstopform.userId = row.row.userId;
      this.uuidstopform.appid = row.row.appid;
      this.uuidstopform.uuid = row.row.id;
      this.uuidstopform.name = row.row.name;
      this.remark = row.row.remark;
    },
    // 分页导航
    handlePageChange_operation_log(val) {
      this.page1 = val;

      this.operation_log_appid(this.seatPhoneAppid);
    },
    handlePageChange_lock_log(val) {
      this.page1 = val;

      let params = {
        page: this.page1,
        size: this.size,
        appid: this.currentRow.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-stop-log/list", params)
        .then((res) => {
          this.lockList = res.data.data;
          this.lockTotal = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //操作日志弹出
    operation_log_appid(appid) {
      let params = {
        page: this.page1,
        size: this.size,
        appid: appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/operationLog/list", params)
        .then((res) => {
          //  // console.log('---------------',res.data.data);
          //  // console.log('--------11111111------',res.data.total);

          this.operationLogRecord = res.data.data;
          this.pageTotal_peration_log = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //锁定日志弹出
    lockLog(row) {
      this.page1 = 1;
      this.size = 10;
      this.currentRow = row;
      this.drawer_lock_log = true; //操作日志弹出框
      let params = {
        page: this.page1,
        size: this.size,
        appid: row.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-stop-log/list", params)
        .then((res) => {
          this.lockList = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //操作日志弹出
    operation_log(row) {
      this.page1 = 1;
      // this.drawer=[]
      //  // console.log(row.row.appid);
      this.seatPhoneAppid = null;
      this.seatPhoneAppid = row.row.appid;
      this.drawer_operation_log = true; //操作日志弹出框
      let params = {
        page: this.page1,
        size: this.size,
        appid: row.row.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/operationLog/list", params)
        .then((res) => {
          //  // console.log('---------------',res.data.data);
          //  // console.log('--------11111111------',res.data.total);

          this.operationLogRecord = res.data.data;
          this.pageTotal_peration_log = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //转发线路  删除
    uuid_forward_del() {
      let params = {
        appid: this.callForward_form.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-callForward/del", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // this.callForward_form = res.data.data;
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.callForwardFlag = false;
        });
    },
    //转发线路  添加
    uuid_forward_add() {
      let params = {
        appid: this.callForward_form.appid,
        type: this.callForward_form.type,
        paramId: this.callForward_form.paramId,
        industry: this.callForward_form.industry,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-callForward/add-update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // this.callForward_form = res.data.data;
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.callForwardFlag = false;
        });
    },
    //转发线路  弹出
    uuid_forward(row) {
      // this.drawer=[]
      this.callForward_form = [];
      this.callForwardFlag = true;
      // console.log(row.row.appid);
      this.callForward_form.appid = row.row.appid;
      // this.seatPhoneAppid = row.row.appid
      let params = {
        appid: row.row.appid,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/uuid-callForward/findOne", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.callForward_form = res.data.data;
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        });
    },
    //坐席弹出颜色变更
    tableRowClassName({ row, rowIndex }) {
      let number = rowIndex % 2;

      if (number === 0) {
        // console.log("warning");
        return "warning-row";
      } else {
        // console.log("success");
        return "success-row";
      }
      // return '';
    },
    // 批量购买弹出框
    allBuy() {
      //  // console.log(this.tableData);
      if (this.rechargeRecordList.size == 0) {
        this.$message({
          message: "未选中号码",
          type: "warning",
        });
        return;
      }
      this.allBuyVisible = true;
    },
    //确定批量购买
    allBuySubmit() {
      if (null == this.buySeatPhoneMoney) {
        this.$message.error("请选择套餐");
        return;
      }
      this.allBuyVisible = false;
      //  // console.log(this.rechargeRecordList.size);
      let appid = null;
      let list = [];
      let seatPhoneId = list;
      for (let item of this.rechargeRecordList.values()) {
        seatPhoneId.push(item.id);
        appid = item.appid;
      }

      let params = {
        listId: seatPhoneId,
        appid: appid,
        buySeatPhoneMoney: this.buySeatPhoneMoney,
      };
      //  // console.log(params);
      //  // console.log(params.listId);
      //  // console.log(params.appid);
      axios.post("api/seat-phone/buy-seatMoney", params).then((res) => {
        if (res.data.statusCode == "00000") {
          this.$message.success(res.data.message);
        } else {
          this.$message.error(res.data.message);
        }
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.allBuyVisible = false;
        this.buySeatPhoneMoney = null;
        this.getList();
      });
    },
    //双击
    ellDblclick(row, column, cell, event) {
      this.rechargeRecordList.set(row.id, row);
      this.$message.success("已选择号码" + row.activePhone);
    },
    //坐席弹出
    drawer_seatPhone(row) {
      this.page1 = 1;
      // this.drawer=[]
      // console.log(row.row.appid);
      this.seatPhoneAppid = row.row.appid;
      this.drawer = true;
      let params = {
        page: this.page1,
        size: this.size,
        appid: row.row.appid,
        orderBySeatMoney: 1,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/seat-phone/list", params)
        .then((res) => {
          //  // console.log('---------------',res.data.data);
          //  // console.log('--------11111111------',res.data.total);

          this.rechargeRecord = res.data.data;
          this.pageTotalDw = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },

    //弹出关闭
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
          this.rechargeRecordList.clear();
          // this.getList()
        })
        .catch((_) => { });
    },
    // 拨打时间开关
    changeSwitch() {
      //  // console.log("userTime", this.userTime);
      this.editSubmit(true);
    },
    // 短信开关
    changeSwitch_sms() {
      //  // console.log("seatAdd", this.seatAdd);
      // this.editSubmit_seatAdd();
    },
    //点击通道类型时
    getData(type) {
      //  // console.log(type)
      let params = {
        type: type,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/list", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          this.options2 = res.data.data;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    changeType(val) {
      // console.log(val);
    },
    talkPageChange(val) {
      this.talkObj.page = val;
      this.openAddSay();
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 分页导航
    handlePageChange_seatPhone(val) {
      this.page1 = val;

      this.getList_seatPhone(this.seatPhoneAppid);
    },
    notMoney() {
      this.notMoney1 = 1000;
      this.getList();
      this.$message.success(
        "已查询出余额不足1000的企业成功！点击“重置”按钮可取消查询！"
      );
    },
    notMonth() {
      this.notMonth1 = 1;
      this.getList();
      this.$message.success(
        "已查询出余额不足以抵扣下月月租的企业！点击“重置”按钮可取消查询！"
      );
    },
    //坐席弹出
    getList_seatPhone(appid) {
      let params = {
        page: this.page1,
        size: this.size,
        appid: appid,
        orderBySeatMoney: 1,
      };
      axios
        // .post("api/uuid/list", params)
        .post("api/seat-phone/list", params)
        .then((res) => {
          //  // console.log('---------------',res.data.data);
          //  // console.log('--------11111111------',res.data.total);

          this.rechargeRecord = res.data.data;
          this.pageTotalDw = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        type: this.paramTpye,
        status: this.status,
        appid: this.appid,
        name: this.name,
        userId: this.userId,
        notMoney: this.notMoney1,
        notMonth: this.notMonth1,
        id: this.id,
        industry: this.industry,
        paramId: this.paramId,
      };
      if (this.sortParmas) {
        params = { ...params, ...this.sortParmas };
      }

      axios
        // .post("http://106.14.0.130:9998/vo/uuid/list", params)
        .post("api/uuid/list-operators", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //搜索
    handleSearch() {
      this.getList();
    },
    //添加按钮
    open() {
      this.addVisible = true;
      (this.form.userId = ""), (this.form.name = "");
      this.form.money = "";
      this.form.rate = "";
      this.form.seatRateW = "";
      this.form.seatRate = 0;
      this.form.seatPass = "";
      this.form.type = "";
      this.form.paramId = "";
      this.form.chargeMode = "";
      this.form.industry = "";
      this.options2 = [];
    },

    //重置按钮
    reset() {
      this.id = null;
      this.paramId = null;
      this.appid = null;
      this.paramTpye = null;
      this.status = null;
      this.name = null;
      this.userId = null;
      this.notMoney1 = null;
      this.notMonth1 = null;
      this.buySeatPhoneMoney = null;
      this.getList();
    },

    //添加确定
    addSubmit() {
      if (
        this.form.seatRate == " " ||
        this.form.rate == " " ||
        this.form.seatRateW == " "
      ) {
        this.$message.error("请选择套餐月租、费率、套餐外费率等必选项！");
        return false;
      }
      let params = {
        id: this.form.id,
        name: this.form.name,
        money: this.form.money,
        type: this.form.type,
        rate: this.form.rate,
        seatRateW: this.form.seatRateW,
        seatPass: this.form.seatPass, //是否限制坐席0不使用坐席1使用
        seatRate: this.form.seatRate, //月租
        chargeMode: this.form.chargeMode,
        industry: this.form.industry,
        paramId: this.form.paramId,
        userId: this.form.userId,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/add", params)
        .post("api/uuid/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            setTimeout(() => {
              this.rechargeBtn = false;
            }, 2000);
            this.$message.success(res.data.message);
            this.addVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message);
            this.addVisible = false;
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },

    //点击编辑弹窗使用下面的方法(数据回显)
    handleClick(row) {
      //  // console.log("编辑弹框", row);
      this.$confirm(`确认要开始修改企业 <${row.row.name}> 的设置吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            // console.log('userTime', row.row);
            this.form = row.row;
            this.form.userTime = row.row.userTime + "";
            let params = {
              id: this.form.id,
            };
            axios
              // .post("http://106.14.0.130:9998/vo/uuid/findOne", params)
              .post("api/uuid/findOne", params)
              .then((res) => {
                console.log(res.data.data);
                let { id, qualityTime, userTime, seatAdd, chargeMode, money, name, rate, seatRateW, seatRate, seatPass, type, industry, paramId, userId, appid } = res.data.data
                this.form = { id, qualityTime, userTime, seatAdd, chargeMode, money, name, rate, seatRateW, seatRate, seatPass, type, industry, paramId, userId, appid }
                // this.form.chargeMode = res.data.data.chargeMode; //代理编号
                // this.form.money = res.data.data.money; //余额
                // this.form.name = res.data.data.name; //企业名称
                // this.form.rate = res.data.data.rate; //费率
                // this.form.seatRateW = res.data.data.seatRateW; //费率
                // this.form.seatRate = res.data.data.seatRate; //月租
                // this.form.seatPass = res.data.data.seatPass; //是否限制坐席 0-不限制
                // this.form.type = res.data.data.type; //通道类型
                // this.form.industry = res.data.data.industry; //行业
                // this.form.paramId = res.data.data.paramId; //密钥
                // this.form.userId = res.data.data.userId; //所属代理的编号
                // this.form.appid = res.data.data.appid;
                // console.log(this.form.paramId);
                this.editVisible = true; //弹窗解除隐藏状态跳出
              })
              .catch((err) => {
                // console.log(err);
              });
            loading.close();
          }, 700);
        })
        .catch(() => {
          this.$message.info("已取消");
        });
    },

    //编辑确定
    editSubmit(kaiguan) {
      this.$confirm(`确认要完成对企业 ${this.form.name} 的修改吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: this.form.id,
            name: this.form.name,
            money: this.form.money,
            type: this.form.type,
            rate: this.form.rate,
            seatRateW: this.form.seatRateW,
            appid: this.form.appid,
            seatPass: this.form.seatPass, //是否限制坐席0不使用坐席1使用
            seatRate: this.form.seatRate, //月租
            chargeMode: this.form.chargeMode,
            industry: this.form.industry,
            paramId: this.form.paramId,
            userId: this.form.userId,
            qualityTime: this.form.qualityTime,
            userTime: this.form.userTime,
            seatAdd: this.form.seatAdd,
          };

          axios
            // .post("http://106.14.0.130:9998/vo/uuid/update", params)
            .post("api/uuid/update", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                setTimeout(() => {
                  this.rechargeBtn = false;
                }, 2000);
                this.$message.success(res.data.message);
                this.editVisible = false;
                this.getList();
              } else {
                this.$message.error(res.data.message);
                this.editVisible = false;
                this.getList;
              }
              this.uuid_custom(this.form);
            })
            .catch((err) => {
              this.$message.error(err.data.message);
            });
        })
        .catch(() => {
          this.$message.info("已取消");
        });
    },
    //编辑确定
    editSubmit_seatAdd() {
      let params = {
        id: this.form.id,
        seatAdd: this.form.seatAdd,
        seatRate: this.form.seatRate, //月租
      };

      axios
        // .post("http://106.14.0.130:9998/vo/uuid/update", params)
        .post("api/uuid/update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            setTimeout(() => {
              this.rechargeBtn = false;
            }, 2000);
            this.$message.success(res.data.message);
            this.editVisible = false;
            this.getList();
          } else {
            this.$message.error(res.data.message);
            this.editVisible = false;
            this.getList;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },

    //划拨
    recharge(row) {
      this.addMoney = true;
      this.rechargeId = row.row.id;
      this.rechargeMoney = row.row.money;
      this.appid = row.row.appid;
      this.name = row.row.name;
      this.formAdd = 0;
      // this.formAdd = row.row.money; // 回填余额
      this.showformAdd = row.row.money; // 显示余额
      this.userId = row.row.userId;
      this.rechargeValue = "充值";

      // this.seatRate = row.row.seatRate;
    },

    // 套餐转移
    transferSubmit() {
      let params = {
        id: this.currentForm.row.id,
        old_money: this.currentForm.row.money,
        appid: this.currentForm.row.appid,
        name: this.currentForm.row.name,
        userId: this.currentForm.row.userId,
        ...this.addForm,
      };
      // console.log("参数",params);
      if (this.transferType == "充值套餐") {
        params.money = params.money * -1;
      }
      axios
        .post("api/uuid/update-moneyAndSeatMoney", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.getList();
            this.PackageTransferShow = false;
            this.$message.success(res.data.message);
          } else {
            this.$alert(res.data.message, "", {
              confirmButtonText: "确定",
              callback: (action) => { },
            });
          }
        })
        .catch((err) => { });
    },
    //划拨确定按钮
    rechargeSubmit() {
      this.rechargeBtn = false;

      let params = {
        id: this.rechargeId,
        money: this.formAdd,
        old_money: this.rechargeMoney,
        remarks: this.remarks,
        appid: this.appid,
        // seatRate: this.seatRate,
        name: this.name,
        userId: this.userId,
      };
      if (this.rechargeValue == "扣费") {
        params.money = this.formAdd * -1;
      }
      this.rechargeBtn = true;
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/update", params)
        .post("api/uuid/update", params)
        .then((res) => {
          // setTimeout(() => {
          //     this.rechargeBtn = false;
          // }, 2000);
          this.money = res.data.money;
          this.appid = null;
          this.name = null;
          this.userId = null;
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }

          this.getList();
        })
        .catch((err) => {
          this.rechargeBtn = false;
          // console.log(err);
        })
        .finally(() => {
          this.addMoney = false;
        });
    },
    // 打开单个锁定弹框
    openLock(row) {
      this.currentRow = row;
      let params = {
        appid: row.appid,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/update", params)
        .post("api/seat-phone/find-count", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.currentRow.seatCount = res.data.data;
          } else {
            this.currentRow.seatCount = "暂无查询";
          }
        });

      this.oneLockShow = true;
    },
    //绑定
    lock() {
      if (!this.remarks) {
        this.$message.warning("请输入需要备注内容");
        return false;
      }
      let status = "";
      if (this.currentRow.status == 1) {
        status = 0;
      } else {
        status = 1;
      }
      let params = {
        id: this.currentRow.id,
        appid: this.currentRow.appid,
        status: status,
      };
      let params2 = {
        appid: this.currentRow.appid,
        userId: this.currentRow.userId,
        name: this.currentRow.name,
        type: this.currentRow.status == 0 ? 1 : 0,
        remarks: this.remarks,
      };
      // appid
      // name企业名称
      // userId 代理id
      // type 操作类型  0解锁  1锁定
      // remarks  备注
      this.oneLockShow = false;
      axios
        .post("api/uuid/del", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            axios
              .post("api/uuid-stop-log/add", params2)
              .then((res) => {
                if (res.data.statusCode == "00000") {
                  this.$message.success(res.data.message);
                  this.getList();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((err) => {
                // console.log(err);
              });
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    isEdit() {
      // console.log(this.form);
      // return
      this.saveAdd();
    },
    //话术按钮
    opensay(row) {
      //form.say双向绑定的是内容
      //  // console.log(row)
      this.form.say = row.row.talk;
      this.talkId = row.row.id;
      this.sayShow = true;
    },
    //中间号回显
    middleNum(row) {
      this.middleNumShow = true;
      let params = {
        id: row.row.id,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/findOne", params)
        .post("api/uuid/findOne", params)
        .then((res) => {
          this.form.id = res.data.data.id;
          //数量
          if (res.data.data.num == null || res.data.data.num == "") {
            this.middleCount = 0;
          } else {
            this.middleCount = res.data.data.num;
          }
          //中间号
          this.middleNums = res.data.data.middleNum;
          //单价
          if (res.data.data.price == null || res.data.data.price == "") {
            this.middleNumPrice = 0;
          } else {
            this.middleNumPrice = res.data.data.price;
          }
          this.getList();
        })
        .catch((err) => {
          this.getList();
        });
    },
    //中间号确定按钮
    submitMiddleNum() {
      let params = {
        id: this.form.id,
        middleNum: this.middleNums,
        price: this.middleNumPrice,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/updatePhone", params)
        .post("api/uuid/updatePhone", params)
        .then((res) => {
          //  // console.log(res)
          if (res.data.statusCode == "00000") {
            this.middleNumShow = false;
            this.$message.success(res.data.message);
            this.getList();
          } else {
            this.middleNumShow = false;
            this.$message.error(res.data.message);
            this.getList();
          }
        })
        .catch((err) => {
          // console.log(err);
          this.getList();
        });
    },
    //话术提交按钮
    submitTalk() {
      let params = {
        id: this.talkId,
        //把页面的内容赋值给talk，传回后端数据库
        talk: this.form.say,
      };
      // axios.post("http://106.14.0.130:9998/vo/uuid/update", params)
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/update", params)
        .post("api/uuid/update", params)
        .then((res) => {
          //  // console.log(res)
          if (res.data.statusCode == "00000") {
            this.sayShow = false;
            this.$message.success(res.data.message);
            this.getList();
          } else {
            this.sayShow = false;
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //详情按钮
    details(row) {
      this.$router.push({
        path: "/audit",
        query: {
          companyName: row.row.name,
        },
      });
    },
    //添加高危按钮
    addDanger(row) {
      // console.log(row);
      let params = {
        uuid: row.row.id,
      };

      axios
        // .post("http://106.14.0.130:9998/vo/uuidDanger/add", params)
        .post("api/uuidDanger/add", params)
        .then((res) => {
          this.$message.success(res.data.message);
        })
        .catch((err) => { });
      this.reload();
    },
  },
});
</script>

<style scoped lang="scss">
.lockBox {
  font-size: 18px;
}

::v-deep .textareaMinHeight .el-textarea__inner {
  min-height: 120px !important;
  max-height: 420px !important;
}

.appPage {
  height: 100%;
}

::v-deep .appPageMain {
  height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    .el-select {
      width: 100%;
    }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      // .el-table__row {
      //     td {
      //         padding: 4px 0;
      //     }
      // }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 24px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.talkTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -20px;

  .talkToptitle {
    position: relative;
    margin: 8px;
  }

  .talkToptitle:after {
    position: absolute;
    content: "";
    left: -8px;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: #5d9cec;
  }
}

.btn-14>.el-button {
  margin-bottom: 14px;
}

// /deep/
::v-deep .edit-box {
  display: flex;
  flex-wrap: wrap;
}

// /deep/
::v-deep .edit-box .el-button {
  margin-left: 0;
  margin-right: 16px;
}

// /deep/
::v-deep .edit-box .el-button span {
  display: flex;
  justify-content: space-between;
  width: 170px;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.tit1 {
  display: flex;
  justify-content: space-between;
}

::v-deep .search {
  // height: 70px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  // overflow: auto;

  .left {
    // width: 60%;
    width: 776px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .leftItem {
      width: 192px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .el-input,
      .el-select,
      .el-date-editor {
        // width: 120px;
        flex: 1;
      }

      .el-input__inner {
        height: 23px !important;
        border-radius: 25px;
        border: 1px solid #666;
      }

      .el-input__icon {
        line-height: 24px !important;
      }

      .lab_tit {
        font-size: 12px;
        // font-weight: bold;
        white-space: nowrap;
        width: 50px !important;
        text-align: right;
        margin-right: 3px;
        margin-left: 8px;
      }
    }
  }

  .right {
    text-align: right;
    // width: 30%;
    flex: 1;

    // display: flex;
    .el-button {
      margin-bottom: 3px;
    }
  }
}

.searchBtn {
  margin-left: 15px;
  align-items: center;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

::v-deep.operateColumn {
  .el-button {
    margin-top: 3px !important;
    margin-left: 4px !important;
  }

  .el-button--mini {
    // min-height: 20px;
    // padding: 6px;
  }
}

/* 话术编辑框高度设置 */
/* .el-textarea__inner{
                      min-height: 300px !important;
                    } */
// div /deep/
// ::v-deep .el-table .cell {
//     display: flex;
//     justify-content: space-evenly;
//     flex-wrap: wrap;
// }
</style>
