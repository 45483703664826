<template>
  <div class="appPage">
    <h3 class="tit">日报表</h3>
    <div class="search">
      <div class="left">
        <span>企业名称</span>
        <el-input v-model="form.name" placeholder="请输入企业名称" class="handle-input mr10"></el-input>

        <span>子企业id</span>
        <el-input v-model="form.uuid" placeholder="请输入子企业id" class="handle-input mr10"></el-input>

        <!-- 日期选择，dayjs -->
        <span>日期</span>
        <el-date-picker v-model="form.day" type="date" placeholder="选择日期" @change="getDate" :clearable="false"
          format="YYYY-MM-DD">
        </el-date-picker>

        <el-button type="primary" icon="el-icon-search" @click="handleSearch(scope)" class="searchBtn">查找</el-button>
        <el-button type="primary" @click="excel()">导出</el-button>

      </div>

      <div class="right">
        <div>
          <span>appid</span>
          <el-input v-model="form.appid" placeholder="请输入appid" class="handle-input mr10"></el-input>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" @click="getList2(scope)" class="searchBtn">实时查询</el-button>

        </div>
        <div>
          <el-button type="primary" icon="el-icon-search" @click="getList3(scope)" class="searchBtn">统计查询</el-button>
        </div>


        <div>
          <el-button class="reset" type="primary" icon="el-icon-refresh" @click="reset()">重置</el-button>
        </div>
      </div>
    </div>
    <div class="container">
      <el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
        @selection-change="handleSelectionChange" show-summary :summary-method="getSummaries">
        <!--        <el-table-column  type="selection" width="55" ></el-table-column>-->
        <el-table-column  prop="day" label="填表日期" ></el-table-column>

        <el-table-column  prop="uuid" label="企业编号" ></el-table-column>
        <el-table-column  prop="name" label="企业名称" ></el-table-column>
        <el-table-column  prop="type" label="通道类型" >
          <template #default="scope">
            <span v-if="scope.row.type == 0">隐私小号通道</span>
            <span v-if="scope.row.type == 1">南京线</span>
            <span v-if="scope.row.type == 2">电信</span>
            <span v-if="scope.row.type == 3">联通2</span>
            <span v-if="scope.row.type == 4">联通1</span>
            <span v-if="scope.row.type == 5">移动ax</span>
            <span v-if="scope.row.type == 6">联通3</span>
            <span v-if="scope.row.type == 7">联通4</span>
            <span v-if="scope.row.type == 8">H</span>
            <span v-if="scope.row.type == 9">联通5</span>
            <span v-if="scope.row.type == 10">移动3</span>
            <span v-if="scope.row.type == 11">联通6</span>
            <span v-if="scope.row.type == 12">联通7</span>
            <span v-if="scope.row.type == 13">移动5</span>
            <span v-if="scope.row.type == 14">S</span>
            <span v-if="scope.row.type == 15">D</span>
            <span v-if="scope.row.type == 16">S2</span>
            <span v-if="scope.row.type == 18">X</span>
            <span v-if="scope.row.type == 20">6号线</span>
            <span v-if="scope.row.type == 23">新2号线</span>
            <span v-if="scope.row.type == 26">无锡</span>
           
          </template>
        </el-table-column>
        <el-table-column  prop="minute" label="计费分钟数(累加)" ></el-table-column>
        <el-table-column  prop="seconds" label="计费秒数(累加)" ></el-table-column>
        <el-table-column  prop="money" label="总成本" ></el-table-column>
        <el-table-column  prop="callCount" label="呼叫总次数" ></el-table-column>

        <el-table-column  prop="sumCount" label="呼叫成功总次数" ></el-table-column>
        <el-table-column  prop="count" label="接听总次数" ></el-table-column>
        <el-table-column  prop="usercount" label="呼叫总用户" ></el-table-column>
        <el-table-column  prop="avg" label="acd平均通话时长（秒数）" ></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex" :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal" @size-change="handleSizeChange"
          @current-change="handlePageChange"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import dayjs from "dayjs"; //日期选择
export default {
  name: "dailyReport",
  data() {
    return {
      form: {
        day: null,
        appid: null,
        name: null,
        uuid: null,
        // minute: "",
        seconds: "",
        money: "",
        callCount: "",
        sumCount: "",
        count: "",
        usercount: "",
        avg: "",
      },

      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() > Date.now();
      //   }
      // },
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      tableData: [], //用于接收后端数据表的数组
      pageTotal: 0, //总共有多少数据
      page: 1, //接口请求的页数
      size: 10, //接口请求的每页的数量
      realname: "",
    };
  },
  // watch: {
  //   day() {
  //     if (this.from.day == "") {
  //       this.from.day = null;
  //     }
  //   },
  //   name() {
  //     if (this.from.name == "") {
  //       this.from.name = null;
  //     }
  //   },
  // },
  created() {
    this.realname = localStorage.getItem("realname");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  methods: {
    //导出
    excel() {
      if (null == this.form.day) {
        this.$message.error("请选择日期");
        return
      }
      let params = {
        uuid: this.form.uuid,
        day: this.form.day,
        type: 1
      };
      axios
        .post("api/table/export-whitephoneD", params, { responseType: "blob" })
        // .post("api/table/export-table", params,{responseType: "blob"  })
        .then((res) => {

          this.loading = false

          const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers['content-disposition']

          a.download = decodeURI(str).substr(decodeURI(str).indexOf('=') + 1);

          a.href = url;

          a.click();
        });
      this.reload();
    },
    getDate(date) {
      this.form.day = dayjs(date).format("YYYY-MM-DD");
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    //展示and查询
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        day: this.form.day,
        name: this.form.name,
        uuid: this.form.uuid,
      };
      // this.set();
      axios
        // .post("http://106.14.0.130:9998/vo/table/daytableAll", params)
        .post("api/table/daytableAll-operators", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
          }
        })
        .catch((err) => {
           // console.log(err);
        });
    },
    //展示and查询
    getList2() {

      if (null == this.form.appid || "" == this.form.appid) {
        alert("请输入appid")
        return;
      }
      let time = dayjs(new Date()).format("YYYY-MM-DD")
      let params = {
        sta: time + " 00:00:00",
        end: time + " 23:59:59",
        appid: this.form.appid,
      };

      // this.set();
      axios
        // .post("http://106.14.0.130:9998/vo/table/daytableAll", params)
        .post("api/calculate/count-table", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {

            let datalist = [res.data.data];

            this.tableData = datalist;
            this.pageTotal = 1;
          }
        })
        .catch((err) => {
           // console.log(err);
        });
    },
    //展示and查询
    getList3() {
      let params = {
        sum: 1
      };

      // this.set();
      axios
        // .post("http://106.14.0.130:9998/vo/table/daytableAll", params)
        .post("api/table/daytableAll", params)
        .then((res) => {
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {

            let datalist = [res.data.data];

            this.tableData = datalist;
            this.pageTotal = 1;
          }
        })
        .catch((err) => {
           // console.log(err);
        });
    },

    handleSearch() {
      this.getList();
    },
    //刷新按钮
    reset() {
      this.form.day = null;
      this.form.name = null;
      this.form.appid = null;
      this.form.uuid = null;
      this.getList();
    },
  },
};
</script>

<style scoped>
.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left {
  display: flex;
  align-items: center;
}

.left span {
  width: 180px;
  text-align: center;
}

.left block {
  display: flex;
  align-items: center;
}

.searchBtn {
  margin-left: 30px;
}

.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
 /* // margin-top: 20px; */
}

div/deep/.el-input__inner {
  width: 200px;
}

div/deep/.el-input {
  width: 40%;
}
</style>
