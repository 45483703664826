<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="坐席管理"></el-tab-pane>
            </el-tabs>

            <div class="search">
                <div class="left">
                    <div class="leftItem" v-if="realname != 'admin'">
                        <span class="lab_tit">企业</span>
                        <!-- <el-input v-model="appid1" placeholder="请输入appid"  v-if="realname == 'admin'" ></el-input> -->
                        <el-select
                                size="mini"
                                v-model="appid1"
                                placeholder="请选择企业"
                                label-width="80px"
                                style="width: 370px"
                                filterable
                        >
                            <el-option
                                    v-for="item in options2"
                                    :key="item.appid"
                                    :label="item.name"
                                    :value="item.appid"
                            >
                            </el-option>
                        </el-select>
                    </div>

                    <div class="leftItem" v-if="realname == 'admin'">
                        <span class="lab_tit">代理ID</span>
                        <el-input
                                size="mini"
                                v-model="userId"
                                placeholder="请输入代理ID"
                        ></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input
                                size="mini"
                                v-model="appid1"
                                placeholder="请输入appid"
                        ></el-input>
                    </div>

                    <div class="leftItem">
                        <span class="lab_tit">主叫</span>
                        <el-input
                                size="mini"
                                v-model="activePhone"
                                placeholder="请输入主叫"
                        ></el-input>
                    </div>

                    <!-- 是否通过验证   0 未通过验证 1 已通过验证 -->
                    <div class="leftItem" v-if="realname == 'admin'">
                        <span class="lab_tit">状态</span>
                        <el-select
                                size="mini"
                                v-model="value"
                                placeholder="请选择状态"
                                style="width: 100%"
                        >
                            <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="right">
                    <!--<el-button-->
                    <!--round size="mini" type="success"-->
                    <!--icon="el-icon-loading"-->
                    <!--@click="getList_D()"-->
                    <!--class="searchBtn"-->
                    <!--&gt;D线路未加白-->
                    <!--</el-button> -->

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-coin"
                            @click="getMoney()"
                            v-if="realname == 'admin'"
                    >套餐计算器
                    </el-button
                    >

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-coin"
                            @click="getLogCount_seatPhoneCount()"
                            v-if="realname == 'admin'"
                    >坐席数量详情
                    </el-button
                    >

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-search"
                            @click="getList()"
                    >查找
                    </el-button
                    >
                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-search"
                            @click="getList_seatMoney()"
                    >金额倒叙
                    </el-button>
                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-refresh"
                            @click="reset()"
                    >重置
                    </el-button>
                     <el-button round size="mini" type="primary" class="addW" @click="addWhite()"
                                  icon="el-icon-loading" >南京线加白</el-button>
                    <el-button
                            round
                            size="mini"
                            type="success"
                            icon="el-icon-mic"
                            @click="addSeats()"
                    >创建坐席
                    </el-button>

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-folder-opened"
                            @click="allSeats()"
                    >批量创建
                    </el-button>

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            icon="el-icon-delete"
                            @click="deleteAll()"
                    >批量删除
                    </el-button>

                    <el-button
                            round
                            size="mini"
                            type="primary"
                            @click="allBuy()"
                            v-if="null != flag_appid1"
                    >批量购买
                    </el-button>


                    <!-- <el-dropdown @command="handleTags" trigger="click">
                                  <el-button round size="mini" type="primary" style="margin-left: 10px;">
                                      更多操作
                                      <i class="el-icon-arrow-down el-icon--right"></i>
                                  </el-button>
                                  <template #dropdown>
                                      <el-dropdown-menu size="small">
                                          <el-dropdown-item command="金额倒叙">金额倒叙</el-dropdown-item>
                                          <el-dropdown-item command="创建坐席">创建坐席</el-dropdown-item>
                                          <el-dropdown-item command="批量创建">批量创建</el-dropdown-item>
                                          <el-dropdown-item command="批量删除">批量删除</el-dropdown-item>
                                          <el-dropdown-item command="批量购买" v-if="null != flag_appid1">批量购买</el-dropdown-item>
                                      </el-dropdown-menu>
                                  </template>
                              </el-dropdown> -->
                </div>
            </div>
            <div class="container mainTable">
                <hr/>
                <div class="table">
                    <el-table
                            :data="tableData"
                            stripe
                            height="calc(100% - 4rem)"
                            ref="multipleTable"
                            header-cell-class-name="table-header"
                            @selection-change="handleSelectionChange"
                    >
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column
                                prop="id"
                                label="坐席账号"
                                width="130"
                        ></el-table-column>
                        <el-table-column
                                prop="name"
                                label="名字"
                                width="160"
                        ></el-table-column>
                        <el-table-column
                                prop="activePhone"
                                label="主叫"
                                width="140"
                        ></el-table-column>
                        <!-- <el-table-column  prop="appid" label="企业appid" ></el-table-column> -->
                        <!--v-if="realname == 'admin'"  -->
                        <el-table-column
                                prop="appid"
                                label="appid"
                                width="300"
                        ></el-table-column>
                        <el-table-column
                                prop="seatMoney"
                                label="套餐金额"
                                width="100"
                        ></el-table-column>
                        <!-- <el-table-column  prop="buyCount" label="购买次数" ></el-table-column> -->
                        <!-- <el-table-column  prop="seatType" label="套餐外消费" >
                                <template #default="scope">
                                    <span v-if="scope.row.seatType == 0">否</span>
                                    <span v-else-if="!scope.row.seatType"></span>
                                    <span v-else>是</span>
                                </template>
                            </el-table-column> -->
                        <el-table-column prop="createdTime" width="180" label="创建时间">
                            <template v-slot="scope">
                                <div>
                                    {{ getYMDHMS("YYYY-mm-dd HH:MM:SS", scope.row.createdTime) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="status" label="是否通过">
                            <template #default="scope">
                <span v-if="scope.row.status == 0" style="color: red"
                >等待验证</span
                >
                                <span v-if="scope.row.status == 1">已通过验证</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                                fixed="right"
                                width="350"
                        >
                            <template #default="scope">
                                <div class="operateColumn">
                                    <el-button
                                            size="mini"
                                            style="margin-bottom: 6px"
                                            type="success"
                                            @click="whitephone_D(scope)"
                                            v-if="white_D == 2"
                                    >D加白
                                    </el-button>
                                    <el-button
                                            size="mini"
                                            style="margin-bottom: 6px"
                                            type="primary"
                                            @click="handleClick(scope)"
                                    >变更号码
                                    </el-button>
                                    <el-button
                                            size="mini"
                                            style="margin-bottom: 6px"
                                            type="danger"
                                            @click="del(scope)"
                                            v-if="realname == 'admin'"
                                    >删除
                                    </el-button>
                                    <el-button
                                            size="mini"
                                            style="margin-bottom: 6px"
                                            type="primary"
                                            @click="openCode(scope)"
                                            v-if="scope.row.status == 0"
                                    >验证号码
                                    </el-button>
                                    <!-- <el-button size="mini" type="primary" @click="revalidation(scope)" v-if="scope.row.status == 0">重新验证
                                          </el-button> -->
                                    <el-button
                                            size="mini"
                                            style="margin-bottom: 6px"
                                            type="success"
                                            @click="revalidationPass(scope)"
                                            v-if="
                      scope.row.status == 0 &&
                      (realname == 'admin' || realname == '客服-xn')
                    "
                                    >验证通过
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="mainTableFY">
                        <el-pagination
                                background
                                layout="total, prev, pager, next,sizes"
                                :current-page="pageIndex"
                                :page-sizes="[10, 20, 50, 100, 200]"
                                :page-size="pageSize"
                                :total="pageTotal"
                                @current-change="handlePageChange"
                                @size-change="handleSizeChange"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 创建坐席弹出框 -->
        <el-dialog title="创建坐席" v-model="addVisible" width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="名字">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item
                        label="代理编号"
                        label-width="80px"
                        v-if="realname == 'admin'"
                >
                    <el-select
                            v-model="id"
                            placeholder="请选择代理"
                            label-width="80px"
                            filterable
                    >
                        <el-option
                                v-for="item in options1"
                                :key="item.id"
                                :label="item.realname"
                                :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业名字">
                    <el-select v-model="appid" placeholder="请选择appid" filterable>
                        <el-option
                                v-for="item in options2"
                                :key="item.appid"
                                :label="item.name"
                                :value="item.appid"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="form.activePhone"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd()">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 创建坐席验证码 -->
        <el-dialog
                title="输入验证码"
                v-model="codeVisible"
                width="30%"
                @close="noteCode = null"
        >
            <el-form label-width="80px">
                <el-form-item label="验证码">
                    <el-input
                            v-model="noteCode"
                            placeholder="请输入短信接收的验证码"
                    ></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="revalidation" v-if="countdown <= 0">
            重新验证
          </el-button>
          <el-button type="primary" plain disabled v-else>
            {{ countdown }}秒后发送验证码
          </el-button>
            <!-- <el-button @click="codeVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="submitCodeEdit">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 编辑弹出框 -->
        <el-dialog title="编辑信息" v-model="editVisible" width="30%">
            <el-form ref="forms" :model="forms" label-width="80px">
                <el-form-item label="名字">
                    <el-input v-model="forms.name"></el-input>
                </el-form-item>
                <el-form-item label="主叫">
                    <el-input v-model="forms.activePhone" @change="onChange"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitEdit()">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 批量创建弹出框 -->
        <el-dialog
                title="批量创建"
                v-model="allVisible"
                width="30%"
                custom-class="upload"
        >
            <div class="content">
                <el-button type="success" icon="el-icon-download" @click="upload()"
                >点击下载模板
                </el-button>

                <el-upload
                        class="upload-demo"
                        action=""
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :auto-upload="false"
                        multiple
                        :limit="1"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                        :on-change="changeUpload"
                        drag="true"
                >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    <!-- <template #tip>
                              <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                            </template> -->
                </el-upload>
            </div>

            <template #footer>
        <span class="dialog-footer">
          <el-button @click="allVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload()">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!--南京线加白弹出框 -->
        <el-dialog title="南京线加白" v-model="white_yd4" width="30%">
            <span>请输入appid:</span>
            <el-input v-model="appid"></el-input>

            <el-form-item>
                <span>请输入加白号码:</span>
                <el-input v-model="phone"></el-input>
            </el-form-item>
            <el-form-item>
                <span>请输入名字</span>
                <el-input v-model="name"></el-input>
            </el-form-item>
            <el-form-item>
                <span>请输入身份证号</span>
                <el-input v-model="idCard"></el-input>
            </el-form-item>

            <el-button
                    type="primary"
                    @click="add_white_yd4(false)"
                    style="float: right"
                    round
            >发送验证码
            </el-button
            >
            <br/>
            <el-form-item>
                <span>请输入验证码</span>
                <el-input v-model="code"></el-input>
            </el-form-item>

            <template #footer>
        <span class="dialog-footer">
          <el-button @click="white_yd4 = false">取 消</el-button>
          <el-button type="primary" @click="add_white_yd4(true)"
          >确 定</el-button
          >
        </span>
            </template>
        </el-dialog>

        <!-- 批量删除弹出框 -->
        <el-dialog title="批量删除" v-model="delVisible" width="30%">
            <p>确定删除所选数据吗？删除后不可恢复</p>
            <template #footer>
        <span class="dialog-footer">
          <el-button @click="delVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitDel">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 批量购买弹框 -->

        <el-dialog title="批量购买" v-model="allBuyVisible" width="30%">
            <span>确定批量购买套餐包？</span>
            <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allBuyVisible = false"
          >取 消</el-button
          >
          <el-button type="primary" @click="allBuySubmit">确 定</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 创建坐席验证码 -->
        <el-dialog
                title="套餐扣费计算器"
                v-model="getMoneyFlag"
                width="30%"
                @close="noteCode = null"
        >
            <label>月租：</label>
            <el-input type="number" v-model="moneyForm.rate" required></el-input
            >
            <br/>
            <label>当月天数：</label>
            <el-input type="number" v-model="moneyForm.total_days" required></el-input
            >
            <br/>
            <label>当月剩余天数：</label>
            <el-input
                    type="number"
                    v-model="moneyForm.remaining_days"
                    required
            ></el-input
            >
            <br/>
            <label>人数：</label>
            <el-input type="number" v-model="moneyForm.people" required></el-input
            >
            <br/>
            <label>扣费金额：</label>
            <el-input type="number" v-model="moneyForm.money" disabled></el-input
            >
            <br/>
            <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="getMoneyFlag = false"
          >取 消</el-button
          >
          <el-button type="primary" @click="getMoney_ing()">计 算</el-button>
        </span>
            </template>
        </el-dialog>

        <!-- 套餐详情个数弹框 -->
        <el-dialog
                :title="`坐席数量详情`"
                v-model="LogCount_seatPhoneCount_flag"
                width="50%"
        >
            <div>
                <span class="lab_tit">选择时间</span>
                <el-date-picker
                        v-model="timestamp"
                        type="month"
                        placeholder="选择月">
                </el-date-picker>
                <div style=" text-align: right">
                    <el-button type="primary" icon="el-icon-search" @click="getLogCount_seatPhoneCount()">查找</el-button>
                </div>
            </div>
            <el-table :cell-style="cellStyle"
                    :data="LogCount_seatPhoneCountData"
                    border
                    header-cell-class-name="table-header"
                    height="700"

            >
                <el-table-column prop="appid" label="公司appid" width="120"></el-table-column>
                <el-table-column prop="name" label="公司名称" width="280"></el-table-column>
                <el-table-column prop="name" label="套餐" width="100"></el-table-column>
                <el-table-column prop="sumCount" label="套餐扣费总数" sortable  ></el-table-column>
                <el-table-column prop="count" label="当前坐席数" sortable  ></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "seats",
        data() {
            return {
                timestamp: null, //扣套餐个数 和当前坐席数
                LogCount_seatPhoneCountData: [], //扣套餐个数 和当前坐席数
                LogCount_seatPhoneCount_flag: false, //扣套餐个数 和当前坐席数
                getMoneyFlag: false, //计算套餐弹出框
                moneyForm: {}, //计算套餐数据
                countdown: 0,
                noteCode: null,
                allBuyVisible: false, //批量购买
                userId: null,
                white_D: 1,
                fileList: [],
                appid: "", //添加坐席处的绑定
                appid1: null, //搜索框的绑定
                appid_gouMai: null,
                flag_appid1: null, //搜索框的绑定
                activePhone: null,
                phone: null,
                name: null,
                idCard: null,
                code: null,
                status: null,
                pageTotal: 0, //总共有多少数据
                pageIndex: 1, //当前在第几页
                pageSize: 10, //前端规定每页显示数量
                page: 1, //接口请求的页数
                size: 10, //接口请求的每页的数量
                tableData: [],
                value: null,
                addVisible: false,
                editVisible: false,
                codeVisible: false,
                delVisible: false,
                allVisible: false,
                white_yd4: false, //南京线弹出框
                options1: [],
                options2: [],
                realname: "",
                file: {},
                id: null,
                timer: null,
                id1: "", //编辑时传的id
                options: [
                    {
                        value: 0,
                        label: "未通过验证",
                    },
                    {
                        value: 1,
                        label: "已通过验证",
                    },
                ],
                form: {
                    appid: "",
                    activePhone: "",
                    name: "",
                },
                forms: {
                    activePhone: "",
                    name: "",
                },
                numsArray: [], //选中号码的ID
            };
        },
        created() {

            (this.realname = localStorage.getItem("realname")), this.getList();
            this.id = localStorage.getItem("id");
            this.changeAppid();
        },
        watch: {
            id(newValue, oldValue) {
                this.options2 = [];
                this.getData(newValue);
                this.appid = "";
                //  // console.log('appidaaa',newValue);
            },
            addVisible() {
                if (this.realname != "admin") {
                    this.id = localStorage.getItem("id");
                    this.getData(this.id);
                }
            },
        },
        methods: {
            cellStyle({ row, column, rowIndex, columnIndex }) {
                if (row.count < row.sumCount || row.count == 0) {

                    return `background-color: #ff000070;font-weight: bold;`; // 绿色,最大值
                }
            },
            //当前月最后一天
            getLastDay(date) {
                var y = new Date(date).getFullYear(); //获取年份
                var m = new Date(date).getMonth() + 1; //获取月份
                var d = new Date(y, m, 0).getDate(); //获取当月最后一日
                m = m < 10 ? '0' + m : m; //月份补 0
                d = d < 10 ? '0' + d : d; //日数补 0
                return [y, m, d].join('-');
            },
            formaDate(value, fmt) {
                if (value == '——' || value == '0001-01-01T00:00:00') {
                    fmt = '';
                } else if (value) {
                    var date = new Date(value);
                    var o = {
                        'M+': date.getMonth() + 1, //月份
                        'd+': date.getDate(), //日
                        'h+': date.getHours(), //小时
                        'm+': date.getMinutes(), //分
                        's+': date.getSeconds(), //秒
                        'w+': date.getDay(), //星期
                        'q+': Math.floor((date.getMonth() + 3) / 3), //季度
                        S: date.getMilliseconds() //毫秒
                    };
                    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
                    for (var k in o) {
                        if (k === 'w+') {
                            if (o[k] === 0) {
                                fmt = fmt.replace('w', '周日');
                            } else if (o[k] === 1) {
                                fmt = fmt.replace('w', '周一');
                            } else if (o[k] === 2) {
                                fmt = fmt.replace('w', '周二');
                            } else if (o[k] === 3) {
                                fmt = fmt.replace('w', '周三');
                            } else if (o[k] === 4) {
                                fmt = fmt.replace('w', '周四');
                            } else if (o[k] === 5) {
                                fmt = fmt.replace('w', '周五');
                            } else if (o[k] === 6) {
                                fmt = fmt.replace('w', '周六');
                            }
                        } else if (new RegExp('(' + k + ')').test(fmt)) {
                            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
                        }
                    }
                } else {
                    fmt = '';
                }
                return fmt;
            },
            //套餐计算
            getLogCount_seatPhoneCount() {
                if (null == this.timestamp) {
                    let rootDate = new Date().toLocaleDateString();
                    this.timestamp = this.formaDate(new Date(rootDate), 'yyyy-MM')
                    this.sta = this.timestamp + "-01"
                    this.end = this.getLastDay(new Date());
                } else {
                    this.sta = this.formaDate(this.timestamp, 'yyyy-MM-dd')
                    this.end = this.getLastDay(this.timestamp);
                }
                // this.sta = this.getDefaultTime( this.timestamp)
                // this.end = this.getDefaultTime( this.timestamp)
                this.LogCount_seatPhoneCount_flag = true
                let params = {
                    sta: this.sta,
                    end: this.end
                };
                axios.post("api/seat-phone/findLogCount-SeatCount-group", params).then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.LogCount_seatPhoneCountData = res.data.data.map((v)=>{
                          v.count =   v.count == null ? 0 : v.count
                            return v
                        })


                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }

                });

            },
            getMoney_ing() {
                if (
                    null == this.moneyForm.total_days ||
                    null == this.moneyForm.remaining_days
                ) {
                    this.$message.error("天数不能为空");
                    return;
                }
                if (null == this.moneyForm.rate) {
                    this.$message.error("套餐不能为空");
                    return;
                }

                this.moneyForm.money = parseFloat(
                    (
                        (this.moneyForm.rate / this.moneyForm.total_days) *
                        this.moneyForm.remaining_days *
                        this.moneyForm.people
                    ).toFixed(2)
                );
                this.$message.success("计算成功");
            },
            //套餐计算 初始
            getMoney() {
                const now = new Date();
                this.firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
                this.lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                this.moneyForm.total_days =
                    (this.lastDay - this.firstDay) / (1000 * 60 * 60 * 24) + 1;

                const currentDay = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate()
                );
                const daysPassed = (currentDay - this.firstDay) / (1000 * 60 * 60 * 24);
                console.log(currentDay);
                console.log(daysPassed);
                console.log(daysPassed);
                this.moneyForm.remaining_days = this.moneyForm.total_days - daysPassed;
                // console.log("当月总共天数：",  this.moneyForm.total_days)
                // console.log("当月剩余天数：", this.moneyForm.remaining_days)
                this.moneyForm.people = 1;
                this.getMoneyFlag = true; //计算套餐弹出框
            },
            handleTags(command) {
                if (command === "金额倒叙") {
                    this.getList_seatMoney();
                } else if (command === "创建坐席") {
                    this.addSeats();
                } else if (command === "批量创建") {
                    this.allSeats();
                } else if (command === "批量删除") {
                    this.deleteAll();
                } else if (command === "批量购买") {
                    this.flag_appid1();
                }
            },
            createFilter(queryString) {
                return (restaurant) => {
                    return (
                        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
                        0
                    );
                };
            },
            // 批量购买弹出框
            allBuy() {
                //  // console.log(this.tableData);
                if (this.numsArray.length == 0) {
                    this.$message({
                        message: "未选中号码",
                        type: "warning",
                    });
                    return;
                }
                this.allBuyVisible = true;
            },
            // 确认批量购买

            allBuySubmit() {
                let userId = localStorage.getItem("id");

                let params = {
                    listId: this.numsArray,
                    appid: this.appid_gouMai,
                };
                axios.post("api/seat-phone/buy-seatMoney", params).then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.numsArray.map((v, i) => {
                        let index = this.tableData.findIndex((item) => item.id === v);
                        this.tableData.splice(index, 1);
                    });
                    this.allBuyVisible = false;
                    this.getList();
                });
            },
            // 转换日期
            getYMDHMS(format, date) {
                if (!date) {
                    return "";
                }
                date = new Date(date);
                const dataItem = {
                    "Y+": date.getFullYear().toString(),
                    "m+": (date.getMonth() + 1).toString(),
                    "d+": date.getDate().toString(),
                    "H+": date.getHours().toString(),
                    "M+": date.getMinutes().toString(),
                    "S+": date.getSeconds().toString(),
                };
                Object.keys(dataItem).forEach((item) => {
                    const ret = new RegExp(`(${item})`).exec(format);
                    if (ret) {
                        format = format.replace(
                            ret[1],
                            ret[1].length === 1
                                ? dataItem[item]
                                : dataItem[item].padStart(ret[1].length, "0")
                        );
                    }
                });
                return format;
            },
            addWhite() {
                this.phone = null;
                this.name = null;
                this.idCard = null;
                this.code = null;
                this.white_yd4 = true;
            },
            //南京线加白提交
            add_white_yd4(flag) {
                let params = {
                    appid: this.appid,
                    phone: this.phone,
                    name: this.name,
                    idCard: this.idCard,
                };
                if (flag) {
                    //提交有验证码
                    params.code = this.code;
                }
                // console.log("------------11111---------", params)

                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/list", params)
                    .post("api/call/add-white-yd", params)
                    .then((res) => {
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                    });
                if (flag) {
                    this.white_yd4 = false;
                    this.getList();
                }
            },
            //选择table表格
            handleSelectionChange(val) {
                this.numsArray = [];
                this.appArray = [];
                val.forEach((item) => {
                    this.numsArray.push(item.id);
                    this.appArray.push(item.appid);
                });
                // console.log("numsArray", this.numsArray);
                // console.log("appArray", this.appArray);
            },
            // 确认批量删除
            submitDel() {
                let appidA = this.appArray.toString();
                let appidB = appidA.split(",");
                let appid = appidB[0];
                // console.log("获取appid", appid);
                let params = {
                    listId: this.numsArray,
                    appid,
                };
                // console.log("params", params);
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/many-del", params)
                    .post("api/seat-phone/many-del", params)
                    .then((res) => {
                        this.$message.success(res.data.message);
                        this.numsArray.map((v, i) => {
                            let index = this.tableData.findIndex((item) => item.id === v);
                            this.tableData.splice(index, 1);
                        });
                        this.getList();
                    })
                    .catch((err) => {
                    });
                this.delVisible = false;
            },
            // 批量删除
            deleteAll() {
                this.delVisible = true;
                // this.appid = row.row.appid;
                //  // console.log('plappid',this.appid);
            },
            //批量创建
            allSeats() {
                this.allVisible = true;
            },
            //点击下载
            upload() {
                window.open("http://paas.cddmt.cn:9005/phone.xls");
            },
            handleRemove(file, fileList) {
                // console.log(file, fileList);
            },
            handlePreview(file) {
                // console.log(file);
            },
            handleExceed(files, fileList) {
                this.$message.warning(
                    `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
                    files.length + fileList.length
                        } 个文件`
                );
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`);
            },
            changeUpload(file, fileList) {
                this.file = file.raw;
                // console.log(file);
                // console.log(fileList);
            },
            submitUpload() {
                let formDate = new FormData();
                formDate.append("file", this.file);
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/import-seat", formDate)
                    .post("api/seat-phone/import-seat", formDate)
                    .then((res) => {
                        // console.log(res);
                        this.$message.success(res.data.data);
                        this.allVisible = false;
                        this.file = {};
                        this.getList();
                    })
                    .catch((err) => {
                        this.$message.error(err.data.message);
                    });
            },

            //appid选择框
            changeAppid() {
                // console.log(this.id);
                let params = {
                    id: this.id,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/uuid/find-uuidbyuser", params)
                    .post("api/uuid/find-uuidbyuser", params)
                    .then((res) => {
                        this.options2 = res.data.data;
                        // console.log(res.data.data);
                    })
                    .catch((err) => {
                        this.$message.error(err.data.message);
                    });
            },

            //点击代理编号回显appid
            getData(id) {
                // console.log(id);
                let params = {
                    id: id,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/uuid/find-uuidbyuser", params)
                    .post("api/uuid/find-uuidbyuser", params)
                    .then((res) => {
                        this.options2 = res.data.data;
                        // console.log(res.data.data);
                    })
                    .catch((err) => {
                        this.$message.error(err.data.message);
                    });
            },
            handleSizeChange(val) {
                this.size = val;
                this.getList();
            },
            // 分页导航
            handlePageChange(val) {
                this.page = val;
                if (this.white_D == 2) {
                    this.getList_D();
                } else {
                    this.getList();
                }
            },

            //展示、搜索
            getList_D() {
                this.white_D = 2;
                let params = {
                    page: this.page,
                    size: this.size,
                    appid: this.appid1,
                    phone: this.activePhone,
                    userId: this.userId,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/list", params)
                    .post("api/whitePhoneD/seatphone-list", params)
                    .then((res) => {
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            },
            //查询D未加白 展示、搜索
            getList() {
                this.white_D = 1;

                if (this.appid1 != null) {
                    this.flag_appid1 = 1;
                }

                let params = {
                    page: this.page,
                    size: this.size,
                    appid: this.appid1,
                    activePhone: this.activePhone,
                    status: this.value,
                    id: this.userId,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/list", params)
                    .post("api/seat-phone/list", params)
                    .then((res) => {
                        res.data.data.map((v, i) => {
                            this.appid_gouMai = v.appid[0];
                        });
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            },

            //查询D未加白 展示、搜索
            getList_seatMoney() {
                this.white_D = 1;

                if (this.appid1 != null) {
                    this.flag_appid1 = 1;
                }

                let params = {
                    page: this.page,
                    size: this.size,
                    appid: this.appid1,
                    activePhone: this.activePhone,
                    status: this.value,
                    id: this.userId,
                    orderBySeatMoney: 1,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/list", params)
                    .post("api/seat-phone/list", params)
                    .then((res) => {
                        res.data.data.map((v, i) => {
                            this.appid_gouMai = v.appid[0];
                        });
                        this.tableData = res.data.data;
                        this.pageTotal = res.data.total;
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            },
            //重置
            reset() {
                this.appid = null;
                this.appid1 = null;
                this.activePhone = null;
                this.value = null;
                this.userId = null;
                this.white_D = 1;
                this.flag_appid1 = null;
                this.getList();
            },
            //创建坐席
            addSeats() {
                this.options1 = [];
                this.options2 = [];
                (this.form = {}), (this.addVisible = true);
                let params = {
                    page: 1,
                    size: 99,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/user/list", params)
                    .post("api/user/list", params)
                    .then((res) => {
                        this.options1 = res.data.data;
                        this.getList();
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
            },
            //添加确定
            submitAdd() {
                let params = {
                    appid: this.appid,
                    name: this.form.name,
                    activePhone: this.form.activePhone,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/add", params)
                    .post("api/seat-phone/add", params)
                    .then((res) => {
                        //  // console.log(res.data.statusCode)
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                this.addVisible = false;
            },
            //变更号码按钮
            handleClick(row) {
                this.editVisible = true;
                this.forms.name = row.row.name;
                this.forms.activePhone = row.row.activePhone;
                this.id1 = row.row.id;
                this.appid = row.row.appid;
                this.status = row.row.status;
            },
            // 通过输入验证码完成创建坐席
            openCode(scope) {
                let row = scope.row;
                this.codeVisible = true;
                this.forms = row;
            },
            //变更号码确定按钮
            submitCodeEdit() {
                let params = {
                    code: this.noteCode,
                    activePhone: this.forms.activePhone,
                    appid: this.forms.appid,
                };
                // console.log("params", params);
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/update", params)
                    .post("api/seat-phone/save/reply-input", params)
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                            this.getList();
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                this.codeVisible = false;
            },
            //变更号码确定按钮
            submitEdit() {
                let params = {
                    id: this.id1,
                    appid: this.appid,
                    status: this.status,
                    name: this.forms.name,
                    activePhone: this.forms.activePhone,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/update", params)
                    .post("api/seat-phone/update", params)
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                            this.getList();
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                this.editVisible = false;
            },
            revalidationPass(row) {
                let params = {
                    activePhone: row.row.activePhone,
                    appid: row.row.appid,
                    id: row.row.id,
                    name: row.row.name,
                    status: 1,
                };
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/update", params)
                    .post("api/seat-phone/update", params)
                    .then((res) => {
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                            this.getList();
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        // console.log(err);
                    });
                this.editVisible = false;
            },
            //D加白
            whitephone_D(row) {
                let params = {
                    seatPhoneId: row.row.id,
                    appid: row.row.appid,
                    phone: row.row.activePhone,
                };
                // console.log("--------------------", params)
                this.$confirm(
                    "请确定是否正确,非特殊情况不可更改?" +
                    "加白号码为：" +
                    row.row.activePhone,
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                        center: true,
                    }
                )
                    .then(() => {
                        axios
                        // .post("http://106.14.0.130:9998/vo/seat-phone/del", params)
                            .post("api/call/add-white-d", params)
                            .then((res) => {
                                // console.log(res);
                                if (res.data.statusCode == "00000") {
                                    this.$message.success(res.data.message);
                                    this.getList_D();
                                } else {
                                    this.$message.error(res.data.message);
                                    this.getList_D();
                                }
                            })
                            .catch((err) => {
                                // console.log(err);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            //删除
            del(row) {
                let params = {
                    id: row.row.id,
                    appid: row.row.appid,
                    activePhone: row.row.activePhone,
                };
                this.$confirm("确认删除坐席吗，删除后不可恢复?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                    center: true,
                })
                    .then(() => {
                        axios
                        // .post("http://106.14.0.130:9998/vo/seat-phone/del", params)
                            .post("api/seat-phone/del", params)
                            .then((res) => {
                                // console.log(res);
                                if (res.data.statusCode == "00000") {
                                    this.$message.success(res.data.message);
                                    this.getList();
                                } else {
                                    this.$message.error(res.data.message);
                                }
                            })
                            .catch((err) => {
                                // console.log(err);
                            });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消删除",
                        });
                    });
            },
            cutdowns() {
                this.countdown = 10;
                this.timer = setInterval(() => {
                    this.countdown--;

                    if (this.countdown <= 0) {
                        this.countdown = 0;
                        this.timer = null;
                        clearInterval(this.timer);
                    }
                }, 1000);
            },
            //重新验证按钮
            revalidation() {
                clearTimeout(this.timer);
                this.timer = null;

                let params = {
                    activePhone: this.forms.activePhone,
                };
                this.cutdowns();
                // console.log("发送验证码");
                axios
                // .post("http://106.14.0.130:9998/vo/seat-phone/h_get_code", params)
                    .post("api/seat-phone/h_get_code", params)
                    .then((res) => {
                        // console.log(res);
                        if (res.data.statusCode == "00000") {
                            this.$message.success(res.data.message);
                            this.getList();
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                    .catch((err) => {
                        this.$message.error(err.data.message);
                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    .appPage {
        height: 100%;
    }

    ::v-deep .appPageMain {
        height: 100%;
        padding: 15px;
        box-sizing: border-box;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        flex-direction: column;

        .search {
            .el-select {
                width: 100%;
            }

            // height: 70px;
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            // overflow: auto;

            .left {
                // width: 60%;
                width: 648px;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .leftItem {
                    width: 160px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .el-input,
                    .el-select,
                    .el-date-editor {
                        // width: 120px;
                        flex: 1;
                    }

                    .el-input__inner {
                        height: 23px !important;
                        border-radius: 25px;
                        border: 1px solid #666;
                    }

                    .el-input__icon {
                        line-height: 24px !important;
                    }

                    .lab_tit {
                        font-size: 12px;
                        // font-weight: bold;
                        white-space: nowrap;
                        width: 40px !important;
                        text-align: right;
                        margin-right: 3px;
                        margin-left: 8px;
                    }
                }
            }

            .right {
                text-align: right;
                // width: 30%;
                flex: 1;

                // display: flex;
                .el-button {
                    margin-bottom: 3px;
                }
            }
        }

        .mainTable {
            flex: 1;
            display: flex;
            flex-direction: column;

            .table {
                flex: 1;

                // .el-table__row {
                //     td {
                //         padding: 4px 0;
                //     }
                // }
            }

            .mainTableFY {
                height: 40px;
                text-align: right;
                margin-top: 4px;
            }
        }
    }

    ::v-deep .titleBox {
        height: 40px;

        .el-tabs__item {
            font-size: 24px;
            font-weight: bold;
            color: #333333 !important;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content .upload-demo {
        margin-top: 50px;
    }

    .content ::v-deep .el-upload--text {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tit {
        font-weight: normal;
        font-size: 36px;
        color: #333333;
    }

    .search {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        align-items: center;
    }

    .left {
        display: flex;
        align-items: center;
        width: 750px;
    }

    .left span {
        // width: 41%;
        text-align: center;
    }

    .searchBtn {
        margin-left: 15px;
    }

    .right {
        /*display: flex;*/
    }

    .reset {
        width: 80px;
        height: 34px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .add {
        margin-left: 15px;
    }

    .container {
        /* // margin-top: 20px; */
    }

    /* div ::v-deep.el-input__inner{
            width:335px
        } */

</style>