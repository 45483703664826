import axios from "axios";

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  // easy-mock服务挂了，暂时不使用了
  // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
  // baseURL: 'http://syshb.cddmt.cn',
  timeout: 20000,
});

service.interceptors.request.use(
  (config) => {
    // 把本地存储的token取出来
    let token = localStorage.getItem("TOKEN");

    // 请求头携带token字段
    if (token) {
      config.headers["X-Access-Token"] = token;
    }

    return config;
  },
  (error) => {
     // console.log(error);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
     // console.log(error);
    return Promise.reject();
  }
);

export default service;
