<template>
  <div class="appPage">
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="落地管理APP端"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="right">
          <el-button
            type="primary"
            round
            icon="el-icon-search"
            @click="screenDrawerShow = true"
            size="mini"
            >筛选
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-refresh"
            @click="reset"
            round
            size="mini"
            >重置
          </el-button>
          <el-button
            type="primary"
            round
            icon="el-icon-circle-plus-outline"
            @click="openAdd()"
            size="mini"
            >添加
          </el-button>
        </div>
      </div>
      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-row>
            <el-col :span="24"
              ><div
                class="appListBox"
                v-for="(item, index) in tableData"
                :key="index"
                @click="openDetails(item)"
              >
                <div class="justified mg-b6">
                  <div style="color: #999; font-weight: bold">
                    {{ $filters.setlineType(item.id) }}
                  </div>
                  <div
                    :style="{ color: item.status == 1 ? '#67c23a' : '#409eff' }"
                  >
                    {{ item.status == 1 ? "正常" : "冻结" }}
                  </div>
                </div>
                <div class="justified mg-b6">
                  <div style="font-weight: bold">余额：{{ item.money }} 元</div>
                  <div>费率：{{ item.rate }}</div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle">名称:</div>
                  <div class="lcontent">
                    {{ item.name }}
                  </div>
                </div>
                <div class="titleLine">
                  <div class="ltitle">备注:</div>
                  <div class="lcontent">
                    {{ item.remarks }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="mainTableFY">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="pageIndex"
            :page-size="pageSize"
            :pager-count="3"
            :total="pageTotal"
            @current-change="handlePageChange"
          ></el-pagination>
        </div>
      </div>
    </div>

    <!-- 添加弹出框 -->
    <el-dialog title="添加" v-model="addVisible" width="100%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="通道类型">
          <el-select v-model="form.id" placeholder="请选择通道类型">
            <el-option
              v-for="item in options1"
              :key="item.id"
              :label="item.label1"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="费率">
          <el-input-number
            :min="0"
            v-model="form.rate"
            :precision="3"
            :step="1.0"
            :max="99999999"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="100%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <!--<el-form-item label="费率">-->
        <!--<el-input v-model="form.rate"></el-input>-->
        <!--</el-form-item>-->
        <el-form-item label="费率">
          <el-input-number
            :min="0"
            v-model="form.rate"
            :precision="3"
            :step="1.0"
            :max="99999999"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remarks"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 充值弹出框 -->
    <el-dialog title="充值" v-model="addMoney" width="100%">
      <el-form ref="dataAddForm" :rules="addMoneyRules" label-width="80px">
        <el-form-item label="余额">
          <el-input-number
            :min="0"
            v-model="formAdd"
            :precision="3"
            :step="1.0"
            :max="99999999"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="充值/扣费">
          <!-- 到账账户 -->
          <el-radio-group v-model="rechargeValue">
            <el-radio label="充值">充值</el-radio>
            <el-radio label="扣费">扣费</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 备注 -->
        <el-form-item label="备注">
          <el-input v-model="moneyRemarks" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <!-- <el-button @click="addMoney = false">取 消</el-button> -->
          <!-- <el-button type="primary" v-if="formAdd == 0 || formAdd == null" disabled>确 定</el-button> -->
          <!-- <el-button type="warning" v-if="formAdd != 0" @click="rechargeSubmit('扣费')">扣费</el-button> -->
          <el-button type="primary" v-if="formAdd != 0" @click="rechargeSubmit"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <!-- 批量加白弹出框 -->
    <el-dialog
      title="批量加白"
      v-model="allVisible"
      width="100%"
      custom-class="upload"
    >
      <div class="content">
        <el-button type="success" icon="el-icon-download" @click="upload()"
          >点击下载模板
        </el-button>

        <el-upload
          class="upload-demo"
          action=""
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :auto-upload="false"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :on-change="changeUpload"
          drag="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <!-- <template #tip>
                              <div class="el-upload__tip">只能上传 jpg/png 文件，且不超过 500kb</div>
                            </template> -->
        </el-upload>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="allVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitUpload()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 详情和操作 -->
    <el-drawer
      title="详情&操作"
      v-model="drawerShow"
      direction="rtl"
      size="100%"
    >
      <el-row>
        <el-col :span="24"
          ><div class="appListBox" style="padding: 10px">
            <div class="titleLine mg-b6">
              <div class="ltitle">名称:</div>
              <div class="lcontent">
                {{ currentRow.name }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">状态:</div>
              <div
                class="lcontent"
                :style="{
                  color: currentRow.status == 1 ? '#67c23a' : '#409eff',
                }"
              >
                {{ currentRow.status == 1 ? "正常" : "冻结" }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">通道:</div>
              <div class="lcontent">
                {{ $filters.setlineType(currentRow.id) }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">余额:</div>
              <div class="lcontent">{{ currentRow.money }}元</div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">费率:</div>
              <div class="lcontent">
                {{ currentRow.rate }}
              </div>
            </div>
            <div class="titleLine">
              <div class="ltitle">备注:</div>
              <div class="lcontent">
                {{ currentRow.remarks }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="padding: 10px">
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="recharge(currentRow)"
          size="mini"
          >充值
        </el-button>
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="handleClick(currentRow)"
          size="mini"
          >编辑
        </el-button>
        <el-button
          type="primary"
          style="margin-top: 10px"
          @click="lock(currentRow)"
          size="mini"
          v-if="currentRow.status == '1'"
          >锁定
        </el-button>
        <el-button
          type="danger"
          style="margin-top: 10px"
          @click="lock(currentRow)"
          size="mini"
          v-if="currentRow.status == '2'"
          >解锁
        </el-button>
      </div>
    </el-drawer>

    <!-- 筛选过滤 -->
    <el-drawer
      title="筛选数据"
      v-model="screenDrawerShow"
      direction="ltr"
      size="100%"
    >
      <el-row>
        <el-col :span="24">
          <div class="search">
            <div class="left">
              <div class="leftItem">
                <div class="leftItem">
                  <span class="lab_tit">选择通道</span>
                  <el-select size="mini" v-model="id" placeholder="请选择通道">
                    <el-option
                      v-for="item in options1"
                      :key="item.id"
                      :label="item.label1"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="leftItem">
                <span class="lab_tit">代理名称</span>
                <el-input
                  v-model="name"
                  placeholder="请输入线路名称"
                  v-on:keyup.enter="handleSearch"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem">
                <span class="lab_tit">锁定状态</span>
                <el-select
                  v-model="status"
                  placeholder="请选择锁定状态"
                  size="mini"
                >
                  <el-option
                    v-for="item in options4"
                    :key="item.type"
                    :label="item.label1"
                    :value="item.type"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <hr />
      <div style="padding: 10px">
        <el-button
          type="primary"
          round
          icon="el-icon-search"
          @click="handleSearch"
          size="mini"
          >查找
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh"
          @click="reset"
          round
          size="mini"
          >重置
        </el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";

import {
  customerQuestionList,
  customerQuestionAdd,
  customerQuestionUpdate,
} from "@/api/company_renew";

export default {
  name: "agent",
  data() {
    return {
      screenDrawerShow: false,
      currentRow: {},
      drawerShow: false,
      rechargeValue: "充值",
      rechargeType: 0,
      options1: [
        // {
        //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
        //   type: 0,
        //   label1: "隐私小号通道",
        // },

        // {
        //     type: 5,
        //     label1: "移动ax通道",
        // },
        {
          id: 1,
          label1: "南京线通道",
        },
        {
          id: 2,
          label1: "移动1通道",
        },
        // {
        //     type: 10,
        //     label1: "移动3通道",
        // },

        // {
        //     type: 13,
        //     label1: "移动5通道",
        // },
        // {
        //   type: 3,
        //   label1: "联通2通道",
        // },
        // {
        //   type: 4,
        //   label1: "联通1通道",
        // },

        // {
        //   type: 6,
        //   label1: "联通3通道",
        // },
        // {
        //   type: 7,
        //   label1: "联通4通道",
        // },
        {
          id: 8,
          label1: "H",
        },
        {
          id: 14,
          label1: "S",
        },

        {
          id: 15,
          label1: "D",
        },

        // {
        //     type: 16,
        //     label1: "S2",
        // },

        {
          id: 17,
          label1: "SIP",
        },
        {
          id: 18,
          label1: "X",
        },
        {
          id: 20,
          label1: "甘肃",
        },
        {
          id: 21,
          label1: "2号线",
        },
        {
          id: 22,
          label1: "3号线",
        },
        {
          id: 23,
          label1: "新2号线",
        },
        {
          id: 24,
          label1: "重庆线路",
        },
        {
          id: 25,
          label1: "1号线",
        },
        {
          id: 26,
          label1: "无锡",
        },
        {
          id: 27,
          label1: "翼信",
        },
        {
          id: 28,
          label1: "连云港YT",
        },
      ],
      companyType: 0,
      earlyWarningVisible: false,
      agentId: null,
      addForm: {},
      uploadHeaders: {
        "X-Access-Token": localStorage.getItem("TOKEN"),
      }, // 请求头携带token值
      currentCompanyTake: {},
      forms: {},
      rechargeRecord: [],
      pageTotalAddMoney: 0,
      addMoney_record_flag: false,
      options4: [
        {
          type: 1,
          label1: "未锁定",
        },
        {
          type: 2,
          label1: "已锁定",
        },
      ],
      imgUrl: "",
      status: null,
      rechargeBtn: false,
      tableData: [],
      multipleSelection: [],
      yd1NumShow: false,
      delList: [],
      addVisible: false, //添加弹框是否显示
      editVisible: false, //编辑弹框是否显示
      addWhiteShow: false, //主叫加白弹框是否显示
      remarks: "", //充值时备注，只传不收
      pageTotal: 0,
      addMoney: false,
      allVisible: false,
      param_id: "",
      param_id1: "",
      appid: "",
      phone: "",
      code: "",
      name: "",
      idCard: "",
      operators: "",
      salesman: "",
      Options2: [],
      Options3: [],
      type: null,
      id: null,
      moneyRemarks: null,
      form: {
        id: "",
        username: "",
        password: "",
        money: "",
        realname: "",
        seatCount: "",
        status: "",
        oldMoney: null,
        remark: "",
        operators: null,
        salesman: null,
      },
      pageIndex: 1,
      pageSize: 10,
      page: 1,
      size: 10,
      companyTakeShow: false,
      options: [
        {
          status: "1",
          label: "正常",
        },
        {
          status: "2",
          label: "禁止",
        },
      ],
      username: null,
      realname: null,
      formAdd: "",
    };
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        // 监听窗口大小 document.body.clientWidth
        if (document.body.clientWidth <= 900) {
          // 手机端适配,自动跳转到对应的手机页面
        }
      })();
    };
  },
  created() {
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    username() {
      if (this.username == "") {
        this.username = null;
      }
    },
    realname() {
      if (this.realname == "") {
        this.realname == null;
      }
    },
    //  "type"(newValue, oldValue){
    //    // console.log(newValue)
    //   this.options2 = []
    //   this.getData(newValue)
    // }
  },
  methods: {
    openDetails(item) {
      console.log("点击了", item);
      this.currentRow = item;
      this.drawerShow = true;
    },
    updataEarlyWarning() {
      // 表单验证
      this.$refs["addForm"].validate(async (valid) => {
        if (valid) {
          this.earlyWarningVisible = false;
          let params = {
            ...this.addForm,
          };
          axios
            .post("api/user-money-alarm/add-update", params)
            .then((res) => {
              if (res.data.statusCode == "00000") {
                this.$message.success(res.data.message);
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        } else {
          return false;
        }
      });
    },
    getMoneyAlarm(row) {
      let params = {
        userId: row.id,
      };
      axios
        .post("api/user-money-alarm/list", params)
        .then((res) => {
          // console.log("res", res.data);
          this.earlyWarningVisible = true;
          res.data.data.length != 0
            ? (this.addForm = res.data.data[0])
            : (this.addForm = {});
          this.addForm.userId = row.id;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //展示、搜索
    getList_addMoney_recoed(id) {
      let params = {
        page: this.page1,
        size: this.size,
        id: id,
        remarks: "admin",
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/list", params)
        .post("api/log/userlist", params)
        .then((res) => {
          //  // console.log(res)
          this.rechargeRecord = res.data.data;
          this.pageTotalAddMoney = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 分页导航
    handlePageChange_addMoney_recoed(val) {
      this.page1 = val;
      this.getList_addMoney_recoed(this.id);
    },
    //充值记录
    addMoney_record() {
      this.addMoney_record_flag = true;
      // console.log("id------------------------", this.id)
      this.getList_addMoney_recoed(this.id);
    },
    //批量加白
    allWhite() {
      this.allVisible = true;
    },
    //点击下载
    upload() {
      window.open("http://118.190.2.70:9005/white.xls");
    },
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    changeUpload(file, fileList) {
      this.file = file.raw;
      // console.log(file);
      // console.log(fileList);
    },
    submitUpload() {
      let formDate = new FormData();
      formDate.append("file", this.file);
      axios
        // .post("http://106.14.0.130:9998/vo/call/import-seat", formDate)
        .post("api/call/import-seat", formDate)
        .then((res) => {
          // console.log(res.data.statusCode);
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.data);
            this.allVisible = false;
            this.file = {};
            this.getList();
          } else {
            this.$message.error(res.data.data);
            this.allVisible = false;
            this.file = {};
            this.getList();
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },

    // 分页导航
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    //展示、搜索
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        name: this.name,
        status: this.status,
        id: this.id,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/list", params)
        .post("api/user-top/list", params)
        .then((res) => {
          //  // console.log(res)
          this.tableData = res.data.data;
          this.pageTotal = res.data.total;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    //重置
    reset() {
      this.form = {};
      this.id = null;
      this.name = null;
      this.status = null;
      this.getList();
      this.screenDrawerShow = false;
    },
    //搜索
    handleSearch() {
      this.getList();
      this.screenDrawerShow = false;
    },
    openAdd() {
      this.addVisible = true;
      // this.form.name = null;
      // this.form.remarks = null;
      this.form = [];
    },

    //添加确定按钮
    submitAdd() {
      let params = {
        name: this.form.name,
        remarks: this.form.remarks,
        id: this.form.id,
        rate: this.form.rate,
      };
      this.rechargeBtn = false;
      axios
        // .post("http://106.14.0.130:9998/vo/user/add", params)
        .post("api/user-top/add-update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            setTimeout(() => {
              this.rechargeBtn = false;
            }, 2000);
            this.addVisible = false;
            this.getList();
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
          this.rechargeBtn = false;
          this.$message({
            message: res.data.message,
          });
        });
    },
    //编辑回显
    handleClick(row) {
      this.form.id = row.id;
      this.form.name = row.name;
      this.form.remarks = row.remarks;
      this.form.rate = row.rate;
      this.editVisible = true;
    },
    //编辑确定按钮
    saveEdit() {
      let params = {
        id: this.form.id,
        name: this.form.name,
        remarks: this.form.remarks,
        rate: this.form.rate,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-top/add-update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          this.editVisible = false;
          this.realname = null;
          this.getList();
        });
    },

    //绑定
    lock(row) {
      let status = "";
      if (row.status == 1) {
        status = 2;
      } else {
        status = 1;
      }
      let params = {
        id: row.id,
        status: status,
      };
      this.$confirm("请确定是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          axios
            // .post("http://106.14.0.130:9998/vo/user/update", params)
            .post("api/user-top/add-update", params)
            .then((res) => {
              // console.log(res);
              if (res.data.statusCode == "00000") {
                this.$message.success(res.data.message);
                this.getList();
              }
            })
            .catch((err) => {
              // console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    //充值
    recharge(row) {
      //  // console.log(row);
      this.addMoney = true;
      this.id = row.id;
      this.formAdd = 0;
      this.money = row.money;
      this.realname = row.realname;
      this.remarks = null;
      this.companyType = 0;
      this.rechargeType = 0;
      this.rechargeValue = "充值";
    },
    //充值提交确定按钮
    rechargeSubmit(type) {
      // 扣费
      // 充值

      // if (!this.imgUrl) {
      //     this.$message.warning('充值时必须上传附件图片!');
      //     return false
      // }
      this.rechargeBtn = false;

      let params = {
        id: this.id,
        oldMoney: this.money,
        money: this.formAdd,
        moneyRemarks: this.moneyRemarks,
      };
      if (this.rechargeValue == "扣费") {
        params.money = this.formAdd * -1;
      }
      this.addMoney = false;
      this.rechargeBtn = true;
      axios
        // .post("http://106.14.0.130:9998/vo/user/update", params)
        .post("api/user-top/add-update", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            // setTimeout(() => {
            //     this.rechargeBtn = false;
            // }, 2000);
            this.$message({
              message: res.data.message,
              type: "success",
            });
            this.realname = null;
            this.username = null;
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.$refs.uploadImg.clearFiles();
          this.imgUrl = "";
          this.rechargeBtn = false;
          this.addMoney = false;
        });
    },

    //主叫加白
    addWhite() {
      this.addWhiteShow = true;
      // this.appid = null;
      // this.param_id = null;
      this.num = null;
      // this.code = null;
      let params = {
        type: this.type,
      };
      //  // console.log(this.type)
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/findAllkey", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          //  // console.log(res)
          this.options2 = res.data.data;
          // this.$message.success(res.data.message)
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    //主叫加白提交按钮
    addWhiteSubmit() {
      let params = {
        appid: this.appid,
        param_id: this.param_id,
        phone: this.phone,
        name: this.name,
        idCard: this.idCard,
        code: this.code,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-yd", params)
        .post("api/call/add-white-yd", params)
        .then((res) => {
          this.addWhiteShow = false;
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => {
          this.addMoney = false;
        });
    },

    //移动一激活号码
    yd1Num() {
      this.yd1NumShow = true;
      this.phone = null;
      this.code = null;
      this.param_id1 = null;
      let params = {
        type: 2,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/uuid/findAllkey", params)
        .post("api/uuid/findAllkey", params)
        .then((res) => {
          //  // console.log(res)
          this.options3 = res.data.data;
          // this.$message.success(res.data.message)
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getCode() {
      let params = {
        phone: this.phone,
        code: "",
        param_id: this.param_id1,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/yd_one_dx_send", params)
        .post("api/call/yd_one_dx_send", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    yd1NumSubmit() {
      let params = {
        phone: this.phone,
        code: this.code,
        param_id: this.param_id1,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/call/yd_one_dx_send", params)
        .post("api/call/yd_one_dx_send", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.data);
          } else {
            this.$message.error(res.data.message);
          }
          this.yd1NumShow = false;
        })
        .catch((err) => {
          // console.log(err);
          this.yd1NumShow = false;
        });
    },
    //点击企业列表按钮
    enterprisesList(row) {
      //  // console.log(row.row.id)
      this.$router.push({
        path: "/enterprise",
        query: {
          userId: row.row.id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.mg-b6 {
  margin-bottom: 6px;
}
.appListBox {
  display: flex;
  flex-flow: column;
  padding: 10px 0;
  border-bottom: 1px solid green;
  .justified {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titleLine {
    display: flex;
    .ltitle {
      font-weight: bold;
      width: 55px;
    }
    
.lcontent {
      flex: 1;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}

.appPage {
  // height: 100%;
}

::v-deep .appPageMain {
  // height: 100%;
  overflow-y: auto;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 14px;
    font-weight: bold;
    color: #333333 !important;
  }
}

div ::v-deep .el-select {
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content .upload-demo {
  margin-top: 50px;
}

.content ::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

::v-deep .search {
  // height: 70px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .left {
    // width: 60%;
    width: 580px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .leftItem {
      width: 192px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .el-input,
      .el-select,
      .el-date-editor {
        // width: 120px;
        flex: 1;
      }

      .el-input__inner {
        height: 23px !important;
        border-radius: 25px;
        border: 1px solid #666;
      }

      .el-input__icon {
        line-height: 24px !important;
      }

      .lab_tit {
        font-size: 12px;
        // font-weight: bold;
        white-space: nowrap;
        width: 50px !important;
        text-align: right;
        margin-right: 3px;
        margin-left: 8px;
      }
    }
  }

  .right {
    text-align: right;
    // width: 30%;
    flex: 1;

    // display: flex;
    .el-button {
      margin-bottom: 3px;
    }
  }
}

.el-input {
  width: 100%;
}

.el-select {
  width: 100%;
}

.item:nth-child(1) {
  margin-left: 0;
}

.searchBtn {
  margin-left: 15px;
}

.reset {
  width: 90px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* // margin-top: 20px; */
}

::v-deep.operateColumn {
  .el-button {
    margin-top: 3px !important;
    margin-left: 4px !important;
  }

  .el-button--mini {
    // min-height: 20px;
    // padding: 8px;
  }
}
</style>
