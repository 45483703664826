<template>
    <div class="appPage">
        <div class="appPageMain">
            <el-tabs type="card" class="titleBox">
                <el-tab-pane label="人工质检结果"></el-tab-pane>
            </el-tabs>
            <div class="search">
                <div class="left">
                    <div class="leftItem">
                        <span class="lab_tit">appid</span>
                        <el-input v-model="currentForm.appid" size="mini" v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">话单id</span>
                        <el-input v-model="currentForm.callId" size="mini" v-on:keyup.enter="handleSearch"></el-input>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">质检员</span>
                        <!-- <el-input v-model="currentForm.operators" size="mini"
                        v-on:keyup.enter="handleSearch"></el-input> -->
                        <el-select v-model="currentForm.operators" size="mini">
                            <el-option v-for="item in zjUserList" :key="item.name" :label="item.name"
                                :value="item.name"></el-option>
                        </el-select>
                    </div>
                    <div class="leftItem">
                        <span class="lab_tit">状态</span>
                        <!-- <el-input v-model="currentForm.operators" size="mini"
                        v-on:keyup.enter="handleSearch"></el-input> -->
                        <el-select size="mini" v-model="currentForm.status">
                            <el-option v-for="item in zjStatusList" :key="item.value" :label="item.status"
                                :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <div class="leftItem" style="width: 270px;">
                        <span class="lab_tit">添加日期</span>
                        <el-date-picker v-model="addTiem" size="mini" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" :clearable="false" end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
                <div class="right">
                    <el-button type="primary" icon="el-icon-search" @click="handleSearch" size="mini" round>查找
                    </el-button>
                    <el-button size="mini" round type="primary" icon="el-icon-refresh" @click="reset()">重置
                    </el-button>
                </div>
            </div>
            <div class="container mainTable">
                <hr>
                <div class="table">
                    <el-table :data="tableData" stripe height="calc(100% - 4rem)" ref="multipleTable"
                        header-cell-class-name="table-header" @cell-dblclick="ellDblclick"
                        @selection-change="selectionLineChangeHandle">
                        <el-table-column type="selection" width="55"></el-table-column>
                        <el-table-column prop="operators" label="质检员" v-if="realname != 'admin'"></el-table-column>
                        <el-table-column prop="name" label="公司名称"></el-table-column>

                        <el-table-column prop="appid" label="appid"></el-table-column>
                        <el-table-column prop="callId" label="话单id"></el-table-column>

                        <el-table-column prop="type" label="通道类型" width="90">
                            <template #default="scope">
                                <span v-if="scope.row.type == 0">隐私小号通道</span>
                                <span v-if="scope.row.type == 1">南京线</span>
                                <span v-if="scope.row.type == 2">电信</span>
                                <span v-if="scope.row.type == 3">联通2</span>
                                <span v-if="scope.row.type == 4">联通1</span>
                                <span v-if="scope.row.type == 5">移动ax</span>
                                <span v-if="scope.row.type == 6">联通3</span>
                                <span v-if="scope.row.type == 7">联通4</span>
                                <span v-if="scope.row.type == 8">H</span>
                                <span v-if="scope.row.type == 9">联通5</span>
                                <span v-if="scope.row.type == 10">移动3</span>
                                <span v-if="scope.row.type == 11">联通6</span>
                                <span v-if="scope.row.type == 12">联通7</span>
                                <span v-if="scope.row.type == 13">移动5</span>
                                <span v-if="scope.row.type == 14">S</span>
                                <span v-if="scope.row.type == 15">D</span>
                                <span v-if="scope.row.type == 16">S2</span>
                                <span v-if="scope.row.type == 16">SIP</span>
                                <span v-if="scope.row.type == 18">X</span>
                                <span v-if="scope.row.type == 21">2号线</span>
                                <span v-if="scope.row.type == 22">3号线</span>
                                <span v-if="scope.row.type == 23">新2号线</span>
                                <span v-if="scope.row.type == 26">无锡</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="type" label="状态" width="90">
                            <template #default="scope">
                                <span v-if="scope.row.status == 1" style="color: greenyellow">符合</span>
                                <span v-if="scope.row.status == 2" style="color: red">不符合</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column  prop="status" label="确认状态"  width="70">
                    <template #default="scope">
                        <span v-if="scope.row.status == 0">未查看</span>
                        <span v-if="scope.row.status == 1" style="color: chartreuse">合格</span>
                        <span v-if="scope.row.status == 2" style="color: red">未合格</span>
                        <span v-if="scope.row.status == 3" style="color: #d8c732">质检失败</span>
                    </template>
                </el-table-column> -->
                        <el-table-column prop="remarks" label="备注"></el-table-column>
                        <el-table-column prop="createdTime" label="添加时间" width="200"></el-table-column>

                        <!--<el-table-column  label="操作" width="300" >-->
                        <!--<template #default="scope">-->
                        <!--<el-button type="primary" @click="handleClick(scope)"-->
                        <!--&gt;编辑</el-button-->
                        <!--&gt;-->
                        <!--<el-button type="primary" @click="del(scope)">删除</el-button>-->
                        <!--</template>-->
                        <!--</el-table-column>-->
                    </el-table>
                    <div class="mainTableFY">
                        <el-pagination background layout="total, prev, pager, next,sizes" :current-page="pageIndex"
                            :page-size="pageSize" :page-sizes="[10, 20, 50, 100, 200]" :total="pageTotal"
                            @current-change="handlePageChange" @size-change="handleSizeChange"></el-pagination>
                    </div>
                </div>
            </div>
        </div>


        <!--<el-dialog title="质检结果" v-model="qcLog_flag" width="80%">-->
        <!--<el-form ref="dataAddForm" :model="qcLog_from" :rules="editRules" label-width="100px">-->

        <!--<el-form-item label="公司名称" prop="name">-->
        <!--<el-input v-model="qcLog_from.name" style="width: 100%" disabled></el-input>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="话单id" prop="userId">-->
        <!--<el-input v-model="qcLog_from.callId" style="width: 100%" disabled></el-input>-->
        <!--</el-form-item>-->
        <!--<el-form-item label="录音" prop="userId">-->
        <!--<audio id="videoDiv2" :src="qcLog_from.recordUrl" controls="controls"></audio>-->
        <!--</el-form-item>-->

        <!--<el-divider style="height: 4px">-->
        <!--<el-button type="success" icon="el-icon-check" @click="updateStatus(1)" style="margin-right: 40px">-->
        <!--合格-->
        <!--</el-button>-->
        <!--<el-button type="danger" icon="el-icon-close" @click="no_pass = true" style="margin-right: -2px">-->
        <!--不合格-->
        <!--</el-button>-->

        <!--</el-divider>-->

        <!--<div class="quality">-->
        <!--<el-card class="quality-left">-->
        <!--<div v-for="(v, i) in qcLog_from.qcLog_from_list" class="quality-for">-->
        <!--<img class="i" src="../assets/img/LOGO.png" style="margin-left: 5px;border-radius: 50%" />-->
        <!--<p class="quality-data" v-html="getGJZstr(v)"></p>-->
        <!--<audio id="videoDiv" :src="qcLog_from.recordUrl" controls="controls" controlslist="nodownload"-->
        <!--:hidden="true"></audio>-->
        <!--<el-button plain circle size="mini" @click='play(i)'>播放</el-button>-->
        <!--&lt;!&ndash;<el-button plain  circle  size="mini"   v-if="null == qcLog_from.time_list[i]"></el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-button plain  circle  size="mini" @click='play(i)' v-if="null != qcLog_from.time_list[i]">{{(qcLog_from.time_list[i].split("-")[1] - qcLog_from.time_list[i].split("-")[0])/1000 }}</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-button plain  circle  size="mini" @click='play(i)' v-else>{{(qcLog_from.time_list[i].split("-")[1] - qcLog_from.time_list[i].split("-")[0])/1000 }}</el-button>&ndash;&gt;-->

        <!--</div>-->
        <!--</el-card>-->

        <!--<el-card class="quality-right">-->
        <!--<el-tabs v-model="activeName">-->
        <!--<el-tab-pane label="正向触发" name="quality" class="quality-right-for">-->
        <!--<div v-for="(v, i) in qcLog_from.qcLog_from_twords_list">-->
        <!--<p class="quality-data" @mousedown='word_button(v)' style="cursor: pointer">{{ v }}</p>-->
        <!--</div>-->

        <!--</el-tab-pane>-->
        <!--<el-tab-pane label="反向触发" name="second" class="quality-right-for">-->
        <!--<div v-for="(v, i) in qcLog_from.qcLog_from_fwords_list">-->
        <!--<p class="quality-data" @mousedown='word_button(v)' style="cursor: pointer"> {{ v }}</p>-->
        <!--</div>-->
        <!--</el-tab-pane>-->
        <!--<el-tab-pane label="高危触发" name="danger" class="quality-right-for">-->
        <!--<div v-for="(v, i) in qcLog_from.qcLog_from_fdangerWords_list">-->
        <!--<p class="quality-data" @mousedown='word_button(v)' style="cursor: pointer">{{ v }}</p>-->
        <!--</div>-->
        <!--</el-tab-pane>-->
        <!--</el-tabs>-->
        <!--</el-card>-->
        <!--</div>-->
        <!--</el-form>-->
        <!--<template #footer>-->
        <!--&lt;!&ndash;<span class="dialog-footer">&ndash;&gt;-->
        <!--&lt;!&ndash;<el-button @click="userLogTicketFlag = false">取 消</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;<el-button type="primary" @click="editSubmit()">确 定</el-button>&ndash;&gt;-->
        <!--&lt;!&ndash;</span>&ndash;&gt;-->
        <!--</template>-->
        <!--</el-dialog>-->
        <!---->
        <!--<el-dialog title="不合格原因" v-model="no_pass" width="30%">-->
        <!--&lt;!&ndash;<el-form-item label="公司名称" prop="name">&ndash;&gt;-->
        <!--<el-input v-model="qcLog_from.remarks" style="width: 100%" type="textarea"></el-input>-->
        <!--&lt;!&ndash;</el-form-item>&ndash;&gt;-->
        <!--<template #footer>-->
        <!--<span class="dialog-footer">-->
        <!--<el-button @click="no_pass = false">取 消</el-button>-->
        <!--<el-button type="primary" @click="updateStatus(2)">确 定</el-button>-->
        <!--</span>-->
        <!--</template>-->
        <!--</el-dialog>-->
        <!--质检企业个数弹出框-->
        <!--<el-dialog title="质检企业个数" v-model="find_count_flag" width="30%">-->
        <!--<h2>{{ find_count_message }}</h2>-->
        <!--<template #footer>-->
        <!--<span class="dialog-footer">-->
        <!--<el-button @click=" find_count_flag = false">取 消</el-button>-->
        <!--</span>-->
        <!--</template>-->
        <!--</el-dialog>-->

        <!--批量处理弹出框-->
        <!--<el-dialog title="批量处理" v-model="yes_no_many_pass" width="30%">-->
        <!--&lt;!&ndash;<el-form-item label="公司名称" prop="name">&ndash;&gt;-->
        <!--<el-select v-model="many_status" placeholder="请选择" class="handle-input mr5">-->
        <!--<el-option v-for="item in options3" :key="item.many_status" :label="item.label" :value="item.many_status">-->
        <!--</el-option>-->
        <!--</el-select>-->
        <!--<el-input v-model="remarks" style="width: 100%" type="textarea"></el-input>-->
        <!--&lt;!&ndash;</el-form-item>&ndash;&gt;-->
        <!--<template #footer>-->
        <!--<span class="dialog-footer">-->
        <!--<el-button @click="no_pass = false">取 消</el-button>-->
        <!--<el-button type="primary" @click="updateStatus_many">确 定</el-button>-->
        <!--</span>-->
        <!--</template>-->
        <!--</el-dialog>-->
    </div>
</template>

<script>


import axios from "axios";

export default {
    name: "centerNum",
    data() {
        return {
            zjUserList: [
                {
                    name: '刘文熙'
                },
                {
                    name: '陈红盈'
                },
                {
                    name: '庞婷'
                },
                {
                    name: '石文静'
                }
            ],
            zjStatusList: [
                {
                    status: '符合',
                    value: '1'
                },
                {
                    status: '不符合',
                    value: '2'
                },
            ],
            currentForm: {},
            addTiem: null,
            gjz: null,
            find_count_message: null,//查询质检企业信息
            find_count_flag: false, //查询质检企业个数
            dataonLineListSelections: [],
            no_pass: false,  //不合格弹出框
            timestamp: null,
            noNull_fWords: null,
            yesNull_tWords: null,
            noNull_fDangerWords: null,

            options: [
                {
                    status: 0,
                    label: "未查看",
                },
                {
                    status: 1,
                    label: "合格",
                },
                {
                    status: 2,
                    label: "未合格",
                },
                {
                    status: 3,
                    label: "质检失败",
                },
            ],
            options3: [

                {
                    many_status: 1,
                    label: "合格",
                },
                {
                    many_status: 2,
                    label: "未合格",
                },
            ],
            options1: [
                // {
                //   //和chargeMode相比少了"",仔细看获取的data数据，type和chargeMode是不一样的数据类型
                //   type: 0,
                //   label1: "隐私小号通道",
                // },
                {
                    type: 5,
                    label1: "移动ax通道",
                },
                {
                    type: 2,
                    label1: "移动1通道",
                },
                {
                    type: 10,
                    label1: "移动3通道",
                },
                {
                    type: 1,
                    label1: "南京线通道",
                },
                {
                    type: 13,
                    label1: "移动5通道",
                },
                // {
                //   type: 3,
                //   label1: "联通2通道",
                // },
                // {
                //   type: 4,
                //   label1: "联通1通道",
                // },

                // {
                //   type: 6,
                //   label1: "联通3通道",
                // },
                // {
                //   type: 7,
                //   label1: "联通4通道",
                // },
                {
                    type: 8,
                    label1: "H",
                },
                {
                    type: 14,
                    label1: "S",
                },

                {
                    type: 15,
                    label1: "D",
                },

                {
                    type: 16,
                    label1: "S2",
                },
                {
                    type: 17,
                    label1: "SIP",
                },
                {
                    type: 18,
                    label1: "X",
                },
                {
                    type: 21,
                    label1: "2号线",
                },
            ],
            activeName: 'quality',
            qcLog_from: {},  //双击 质检结果
            qcLog_flag: false,  //双击 质检结果弹出
            areaCode: "",
            form: {},
            appid: null,
            callId: null,
            type: null,
            name: null,
            twords: null,
            fwords: null,
            fdangerWords: null,
            operators: null,
            status: null,
            talk: null,
            remarks: null,
            second: null,
            many_status: null,
            yes_no_many_pass: false, //批量处理弹出框
            editVisible: false, //是否显示编辑弹窗
            addVisible: false, //是否显示添加弹窗
            pageIndex: 1, //当前在第几页
            pageSize: 10, //前端规定每页显示数量
            pageTotal: 0, //总共有多少数据
            tableData: [], //用于接收后端数据表的数组
            page: 1, //接口请求的页数
            size: 10, //接口请求的每页的数量

        };
    },
    created() {
        let rootDate = new Date().toLocaleDateString()
        this.addTiem = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
        this.getList();
    },
    //切换界面就执行方法
    activated() {
        this.getList();
    },
    watch: {},
    methods: {
        formatDate(date) {
            var date = new Date(date);
            var YY = date.getFullYear() + "-";
            var MM =
                (date.getMonth() + 1 < 10
                    ? "0" + (date.getMonth() + 1)
                    : date.getMonth() + 1) + "-";
            var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
            var hh =
                (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
            var mm =
                (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
                ":";
            var ss =
                date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            return YY + MM + DD + " " + hh + mm + ss;
        },
        getGJZstr(v) {

            // console.log("点击", this.gjz);
            var reg = new RegExp(this.gjz, 'g');//g 全局匹配
            // var html = $('.quality').text(); //用文字，不能用 $(this).html(),这里只匹配标签中的文字
            var newHtml = v.replace(reg, '<font class="bh" color="red " size="12px">' + this.gjz + '</font>'); //这里就是标红
            return newHtml
        },
        word_button(v) {
            //  // console.log("点击",v);
            // var reg = new RegExp(v, 'g');//g 全局匹配
            // var html = $('.quality').text(); //用文字，不能用 $(this).html(),这里只匹配标签中的文字
            // var newHtml = html.replace(reg, '<font class="bh" color="red">'+v+'</font>'); //这里就是标红
            // $('.quality').html(newHtml);
            this.$message.success(v + "已标红放大");
            this.gjz = v
        },
        // 质检录音 转文字
        qc_sendRecord_text() {
            let arr = [];
            this.dataonLineListSelections.forEach((v) => {
                let qc = {};

                qc.callId = v.callId;
                qc.recordUrl = v.recordUrl;
                qc.uuid = v.uuid
                qc.type = v.type
                qc.chooseType = "2"
                qc.appid = v.appid
                qc.name = v.name
                v.record_url != "" ? arr.push(qc) : "";
            });
            let params = {
                qcList: arr
            };
            axios
                .post("api/quality/send/aliyun-sendRecord-wait-list", params)
                .then((res) => {
                    // console.log(">>>>>>>>", res.data);
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
        },
        //查询质检企业个数
        find_count() {

            this.find_count_flag = true
            if (this.timestamp != null) {
                this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[0]);
                this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.timestamp[1]);
            }
            let params = {
                sta: this.sta,
                end: this.end,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/xphoneS/list", params)
                .post("api/quality/find-count", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.$message.success(res.data.message);

                        this.find_count_message = res.data.message
                    } else {
                        this.find_count_flag = false
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },

        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getList();
        },
        //批量处理 同意 或不同意
        updateStatus_many() {
            let listid = []
            this.dataonLineListSelections.forEach((v) => {
                listid.push(v.id)
            });
            // console.log("listId----------------------", listid)
            let params = {
                qcListId: listid,
                status: this.many_status,
                remarks: this.remarks,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/xphoneS/list", params)
                .post("api/quality/update-many", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        },

        // 添加选中项
        selectionLineChangeHandle(val) {
            this.dataonLineListSelections = val;
            // console.log("已被选中的数据", this.dataonLineListSelections);
        },
        //分段播放
        play(i) {
            if (null == this.qcLog_from.time_list) {
                return;
            }
            let timer;
            let split = this.qcLog_from.time_list[i].split("-");
            let sta = split[0];
            let end = split[1];
            var myVid = document.getElementById("videoDiv");
            var myVid2 = document.getElementById("videoDiv2");
            if (myVid != null) {
                this.$nextTick(() => {
                    myVid.load(); //方法重新加载音频/视频元素
                    myVid.currentTime = (sta / 1000); //默认指定音频默认从20s的时候开始播放（默认时间为s）
                    myVid2.load(); //方法重新加载音频/视频元素
                    myVid2.currentTime = (sta / 1000); //默认指定音频默认从20s的时候开始播放（默认时间为s）
                    // myVid.oncanplay = function () {
                    //     //duration 属性返回当前音频的长度，以秒计。
                    //      // console.log("音频时间", myVid.duration);
                    //     //  // console.log("时分秒格式转化：", timeToMinute(myVid.duration))
                    // }
                    //  // console.log("开始")
                    myVid.play()
                    timer = setTimeout(() => {
                        //  // console.log("结束")
                        myVid.pause()
                    }, 2400);
                })
                clearTimeout(timer)
            }
        },

        // 转换日期
        getYMDHMS(format, date) {
            if (!date) {
                return "";
            }
            date = new Date(date);
            const dataItem = {
                "Y+": date.getFullYear().toString(),
                "m+": (date.getMonth() + 1).toString(),
                "d+": date.getDate().toString(),
                "H+": date.getHours().toString(),
                "M+": date.getMinutes().toString(),
                "S+": date.getSeconds().toString(),
            };
            Object.keys(dataItem).forEach((item) => {
                const ret = new RegExp(`(${item})`).exec(format);
                if (ret) {
                    format = format.replace(
                        ret[1],
                        ret[1].length === 1
                            ? dataItem[item]
                            : dataItem[item].padStart(ret[1].length, "0")
                    );
                }
            });
            return format;
        },

        //展示and查找
        updateStatus(vlues) {

            let params = {
                id: this.qcLog_from.id,
                status: vlues,
                remarks: this.qcLog_from.remarks,
            };
            axios
                // .post("http://106.14.0.130:9998/vo/xphoneS/list", params)
                .post("api/quality/update", params)
                .then((res) => {
                    if (res.data.statusCode == "00000") {
                        this.getList();
                        this.$message.success(res.data.message);
                    } else {
                        this.$message.error(res.data.message);
                    }
                    this.qcLog_flag = false;  //质检结果
                    this.no_pass = false;  //质检结果
                    this.qcLog_from = [];  //质检结果

                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        //双击
        ellDblclick(row, column, cell, event) {
            this.qcLog_from = row;  //质检结果弹出
            // this.qcLog_from.qcLog_from_list = []  //双击 质检结果 集

            if (null != row.talk) {
                this.qcLog_from.qcLog_from_list = row.talk.split("&")
            }
            if (null != row.time) {
                this.qcLog_from.time_list = row.time.split("&")
            }
            if (null != row.twords) {
                this.qcLog_from.qcLog_from_twords_list = row.twords.split("&")
            }
            if (null != row.fwords) {
                this.qcLog_from.qcLog_from_fwords_list = row.fwords.split("&")
            }
            if (null != row.fdangerWords) {
                this.qcLog_from.qcLog_from_fdangerWords_list = row.fdangerWords.split("&")
            }
            // console.log("  this.qcLog_from.qcLog_from_fwords_list", this.qcLog_from.qcLog_from_fwords_list)
            // console.log("  this.qcLog_from.qcLog_from_fdangerWords_list", this.qcLog_from.qcLog_from_fdangerWords_list)

            //  // console.log(this.qcLog_from)
            this.qcLog_flag = true;  //质检结果
        },
        // 分页导航
        handlePageChange(val) {
            this.page = val;
            this.getList();
        },
        handleSearch(value) {
            this.getList();
        },
        //展示and查找
        getList() {
            let params = { ...this.currentForm };
            params.size = this.size
            params.page = this.page
            if (this.addTiem) {
                params.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.addTiem[0])
                params.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.addTiem[1])
            }
            axios
                // .post("http://106.14.0.130:9998/vo/xphoneS/list", params)
                .post("api/quality-uuid/list", params)
                .then((res) => {
                    this.tableData = res.data.data;
                    this.pageTotal = res.data.total;
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        //添加弹窗
        open() {
            this.form = [];
            this.addVisible = true;
        },

        //编辑弹窗
        handleClick(row) {
            this.editVisible = true;
            this.from = {};
            this.form.appid = row.row.appid;
            this.form.twords = row.row.twords;
            this.form.fwords = row.row.fwords;
        },


        //刷新按钮
        reset() {
            this.currentForm = {};
            let rootDate = new Date().toLocaleDateString()
            this.addTiem = [new Date(rootDate + " 00:00:00"), new Date(rootDate + " 23:59:59")]
            this.getList();
        },
    },
};
</script>

<style scoped lang="scss">
.quality-right-for {
    display: flex;
    flex-wrap: wrap;
    line-height: 20px;
    /*display: grid;*/
    /*grid-template-columns: auto auto auto;*/
    /*grid-template-rows: auto auto auto;*/
    /*grid-gap: 10px;*/
}

.appPage {
    height: 100%;
}

::v-deep .appPageMain {
    height: 100%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12);
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-direction: column;


    .search {
        .el-select {
            width: 100%;
        }

        // height: 70px;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        // overflow: auto;


        .left {
            width: 700px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .leftItem {
                width: 200px;
                height: 28px;
                display: flex;
                align-items: center;
                margin-bottom: 10px;

                .el-input,
                .el-select,
                .el-date-editor {
                    // width: 120px;
                    flex: 1;
                }

                .el-input__inner {
                    border-radius: 25px;
                    border: 1px solid #666;
                }

                .lab_tit {
                    font-size: 12px;
                    // font-weight: bold;
                    white-space: nowrap;
                    width: 50px !important;
                    text-align: right;
                    margin-right: 3px;
                    margin-left: 8px;
                }
            }
        }

        // .right {
        //     text-align: right;
        //     width: 30%;

        //     .el-button {
        //         margin-bottom: 4px;
        //     }

        // }
    }


    .mainTable {
        flex: 1;
        display: flex;
        flex-direction: column;

        .table {
            flex: 1;

            // .el-table__row {
            //     td {
            //         padding: 4px 0;
            //     }
            // }
        }

        .mainTableFY {
            height: 40px;
            text-align: right;
            margin-top: 4px;
        }
    }
}

::v-deep .titleBox {
    height: 40px;

    .el-tabs__item {
        font-size: 24px;
        font-weight: bold;
        color: #333333 !important;
    }
}

.quality-right-for>div {
    margin: 0px 5px;
}

.quality-for {
    display: flex;
    align-items: center;
    /*float: right;*/
    flex-direction: row-reverse;
}

.quality .i {
    width: 30px;
    height: 30px;
    color: #409EFF;
}

.quality-data {
    border-radius: 30px;
    margin: 2px;
    background-color: #e5f2ff;
    padding: 8px 12px;
    box-sizing: border-box;
    /*width: 100%;*/
    margin: 10px 0;
}

.quality {
    display: flex;
}

.quality-left {
    overflow-y: scroll;
    height: 600px;
    width: 60%;
    /*height: 100%;*/
}

.quality-right {
    flex: 1;
    height: 100%;
}

.tit {
    font-weight: normal;
    font-size: 36px;
    color: #333333;
}

// .search {
//     display: flex;
//     justify-content: space-between;
//     margin-top: 20px;
// }


// .left {
//     display: flex;
//     align-items: center;
// }

// .left-wrap {
//     display: flex;
//     align-items: center;
// }

.item {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

// .left span {
//     text-align: center;
//     width: 100px;
// }

// .searchBtn {
//     margin-left: 30px;
// }

// .right {
//     display: flex;
//     justify-content: flex-end;
// }

/* .left {
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;
} */
// .left {
//     display: flex;
//     align-items: center;
// }

// .item {
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .left span {
//     text-align: center;
//     width: 100px;
// }

.reset {
    width: 80px;
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add {
    margin-left: 15px;
}

.container {
    // margin-top: 20px;
}

/* /deep/.el-form-item__label{
      font-size: 13px  !important;
    } */
/*div /deep/ .quality{*/
/*width: 60%;*/
/*}*/
</style>
