<template>
  <div class="appPage">
    <!--    <div class="crumbs">-->
    <!--      <el-breadcrumb separator="/">-->
    <!--        <el-breadcrumb-item>-->
    <!--          基础表格-->
    <!--        </el-breadcrumb-item>-->
    <!--      </el-breadcrumb>-->
    <!--    </div>-->
    <!-- <h3 class="tit">代理充值记录</h3> -->
    <div class="appPageMain">
      <el-tabs type="card" class="titleBox">
        <el-tab-pane label="代理充值记录App端"></el-tab-pane>
      </el-tabs>
      <div class="search">
        <div class="right">
          <el-button
            type="primary"
            round
            icon="el-icon-search"
            @click="screenDrawerShow = true"
            size="mini"
            >筛选
          </el-button>
          <el-button
            round
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            >重置</el-button
          >
          <el-button type="primary" @click="excel()" round size="mini"
            >导出</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getList2"
            round
            size="mini"
            v-if="realname == 'admin'"
            >{{ adminSum_Z }}
          </el-button>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="getList3"
            round
            size="mini"
            v-if="realname == 'admin'"
            >{{ adminSum_F }}
          </el-button>
        </div>
      </div>

      <div class="container mainTable">
        <hr />
        <div class="table">
          <el-row>
            <el-col :span="24"
              ><div
                class="appListBox"
                v-for="(item, index) in tableData"
                :key="index"
                @click="openDetails(item)"
              >
                <div class="justified mg-b6">
                  <div style="font-weight: bold">
                    代理编号：{{ item.userId }}
                  </div>
                  <div
                    :style="{
                      color: item.status == 1 ? '#409eff' : '#666',
                    }"
                  >
                    {{ item.status == 1 ? "已查看" : "未查看" }}
                  </div>
                </div>
                <div class="justified mg-b6" style="font-weight: bold">
                  <div>充值前余额：{{ item.oldMoney }}</div>
                  <div>充值后余额：{{ item.money }}</div>
                </div>
                <div class="justified mg-b6">
                  <div style="font-weight: bold">
                    充值类型：{{ getRechargeType(item.type) }}
                  </div>
                  <div>到账账号：{{ getCompanyType(item.companyType) }}</div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle" style="width: 90px">代理名称:</div>
                  <div class="lcontent">
                    {{ item.realname }}
                  </div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle">时间:</div>
                  <div class="lcontent">
                    {{ item.createTime }}
                  </div>
                </div>
                <div class="titleLine mg-b6">
                  <div class="ltitle">备注:</div>
                  <div class="lcontent">
                    {{ item.remarks }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="mainTableFY">
            <el-pagination
              gination
              background
              layout="total, prev, pager, next,sizes"
              :current-page="pageIndex"
              :page-size="pageSize"
              :pager-count="3"
              :page-sizes="[10, 20, 50, 100, 200]"
              :total="pageTotal"
              @size-change="handleSizeChange"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 双击 弹出框 -->
    <el-dialog title="开票" id="ticket" v-model="userLogTicketFlag" width="80%">
      <el-form
        ref="dataAddForm"
        :model="form_ticket"
        :rules="editRules"
        label-width="100px"
      >
        <el-row>
          <el-form-item label="代理编号" prop="userId">
            <el-input
              v-model="form_ticket.userId"
              style="width: 110%"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="代理名称" prop="realname">
            <el-input
              v-model="form_ticket.realname"
              style="width: 150%"
              disabled
            ></el-input>
          </el-form-item>
          <el-col>
            <el-row>
              <el-col>
                <el-form-item label="日志充值时间" prop="createTime">
                  <el-input
                    v-model="form_ticket.createTime"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-form-item label="充值后金额" prop="oldMoney">
                <el-input
                  v-model="form_ticket.oldMoney"
                  style="width: 100%"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="充值前金额" prop="money">
                <el-input
                  v-model="form_ticket.money"
                  style="width: 100%"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item label="充值金额" prop="addMoney">
                <el-input
                  v-model="form_ticket.addMoney"
                  style="width: 100%"
                  disabled
                ></el-input>
              </el-form-item>
            </el-row>
          </el-col>
          <el-form-item label="充值备注" prop="remarks">
            <el-input
              v-model="form_ticket.remarks"
              type="textarea"
              style="width: 150%"
              disabled
            ></el-input>
          </el-form-item>
        </el-row>
        <br />
        <span style="color: #98fd98; font-size: 20px"
          >-----------------------------------------------------------------------------开票信息-----------------------------------------------------------------------------</span
        >
        <br />
        <br /><br />
        <el-row style="display: flex; align-items: center; margin-bottom: 40px">
          <h3>新增开票记录：</h3>
          <el-row>
            <el-col>
              <el-form-item
                label="开票名称"
                prop="ticket_money"
                style="margin-bottom: 0px"
              >
                <el-input
                  v-model="form_ticket.name"
                  style="width: 100%"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="税号"
                prop="remark"
                style="margin-bottom: 0px"
              >
                <el-input
                  v-model="form_ticket.dutyNum"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item
                label="充值金额"
                prop="ticket_money"
                style="margin-bottom: 0px"
              >
                <el-input
                  v-model="form_ticket.ticket_money"
                  style="width: 100%"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="充值备注"
                prop="remark"
                style="margin-bottom: 0px"
              >
                <el-input
                  v-model="form_ticket.remark"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button
            @click="userLogTicketFlag = false"
            style="margin-left: 30px"
            >取 消</el-button
          >
          <el-button type="primary" @click="ticket_add()">确 定</el-button>
        </el-row>

        <el-table
          :data="tableData_ticket"
          style="width: 80%; text-align: center"
          height="80%"
          :row-class-name="tableRowClassName"
          @cell-dblclick="ellDblclick"
        >
          <el-table-column
            property="name"
            label="名称"
            width="150"
          ></el-table-column>
          <el-table-column
            property="dutyNum"
            label="税号"
            width="200"
          ></el-table-column>
          <el-table-column
            property="money"
            label="金额"
            width="150"
          ></el-table-column>
          <el-table-column
            property="createTime"
            label="开票时间"
            width="150"
          ></el-table-column>
          <el-table-column
            property="remark"
            label="开票备注信息"
          ></el-table-column>
        </el-table>
      </el-form>
      <template #footer>
        <!--<span class="dialog-footer">-->
        <!--<el-button @click="userLogTicketFlag = false">取 消</el-button>-->
        <!--<el-button type="primary" @click="editSubmit()">确 定</el-button>-->
        <!--</span>-->
      </template>
    </el-dialog>

    <!-- 详情和操作 -->
    <el-drawer
      title="详情&操作"
      v-model="drawerShow"
      direction="rtl"
      size="100%"
    >
      <el-row>
        <el-col :span="24"
          ><div class="appListBox">
            <div class="justified mg-b6">
              <div style="font-weight: bold">
                代理编号：{{ currentRow.userId }}
              </div>
              <div
                :style="{
                  color: currentRow.status == 1 ? '#409eff' : '#666',
                }"
              >
                {{ currentRow.status == 1 ? "已查看" : "未查看" }}
              </div>
            </div>
            <div class="justified mg-b6" style="font-weight: bold">
              <div>充值前余额：{{ currentRow.oldMoney }}</div>
              <div>充值后余额：{{ currentRow.money }}</div>
            </div>
            <div class="justified mg-b6">
              <div style="font-weight: bold">
                充值类型：{{ getRechargeType(currentRow.type) }}
              </div>
              <div>到账账号：{{ getCompanyType(currentRow.companyType) }}</div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle" style="width: 90px">代理名称:</div>
              <div class="lcontent">
                {{ currentRow.realname }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">时间:</div>
              <div class="lcontent">
                {{ currentRow.createTime }}
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">附件:</div>
              <div class="lcontent">
                <el-image
                  v-if="currentRow.img"
                  style="width: 100px; height: 100px"
                  :src="currentRow.img"
                  :preview-src-list="[currentRow.img]"
                >
                  <div
                    slot="error"
                    class="image-slot"
                    style="text-align: center; line-height: 100px"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <p v-else>暂无附件</p>
              </div>
            </div>
            <div class="titleLine mg-b6">
              <div class="ltitle">备注:</div>
              <div class="lcontent">
                {{ currentRow.remarks }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div style="padding: 10px">
        <el-button
          type="primary"
          @click="unsubscribe(currentRow)"
          size="mini"
          v-if="realname == 'admin'"
          >财务查看确认</el-button
        >
      </div>
    </el-drawer>

    <!-- 筛选过滤 -->
    <el-drawer
      title="筛选数据"
      v-model="screenDrawerShow"
      direction="ltr"
      size="100%"
    >
      <el-row>
        <el-col :span="24" class="appPageMain">
          <div class="search">
            <div class="left">
              <div class="leftItem">
                <span class="lab_tit">备注</span>
                <el-input
                  v-model="form.remarks"
                  placeholder="请输入备注"
                  size="mini"
                ></el-input>
              </div>
              <div class="leftItem" v-if="realname == 'admin'">
                <span class="lab_tit">代理编号</span>
                <el-input
                  v-model="form.id"
                  placeholder="请输入代理编号"
                  size="mini"
                ></el-input>
              </div>

              <div class="leftItem" v-if="realname == 'admin'">
                <span class="lab_tit">确认状态</span>
                <el-select
                  v-model="form.status"
                  placeholder="请选择"
                  size="mini"
                  v-if="realname == 'admin'"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- 充值类型 -->
              <!-- RechargeOptions -->
              <div class="leftItem" v-if="realname == 'admin'">
                <span class="lab_tit">充值类型</span>
                <el-select
                  v-model="form.type"
                  placeholder="请选择"
                  size="mini"
                  v-if="realname == 'admin'"
                >
                  <el-option
                    v-for="item in RechargeOptions"
                    :key="item.status"
                    :label="item.label"
                    :value="item.status"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="leftItem" style="width: 263px">
                <span class="lab_tit">时间</span>
                <!-- <el-date-picker v-model="createdTime" type="datetimerange" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" style="width: 70%" 
                            :picker-options="pickerOptions">
                        </el-date-picker> -->
                <el-date-picker
                  v-model="createdTime"
                  type="datetimerange"
                  range-separator="-"
                  size="mini"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeTime"
                  :disabled-date="publishDateAfter"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <hr />
      <div style="padding: 10px">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="handleSearch"
          round
          size="mini"
          >查找</el-button
        >
        <el-button
          round
          size="mini"
          type="primary"
          icon="el-icon-refresh"
          @click="reset()"
          >重置</el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "enterpriseRecharge",
  data() {
    return {
      screenDrawerShow: false,
      currentRow: {},
      drawerShow: false,
      pickerOneDate: "",
      pickerOptions: {
        // 获取选择的第一个时间
        onPick: ({ maxDate, minDate }) => {
          this.pickerOneDate = minDate.getTime();
          if (maxDate) {
            this.pickerOneDate = "";
          }
        },
        disabledDate: (time) => {
          //选择了第一个时间的情况下根据第一个时间设置可选范围
          //未选择第一个时间的情况下可选范围只能选择过去的时间
          // const day = 30 * 24 * 3600 * 1000 // 90天
          // if (this.pickerOneDate) {
          //     let maxTime = this.pickerOneDate + day
          //     let minTime = this.pickerOneDate - day
          //     return time.getTime() > maxTime || time.getTime() < minTime || time.getTime() > Date.now()
          // } else {
          //     return time.getTime() > Date.now() || time.getTime() < Date.now() - day * 3
          // }
          // return time.getTime() > Date.now();
        },
      },
      userLogTicketFlag: false, //日志开票记录
      form: {},
      form_ticket: {}, //回显开票信息
      tableData: [],
      tableData_ticket: [],
      pageTotal: 0, //总共有多少数据
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示数量
      page: 1,
      size: 10,
      sta: null,
      end: null,
      createdTime: null,
      adminSum_Z: "admin充值",
      adminSum_F: "admin扣款",
      listType: 1,
      realname: "",
      status: null,
      //  0 暂无 1 充值  2 返费 3 赠送 4 罚款 5 扣号码费 6 退费  7转移
      RechargeOptions: [
        {
          status: 0,
          label: "暂无",
        },
        {
          status: 1,
          label: "充值",
        },
        {
          status: 2,
          label: "返费",
        },
        {
          status: 3,
          label: "赠送",
        },
        {
          status: 4,
          label: "罚款",
        },
        {
          status: 5,
          label: "扣号码费",
        },
        {
          status: 6,
          label: "退费",
        },
        {
          status: 7,
          label: "转移",
        },
      ],
      options: [
        {
          status: 0,
          label: "未看",
        },
        {
          status: 1,
          label: "确认",
        },
      ],
      // options: [{
      //   value: '选项1',
      //   label: '充值',
      //   disabled: true
      // }, {
      //   value: '选项2',
      //   label: '划拨'
      // }],
      // value: ''
    };
  },
  created() {
    let rootDate = new Date().toLocaleDateString();
    this.createdTime = [
      new Date(rootDate + " 00:00:00"),
      new Date(rootDate + " 23:59:59"),
    ];
    this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
    this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
    this.realname = localStorage.getItem("realname");
    this.getList();
  },
  //切换界面就执行方法
  activated() {
    this.getList();
  },
  watch: {
    id() {},
  },
  inject: ["reload"],

  methods: {
    openDetails(item) {
      console.log("点击了", item);
      this.currentRow = item;
      this.drawerShow = true;
    },
    getRechargeType(type) {
      // 0没有 1充值  2返费 3赠送 4罚款 5扣号码费 6退费
      if (type == 0) {
        return "暂无";
      } else if (type == 1) {
        return "充值";
      } else if (type == 2) {
        return "返费";
      } else if (type == 3) {
        return "赠送";
      } else if (type == 4) {
        return "罚款";
      } else if (type == 5) {
        return "扣号码费";
      } else if (type == 6) {
        return "退费";
      } else if (type == 7) {
        return "转移";
      }
    },
    getCompanyType(type) {
      if (type == 0) {
        return "暂无";
      } else if (type == 1) {
        return "商机云";
      } else if (type == 2) {
        return "星链云";
      } else {
        return " ";
      }
    },
    //导出
    excel() {
      if (this.createdTime == null) {
        // this.sta1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        // this.end1 = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
        this.$message({
          type: "error",
          message: "请选择时间后导出",
        });
        return;
      }
      let params = {
        // sta: this.sta1,
        // end: this.end1,
        exportList: this.tableData,
      };
      // console.log(params)

      // axios.defaults.headers['Content-Type'] = 'multipart/form-data';
      axios
        // .post("http://106.14.0.130:9998/vo/call/add-white-d", params)
        .post("api/log/export-user-log", params, { responseType: "blob" })
        //     .post("api/whitePhoneD/export-whitephoneD", params,{responseType: "multipart/form-data"  })
        .then((res) => {
          this.loading = false;

          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });

          const url = window.URL.createObjectURL(res.data);

          const a = document.createElement("a");

          a.style.display = "none";

          let str = res.headers["content-disposition"];

          a.download = decodeURI(str).substr(decodeURI(str).indexOf("=") + 1);

          a.href = url;

          a.click();
        });
      this.getList();
    },
    changeTime(val) {
      if (new Date(val[1]) - new Date(val[0]) > 1 * 24 * 60 * 60 * 1000 * 30) {
        this.$message.error("选择时间范围不能大于30天");
        this.createdTime = "";
      }
    },
    publishDateAfter(time) {
      const day = 90 * 24 * 3600 * 1000; // 90天
      if (this.pickerOneDate) {
        let maxTime = this.pickerOneDate + day;
        let minTime = this.pickerOneDate - day;
        return (
          time.getTime() > maxTime ||
          time.getTime() < minTime ||
          time.getTime() > Date.now()
        );
      } else {
        return (
          time.getTime() > Date.now() || time.getTime() < Date.now() - day * 3
        );
      }
    },
    //开票提交确定
    ticket_add() {
      let params = {
        userLogId: this.form_ticket.id,
        money: this.form_ticket.ticket_money,
        remark: this.form_ticket.remark,
      };
      //  // console.log(params);
      axios
        .post("api/userLogTicket/add", params)
        .then((res) => {
          if (res.data.statusCode == "00000") {
            this.$message.success(res.data.message);
            this.userLogTicketFlag = false; //弹出框
            this.getList();
          } else {
            this.$message.error(res.data.message);
            this.userLogTicketFlag = false;
            this.getList;
          }
        })
        .catch((err) => {
          this.$message.error(err.data.message);
        });
    },
    //双击
    ellDblclick(row, column, cell, event) {
      // this.userLogTicketFlag = true  //弹出框
      // this.rechargeRecordList.set(row.id, row)
      // this.$message.success(
      //     '已选择id' + row.id
      // );
      //  // console.log(row);
      this.form_ticket = row;
      //  // console.log(this.form_ticket);

      let params = {
        userLogId: row.id,
      };
      axios
        .post("api/userLogTicket/findOne", params)
        .then((res) => {
          // console.log(res.data.data);
          this.tableData_ticket = res.data.data; //代理编号

          this.userLogTicketFlag = true; //弹出框
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 搜索
    handleSearch() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      }
      this.getList();
      this.screenDrawerShow = false;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 分页导航
    handlePageChange(val) {
      this.page = val;
      if (2 === this.listType) {
        this.getList2();
      } else if (3 === this.listType) {
        this.getList3();
      } else {
        this.getList();
      }
    },

    //展示and搜索
    getList() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      } else {
        if (this.realname != "admin") {
          this.$message.warning("请选择时间!");
          return false;
        }
      }
      this.listType = 1;
      let params = {
        id: this.form.id,
        status: this.form.status,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        remarks: this.form.remarks,
        type: this.form.type,
      };

      if (!this.createdTime) {
        delete params.sta;
        delete params.end;
      }
      //  // console.log("查询条件1", params);
      // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
      axios
        .post("api/log/userlist", params)
        .then((res) => {
          // console.log(res.data.data)
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data;
            this.pageTotal = res.data.total;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }, //展示and搜索 只admin充值
    getList2() {
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      } else {
        if (this.realname != "admin") {
          this.$message.warning("请选择时间!");
          return false;
        }
      }
      this.listType = 2;

      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        remarks: this.form.remarks,
        moneyType: 1,
      };
      // console.log("查询条件2", params);

      // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
      axios
        .post("api/log/sum-userlog", params)
        .then((res) => {
          // console.log(res.data.data)
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data.list;
            this.pageTotal = res.data.total;
            this.adminSum_Z = res.data.data.sum;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getList3() {
      this.listType = 3;
      if (this.createdTime) {
        this.sta = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[0]);
        this.end = this.getYMDHMS("YYYY-mm-dd HH:MM:SS", this.createdTime[1]);
      } else {
        if (this.realname != "admin") {
          this.$message.warning("请选择时间!");
          return false;
        }
      }
      let params = {
        id: this.form.id,
        page: this.page,
        size: this.size,
        sta: this.sta,
        end: this.end,
        remarks: this.form.remarks,
      };
      // console.log("查询条件3", params);

      // axios.post("http://106.14.0.130:9998/vo/log/userlist",params)
      axios
        .post("api/log/sum-userlog", params)
        .then((res) => {
          // console.log(res.data.data)
          if (res.data.success == false) {
            this.$router.push("/403");
          } else {
            this.tableData = res.data.data.list;
            this.pageTotal = res.data.total;
            this.adminSum_F = res.data.data.sum;
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    //确认按钮
    unsubscribe(row) {
      // console.log(row)
      let Tstatis = row.status;
      if (0 === Tstatis) {
        Tstatis = 1;
      } else if (1 === Tstatis) {
        Tstatis = 0;
      } else {
        Tstatis = null;
      }
      let params = {
        id: row.id,
        status: Tstatis,
      };
      axios
        // .post("http://106.14.0.130:9998/vo/whitePhoneD/update", params)
        .post("api/log/userlog-update", params)
        .then((res) => {
          this.$message.success(res.data.message);
        })
        .catch((err) => {});
      // this.reload();
      if (2 === this.listType) {
        this.getList2();
      } else if (3 === this.listType) {
        this.getList3();
      } else {
        this.getList();
      }
    },
    //重置
    reset() {
      this.form.id = null;
      this.form.remarks = null;
      if (this.realname == "admin") {
        this.createdTime = null;
      }
      this.form.createdTime = null;
      this.adminSum_Z = "admin充值";
      this.adminSum_F = "admin扣款";
      this.listType = 1;
      // this.page = 1
      // this.getList();
      this.reload();
    },
    //搜索,再次调用getList方法就是展示
    handleSearch() {
      if (this.form.id == "") {
        this.form.id = null;
      }
      this.getList();
      this.screenDrawerShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.mg-b6 {
  margin-bottom: 6px;
}
.appListBox {
  display: flex;
  flex-flow: column;
  padding: 10px;
  border-bottom: 1px solid green;
  .justified {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .titleLine {
    display: flex;
    .ltitle {
      font-weight: bold;
      width: 55px;
      text-align: justify;
    }
    .lcontent {
      flex: 1;
      text-align: justify;
      word-wrap: break-word;
      word-break: break-all;
    }
  }
}
.appPage {
  //   height: 100%;
}

::v-deep .appPageMain {
  overflow-y: auto;
  //   height: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;

  .search {
    // height: 70px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    // overflow: auto;

    .left {
      // width: 68%;
      width: 550px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .leftItem {
        width: 176px;
        height: 28px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .el-input,
        .el-select,
        .el-date-editor {
          // width: 120px;
          flex: 1;
        }

        .el-input__inner {
          border-radius: 25px;
          border: 1px solid #666;
        }

        .lab_tit {
          font-size: 12px;
          // font-weight: bold;
          white-space: nowrap;
          width: 50px !important;
          text-align: right;
          margin-right: 3px;
          margin-left: 8px;
        }
      }
    }

    // .right {
    //     text-align: right;
    //     width: 30%;

    //     .el-button {
    //         margin-bottom: 4px;
    //     }

    // }
  }

  .mainTable {
    flex: 1;
    display: flex;
    flex-direction: column;

    .table {
      flex: 1;

      // .el-table__row {
      //     td {
      //         padding: 4px 0;
      //     }
      // }
    }

    .mainTableFY {
      height: 40px;
      text-align: right;
      margin-top: 4px;
    }
  }
}

::v-deep .titleBox {
  height: 40px;

  .el-tabs__item {
    font-size: 14px;
    font-weight: bold;
    color: #333333 !important;
  }
}

.tit {
  font-weight: normal;
  font-size: 36px;
  color: #333333;
}

.search {
  /*display: flex;*/
  /*justify-content: space-between;*/
  margin-top: 20px;
}

// .left {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     /*flex-wrap: wrap;*/
//     margin-bottom: 20px;
// }

// .left span {
//     width: 25%;
//     margin: 10px;
// }

// .time span {
//     text-align: right;
//     margin-right: 5px;
//     width: 120px;
// }

.searchBtn {
  margin-left: 15px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

// div /deep/ .el-input {
/*width%;*/
// }

// .right {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
// }

.reset {
  width: 80px;
  height: 34px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add {
  margin-left: 15px;
}

.container {
  /* margin-top: 20px; */
}

.el-input__inner {
  width: 170%;
}

#ticket ::v-deep .el-input__inner {
  width: 99%;
  /*height: 99%;*/
}

#ticket .el-input {
  width: 30%;
  background-color: #98fd98;
}
</style>
