/**
 * company_renew
 *
 */

import request from "@/utils/request";

//
export const companyRenewAddWithRenew = (data) => {
  return request({
    method: "post",
    url: "api//company_renew/add-withRenew",
    data,
  });
};
//
export const companyRenewFindNoRenew = (data) => {
  return request({
    method: "post",
    url: "api//company_renew/find-noRenew",
    data,
  });
};
//
export const companyRenewFindOperatorsRenew = (data) => {
  return request({
    method: "post",
    url: "api//company_renew/find-operatorsRenew",
    data,
  });
};

// 查询代理问题记录
export const customerQuestionList = (data) => {
  return request({
    method: "post",
    url: "api/customer_question/list",
    data,
  });
};

// 处理代理问题
export const customerQuestionAdd = (data) => {
  return request({
    method: "post",
    url: "api/customer_question/add",
    data,
  });
};

// 修改代理问题
export const customerQuestionUpdate = (data) => {
  return request({
    method: "post",
    url: "api/customer_question/update ",
    data,
  });
};

// 修改代理问题
export const uuidMany = (data) => {
  return request({
    method: "post",
    url: "api/uuid/many-del",
    data,
  });
};
